import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces.js";
import * as geom from "js/core/utilities/geom";
import { controls, CreateColorChit } from "legacy-js/editor/ui";
import {
    WidgetPositionType,
    HorizontalAlignType,
    ContentBlockType,
    TextEditorEvent,
    PositionType, ListStyleType, DragType
} from "legacy-common/constants";
import { $, _ } from "legacy-js/vendor";
import { getStaticUrl } from "legacy-js/config";

import { AssetType } from "legacy-common/constants";

import { Convert } from "js/core/utilities/geom";
import { themeColors } from "legacy-js/react/sharedStyles";
import { ShowDialog, ShowDialogAsync, ShowWarningDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "legacy-js/react/views/AddAssets";
import BadFitDialog from "legacy-js/react/components/Dialogs/BadFitDialog";
import renderReactRoot from "legacy-js/react/renderReactRoot";

import { TextElementSelection } from "./TextEditor";
import { ElementSelection } from "../BaseElementEditor";
import { CollectionItemElementSelection } from "../CollectionElementEditor";
import { CreateMediaMenu, CreateImageFrameMenu } from "./pictureEditor";
import { createResizer } from "../EditorComponents/Resizer";
import { ImageFrameMenu } from "../EditorComponents/ImageFrameMenu";

export function BuildTextFrameOptionsMenu(view, element, label = null) {
    let $menu = view.addControl({
        id: "contentBlockOptionsMenu",
        type: controls.POPUP_BUTTON,
        icon: "font_download",
        label,
        showArrow: label != null,
        menuContents: close => {
            let $menu = $.grid();

            const hideDisabledControls = true; //element.hideDisabledControls;
            const isStyleMenuEnabled = element.isOverImage;

            if (element.parentElement.showImage) {
                $menu.append(controls.createIconDropdownMenu(this, {
                    id: "textStyleMenu",
                    label: "Style",
                    model: element.model,
                    property: "textStyle",
                    menuClass: "icon-menu",
                    enabled: isStyleMenuEnabled,
                    transitionModel: false,
                    markStylesAsDirty: true,
                    items: [{
                        value: "white_text", label: "White", image: getStaticUrl("/images/ui/textstyles/text_white.svg")
                    }, {
                        value: "white_text_with_shadow",
                        label: "White with Shadow",
                        image: getStaticUrl("/images/ui/textstyles/text_shadow.svg")
                    }, {
                        value: "dark_text", label: "Dark", image: getStaticUrl("/images/ui/textstyles/text_dark.svg")
                    }, {
                        value: "white_box",
                        label: "White Backdrop",
                        image: getStaticUrl("/images/ui/textstyles/backdrop_white.svg")
                    }, {
                        value: "transparent_light_box",
                        label: "Transparent Backdrop",
                        image: getStaticUrl("/images/ui/textstyles/backdrop_transparent.svg")
                    }, {
                        value: "transparent_dark_box",
                        label: "Dark Backdrop",
                        image: getStaticUrl("/images/ui/textstyles/backdrop_dark.svg")
                    }],
                    callback: () => {
                        ds.selection.element = element;
                    }

                }));

                const isPaddingMenuEnabled = element.textFrameBox.textStyle != null && element.textFrameBox.textStyle.contains("box");
                if (isPaddingMenuEnabled || !hideDisabledControls) {
                    $menu.append(controls.createNumericStepper(this, {
                        label: "Padding",
                        model: element.model,
                        commitOnInput: false,
                        enabled: isPaddingMenuEnabled,
                        min: 0,
                        max: 200,
                        property: "backdropPadding",
                        transitionModel: false
                    }));
                }
            }

            $menu.append(controls.createPositionPicker(this, {
                label: "Position",
                value: element.parentElement?.textPosition || element.model.textPosition,
                callback: value => {
                    view.selectionLayer.hideWidgets();
                    ds.selection.element = element;

                    element.model.textAlign = null;
                    element.model.textPosition = value;
                    element.model.userPositionX = element.model.userPositionY = element.model.userWidth = null;

                    switch (value) {
                        case PositionType.LEFT:
                        case PositionType.BOTTOM_LEFT:
                        case PositionType.TOP_LEFT:
                            element.model.textAlign = HorizontalAlignType.LEFT;
                            break;
                        case PositionType.RIGHT:
                        case PositionType.BOTTOM_RIGHT:
                        case PositionType.TOP_RIGHT:
                            element.model.textAlign = HorizontalAlignType.RIGHT;
                            break;
                        default:
                            element.model.textAlign = HorizontalAlignType.CENTER;
                    }

                    element.canvas.updateCanvasModel(true)
                        .catch(() => {
                            ShowDialogAsync(BadFitDialog, {
                                title: "Sorry, we aren't able to fit all elements into the new layout",
                            });
                        });
                }
            }));

            return $menu;
        }
    });

    return $menu;
}

const TextFrameBoxSelection = ElementSelection.extend({
    showSelectionBox: true,

    renderControls() {
        if (this.element.parentElement.canDelete) {
            this.createDeleteComponentWidget({
                action: () => {
                    ds.selection.element = null;
                    this.element.model.text = null;
                    this.element.canvas.updateCanvasModel(false);
                }
            });
        }

        this.$resizeHandle = this.$el.addEl($.div("resize-handle control horizontal"));
        this.$resizeHandle.on("mousedown", event => this.handleResizeHorizontal(event));

        if (this.element.parentElement.canDragPosition) {
            let $dragWidget = this.createDragWidget(this, {
                position: WidgetPositionType.DRAG_HANDLE
            });
            $dragWidget.on("mousedown", event => this.handleDragStart(event));
        }
    },

    _layout() {
        let selectionBounds = this.getElementSelectionBounds();

        if (this.element.parentElement.canDragResize) {
            this.$resizeHandle.show();
            this.$resizeHandle.left(selectionBounds.width - 5).top(selectionBounds.height / 2 - 5);
        } else {
            this.$resizeHandle.hide();
        }
    },

    handleResizeHorizontal(event) {
        app.isDraggingItem = true;

        let containerElement = this.element.parentElement;

        ds.selection.element = this.element;
        ds.selection.rolloverElement = this.element;

        this.selectionLayer.hideWidgets();

        let $dragSelectionBox = this.selectionLayer.$el.addEl($.div().css({
            position: "absolute",
            background: themeColors.ui_blue
        }));

        this.element.isDragResizing = true;
        let elementBounds = this.getElementSelectionBounds();

        let mouseMoveHandledAt;
        $("body").on("mousemove.drag", event => {
            event.stopPropagation();

            // Making dragging smoother
            window.requestAnimationFrame(timestamp => {
                if (mouseMoveHandledAt === timestamp) {
                    return;
                }

                mouseMoveHandledAt = timestamp;

                let dragPt = Convert.ScreenToSelectionLayerCoordinates(event.pageX, event.pageY);
                let width;

                if (containerElement.textPosition == PositionType.CENTER) {
                    width = 2 * Math.abs(dragPt.x - elementBounds.centerH) / this.canvasScale;
                } else {
                    width = Math.abs(dragPt.x - elementBounds.left) / this.canvasScale;
                }

                this.model.userWidth = width;

                if (containerElement.canRefreshElement) {
                    containerElement.refreshElement();
                    $dragSelectionBox.setBounds(new geom.Rect(dragPt.x, 0, 1, this.selectionLayer.$el.height()));
                } else {
                    containerElement.canvas.refreshCanvas()
                        .then(() => {
                            $dragSelectionBox.setBounds(new geom.Rect(dragPt.x, 0, 1, this.selectionLayer.$el.height()));
                        });
                }
            });
        });

        $("body").on("mouseup.drag", event => {
            event.stopPropagation();
            $("body").off(".drag");
            app.isDraggingItem = false;
            this.element.isDragResizing = false;
            this.selectionLayer.showWidgets();
            $dragSelectionBox.remove();

            this.element.canvas.updateCanvasModel(false).then(() => {
                ds.selection.element = this.element;
            });
        });
    },

    handleDragStart(event) {
        app.isDraggingItem = true;

        let containerElement = this.element.parentElement;

        ds.selection.element = this.element;
        ds.selection.rolloverElement = this.element;

        this.selectionLayer.hideWidgets(this.$el.find(".resize-handle,.selection-box"));

        let containerBounds = containerElement.bounds;

        if (this.element.model.userWidth == null) {
            this.element.model.userWidth = this.element.bounds.width;
        }

        let mouseMoveHandledAt;
        $("body").on("mousemove.drag", event => {
            event.stopPropagation();

            // Making dragging smoother
            window.requestAnimationFrame(timestamp => {
                if (mouseMoveHandledAt === timestamp) {
                    return;
                }

                mouseMoveHandledAt = timestamp;

                let dragPt = Convert.ScreenToElementCoordinates(containerElement.canvas, containerElement, event.pageX, event.pageY);

                let elementBounds = new geom.Rect(dragPt.x, dragPt.y, this.element.bounds.width, this.element.bounds.height);

                if (elementBounds.left < 0) {
                    elementBounds.left = 0;
                }
                if (elementBounds.top < 0) {
                    elementBounds.top = 0;
                }
                if (elementBounds.right > containerBounds.width) {
                    elementBounds.left = containerBounds.width - elementBounds.width;
                }
                if (elementBounds.bottom > containerBounds.height) {
                    elementBounds.top = containerBounds.height - elementBounds.height;
                }

                this.model.userPositionX = elementBounds.x / containerBounds.width;
                this.model.userPositionY = elementBounds.y / containerBounds.height;

                if (containerElement.canRefreshElement) {
                    containerElement.refreshElement();
                    this.layout();
                } else {
                    containerElement.canvas.refreshCanvas()
                        .then(() => {
                            this.layout();
                        });
                }
            });
        });

        $("body").on("mouseup.drag", event => {
            event.stopPropagation();
            $("body").off(".drag");
            app.isDraggingItem = false;
            this.element.isDragResizing = false;
            this.selectionLayer.showWidgets();

            this.element.canvas.updateCanvasModel(false).then(() => {
                ds.selection.element = this.element;
            });
        });
    }
});

export const editors = {
    TextFrameBoxSelection,
};
