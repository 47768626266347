import { app } from "js/namespaces";
import { controls, Dialog, Tab } from "legacy-js/editor/ui";
import { _, $ } from "legacy-js/vendor";
import { getStaticUrl } from "legacy-js/config";
import { FeatureType } from "legacy-common/features";

import { ElementOptionsMenu, ElementSelection } from "../BaseElementEditor";
import { EditWordCloudDialog } from "legacy-js/react/components/Dialogs/WordCloud/EditWordCloudDialog";
import { GenerateWordCloudDialog } from "legacy-js/react/components/Dialogs/WordCloud/GenerateWordCloudDialog";
import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { themeColors } from "legacy-js/react/sharedStyles";
import AppController from "legacy-js/core/AppController";
import { wordStringsToWordList } from "legacy-js/react/components/Dialogs/WordCloud/common";

function updateWordCloud(element) {
    // Setting shouldRegen and letting the element to recalc itself
    element.shouldRegen = true;
    element.canvas.refreshCanvas();
}

const WordCloudElementSelection = ElementSelection.extend({
    setupElement() {
    },

    renderControls: function() {
        const designerBotDisabled = app.user.features.isFeatureEnabled(FeatureType.PROHIBIT_GENERATIVE_AI, AppController.workspaceId);
        const designerBotAccessible = app.user.features.isFeatureEnabled(FeatureType.DESIGNER_BOT, AppController.workspaceId);
        if (!designerBotDisabled && designerBotAccessible) {
            this.addControl({
                type: controls.BUTTON,
                color: themeColors.aiColor,
                icon: getStaticUrl("/images/bai-bot/white-bot-icon.svg"),
                callback: () => ShowDialog(GenerateWordCloudDialog,
                    {
                        element: this.element,
                        onSuccess: aiGeneratedWords => {
                            this.element.wordList = wordStringsToWordList(aiGeneratedWords);
                            updateWordCloud(this.element);
                        }
                    })
            });
        }

        this.addControl({ type: controls.BUTTON,
            label: "Set Words...",
            callback: () => ShowDialog(EditWordCloudDialog, { element: this.element }) });

        this.addControl({
            type: controls.BUTTON,
            label: "Randomize",
            icon: "loop",
            callback: () => {
                updateWordCloud(this.element);
            }
        });
    },

});

const WordCloudElementOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Layout",
            items: [{ label: "Straight", value: "straight" }, {
                label: "Right Angles",
                value: "right_angles"
            }, { label: "Rotated", value: "rotated" }],
            value: this.element.model.style,
            callback: value => {
                this.element.model.style = value;
                updateWordCloud(this.element);
            }
        });

        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Sort",
            items: [{ label: "Size", value: "size" }, { label: "Random", value: "random" }],
            value: this.element.model.sort,
            callback: value => {
                this.element.model.sort = value;
                updateWordCloud(this.element);
            }
        });

        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Font",
            value: this.element.fontType,
            items: [{
                value: "block", label: "Block Font"
            }, {
                value: "theme", label: "Use Theme Font"
            }],
            callback: value => {
                this.element.model.font = value;
                updateWordCloud(this.element);
            }
        });

        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Scale",
            items: [{
                value: 10, label: "Tiny"
            }, {
                value: 15, label: "Small"
            }, {
                value: 20, label: "Medium"
            }, {
                value: 25, label: "Large"
            }],
            value: this.element.model.scale,
            callback: value => {
                this.element.model.scale = value;
                updateWordCloud(this.element);
            }
        });

        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Spacing",
            items: [{
                value: 1, label: "Tight"
            }, {
                value: 5, label: "Medium"
            }, {
                value: 8, label: "Wide"
            }],
            value: this.element.spacing,
            callback: value => {
                this.element.model.spacing = value;
                updateWordCloud(this.element);
            }
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Invert Colors",
            property: "invertColors",
            enabled: this.element.canvas.getBackgroundColor().isColor == false
        });
    }
});

export const editors = {
    WordCloudElementSelection,
    WordCloudElementOptionsMenu,
};
