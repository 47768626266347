import { _ } from "legacy-js/vendor";
import { FormatType, AuthoringBlockType, TextStyleType } from "legacy-common/constants";
import { formatter } from "js/core/utilities/formatter";
import { getValidChartDataFromCsv } from "js/core/utilities/xlsx";

import { CollectionElement } from "../../base/CollectionElement";
import { detectCompareContent } from "js/core/services/sharedModelManager";

export default class CompareValuesCollection extends CollectionElement {
    get defaultItemData() {
        return {
            value: 50
        };
    }

    get maxItemCount() {
        return 10;
    }

    get minValue() {
        return this.model.minValue ?? 0;
    }

    get maxValue() {
        return this.model.maxValue ?? 100;
    }

    get format() {
        return this.model.format ?? FormatType.PERCENT;
    }

    get _formatOptions() {
        return this.model.formatOptions ?? formatter.getDefaultFormatOptions();
    }

    formatValue(value) {
        let displayValue = value;
        if (this.format == FormatType.PERCENT) {
            displayValue = value / 100;
        } else {
            displayValue = value;
        }

        return formatter.formatValue(displayValue, this.format, this.formatOptions);
    }

    _build() {
        this.buildItems();
        this.model.minValue = Math.min(this.minValue, _.minBy(this.itemCollection, "value").value);
        this.model.maxValue = Math.max(this.maxValue, _.maxBy(this.itemCollection, "value").value);
    }

    autoFitRange() {
        this.model.minValue = 0;
        this.model.maxValue = _.maxBy(this.itemCollection, "value").value;
        this.canvas.updateCanvasModel(true);
    }

    _exportToSharedModel() {
        const textContent = this.itemElements.map(
            itemElement => itemElement.text._exportToSharedModel().textContent
        ).flat();

        const compareContent = this.itemElements.map((item, i) => ({
            value: item.model.value, text: textContent[i],
            format: this.model.format, emphasized: !!item.model.hilited
        }));

        return { textContent, compareContent };
    }

    _importFromSharedModel(model) {
        const compareContent = detectCompareContent(model);
        if (!compareContent?.length) return;

        const items = compareContent.map(({ text, value, emphasized }) => ({
            value: value ?? Math.round(Math.random() * (80 - 40) + 40),
            text: {
                blocks: [{
                    html: text.mainText.text,
                    type: AuthoringBlockType.TEXT,
                    textStyle: TextStyleType.BODY,
                }]
            },
            hilited: !!emphasized
        }));

        items.splice(this.maxItemCount);
        return { items, format: compareContent[0].format ?? FormatType.PERCENT };
    }
}
