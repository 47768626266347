import React, { Component } from "react";
import styled from "styled-components";
import { Gap10, Gap20 } from "legacy-js/react/components/Gap";
import { Slider } from "@material-ui/core";
import { Fragment } from "react";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledInput = styled.input`
  border: solid 1px #ccc;
  background: white;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  outline: none;
  position: relative;
  top: -1px;

  /* Chrome, Safari, Edge, Opera */

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
`;

export class InputSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    onChange = () => {
        window.requestAnimationFrame(timestamp => {
            const { value } = this.state;
            const { onChange } = this.props;

            let doChange = false;
            let result = parseFloat(value);
            doChange = !isNaN(result);

            if (doChange) {
                onChange(result);
            }
        });
    }

    onChangeCommitted = () => {
        const { value } = this.state;
        const { onChangeCommitted, onEndDrag } = this.props;

        this.setState({ isDragging: false });
        onEndDrag && onEndDrag();

        let result = parseFloat(value);

        if (!isNaN(result)) {
            onChangeCommitted(result);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value != this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    handleCommitValue = () => {
        this.onChangeCommitted();
    }

    handleChangeInput = event => {
        const { inputFormat } = this.props;

        event.stopPropagation();

        let value = event.target.value;
        if (inputFormat == "percentage") {
            value = value / 100;
        }

        this.setState({ value });
    }

    handleChangeSlider = (event, value) => {
        if (!this.state.isDragging) {
            this.setState({ isDragging: true });
            this.props.onStartDrag && this.props.onStartDrag();
        }

        this.setState({ value }, () => this.onChange());
    }

    handleFocus = event => {
        event.target.select();
    }

    handleKeyDown = event => {
        event.stopPropagation();
    }

    handleKeyPress = event => {
        if (event.which == 13) {
            this.handleCommitValue();
        }
    }

    render() {
        const { className, styles, showBorder = true, inputFormat = "number", inputMin = 0, inputMax = 99, sliderMin = 0, sliderMax = 99, step = 1, disabled } = this.props;
        const { value } = this.state;

        let formattedValue = value;

        if (inputFormat == "percentage") {
            formattedValue = Math.round(formattedValue * 100);
        }

        return (
            <Container
                className={className}
                style={{
                    flexDirection: "row",
                    height: 34,
                    ...styles
                }}
                showBorder={showBorder}
            >
                <StyledInput
                    type="number"
                    min={inputMin}
                    max={inputMax}
                    value={formattedValue}
                    style={{
                        width: 60,
                        height: 25,
                    }}
                    onFocus={this.handleFocus}
                    onBlur={this.handleCommitValue}
                    onChange={this.handleChangeInput}
                    onKeyDown={this.handleKeyDown}
                    onKeyPress={this.handleKeyPress}
                    disabled={disabled}
                />
                {inputFormat == "percentage" && <div style={{ marginLeft: 5 }}>%</div>}
                <Gap10/>
                <Slider
                    min={sliderMin}
                    max={sliderMax}
                    step={step}
                    value={value}
                    onChange={this.handleChangeSlider}
                    onChangeCommitted={this.handleCommitValue}
                    disabled={disabled}
                    style={{
                        width: 150
                    }}
                />
            </Container>
        );
    }
}
