import React, { Component } from "react";
import styled from "styled-components";
import { Button, Icon, Slider, Switch } from "@material-ui/core";

import { ds } from "js/core/models/dataService";
import { $, SVG, Backbone, _ } from "legacy-js/vendor";
import { app } from "js/namespaces.js";
import { controls } from "legacy-js/editor/ui";
import * as geom from "js/core/utilities/geom";
import { PaletteColorType, AssetType, ASSET_FILETYPE } from "legacy-common/constants";
import getLogger, { LogGroup } from "js/core/logger";
import { AddAssetsContainer } from "legacy-js/react/views/AddAssets";
import { BeautifulDialog, ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { renderReactRootJSX } from "legacy-js/react/renderReactRoot";
import { getStaticUrl } from "legacy-js/config";
import { FlexSpacer, Gap10, Gap20 } from "legacy-js/react/components/Gap";
import { renderAdjustmentFilter } from "js/core/utilities/svgFilters";
import { themeColors } from "legacy-js/react/sharedStyles";
import { LabeledContainer } from "legacy-js/react/components/LabeledContainer";

import { ElementEditor } from "../BaseElementEditor";
import { MultiInputSlider } from "../EditorComponents/MultiInputSlider";
import { LabelContainer, SmallLabel, StyledIconButton } from "../EditorComponents/Controls";
import { trackActivity, uuid } from "js/core/utilities/utilities";
import { mergeMediaElementModelDefaults } from "legacy-common/assetUtils";
import Api from "js/core/api";
import ProgressDialog from "legacy-js/react/components/Dialogs/ProgressDialog";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";

const logger = getLogger(LogGroup.ELEMENTS);

const PictureSelection = ElementEditor.extend({
    type: "picture",

    getTitle: function() {
        return false;
    },

    getOffset: function() {
        if (this.$el.height() < 175) {
            return 10;
        } else {
            return -40;
        }
    },

    renderControls: function() {
        // only show this warning if an image has been added
        if (this.element.assetId && this.element.model.scale > 1.5) {
            this.showWarning("Warning", "Image is scaled beyond 1.5X and may appear pixelated");
        }

        CreateMediaMenu(this, this.element.parentElement, { allowAdjust: false });

        if (this.element.assetId) {
            let adjustScale = scale => {
                let minScale = this.element.minScale(this.element.elementBounds);
                let maxScale = this.element.maxScale(this.element.elementBounds);

                return (scale - minScale) / (maxScale - minScale) * 100;
            };

            if (this.element.options.allowImageScaling !== false) {
                this.$scaleSlider = this.addControl({
                    type: controls.SLIDER,
                    value: adjustScale(this.element.getScale(this.element.elementBounds.size)),
                    callbackRequiresLayouterReady: true,
                    callback: this.scaleImage,
                    onEnd: async () => {
                        if (this.element.canvas.layouter.isGenerating) {
                            await this.element.canvas.layouter.generationPromise;
                        }
                        this.element.canvas.saveCanvasModel();
                        this.render();
                        this.layout();
                    },
                    min: 0,
                    max: 100,
                    step: 1,
                });

                this.addControl({
                    type: controls.BUTTON,
                    icon: "settings_overscan",
                    callback: () => {
                        this.reset();
                    }
                });
            }

            if (this.element.options.allowOpacity) {
                this.addControl({
                    type: controls.SLIDER,
                    label: "Opacity",
                    property: "mediaOpacity",
                    min: .1,
                    max: 2,
                    step: 0.01
                });
            }

            if (this.type === "video" || this.type === "stock_video") {
                this.addControl({
                    type: controls.POPUP_BUTTON,
                    icon: "video_settings",
                    showArrow: false,
                    ...BuildVideoSettingsMenu(this.element),
                });
            }

            if (this.type === "picture") {
                this.addControl({
                    type: controls.BUTTON,
                    icon: "palette",
                    callback: () => {
                        ShowDialog(FilterDialog, { element: this.element });
                    }
                });
            }

            // if ((this.type == AssetType.LOGO || this.element.hasAlpha || this.element.isScaledBelowFill) && this.element.allowBackdrop) {
            if ((this.element.hasAlpha || (this.element.isScaledBelowFill && !this.element.hasSolidBackground)) && this.element.allowBackdrop) {
                this.addControl({
                    type: controls.COLOR_PALETTE_PICKER,
                    property: "backgroundColor",
                    showBackgroundColors: true,
                    showNone: true
                });
            }

            this.addControl({
                type: controls.BUTTON,
                icon: "close",
                callback: () => {
                    ds.selection.element = null;
                }
            });

            this.$dragImage = $.div("image_drag");
            this.$el.prepend(this.$dragImage);

            this.$dragImage.on("mousedown", $.proxy(this.onImageStartDrag, this));
            this.$dragImage.on("click", event => {
                event.stopPropagation();
            });

            if (!$(".image_drag_shield").length) {
                this.showElementFocus();
            }

            app.isEditingImage = true;
        }
    },

    cleanUp: function() {
        $(".image_drag_shield").remove();
        app.isEditingImage = false;
    },

    reset: async function() {
        var element = this.element.content || this.element;
        element.resetMediaTransform();
        await element.canvas.updateCanvasModel();
        this.render();
        this.layout();
    },

    scaleImage: function(value) {
        let min = this.element.minScale(this.element.elementBounds);
        let max = this.element.maxScale(this.element.elementBounds);

        value = value / 100;

        let scale = min + (max - min) * value;

        this.element.updateModel({ scale }, this.element.elementBounds);
        this.element.canvas.refreshCanvas(false);

        if (this.element.model.scale > 1.5) {
            this.showWarning("Warning", "Image is scaled beyond 1.5X and may appear pixelated");
        } else {
            this.hideWarning();
        }
    },

    onImageStartDrag: function(event) {
        event.stopPropagation();
        let element = this.element.content || this.element;

        app.isDraggingItem = true;

        let dragStartPoint = new geom.Point(event.clientX, event.clientY);
        let offset = element.getOffset(element.innerBounds);

        let scale = element.getScale(element.innerBounds);

        let onImageDrag = event => {
            if (!element.canvas.layouter.isGenerating) {
                let hflip = element.model.flipHorizontal ? -1 : 1;
                element.updateModel({
                    offset: offset.plus(
                        hflip * (dragStartPoint.x - event.clientX) / scale,
                        (dragStartPoint.y - event.clientY) / scale)
                }, element.innerBounds);
                this.element.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true });
            }
        };

        let onImageDragEnd = event => {
            $(document).off("mousemove", onImageDrag);
            $(document).off("mouseup", onImageDragEnd);
            event.stopPropagation();
            app.isDraggingItem = false;
            this.element.canvas.updateCanvasModel();
        };

        $(document).on("mousemove", onImageDrag);
        $(document).on("mouseup", onImageDragEnd);
    }
});

const LogoSelection = PictureSelection.extend({
    type: "logo"

});

const VideoElementSelection = PictureSelection.extend({
    type: "video"
});

const FilterImage = styled.div`
  .background {
    position: relative;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  label {
    display: block;
    padding: 3px;
    color: #333;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
  }
`;

const SelectedFilter = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  color: white;
  background: ${themeColors.ui_blue};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px;
`;

const optionsWidth = 380;

const ImagePreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 64px - 24px - 24px);
  width: calc(100% - ${optionsWidth}px - 20px);
  background: #eee;
`;

const OptionsContainer = styled.div`
    position: relative;
    width: ${optionsWidth}px;
    margin-left: 20px;
`;

const FilterContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50px;
    display: grid;
    gap: 10px;
    grid-auto-rows: min-content;
    margin-right: -24px;
    padding-right: 24px;
    overflow-x: hidden;
    overflow-y: scroll;

    header {
        color: #333;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.5px;
        text-align: left;
        text-transform: uppercase;
        margin-top: 15px;
    }
`;

const TransformsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: -8px;
    margin-right: -2px;
`;

const ButtonsContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding-top: 6px;
`;

const FilterGrid = styled.div`
  display: grid;
  gap: 10px;
`;

const RestyledIconButton = styled(StyledIconButton)`
&&& {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
}
`;

const StyledLabelContainer = styled(LabelContainer)`
&&& {
    box-sizing: content-box;
    margin-right: 16px;
}
`;

export class FilterDialog extends Component {
    constructor(props) {
        super(props);

        this.trackChanged = {};

        this.gridRef = React.createRef();

        let filter = props.element.model.filter;
        if (filter == "None" || !filter) {
            filter = "none";
        }

        let elemAsset = props.element.asset.attributes;
        let originalRotation = elemAsset.rotation || 0;

        this.state = {
            rotationOffset: 0,
            flipHorizontal: props.element.model.flipHorizontal || false,
            noBg: false,
            originalAssetId: null,
            originalAssetUrl: null,
            altAssetUrl: null,
            altAssetId: null,
            activeFilterId: 1,
            filterBrightness: props.element.model.filterBrightness ?? 1,
            filterContrast: props.element.model.filterContrast ?? 1,
            filterBlur: props.element.model.filterBlur ?? 0,
            mediaOpacity: props.element.model.mediaOpacity ?? 1,
            filter,
            gridColCount: 3,
            thumbnailWidth: 0,
            thumbnailHeight: 0,
            previewWidth: 0,
            previewHeight: 0,
            originalRotation,
        };
    }

    componentDidMount = async () => {
        window.addEventListener("resize", this.onResize);

        let elemAsset = this.props.element.asset.attributes;
        let originalAssetId = elemAsset.id;
        let altAssetId = null;
        if (elemAsset.alteredAssetId) {
            originalAssetId = elemAsset.alteredAssetId;
            altAssetId = elemAsset.id;
        }

        let originalAssetUrl = await ds.assets
            .getAssetById(originalAssetId, AssetType.IMAGE)
            .then(asset => asset.getURL("original", true));

        let altAssetUrl = null;
        if (altAssetId) {
            altAssetUrl = await ds.assets
                .getAssetById(altAssetId, AssetType.IMAGE)
                .then(asset => asset.getURL("original", true));
        }

        let delta = {
            noBg: elemAsset.noBg ?? false,
            originalAssetId,
            originalAssetUrl,
            altAssetUrl,
            altAssetId,
        };

        await new Promise(resolve => {
            let img = new Image();
            img.onload = () => {
                delta.baseWidth = img.width;
                delta.baseHeight = img.height;
                resolve();
            };
            img.src = originalAssetUrl;
        });

        this.setState(delta);

        this.orientPreviews();
    }

    componentWillUnmount = () => {
        window.removeEventListener("resize", this.onResize);
    }

    onResize = () => {
        this.orientPreviews();
    }

    orientPreviews = (delta = {}) => {
        let {
            noBg,
            rotationOffset,
            originalRotation,
            baseWidth,
            baseHeight,
        } = this.state;

        // Ensure that current state is accounted for
        delta = {
            noBg,
            rotationOffset,
            ...delta,
        };
        rotationOffset = delta.rotationOffset;
        delta.rotationOffset %= 360;

        let totalRotation = (originalRotation + rotationOffset) % 360;
        let transpose = totalRotation % 180 !== 0;

        // Get the original width and height
        let adjustedBaseWidth = transpose ? baseHeight : baseWidth;
        let adjustedBaseHeight = transpose ? baseWidth : baseHeight;

        let isPortrait = adjustedBaseHeight > adjustedBaseWidth;

        // Landscape
        let gridColCount = 3;
        let thumbnailWidth = (optionsWidth - 10 * 2) / 3;
        // Portrait
        if (isPortrait) {
            gridColCount = 4;
            thumbnailWidth = (optionsWidth - 10 * 3) / 4;
        }
        let thumbnailHeight = adjustedBaseHeight / adjustedBaseWidth * thumbnailWidth;

        let previewMaxWidth = window.innerWidth - 64 - 24 - 24 - optionsWidth - 20;
        let previewMaxHeight = window.innerHeight - 64 - 24 - 24;
        let previewWidth = Math.min(previewMaxWidth, adjustedBaseWidth / adjustedBaseHeight * previewMaxHeight);
        let previewHeight = Math.min(previewMaxHeight, adjustedBaseHeight / adjustedBaseWidth * previewMaxWidth);

        this.setState({
            ...delta,
            gridColCount,
            thumbnailWidth,
            thumbnailHeight,
            previewWidth,
            previewHeight,
        });
    }

    onToggleBg = async () => {
        let {
            noBg,
            altAssetId,
            originalAssetId,
            rotationOffset,
            originalRotation,
        } = this.state;
        noBg = !noBg;

        this.trackChanged.bgToggled = true;

        let delta = {
            noBg,
        };

        // If we're flagged to use the alt bg, but we don't
        //   have the alt asset yet, then generate it
        if (
            noBg &&
            !altAssetId
        ) {
            let elemAsset = this.props.element.asset.attributes;

            let quitTransform = false;
            let progressDialog = ShowDialog(ProgressDialog, {
                title: "Removing background...",
                message: (
                    <>
                        Hang tight. This might take a minute. Please
                        <br />
                        don't close or refresh your browser.
                    </>
                ),
                onCancel: () => {
                    quitTransform = true;
                    progressDialog.props.closeDialog();
                }
            });

            let imageUrl = await ds.assets
                .getAssetById(originalAssetId, AssetType.IMAGE)
                .then(async asset => {
                    let url = await asset.getURL("original", true);

                    // Rasterize the asset if it's an SVG
                    if (asset.attributes.fileType === "svg") {
                        url = await new Promise((resolve, reject) => {
                            const img = new Image();
                            img.crossOrigin = "anonymous";
                            img.onload = () => {
                                const canvas = document.createElement("canvas");
                                canvas.width = img.width;
                                canvas.height = img.height;
                                const ctx = canvas.getContext("2d");

                                ctx.drawImage(img, 0, 0);

                                let result = canvas.toDataURL();
                                resolve(result);
                            };
                            img.onerror = err => {
                                reject(err);
                            };
                            img.src = url;
                        });
                    }

                    return url;
                });

            progressDialog.setTask(80, 40000);

            let rotation = (originalRotation + rotationOffset) % 360;
            let { transformUrl } = await Api.cloudinaryTransform.post({
                assetId: originalAssetId,
                imageUrl,
                transformOptions: {
                    background_removal: "cloudinary_ai",
                    angle: rotation,
                }
            });

            if (quitTransform) {
                progressDialog.closeDialog();
                return;
            }

            progressDialog.setTask(99, 3000);

            let transpose = rotation % 180 > 0;

            // Extract the original width and height
            const baseAsset = await ds.assets.getAssetById(originalAssetId, AssetType.IMAGE);
            let {
                w,
                h,
            } = baseAsset.attributes;
            if (transpose) {
                let temp = w;
                w = h;
                h = temp;
            }

            let newAsset = await ds.assets.getOrCreateImage({
                url: transformUrl,
                fileType: ASSET_FILETYPE.PNG,
                assetType: AssetType.IMAGE,
                name: `${elemAsset.name}-no-bg`,
                metadata: {
                    source: elemAsset.source,
                    attribution: elemAsset.attribution,
                    tags: elemAsset.tags,
                    link: elemAsset.link,
                },
                imagePropsOverride: {
                    hasAlpha: true,
                    hasSolidBackground: false,
                    imageBackgroundColor: false,
                    width: w,
                    height: h,
                },
                extra: {
                    alteredAssetId: originalAssetId,
                    noBg: true,
                    rotation,
                },
            });

            let altAssetId = newAsset.id;
            let altAssetUrl = await ds.assets
                .getAssetById(altAssetId, AssetType.IMAGE)
                .then(asset => asset.getURL("original", true));

            progressDialog.setProgress(100);
            progressDialog.props.closeDialog();

            delta = {
                ...delta,
                rotationOffset: 0,
                originalRotation: rotation,
                altAssetId,
                altAssetUrl,
            };
        }

        this.setState(delta);
    }

    setStateAndRefreshEditor = (trackLabel, delta) => {
        // HACK: For Safari, we need to make sure to sync all
        // filter IDs and use a unique ID each time, otherwise
        // sometimes the filter is not applied
        const activeFilterId = this.state.activeFilterId + 1;
        this.setState({
            ...delta,
            activeFilterId,
        });
        document.querySelectorAll("[filter-preview]")
            .forEach(node => node.setAttribute("filter", `url(#filter-preview--${activeFilterId}-adj-filter)`));

        this.trackChanged[trackLabel] = true;

        // make sure to reuse the shared filter ID
        this.props.element.updateFilterVersion(activeFilterId);
    }

    applyFilters = async () => {
        let {
            noBg,
            originalAssetId,
            altAssetId,
            rotationOffset,
            originalRotation,
            flipHorizontal,
            filterBrightness,
            filterContrast,
            filterBlur,
            mediaOpacity,
            filter
        } = this.state;

        const elemAsset = this.props.element.asset.attributes;
        const model = this.props.element.model;

        const totalRotation = (originalRotation + rotationOffset) % 360;

        const trackDelta = {};
        if ((elemAsset.noBg ?? false) !== noBg) {
            trackDelta.noBg = noBg;
        }
        if ((elemAsset.rotation ?? 0) !== totalRotation) {
            trackDelta.rotation = totalRotation;
        }
        if ((model.flipHorizontal ?? false) !== flipHorizontal) {
            trackDelta.flipHorizontal = flipHorizontal;
        }
        if ((model.filterBrightness ?? 1) !== filterBrightness) {
            trackDelta.brightness = filterBrightness;
        }
        if ((model.filterContrast ?? 1) !== filterContrast) {
            trackDelta.contrast = filterContrast;
        }
        if ((model.filterBlur ?? 0) !== filterBlur) {
            trackDelta.blur = filterBlur;
        }
        if ((model.mediaOpacity ?? 1) !== mediaOpacity) {
            trackDelta.opacity = mediaOpacity;
        }
        if ((model.filter ?? "none") !== filter) {
            trackDelta.filter = filter;
        }

        model.flipHorizontal = flipHorizontal;
        model.filterBrightness = filterBrightness;
        model.filterContrast = filterContrast;
        model.filterBlur = filterBlur;
        model.mediaOpacity = mediaOpacity;
        model.filter = filter;

        const assetWasPreviouslyAltered = (
            !!altAssetId &&
            (
                noBg ||
                !!originalRotation
            )
        );

        let assetId = assetWasPreviouslyAltered ? altAssetId : originalAssetId;

        // If there's been any additional rotation, save it in the asset itself
        if (rotationOffset) {
            let progressDialog = ShowDialog(ProgressDialog, {
                title: "Saving your changes...",
                message: (
                    <>
                        Hang tight. This might take a minute. Please
                        <br />
                        don't close or refresh your browser.
                    </>
                ),
            });

            progressDialog.setTask(50, 1000);

            let assetUrl = await ds.assets
                .getAssetById(assetId, AssetType.IMAGE)
                .then(asset => asset.getURL("original", true));

            let transpose = rotationOffset % 180 !== 0;
            let assetRotatedUrl = await new Promise(resolve => {
                let img = new Image();
                img.crossOrigin = "anonymous";
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
                    // Set the canvas dimensions according to the rotated image
                    if (transpose) {
                        canvas.width = img.height;
                        canvas.height = img.width;
                    } else {
                        canvas.width = img.width;
                        canvas.height = img.height;
                    }

                    // Rotate and draw the image
                    ctx.translate(canvas.width / 2, canvas.height / 2);
                    ctx.rotate(rotationOffset * Math.PI / 180);
                    ctx.drawImage(img, -img.width / 2, -img.height / 2);

                    let dataUrl = canvas.toDataURL();
                    resolve(dataUrl);
                };

                img.src = assetUrl;
            });

            progressDialog.setTask(99, 3000);

            // Extract the original width and height
            const baseAsset = await ds.assets.getAssetById(assetId, AssetType.IMAGE);
            let {
                w,
                h,
            } = baseAsset.attributes;
            if (transpose) {
                let temp = w;
                w = h;
                h = temp;
            }

            let newAsset = await ds.assets.getOrCreateImage({
                url: assetRotatedUrl,
                fileType: ASSET_FILETYPE.PNG,
                assetType: AssetType.IMAGE,
                name: `${elemAsset.name}-rotated`,
                metadata: {
                    source: elemAsset.source,
                    attribution: elemAsset.attribution,
                    tags: elemAsset.tags,
                    link: elemAsset.link,
                },
                imagePropsOverride: {
                    hasAlpha: noBg,
                    hasSolidBackground: false,
                    imageBackgroundColor: false,
                    width: w,
                    height: h,
                },
                extra: {
                    alteredAssetId: originalAssetId,
                    noBg,
                    rotation: totalRotation,
                },
            });

            assetId = newAsset.id;

            progressDialog.setProgress(100);
            progressDialog.props.closeDialog();
        }

        // Update the asset id
        const mediaElement = this.props.element;
        const contentElement = mediaElement.parentElement;
        const contentModel = contentElement.model;
        contentModel[contentElement.bindTo] = assetId;

        // Remove the background color if we have any image transparency
        if (
            noBg ||
            mediaOpacity < 1
        ) {
            contentElement.model.cellColor = "none";
        }

        trackActivity("PictureEditor", "FiltersApplied", null, null, {
            changed: this.trackChanged,
            delta: trackDelta,
        });

        this.props.element.updateFilterVersion();
        this.props.element.canvas.updateCanvasModel(false);
        this.props.closeDialog();
    }

    removeFilters = () => {
        this.orientPreviews({
            rotationOffset: 360 - this.state.originalRotation,
            flipHorizontal: false,
            noBg: false,
            filter: "none",
            filterBrightness: 1,
            filterContrast: 1,
            filterBlur: 0,
            mediaOpacity: 1,
        });

        this.trackChanged.allFiltersReset = true;
    }

    onCancel = () => {
        trackActivity("PictureEditor", "FiltersCancelled", null, null, { changed: this.trackChanged });

        this.props.closeDialog();
    }

    render() {
        let {
            element
        } = this.props;
        let {
            rotationOffset,
            originalRotation,
            flipHorizontal,
            noBg,
            originalAssetUrl,
            altAssetUrl,
            gridColCount,
            thumbnailWidth,
            thumbnailHeight,
            previewWidth,
            previewHeight,
            filterBrightness,
            filterContrast,
            filterBlur,
            mediaOpacity,
            filter,
            activeFilterId,
        } = this.state;

        let assetUrl = noBg ? altAssetUrl : originalAssetUrl;
        let rotation = noBg ? rotationOffset : (originalRotation + rotationOffset) % 360;

        let colors = _.extend(
            {
                [PaletteColorType.THEME]: element.canvas.getTheme().palette.getColor(PaletteColorType.THEME).toHexString()
            },
            element.canvas.getTheme().palette.getAccentColors(),
            {
                [PaletteColorType.BACKGROUND_ACCENT]: element.canvas.getTheme().palette.getColor(PaletteColorType.BACKGROUND_ACCENT).toHexString()
            }
        );
        let filterId = `filter-preview--${activeFilterId}`;

        let filters = ["none", "brannan", "earlybird", "inkwell", "lofi", "mayfair", "nashville"];
        _.forIn(colors, (color, key) => {
            filters.push(key);
        });

        return (
            <BeautifulDialog
                maxWidth={false}
                closeDialog={this.onCancel}
            >
                <svg width={0} height={0}>
                    {renderAdjustmentFilter({ filterId }, this.state)}
                </svg>

                <Container>
                    <ImagePreview>
                        <FilterPreview
                            // key={`filter_preview`}
                            width={previewWidth}
                            height={previewHeight}
                            filter={filter}
                            element={element}
                            assetUrl={assetUrl}
                            rotation={rotation}
                            flipHorizontal={flipHorizontal}
                            opacity={mediaOpacity}
                            activeFilterId={activeFilterId}
                            showFilterName={false}
                        />
                    </ImagePreview>
                    <OptionsContainer>
                        <FilterContainer>
                            <header>Transform</header>
                            <TransformsContainer>
                                <RestyledIconButton
                                    size="small"
                                    onClick={() => {
                                        this.orientPreviews({ rotationOffset: (this.state.rotationOffset + 90) % 360 });
                                        this.trackChanged.rotated = true;
                                    }}
                                >
                                    <Icon>rotate_right</Icon>
                                    Rotate
                                </RestyledIconButton>
                                <RestyledIconButton
                                    size="small"
                                    onClick={() => {
                                        this.setState({ flipHorizontal: !this.state.flipHorizontal });
                                        this.trackChanged.flippedHorizontal = true;
                                    }}
                                >
                                    <Icon>flip</Icon>
                                    Flip
                                </RestyledIconButton>
                                <RestyledIconButton
                                    size="small"
                                    onClick={this.onToggleBg}
                                >
                                    <Icon>hide_image</Icon>
                                    Remove Background
                                </RestyledIconButton>
                            </TransformsContainer>
                            <header>Filters</header>
                            <StyledLabelContainer>
                                <SmallLabel>Brightness</SmallLabel>
                                <Slider
                                    value={filterBrightness}
                                    min={0.1}
                                    max={2}
                                    step={0.01}
                                    valueLabelDisplay="auto"
                                    onChange={(e, filterBrightness) => this.setStateAndRefreshEditor("brightness", { filterBrightness })}
                                />
                            </StyledLabelContainer>
                            <StyledLabelContainer>
                                <SmallLabel>Contrast</SmallLabel>
                                <Slider
                                    value={filterContrast}
                                    min={0.1}
                                    max={2}
                                    step={0.01}
                                    valueLabelDisplay="auto"
                                    onChange={(e, filterContrast) => this.setStateAndRefreshEditor("contrast", { filterContrast })}
                                />
                            </StyledLabelContainer>
                            <StyledLabelContainer>
                                <SmallLabel>Blur</SmallLabel>
                                <Slider
                                    value={filterBlur}
                                    min={0}
                                    max={100}
                                    step={1}
                                    valueLabelDisplay="auto"
                                    onChange={(e, filterBlur) => this.setStateAndRefreshEditor("blur", { filterBlur })}
                                />
                            </StyledLabelContainer>
                            <StyledLabelContainer>
                                <SmallLabel>Opacity</SmallLabel>
                                <Slider
                                    value={(mediaOpacity * 100).toFixed(0)}
                                    min={1}
                                    max={100}
                                    step={1}
                                    valueLabelDisplay="auto"
                                    onChange={(e, mediaOpacity) => this.setStateAndRefreshEditor("opacity", { mediaOpacity: parseFloat((mediaOpacity / 100).toFixed(2)) })}
                                />
                            </StyledLabelContainer>
                            <FlexSpacer />
                            <FilterGrid ref={this.gridRef}
                                style={{ gridTemplateColumns: `repeat(${gridColCount}, 1fr)` }}>
                                {filters.map((f, i) => (
                                    <FilterPreview
                                        key={`filter_${i}`}
                                        selected={f === filter}
                                        width={thumbnailWidth}
                                        height={thumbnailHeight}
                                        filter={f}
                                        element={element}
                                        assetUrl={assetUrl}
                                        rotation={rotation}
                                        flipHorizontal={flipHorizontal}
                                        opacity={mediaOpacity}
                                        activeFilterId={activeFilterId}
                                        onClick={() => this.setStateAndRefreshEditor("filter", { filter: f })}
                                    />
                                ))}
                            </FilterGrid>
                            <FlexSpacer />
                        </FilterContainer>
                        <ButtonsContainer>
                            <Button
                                variant="text"
                                style={{
                                    color: "#FF4500",
                                }}
                                onClick={this.removeFilters}
                            >Reset Image</Button>
                            <FlexBox>
                                <Button
                                    variant="text"
                                    color="secondary"
                                    onClick={this.onCancel}
                                >Cancel</Button>
                                <Gap10 />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.applyFilters}
                                >Apply</Button>
                            </FlexBox>
                        </ButtonsContainer>
                    </OptionsContainer>

                </Container>

            </BeautifulDialog>
        );
    }
}

class FilterPreview extends Component {
    constructor() {
        super();

        this.ref = React.createRef();
    }

    componentDidUpdate = async prevProps => {
        if (
            !this.img ||
            prevProps.assetUrl !== this.props.assetUrl ||
            prevProps.width !== this.props.width ||
            prevProps.height !== this.props.height ||
            prevProps.rotation !== this.props.rotation
        ) {
            await this.resize();
            this.applyFilter();
        } else if (prevProps.filter !== this.props.filter) {
            this.applyFilter();
        }
    }

    resize = async () => {
        let {
            width,
            height,
            assetUrl,
            activeFilterId,
            rotation,
        } = this.props;

        // Clear svg content
        this.ref.current.innerHTML = "";

        let draw = SVG(this.ref.current);
        this.group = draw.group();
        this.img = this.group.image(assetUrl);
        await new Promise(resolve => this.img.loaded(resolve));

        let transpose = rotation % 180 !== 0;
        this.img.width(transpose ? height : width);
        this.img.height(transpose ? width : height);

        this.group.node.setAttribute("filter", `url(#filter-preview--${activeFilterId}-adj-filter)`);

        // HACK: For Safari, make sure to identify this as the
        // node to refresh the filter ID for
        this.group.node.setAttribute("filter-preview", "");
    }

    applyFilter = async () => {
        let {
            filter,
            element,
        } = this.props;
        let colors = _.extend(
            {
                [PaletteColorType.THEME]: element.canvas.getTheme().palette.getColor(PaletteColorType.THEME).toHexString()
            },
            element.canvas.getTheme().palette.getAccentColors(),
            {
                [PaletteColorType.BACKGROUND_ACCENT]: element.canvas.getTheme().palette.getColor(PaletteColorType.BACKGROUND_ACCENT).toHexString()
            },
        );

        switch (filter) {
            case "none":
                this.img.attr("filter", "");
                break;
            case "brannan":
            case "earlybird":
            case "inkwell":
            case "lofi":
            case "mayfair":
            case "nashville":
                this.img.attr("filter", `url(#${filter})`);
                break;
            default:
                this.img.filter(add => {
                    let flood = add.flood(colors[filter], 1);
                    let grayscale = add.colorMatrix("saturate", 0);
                    add.blend(flood, grayscale, "multiply");

                    // NOTE: Possible solution for keeping transparent pixels. Currently produces different tinting.
                    // let color = tinycolor(colors[filter]).toRgb();
                    // let r = color.r / 255;
                    // let g = color.g / 255;
                    // let b = color.b / 255;
                    // let colorMap = add.colorMatrix("matrix", [
                    //     r, 0, 0, 0, 0,
                    //     0, g, 0, 0, 0,
                    //     0, 0, b, 0, 0,
                    //     0, 0, 0, 1, 0,
                    // ]);
                    // let grayscale = add.colorMatrix("saturate", 0);
                    // add.blend(colorMap, grayscale, "multiply");
                });
        }
    }

    render() {
        let {
            width,
            height,
            rotation,
            flipHorizontal,
            opacity,
            filter,
            onClick,
            selected,
            showFilterName = true,
        } = this.props;

        let transpose = rotation % 180 !== 0;

        let scaleTransform = "";
        if (flipHorizontal) {
            scaleTransform = transpose ? "scaleY(-1)" : "scaleX(-1)";
        }

        let rotWidth = width;
        let rotHeight = height;
        if (transpose) {
            rotWidth = height;
            rotHeight = width;
        }

        let filterName;
        switch (filter) {
            case "background_accent":
                filterName = "bg accent";
                break;
            default:
                filterName = filter;
                break;
        }

        return (
            <FilterImage
                style={{
                    width,
                    height: height + (showFilterName ? 24 : 0),
                }}
                onClick={onClick}
                selected={selected}
            >
                <div
                    className="background"
                    style={{
                        width,
                        height,
                        flexDirection: width > height ? "row" : "column"
                    }}
                >
                    <div
                        style={{
                            width: rotWidth,
                            height: rotHeight,
                            transform: `rotate(${rotation}deg) ${scaleTransform}`,
                        }}
                    >
                        <svg
                            ref={this.ref}
                            width="100%"
                            height="100%"
                            style={{
                                opacity,
                            }}
                        ></svg>
                    </div>
                    {
                        selected &&
                        <SelectedFilter>
                            <Icon>check</Icon>
                        </SelectedFilter>
                    }
                </div>
                {
                    showFilterName &&
                    <label>{filterName}</label>
                }
            </FilterImage>
        );
    }
}

export const CreateAdjustImageMenu = function(view, contentElement, options = {}) {
    if (contentElement.options.useSmallContentUI) {
        view.$el.append(controls.createPopupButton(view, {
            icon: "photo_camera",
            className: "small-content-popup-button",
            label: options.label,
            showArrow: options.label != null,
            ...BuildAdjustImageMenu(contentElement, options)
        }));
    } else {
        return view.addControl(Object.assign({
            type: controls.POPUP_BUTTON,
            icon: "photo_camera",
            label: options.label,
            showArrow: options.label != null,
        }, BuildAdjustImageMenu(contentElement, options)));
    }
};

export const CreateImageFrameMenu = function(view, contentElement) {
    return view.addControl({
        id: "image-frame-menu",
        type: controls.POPUP_BUTTON,
        icon: "vignette",
        showArrow: false,
        menuClass: "icon-menu threecol",
        items: [{
            value: "none", label: "No Frame", image: getStaticUrl("/images/ui/frame_nocrop.svg")
        }, {
            value: "square", label: "Square", image: getStaticUrl("/images/ui/frame_square.svg")
        }, {
            value: "circle", label: "Circle", image: getStaticUrl("/images/ui/frame_circle.svg")
        }],
        callback: value => {
            contentElement.model.frameType = value;
            contentElement.markStylesAsDirty();
            contentElement.canvas.updateCanvasModel(false);
        }
    });
};

export function BuildAdjustImageMenu(contentElement, options = {}) {
    let items;
    if (contentElement.hasValidAsset) {
        const type = contentElement.model.content_type.replace(/_/g, " ").toTitleCase();

        items = [{
            value: "replace", label: `Replace ${type}...`, icon: "add_a_photo"
        }];

        if (options.allowAdjust !== false) {
            items.push({ value: "edit", label: `Adjust ${type}`, icon: "crop" });
        }

        items.push({ type: "divider" });
        items.push({ value: "remove", label: `Remove ${type}`, icon: "delete" });
    } else {
        items = [{
            value: "replace", label: `Add Media...`, icon: "add_a_photo"
        }];
    }

    return {
        id: uuid(),
        items: items,
        callback: action => {
            switch (action) {
                case "replace":
                    ShowDialog(AddAssetsContainer, {
                        assetType: contentElement.model.content_type || contentElement.options.defaultAssetType || AssetType.IMAGE,
                        workspaceId: ds.selection.presentation.getWorkspaceId(),
                        backgroundVideoOnly: true,
                        callback: model => {
                            mergeMediaElementModelDefaults(
                                contentElement.model,
                                model,
                            );

                            if (options.onReplaceAsset) {
                                options.onReplaceAsset(model);
                            }

                            contentElement.canvas.updateCanvasModel(false);
                        },
                    });
                    break;
                case "edit":
                    ds.selection.element = contentElement.assetElement;
                    break;
                case "filter":
                    ds.selection.element = contentElement.assetElement;
                    break;
                case "remove":
                    contentElement.model.content_value = null;
                    contentElement.model.content_type = null;
                    if (options.onRemove) {
                        options.onRemove();
                    }
                    contentElement.canvas.updateCanvasModel(false).then(() => {
                        ds.selection.element = null;
                    });
                    break;
            }
        }
    };
}

export const CreateVideoMenu = function(view, contentElement, options = {}) {
    view.addControl(Object.assign({
        type: controls.POPUP_BUTTON,
        icon: "photo_camera",
        showArrow: false,
    }, BuildVideoSourceMenu(contentElement, options)));
};

export function BuildVideoSourceMenu(contentElement, options = {}) {
    let items;
    if (contentElement.hasValidAsset) {
        let type = contentElement.model.content_type;

        items = [{
            value: "replace", label: `Replace Video...`, icon: "video_library"
        }];

        items.push({ value: "edit", label: `Adjust Video`, icon: "crop" });
        items.push({ type: "divider" });
        items.push({ value: "remove", label: `Remove Video`, icon: "delete" });
    } else {
        let type = contentElement.options.defaultAssetType || AssetType.VIDEO;
        items = [{
            value: "replace", label: `Add Video...`, icon: "video_library"
        }];
    }

    return {
        items: items,
        callback: action => {
            switch (action) {
                case "replace":
                    ShowDialog(AddAssetsContainer, {
                        assetType: contentElement.model.content_type || contentElement.options.defaultAssetType || AssetType.VIDEO,
                        workspaceId: ds.selection.presentation.getWorkspaceId(),
                        backgroundVideoOnly: true,
                        callback: model => {
                            mergeMediaElementModelDefaults(
                                contentElement.model,
                                model,
                            );

                            contentElement.canvas.updateCanvasModel(false);
                        },
                    });
                    break;
                case "edit":
                    ds.selection.element = contentElement.assetElement;
                    break;
                case "filter":
                    ds.selection.element = contentElement.assetElement;
                    break;
                case "remove":
                    contentElement.model.content_value = null;
                    contentElement.model.content_url = null;
                    contentElement.model.content_type = null;
                    if (options.onRemove) {
                        options.onRemove();
                    }
                    contentElement.canvas.updateCanvasModel(false).then(() => {
                        ds.selection.element = null;
                    });
                    break;
            }
        }
    };
}

export function BuildVideoSettingsMenu(videoElement, options = {}) {
    const { canBeBackgroundVideo } = videoElement;

    const refreshCanvasAndSaveChanges = (save = true) => {
        save && videoElement.canvas.saveCanvasModel();
        videoElement.canvas.refreshElement(videoElement, null, true);
    };

    const refreshList = [];
    const refreshMenu = () => {
        refreshList.forEach(refresh => refresh());
    };

    const items = [
        // {
        //     type: "control",
        //     icon: "dvr",
        //     view: () => {
        //         const $menu = $.div();
        //         const $reactWrapper = $menu.addEl($.div("control"));

        //         const handleChangeVideoControls = event => {
        //             if (videoElement) {
        //                 const value = event.currentTarget.checked;
        //                 videoElement.toggleControls({ value, refresh: false });
        //             }
        //             refreshCanvasAndSaveChanges();
        //             refreshMenu();
        //         };

        //         const refresh = () => {
        //             const jsx = (
        //                 <LabeledContainer label="Interactive">
        //                     <Switch
        //                         name="controls"
        //                         color="primary"
        //                         checked={videoElement.model.assetProps?.controls || false}
        //                         onChange={handleChangeVideoControls}
        //                         disabled={!canBeBackgroundVideo}
        //                     />
        //                 </LabeledContainer>
        //             );
        //             renderReactRootJSX(jsx, $reactWrapper[0]);
        //         };
        //         refreshList.push(refresh);
        //         refresh();
        //         return $menu;
        //     },
        // },
        {
            type: "control",
            icon: "volume_up",
            view: () => {
                const $menu = $.div();
                const $reactWrapper = $menu.addEl($.div("control"));

                const handleChangeVideoMuted = event => {
                    if (videoElement) {
                        videoElement.model.assetProps.muted = !event.target.checked;
                        videoElement.toggleAudio({ value: !videoElement.model.assetProps.muted, refresh: false });
                    }
                    refreshCanvasAndSaveChanges();
                    refresh();
                };

                const refresh = () => {
                    const jsx = (
                        <LabeledContainer label="Audio">
                            <Switch
                                name="muted"
                                color="primary"
                                checked={!(videoElement.model.assetProps?.muted || false)}
                                onChange={handleChangeVideoMuted}
                                disabled={!videoElement.canPlayAudio}
                            />
                        </LabeledContainer>
                    );
                    renderReactRootJSX(jsx, $reactWrapper[0]);
                };
                refreshList.push(refresh);
                refresh();
                return $menu;
            },
        },
        // {
        //     type: "control",
        //     icon: "play_circle_filled",
        //     view: () => {
        //         const $menu = $.div();
        //         const $reactWrapper = $menu.addEl($.div("control"));

        //         const handleChangeVideoAutoPlay = event => {
        //             if (videoElement) {
        //                 videoElement.model.assetProps.autoPlay = event.target.checked;
        //                 videoElement.togglePlayback({ value: event.target.checked });
        //             }
        //             refreshCanvasAndSaveChanges();
        //             refresh();
        //         };

        //         const refresh = () => {
        //             const jsx = (
        //                 <LabeledContainer label="Auto Play">
        //                     <Switch
        //                         name="autoPlay"
        //                         color="primary"
        //                         checked={videoElement.model.assetProps?.autoPlay || false}
        //                         onChange={handleChangeVideoAutoPlay}
        //                         disabled={!videoElement.model.assetProps?.controls}
        //                     />
        //                 </LabeledContainer>
        //             );
        //             renderReactRootJSX(jsx, $reactWrapper[0]);
        //         };
        //         refreshList.push(refresh);
        //         refresh();
        //         return $menu;
        //     },
        // },
        {
            type: "control",
            icon: "loop",
            view: () => {
                const $menu = $.div();
                const $reactWrapper = $menu.addEl($.div("control"));

                const handleChangeVideoLoop = event => {
                    const loop = event.target.checked;
                    if (videoElement) {
                        videoElement.model.assetProps.loop = loop;
                        if (!videoElement.isPlaying && loop) {
                            videoElement.togglePlayback({ value: true, refresh: false });
                        }
                    }
                    refreshCanvasAndSaveChanges();
                    refresh();
                };

                const refresh = () => {
                    const jsx = (
                        <LabeledContainer label="Loop Video">
                            <Switch
                                name="loop"
                                color="primary"
                                checked={videoElement.model.assetProps?.loop || false}
                                onChange={handleChangeVideoLoop}
                                disabled={!canBeBackgroundVideo}
                            />
                        </LabeledContainer>
                    );
                    renderReactRootJSX(jsx, $reactWrapper[0]);
                };
                refreshList.push(refresh);
                refresh();
                return $menu;
            },
        },
        {
            type: "control",
            icon: "timer",
            view: () => {
                const $menu = $.div();
                const $reactWrapper = $menu.addEl($.div("control"));

                const handleChangeVideoTime = (value, save = true) => {
                    const [
                        startTime,
                        endTime,
                    ] = value;

                    if (videoElement) {
                        videoElement.model.assetProps.startTime = startTime;
                        videoElement.model.assetProps.endTime = endTime;
                    }
                    refreshCanvasAndSaveChanges(save);
                    videoElement.togglePlayback({
                        value: startTime < endTime,
                        refresh: false,
                        reset: true,
                    });
                    // refresh(); // Not needed for InputSlider
                };

                const refresh = () => {
                    const jsx = (
                        <LabeledContainer label="Video Range">
                            <div style={{ paddingRight: "10px" }}>
                                <MultiInputSlider
                                    value={[
                                        videoElement.model.assetProps?.startTime || 0,
                                        videoElement.model.assetProps?.endTime || videoElement.model.assetProps?.duration,
                                    ]}
                                    multiValue
                                    onChange={values => handleChangeVideoTime(values, false)}
                                    onChangeCommitted={values => handleChangeVideoTime(values)}
                                    sliderMin={0}
                                    sliderMax={videoElement.model.assetProps?.duration}
                                    inputMin={0}
                                    inputMax={videoElement.model.assetProps?.duration}
                                    step={1}
                                />
                            </div>
                        </LabeledContainer>
                    );
                    renderReactRootJSX(jsx, $reactWrapper[0]);
                };
                refreshList.push(refresh);
                refresh();
                return $menu;
            },
        },
        {
            type: "control",
            icon: "speed",
            view: () => controls.createDropdownMenu(this, {
                label: "Speed",
                items: [
                    { value: 2.0, label: "2.0x" },
                    { value: 1.75, label: "1.75x" },
                    { value: 1.5, label: "1.5x" },
                    { value: 1.25, label: "1.25x" },
                    { value: 1.0, label: "1.0x" },
                    { value: 0.75, label: "0.75x" },
                    { value: 0.5, label: "0.5x" },
                    { value: 0.25, label: "0.25x" },
                ],
                property: "speed",
                model: videoElement.model.assetProps,
                enabled: canBeBackgroundVideo,
            }),
        },
    ];

    return {
        items: items,
    };
}

export const CreateMediaMenu = function(view, contentElement, options = {}) {
    const type = contentElement.model.content_type;

    switch (type) {
        case AssetType.VIDEO:
        case AssetType.STOCK_VIDEO:
            return CreateVideoMenu(view, contentElement, options);
        default:
            return CreateAdjustImageMenu(view, contentElement, options);
    }
};

export const editors = {
    PictureSelection,
    LogoSelection,
    VideoElementSelection,
};

