import React from "react";
import * as geom from "js/core/utilities/geom";

import { formatter } from "js/core/utilities/formatter";
import { FormatType } from "legacy-common/constants";
import { _ } from "legacy-js/vendor";
import { getSVGStyleProps, SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import { getValueOrDefault } from "js/core/utilities/extensions";
import { Path } from "js/core/utilities/shapes";

import { AnnotationLayer } from "./AnnotationLayer";
import { BaseElement } from "../base/BaseElement";
import { TextElement } from "../base/Text/TextElement";
import { SVGElement } from "../base/SVGElement";

class ThermometerAnnotations extends AnnotationLayer {
    get maxItemCount() {
        return 11;
    }

    getChildOptions(model) {
        return {
            ...super.getChildOptions(model),
            canChangeTextDirection: false
        };
    }

    get constrainNodesToBounds() {
        return false;
    }
}

//CurrentValueLabel was created so a textElement editor unique to this element could be used.
class ThermometerLabel extends TextElement {
    get minTextScale() {
        return 0.3;
    }
}

class Thermometer extends BaseElement {
    static get schema() {
        return {
            showBulb: true,
            position: "center",
            showValue: true,
            tickCount: 5,
            showTickValues: true,
            format: FormatType.PERCENT,
            formatOptions: formatter.getDefaultFormatOptions(),
            value: 75,
            targetMax: 100
        };
    }

    get format() {
        return this.model.format || FormatType.PERCENT;
    }

    get _formatOptions() {
        return this.model.formatOptions || formatter.getDefaultFormatOptions();
    }

    get tickCount() {
        return this.model.tickCount;
    }

    get position() {
        return this.model.position;
    }

    get showTickValues() {
        return getValueOrDefault(this.model.showTickValues, true);
    }

    get showValue() {
        return getValueOrDefault(this.model.showValue, true);
    }

    get currentValue() {
        return this.formatValue(this.valueAsPercent);
    }

    get valueAsPercent() {
        const currentValue = (this.isAnimating ? (this.animationState.growProgress ?? 1) : 1) * this.model.value;
        return currentValue / 100 * this.model.targetMax;
    }

    get valuePosition() {
        return this.model.valuePosition || "left";
    }

    _migrate_8() {
        super._migrate_8();

        const annotationItems = _.cloneDeep(this.model.items);

        this.model.annotations = { items: [], connections: { items: [] } };
        delete this.model.items;
        delete this.model.connections;

        if (!annotationItems) {
            return;
        }

        for (const annotationModel of annotationItems) {
            const { blocks, nodeType, id, y } = annotationModel;
            this.model.annotations.items.push({
                id,
                blocks,
                nodeType,
                hideNodeConnectorWidget: true,
                x: this.position === "right" ? 0 : 0.75,
                y
            });

            this.model.annotations.connections.items.push({
                source: id,
                target: `${this.uniquePath}/thermometerGauge`,
                endPointIsLocked: true,
                canChangeConnectorType: false
            });
        }
    }

    _build() {
        if (!this.model.annotations) {
            this.model.annotations = { items: [], connections: { items: [] } };
        }

        this.thermometerGauge = this.addElement("thermometerGauge", () => ThermometerGauge);
        this.thermometerTicksContainer = this.addElement("thermometerTicksContainer", () => ThermometerTicksContainer);

        if (this.showValue) {
            this.label = this.addElement("label", () => ThermometerLabel, {
                scaleTextToFit: true,
                canEdit: false,
                constrainWidth: true,
                minTextScale: 0,
                model: { label: this.currentValue },
            });
            this.label.layer = 3;
        }

        this.annotations = this.addElement("annotations", () => ThermometerAnnotations, { model: this.model.annotations });
        this.annotations.layer = 2;
    }

    _calcProps(props, options) {
        const { size } = props;

        const gaugeProps = this.thermometerGauge.calcProps(size, { showBulb: this.model.showBulb });

        let gaugeLeft = 0;
        if (this.position === "center") {
            gaugeLeft = size.width / 2 - gaugeProps.size.width / 2;
        } else if (this.position === "right") {
            gaugeLeft = size.width - gaugeProps.size.width;
        }
        gaugeProps.bounds = new geom.Rect(gaugeLeft, 0, gaugeProps.size);

        const ticksSize = new geom.Size(100, gaugeProps.ticksHeight);
        const ticksProps = this.thermometerTicksContainer.calcProps(ticksSize, {
            showTickValues: this.showTickValues,
            formatValue: (...args) => this.formatValue(...args)
        });
        ticksProps.bounds = new geom.Rect(gaugeLeft + gaugeProps.stemOffset - ticksProps.size.width, gaugeProps.ticksTop, ticksProps.size);

        if (this.showValue) {
            const LABEL_PADDING = -20;

            if (this.isAnimating) {
                this.label.updateText(this.currentValue);
            }

            const labelProps = this.label.calcProps(new geom.Size(gaugeProps.stemWidth - LABEL_PADDING, gaugeProps.stemWidth - LABEL_PADDING));
            labelProps.bounds = new geom.Rect(gaugeProps.bounds.centerH - labelProps.size.width / 2, gaugeProps.fluidTop - labelProps.size.height / 2, labelProps.size);
        }

        const annotationsSize = new geom.Size(size.width, ticksProps.bounds.height);
        const calcAnnotations = () => {
            const annotationProps = this.annotations.calcProps(annotationsSize);
            annotationProps.bounds = new geom.Rect(0, ticksProps.bounds.top, annotationsSize);
        };

        calcAnnotations();

        let needsRecalc = false;
        this.annotations.itemElements.forEach(annotationElement => {
            const elementPostionX = annotationElement.model.x * annotationsSize.width;
            const alignRight = () => {
                if (annotationElement.bounds.left < gaugeProps.bounds.right) {
                    const elementOffset = Math.max(0, elementPostionX - annotationElement.bounds.left);
                    annotationElement.model.x = (gaugeProps.bounds.left + gaugeProps.bounds.width + elementOffset) / annotationsSize.width;
                    needsRecalc = true;
                }
            };
            const alignLeft = () => {
                if (annotationElement.bounds.right > ticksProps.bounds.left) {
                    const elementOffset = Math.max(0, annotationElement.bounds.right - elementPostionX);
                    annotationElement.model.x = Math.max(0, 1 - (elementOffset + ticksProps.bounds.width + annotationsSize.width - ticksProps.bounds.right) / annotationsSize.width);
                    needsRecalc = true;
                }
            };

            if (this.position === "left") {
                alignRight();
            } else if (this.position === "right") {
                alignLeft();
            } else {
                if (elementPostionX < gaugeProps.bounds.center.x) {
                    alignLeft();
                } else {
                    alignRight();
                }
            }
        });

        if (needsRecalc) {
            calcAnnotations();
        }

        return { size };
    }

    formatValue(value) {
        let displayValue = value;
        if (this.format == FormatType.PERCENT) {
            displayValue = value / 100;
        } else {
            displayValue = value;
        }

        return formatter.formatValue(displayValue, this.format, this.formatOptions);
    }

    get animationElementName() {
        return "Thermometer";
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            easing: "easeOutQuad",
            defaultDuration: 1200,
            animatingElements: [this, this.thermometerGauge],
            prepare: () => {
                this.animationState.growProgress = 0;
                this.thermometerGauge.animationState.growProgress = 0;
            },
            onBeforeAnimationFrame: progress => {
                this.animationState.growProgress = progress;
                this.thermometerGauge.animationState.growProgress = progress;
                return this;
            }
        }];
    }

    getAnimations() {
        return [...super.getAnimations(), ...this.annotations.getAnimations()];
    }
}

class ThermometerGauge extends BaseElement {
    getAnchorPointType() {
        return geom.AnchorType.FREE;
    }

    getAnchorPoint(connector, anchor, connectorPoint, connectorType) {
        if (connectorPoint.x < this.bounds.centerH) {
            return new geom.Point(this.bounds.left + this.calculatedProps.stemOffset, connectorPoint.y);
        } else {
            return new geom.Point(this.bounds.right - this.calculatedProps.stemOffset, connectorPoint.y);
        }
    }

    get exportAsImage() {
        return true;
    }

    _calcProps(props, options) {
        const { size, options: { showBulb } } = props;

        const bulbRadius = 60;
        const stemRadius = 30;
        const gaugePadding = 7;

        const stemWidth = stemRadius * 2;
        const gaugeWidth = showBulb ? bulbRadius * 2 : stemWidth;
        const stemOffset = (gaugeWidth - stemWidth) / 2;

        const stemHeight = showBulb ? size.height - stemRadius - bulbRadius * 2 : size.height - stemRadius * 2;

        const currentValue = (this.isAnimating ? (this.animationState.growProgress ?? 1) : 1) * this.model.value;

        let fluidHeight = stemHeight * currentValue / 100;
        if (showBulb) {
            fluidHeight += bulbRadius * 2; // add the bulb
        } else {
            fluidHeight += stemRadius; // add the bottom space
        }
        if (currentValue == 100) {
            fluidHeight += stemRadius; // fill the whole thermometer if 100%
        }

        const outerPath = this.drawGauge(stemRadius, size.height, showBulb, bulbRadius, 0)
            .toPathData();
        const innerPath = this.drawGauge(stemRadius, size.height, showBulb, bulbRadius, gaugePadding)
            .toPathData();

        const fluidTop = size.height - fluidHeight;

        const ticksTop = stemRadius - 5;
        const ticksHeight = stemHeight;

        return {
            size: new geom.Size(gaugeWidth, size.height),
            outerPath, innerPath, fluidTop, stemWidth, stemOffset, fluidHeight, ticksTop, ticksHeight
        };
    }

    drawGauge(stemRadius, stemHeight, showBulb, bulbRadius, innerPadding) {
        const path = new Path();

        path.moveTo(0 + innerPadding, stemRadius);
        path.arc(stemRadius - innerPadding, stemRadius - innerPadding, 180, 0, 1, stemRadius * 2 - innerPadding, stemRadius);

        if (showBulb) {
            path.lineTo(stemRadius * 2 - innerPadding, stemHeight - bulbRadius * 2 + innerPadding / 2);
            path.arc(bulbRadius - innerPadding, bulbRadius - innerPadding, 180, 1, 1, 0 + innerPadding, stemHeight - bulbRadius * 2 + innerPadding / 2);
        } else {
            path.lineTo(stemRadius * 2 - innerPadding, stemHeight - stemRadius);
            path.arc(stemRadius - innerPadding, stemRadius - innerPadding, 180, 1, 1, 0 + innerPadding, stemHeight - stemRadius);
        }

        path.close();

        if (showBulb) {
            path.offset(bulbRadius - stemRadius, 0);
        }

        return path;
    }

    renderChildren(transition) {
        let props = this.calculatedProps;
        return (
            <SVGGroup ref={this.ref} key={this.id}>
                <clipPath id={this.clipId}>
                    <path d={props.innerPath} />
                </clipPath>
                <path className="outer-path"
                    d={props.outerPath}
                    {...getSVGStyleProps(this.styles.thermometerPath)}
                />
                <rect className="gauge"
                    x={0}
                    y={props.size.height - props.fluidHeight - 5}
                    width={props.size.width}
                    height={props.fluidHeight}
                    clipPath={`url(#${this.clipId})`}
                    {...getSVGStyleProps(this.styles.thermometerGauge)}
                />
            </SVGGroup>
        );
    }
}

class ThermometerTicksContainer extends BaseElement {
    _build() {
        this.ThermometerTicks = this.addElement("ThermometerTicks", () => ThermometerTicks);
    }

    _calcProps(props, options) {
        const { size } = props;
        this.ThermometerTicks.calcProps(size, options);
        return { size };
    }
}

class ThermometerTicks extends SVGElement {
    get exportAsImage() {
        return true;
    }

    renderSVG(props, styles) {
        const { size, options: { showTickValues, formatValue } } = props;

        const ticks = [];
        let y = 0;

        const majorTickWidth = 20;
        const minorTickWidth = 10;
        const tickGap = 10;

        const majorTickDelta = size.height / (this.model.tickCount - 1);

        let minorTickCount = 0;
        if (majorTickDelta / 4 > 15) {
            minorTickCount = 4;
        } else if (majorTickDelta / 2 > 15) {
            minorTickCount = 2;
        }

        for (let i = 0; i < this.model.tickCount; i++) {
            const tickValue = formatValue((this.model.tickCount - i - 1) * this.model.targetMax / (this.model.tickCount - 1));

            if (showTickValues) {
                ticks.push(<text
                    key={`value${i}`}
                    dx={size.width - tickGap - majorTickWidth - 10}
                    dy={y + 5}
                    textAnchor="end"
                    fontFamily={this.styles.tickLabel.fontId}
                    fontWeight={this.styles.tickLabel.fontWeight}
                    fontSize={this.styles.tickLabel.fontSize}
                    fill={this.styles.tickLabel.resolved_fontColor.toRgbString()}
                >
                    {tickValue}
                </text>);
            }

            ticks.push(<line
                key={i}
                x1={size.width - majorTickWidth - tickGap}
                y1={y}
                x2={size.width - tickGap}
                y2={y}
                {...getSVGStyleProps(this.styles.majorTick)}
            />);

            let minorTickY = y;
            if (minorTickCount > 0 && i < this.model.tickCount - 1) {
                for (let t = 0; t < minorTickCount; t++) {
                    minorTickY += majorTickDelta / minorTickCount;
                    ticks.push(<line
                        key={`${i}${t}`}
                        x1={size.width - tickGap - minorTickWidth}
                        y1={minorTickY}
                        x2={size.width - tickGap}
                        y2={minorTickY}
                        {...getSVGStyleProps(this.styles.minorTick)}
                    />);
                }
            }
            y += majorTickDelta;
        }

        return (
            <SVGGroup ref={this.ref} key={this.id} >
                <g style={this.styles} id={this.id}>{ticks}</g>
            </SVGGroup>
        );
    }
}

export const elements = {
    Thermometer
};

