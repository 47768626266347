import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Select, MenuItem, Icon, Slider, Menu, Divider, Button } from "@material-ui/core";

import { _, $ } from "legacy-js/vendor";
import { getStaticUrl } from "legacy-js/config";
import { Key } from "js/core/utilities/keys";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { Gap20, Gap10 } from "legacy-js/react/components/Gap";
import { themeColors } from "legacy-js/react/sharedStyles";
import * as geom from "js/core/utilities/geom";
import { SnapLineDirection, SnapLineBindingPoint, SNAP_TOLERANCE } from "legacy-common/constants";
import { PopupMenu, PopupMenuPaddedContainer } from "legacy-js/react/components/PopupMenu";
import { LabeledContainer } from "legacy-js/react/components/LabeledContainer";
import { ColorPicker } from "legacy-js/react/components/ColorPicker";

import { ControlBar } from "../../EditorComponents/ControlBar";
import { ConvertScreenToSelectionLayer } from "./AuthoringHelpers";
import { BoundsBox } from "../../authoring/SelectionBox";
import { SelectInput } from "../../EditorComponents/SelectInput";
import { ShadowEditor } from "./Components/ShadowEditor";

const ADD_HANDLE_OFFSET = 20;

const MenuDivider = styled.hr`
    border: 1px solid #eeeeee;
`;

const AdjustmentContainer = styled.div.attrs(({ bounds }) => ({
    style: {
        ...bounds.toObject()
    }
}))`
    position: absolute;
    cursor: default;
`;

const AdjustPointHandle = styled.div.attrs(({ point, selected, canvas }) => ({
    style: {
        left: point.x * canvas.getScale() - 4,
        top: point.y * canvas.getScale() - 4,
        background: selected ? themeColors.ui_blue : "white",
        border: selected ? "none" : ("solid 1px " + themeColors.ui_blue),
        boxShadow: selected ? "0px 0px 0px 2px white" : "none"
    }
}))`
  position: absolute;
  background: white;
  border: solid 1px ${themeColors.ui_blue};
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  pointer-events: auto;
  &:hover {
    background: ${themeColors.ui_blue};
  } 
`;

const AddPointHandle = styled.div.attrs(({ point, canvas }) => {
    let x = point?.x || 0;
    let y = point?.y || 0;
    let scale = canvas.getScale() || 1;
    return {
        style: {
            left: x * scale - 7,
            top: y * scale - 7
        }
    };
})`
  position: absolute;
  background: ${themeColors.ui_blue};
  border: solid 1px ${themeColors.ui_blue};
  border-radius: 50%;
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-items: center;
  pointer-events: auto;
  .MuiIcon-root{
    font-size: 12px;
  }
`;

const MenuItemWithImage = styled(MenuItem)`
  &&& {
    justify-content: space-between !important;
  }
`;

const FullWidthSelectWithImage = styled(Select)`
  width: 100% !important;
  .MuiSelect-root {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
`;
class AuthoringPathDesigner extends Component {
    state = {
        isDragging: false,
        showContextMenu: false,
        selectedPoints: []
    }

    get pathElement() {
        const { selection } = this.props;
        return selection[0];
    }

    refresh() {
        this.pathElement.refreshElement();
        this.forceUpdate();
    }

    refreshAndSave() {
        const { refreshCanvasAndSaveChanges, onChange } = this.props;
        this.updateContainerBounds();
        return refreshCanvasAndSaveChanges()
            .then(() => onChange());
    }

    updateContainerBounds = () => {
        const model = this.pathElement.model;

        let left = _.minBy(model.points, pt => pt.x).x;
        const width = _.maxBy(model.points, pt => pt.x).x - left;
        let top = _.minBy(model.points, pt => pt.y).y;
        const height = _.maxBy(model.points, pt => pt.y).y - top;

        left += model.x;
        top += model.y;

        // Normalize path points coordinates
        for (const point of model.points) {
            point.x += model.x - left;
            point.y += model.y - top;
        }

        model.x = left;
        model.y = top;
        model.width = width;
        model.height = height;

        const minSize = 10;
        // We don't want the shape to have width or height less than minSize
        // which is possible for vertical or horizontal lines
        if (model.width < minSize) {
            const diff = (minSize - model.width) / 2;
            model.x -= diff;
            model.points.forEach(point => point.x += diff);
            model.width = minSize;
        }
        if (model.height < minSize) {
            const diff = (minSize - model.height) / 2;
            model.y -= diff;
            model.points.forEach(point => point.y += diff);
            model.height = minSize;
        }
    }

    componentDidMount() {
        $(document).on("mousedown.authoringPath", event => {
            const { stopEditing } = this.props;
            const { showContextMenu } = this.state;
            if (showContextMenu) {
                return;
            }

            if (!$(event.target).hasClass("handle")) {
                this.setState({
                    selectedPoints: []
                });
            }

            if (event.button == 0 && $(event.target).closest(".editor-control").length == 0) {
                stopEditing();
            }
        });

        $(document).on("keydown.authoringPath", event => {
            switch (event.which) {
                case Key.DELETE:
                case Key.BACKSPACE:
                    this.deleteSelectedPoints();
                    break;
                case Key.DOWN_ARROW:
                    this.moveSelectedPoints(0, event.shiftKey ? 10 : 1);
                    break;
                case Key.UP_ARROW:
                    this.moveSelectedPoints(0, event.shiftKey ? -10 : -1);
                    break;
                case Key.LEFT_ARROW:
                    this.moveSelectedPoints(event.shiftKey ? -10 : -1, 0);
                    break;
                case Key.RIGHT_ARROW:
                    this.moveSelectedPoints(event.shiftKey ? 10 : 1, 0);
                    break;
            }
        });
    }

    componentWillUnmount() {
        $(document).off(".authoringPath");
    }

    onStartDragging() {
        const { onStartEditing } = this.props;
        onStartEditing();

        this.setState({ isDragging: true });
    }

    onStopDragging() {
        const { onEndEditing } = this.props;
        onEndEditing();

        this.setState({ isDragging: false });
    }

    moveSelectedPoints(xShift, yShift) {
        const { selectedPoints } = this.state;

        for (const point of selectedPoints) {
            point.x += xShift;
            point.y += yShift;
        }

        this.refreshAndSave();
    }

    deleteSelectedPoints() {
        const { selectedPoints } = this.state;

        for (const point of selectedPoints) {
            if (this.pathElement.model.points.length === 2) {
                break;
            }
            this.pathElement.model.points.remove(point);
        }

        this.refreshAndSave();
    }

    handleStartDrag = (event, point) => {
        this.onStartDragging();

        let isDragging = true;
        let dragHandledAt;
        $(document).on("mousemove.dragShapePoint", event => {
            window.requestAnimationFrame(timestamp => {
                if (!isDragging) {
                    return;
                }
                if (dragHandledAt === timestamp) {
                    return;
                }

                dragHandledAt = timestamp;

                const offset = ConvertScreenToSelectionLayer(event.pageX, event.pageY).multiply(1 / this.pathElement.canvas.getScale());
                point.x = offset.x - this.pathElement.model.x;
                point.y = offset.y - this.pathElement.model.y;

                this.snapPoint(point);

                this.refresh();
            });
        });

        $(document).on("mouseup.dragShapePoint", event => {
            isDragging = false;

            $(document).off(".dragShapePoint");

            this.resetSnap();
            this.onStopDragging();
            this.refreshAndSave();
        });
    }

    handleMouseDown = (event, point) => {
        if (event.button !== 0) {
            return;
        }

        event.stopPropagation();

        this.setState({
            selectedPoints: [point],
            showContextMenu: false
        });

        this.handleStartDrag(event, point);
    }

    handlePointContextMenu = (event, point) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            selectedPoints: [point],
            showContextMenu: true
        });
    }

    handleAddPoint = (event, fromEnd) => {
        this.onStartDragging();

        let point;
        let isDragging = true;
        let dragHandledAt;
        $(document).on("mousemove.dragShapePoint", event => {
            window.requestAnimationFrame(timestamp => {
                if (!isDragging) {
                    return;
                }
                if (dragHandledAt === timestamp) {
                    return;
                }

                dragHandledAt = timestamp;

                const offset = ConvertScreenToSelectionLayer(event.pageX, event.pageY).multiply(1 / this.pathElement.canvas.getScale());

                const x = offset.x - this.pathElement.model.x;
                const y = offset.y - this.pathElement.model.y;

                if (!point) {
                    point = { x, y };

                    if (fromEnd) {
                        this.pathElement.model.points.push(point);
                    } else {
                        this.pathElement.model.points.insert(point, 0);
                    }
                } else {
                    point.x = x;
                    point.y = y;
                }

                this.snapPoint(point);

                this.refresh();
            });
        });

        $(document).on("mouseup.dragShapePoint", event => {
            isDragging = false;

            $(document).off(".dragShapePoint");

            this.resetSnap();
            this.onStopDragging();

            if (point) {
                this.refreshAndSave();
            }
        });
    }

    snapPoint = point => {
        const { calcSnap, drawSnapLines, containerElement, getElements, getSnapLines, snapToGrid, getSnapToGridOffset, showGridLines } = this.props;

        if (snapToGrid) {
            point.x += getSnapToGridOffset(point.x + this.pathElement.model.x);
            point.y += getSnapToGridOffset(point.y + this.pathElement.model.y);

            showGridLines();
        } else {
            const sourceSnapLines = this.getPointSnapLines(point);
            const destinationSnapLines = [];
            this.pathElement.model.points
                .filter(anotherPoint => anotherPoint !== point)
                .forEach(anotherPoint => destinationSnapLines.push(...this.getPointSnapLines(anotherPoint)));

            // All authoring elements including the path so we can snap points to the centerlines
            // of the path bounds
            destinationSnapLines.push(...getSnapLines(getElements()));
            // Including the authoring canvas
            destinationSnapLines.push(...getSnapLines([containerElement]));

            const snapLines = [];
            const { horizontalSnapLine, verticalSnapLine } = calcSnap(sourceSnapLines, destinationSnapLines);
            if (horizontalSnapLine || verticalSnapLine) {
                if (verticalSnapLine) {
                    point.x += verticalSnapLine.offset;
                    snapLines.push(verticalSnapLine);
                }
                if (horizontalSnapLine) {
                    point.y += horizontalSnapLine.offset;
                    snapLines.push(horizontalSnapLine);
                }
            }

            drawSnapLines(snapLines);
        }
    }

    resetSnap = () => {
        const { drawSnapLines, snapToGrid, hideGridLines } = this.props;

        if (snapToGrid) {
            hideGridLines();
        } else {
            drawSnapLines([]);
        }
    }

    getPointSnapLines = point => {
        // Authoring canvas bounds
        const containerBounds = this.pathElement.parentElement.selectionBounds.setPosition(0, 0);

        const bounds = new geom.Rect(this.pathElement.model.x, this.pathElement.model.y, this.pathElement.model.width, this.pathElement.model.height);

        return [{
            direction: SnapLineDirection.HORIZONTAL,
            bindingPoint: SnapLineBindingPoint.CENTER,
            corridorBounds: new geom.Rect(containerBounds.left, bounds.top + point.y - SNAP_TOLERANCE / 2, containerBounds.width, SNAP_TOLERANCE),
            snapLineBounds: new geom.Rect(containerBounds.left, bounds.top + point.y, containerBounds.width, 0),
            sourceBounds: containerBounds
        }, {
            direction: SnapLineDirection.VERTICAL,
            bindingPoint: SnapLineBindingPoint.CENTER,
            corridorBounds: new geom.Rect(bounds.left + point.x - SNAP_TOLERANCE / 2, containerBounds.top, SNAP_TOLERANCE, containerBounds.height),
            snapLineBounds: new geom.Rect(bounds.left + point.x, containerBounds.top, 0, containerBounds.height),
            sourceBounds: containerBounds
        }];
    }

    handleSetSelectedPointDecoration = decorationType => {
        const { selectedPoints } = this.state;

        const selectedPoint = selectedPoints[0];
        const selectedPointIndex = this.pathElement.model.points.indexOf(selectedPoint);

        if (selectedPointIndex === 0) {
            this.pathElement.model.startDecoration = decorationType;
        } else if (selectedPointIndex === this.pathElement.model.points.length - 1) {
            this.pathElement.model.endDecoration = decorationType;
        }

        this.refreshAndSave();
    }

    render() {
        const {
            bounds,
            offsetStartPoint,
            offsetEndPoint
        } = this.props;

        const {
            isDragging,
            selectedPoints,
            showContextMenu
        } = this.state;

        const model = this.pathElement.model;

        const selectedPoint = selectedPoints[0];
        const selectedPointPosition = selectedPoint
            ? new geom.Point(
                bounds.position
                    .offset(selectedPoint.x * this.pathElement.canvas.getScale(), selectedPoint.y * this.pathElement.canvas.getScale())
                    .offset($("#selection_layer").offset().left, $("#selection_layer").offset().top)
            )
            : new geom.Point(0, 0);

        const canDeletePoint = model.points.length > 2;
        const isLastOrFirstPointSelected = selectedPoint
            ? (model.points.indexOf(selectedPoint) === 0 || model.points.indexOf(selectedPoint) === model.points.length - 1)
            : false;

        return (
            <Fragment>
                <AdjustmentContainer ref={this.ref} bounds={bounds.zeroOffset()}>
                    {model.points.map((point, index) => (
                        <AdjustPointHandle
                            key={index}
                            className="editor-control"
                            point={point}
                            canvas={this.pathElement.canvas}
                            selected={selectedPoint === point}
                            onMouseDown={event => this.handleMouseDown(event, point)}
                            onContextMenu={event => this.handlePointContextMenu(event, point)}
                        />
                    ))}

                    {!isDragging &&
                        <AddPointHandle
                            className="editor-control"
                            point={offsetStartPoint}
                            canvas={this.pathElement.canvas}
                            onMouseDown={event => this.handleAddPoint(event, false)}
                        >
                            <Icon>add</Icon>
                        </AddPointHandle>
                    }

                    {!isDragging &&
                        <AddPointHandle
                            className="editor-control"
                            point={offsetEndPoint}
                            canvas={this.pathElement.canvas}
                            onMouseDown={event => this.handleAddPoint(event, true)}
                        >
                            <Icon>add</Icon>
                        </AddPointHandle>
                    }
                </AdjustmentContainer>
                <Menu
                    open={showContextMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={{ left: selectedPointPosition.x, top: selectedPointPosition.y }}
                    onClose={() => this.setState({ showContextMenu: false })}
                >
                    {isLastOrFirstPointSelected && <MenuItemWithImage
                        onMouseDown={() => {
                            this.setState({ showContextMenu: false });
                            this.handleSetSelectedPointDecoration("none");
                        }}
                    >
                        Plain
                        <img src={getStaticUrl("/images/ui/connectors/line-start-none.svg")} />
                    </MenuItemWithImage>}
                    {isLastOrFirstPointSelected && <MenuItemWithImage
                        onMouseDown={() => {
                            this.setState({ showContextMenu: false });
                            this.handleSetSelectedPointDecoration("arrow");
                        }}
                    >
                        Arrow
                        <img src={getStaticUrl("/images/ui/connectors/line-start-arrow.svg")} />
                    </MenuItemWithImage>}
                    {isLastOrFirstPointSelected && <MenuItemWithImage
                        onMouseDown={() => {
                            this.setState({ showContextMenu: false });
                            this.handleSetSelectedPointDecoration("circle");
                        }}
                    >
                        Circle
                        <img src={getStaticUrl("/images/ui/connectors/line-start-circle.svg")} />
                    </MenuItemWithImage>}
                    {(canDeletePoint && isLastOrFirstPointSelected) && <MenuDivider />}
                    {canDeletePoint && <MenuItemWithImage
                        onMouseDown={() => {
                            this.setState({ showContextMenu: false });
                            this.deleteSelectedPoints();
                        }}
                    >
                        <Icon>delete_outline</Icon>
                        Delete point
                    </MenuItemWithImage>}
                </Menu>
            </Fragment>
        );
    }
}

export class AuthoringPathEditor extends Component {
    constructor(props) {
        super(props);

        this.controlBarRef = React.createRef();
        this.designerRef = React.createRef();
        this.state = {
            isEditing: false,
            fill: null,
            stroke: null,
            strokeWidth: null,
            strokeStyle: null,
            opacity: null,
            cornerRadius: null,
        };
    }

    componentDidMount() {
        this.setSelectionState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selection !== this.props.selection) {
            this.setSelectionState();
        }
    }

    setSelectionState = () => {
        this.setState({
            x: this.getModelValue("x"),
            y: this.getModelValue("y"),
            width: this.getModelValue("width"),
            height: this.getModelValue("height"),
            rotation: this.getModelValue("rotation"),
            opacity: this.getModelValue("opacity"),
            fill: this.getModelValue("fill"),
            stroke: this.getModelValue("stroke"),
            strokeWidth: this.getModelValue("strokeWidth"),
            strokeStyle: this.getModelValue("strokeStyle"),
            startDecoration: this.getModelValue("startDecoration") || "none",
            endDecoration: this.getModelValue("endDecoration") || "none",
            cornerRadius: this.getModelValue("cornerRadius"),
            shadow: this.getModelValue("shadow")
        });
    }

    getModelValue = property => {
        const values = [...new Set(this.props.selection.map(element => element.model[property]))];
        if (values.length == 1) {
            return values[0];
        } else {
            return "mixed";
        }
    }

    setModelValue = (property, value) => {
        const { refreshCanvasAndSaveChanges } = this.props;

        for (const element of this.props.selection) {
            element.model[property] = value;
        }

        refreshCanvasAndSaveChanges().then(() => this.setState({ [property]: value }));
    }

    render() {
        const {
            selection,
            bounds,
            startEditing,
            isEditing
        } = this.props;

        const {
            // isEditing,
            fill,
            stroke,
            shadow,
            strokeWidth,
            strokeStyle,
            opacity,
            startDecoration,
            endDecoration
        } = this.state;

        let offsetStartPoint = null;
        let offsetEndPoint = null;
        let controlBarOffset = 44;
        if (isEditing) {
            const model = selection[0].model;

            const firstSegment = new geom.Line(
                new geom.Point(model.points[1]),
                new geom.Point(model.points[0])
            );
            offsetStartPoint = new geom.Point(
                firstSegment.end.x + (firstSegment.end.x - firstSegment.start.x) / firstSegment.length * ADD_HANDLE_OFFSET,
                firstSegment.end.y + (firstSegment.end.y - firstSegment.start.y) / firstSegment.length * ADD_HANDLE_OFFSET
            );

            const lastSegment = new geom.Line(
                new geom.Point(model.points[model.points.length - 2]),
                new geom.Point(model.points[model.points.length - 1])
            );
            offsetEndPoint = new geom.Point(
                lastSegment.end.x + (lastSegment.end.x - lastSegment.start.x) / lastSegment.length * ADD_HANDLE_OFFSET,
                lastSegment.end.y + (lastSegment.end.y - lastSegment.start.y) / lastSegment.length * ADD_HANDLE_OFFSET
            );

            const bottomShift = Math.max(offsetStartPoint.y - model.height, offsetEndPoint.y - model.height);
            if (bottomShift > 0) {
                controlBarOffset += bottomShift;
            }
        }

        return (
            <BoundsBox
                bounds={bounds.zeroOffset()}
            >
                {isEditing && <AuthoringPathDesigner
                    {...this.props}
                    ref={this.designerRef}
                    onStartEditing={() => this.setState({ isEditing: true })}
                    onEndEditing={() => this.setState({ isEditing: false })}
                    offsetStartPoint={offsetStartPoint}
                    offsetEndPoint={offsetEndPoint}
                    onChange={this.setSelectionState}
                />}
                {!isEditing && <ControlBar ref={this.controlBarRef} offset={controlBarOffset}>
                    <Button onClick={startEditing}>Edit Path</Button>
                    <ColorPicker
                        label="Fill"
                        color={fill}
                        showNone
                        showColorPicker
                        onChange={color => this.setModelValue("fill", color)}
                    />
                    <ColorPicker
                        label="Stroke"
                        color={stroke}
                        showNone
                        showColorPicker
                        onChange={color => this.setModelValue("stroke", color)}
                    >
                        <FlexBox left middle style={{ marginBottom: 10 }}>
                            <label>Width</label>
                            <Gap10 />
                            <SelectInput
                                value={strokeWidth}
                                onChange={value => this.setModelValue("strokeWidth", value)}
                            >
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                            </SelectInput>

                            <Gap20 />
                            <label>Style</label>
                            <Gap10 />

                            <ToggleButtonGroup value={strokeStyle} exclusive onChange={(event, value) => this.setModelValue("strokeStyle", value)}>
                                <ToggleButton value="solid">
                                    <img src={getStaticUrl("/images/ui/connectors/line-style-solid.svg")} />
                                </ToggleButton>
                                <ToggleButton value="dotted">
                                    <img src={getStaticUrl("/images/ui/connectors/line-style-dotted.svg")} />
                                </ToggleButton>
                                <ToggleButton value="dashed">
                                    <img src={getStaticUrl("/images/ui/connectors/line-style-dashed.svg")} />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </FlexBox>
                    </ColorPicker>
                    <PopupMenu icon="compare_arrows">
                        <PopupMenuPaddedContainer>
                            <LabeledContainer label="Start decoration">
                                <FullWidthSelectWithImage
                                    value={startDecoration}
                                    onChange={event => this.setModelValue("startDecoration", event.target.value)}
                                    disableUnderline
                                    variant="outlined"
                                >
                                    <MenuItemWithImage value="none">None<img src={getStaticUrl("/images/ui/connectors/line-start-none.svg")} /></MenuItemWithImage>
                                    <MenuItemWithImage value="arrow">Arrow<img src={getStaticUrl("/images/ui/connectors/line-start-arrow.svg")} /></MenuItemWithImage>
                                    <MenuItemWithImage value="circle">Circle<img src={getStaticUrl("/images/ui/connectors/line-start-circle.svg")} /></MenuItemWithImage>
                                </FullWidthSelectWithImage>
                            </LabeledContainer>
                            <LabeledContainer label="End decoration">
                                <FullWidthSelectWithImage
                                    value={endDecoration}
                                    onChange={event => this.setModelValue("endDecoration", event.target.value)}
                                    disableUnderline
                                    variant="outlined"
                                >
                                    <MenuItemWithImage value="none">None<img src={getStaticUrl("/images/ui/connectors/line-start-none.svg")} /></MenuItemWithImage>
                                    <MenuItemWithImage value="arrow">Arrow<img src={getStaticUrl("/images/ui/connectors/line-start-arrow.svg")} /></MenuItemWithImage>
                                    <MenuItemWithImage value="circle">Circle<img src={getStaticUrl("/images/ui/connectors/line-start-circle.svg")} /></MenuItemWithImage>
                                </FullWidthSelectWithImage>
                            </LabeledContainer>
                        </PopupMenuPaddedContainer>
                    </PopupMenu>
                    <PopupMenu icon="settings">
                        <PopupMenuPaddedContainer>
                            <LabeledContainer icon="lens_blur" label="Shadow">
                                <ShadowEditor shadow={shadow} onChange={value => this.setModelValue("shadow", value)} />
                            </LabeledContainer>
                            <LabeledContainer icon="opacity" label="Opacity">
                                <Slider
                                    value={_.defaultTo(opacity, 100)}
                                    onChange={(event, value) => this.setModelValue("opacity", value)}
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={100}
                                />
                            </LabeledContainer>
                        </PopupMenuPaddedContainer>
                    </PopupMenu>
                </ControlBar>}
            </BoundsBox >
        );
    }
}
