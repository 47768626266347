import * as geom from "js/core/utilities/geom";
import { VerticalAlignType, HorizontalAlignType } from "legacy-common/constants";
import { breakColumns, fitIntoColumns } from "js/core/utilities/linebreak";
import { _ } from "legacy-js/vendor";
import { getSizingValue } from "js/core/utilities/utilities";
import perf from "js/core/utilities/perf";

export function calcColumnLayout(options = {}) {
    let styles = _.defaults({}, options, this.styles, {
        vGap: 0,
        hGap: 0,
        minCols: 1,
        maxCols: 2,
        maxRows: 100,
        maxItemHeight: "100%",
        maxColWidth: "100%",
        minColWidth: 300,
        distributeRows: "left",
        hGridAlign: "center",
        vGridAlign: "middle",
        autoSize: false,
        colAlign: "top"
    });

    let maxColWidth = getSizingValue(styles.maxColWidth, this.containerSize.width);
    let minColWidth = getSizingValue(styles.minColWidth, this.containerSize.width);
    let maxItemHeight = getSizingValue(styles.maxItemHeight, this.containerSize.height);
    let minItemHeight = options.minItemHeight || 50;

    let maxActualCols = Math.floor(this.containerSize.width / minColWidth);

    let maxCols = Math.min(styles.maxCols, maxActualCols, this.items.length);
    let minCols = Math.min(styles.minCols, this.items.length, maxCols);

    if (options.forceSingleColumn) {
        minCols = 1;
        maxCols = 1;
    }

    let calcColWidth = cols => Math.min(maxColWidth, Math.floor((this.containerSize.width - (cols - 1) * styles.hGap) / cols));

    let calcLayoutsForCols = cols => this.items.map(item => {
        return item.calcProps(new geom.Size(calcColWidth(cols), maxItemHeight), options.itemOptions);
    });

    let isFit = true;
    let heights, isTextClipped;

    if (options.forceFit) {
        // get a list of the items desired sizes
        let desiredItemHeights = this.items.map(item => {
            return item.calcProps(new geom.Size(calcColWidth(maxCols), maxItemHeight), options.itemOptions).size.height;
        });

        heights = fitIntoColumns(maxCols, styles.vGap, this.containerSize.height, minItemHeight, desiredItemHeights);

        if (heights) {
            isFit = true;
        } else {
            this.isFit = false;
            this.size = this.containerSize;
            return this;
        }
    } else {
        perf.start("breakColums");
        if (styles.distributeRows === "even") {
            ({
                heights,
                isFit,
                isTextClipped
            } = breakColumns(minCols, maxCols, styles.vGap, this.containerSize.height, calcLayoutsForCols));
        } else {
            for (let cols = minCols; cols <= maxCols; cols++) {
                let calculatedItemHeights = calcLayoutsForCols(cols).map(props => props.size.height);
                let heightsByCol = [[]];
                let currHeight = 0;

                while (calculatedItemHeights.length) {
                    if (currHeight + calculatedItemHeights[0] < this.containerSize.height || heightsByCol.length == maxCols) {
                        currHeight += calculatedItemHeights[0] + styles.vGap;
                        heightsByCol[heightsByCol.length - 1].push(calculatedItemHeights.shift());
                    } else {
                        currHeight = calculatedItemHeights[0];
                        heightsByCol.push([calculatedItemHeights.shift()]);
                    }
                }

                if (heightsByCol.length == cols) {
                    heights = heightsByCol;
                    break;
                }
            }
        }
        perf.stop("breakColums");
    }

    if (!heights) {
        this.isFit = false;
        this.size = this.containerSize;

        for (let item of this.items) {
            item.calculatedProps.bounds = new geom.Rect(0, 0, this.containerSize.width, 20);
        }

        return this;
    }

    let totalCols = heights.length;
    let colWidth = calcColWidth(totalCols);

    // calculate the size of each item and organize into an array of arrays for cols of items
    let itemIdx = 0;
    let itemsInColumns = [];

    for (let columnIdx = 0; columnIdx < heights.length; columnIdx++) {
        const rawItemsInColumn = [];
        for (let itemInColumnIdx = 0; itemInColumnIdx < heights[columnIdx].length; itemInColumnIdx++) {
            rawItemsInColumn.push(this.items[itemIdx]);
            itemIdx++;
        }

        let actualColumnHeight = 0;
        const itemsInColumn = [];
        rawItemsInColumn.forEach((item, itemInColumnIdx) => {
            let itemHeight = heights[columnIdx][itemInColumnIdx];
            actualColumnHeight += itemHeight;
            if (actualColumnHeight > this.containerSize.height) {
                // shrink item's available height
                itemHeight -= actualColumnHeight - this.containerSize.height;
            }
            actualColumnHeight += styles.vGap;
            const itemProps = item.calcProps(
                new geom.Size(colWidth, itemHeight),
                options.itemOptionsCallback ? options.itemOptionsCallback(item, columnIdx, totalCols, rawItemsInColumn) : options.itemOptions
            );
            itemsInColumn.push(itemProps);
        });
        itemsInColumns.push(itemsInColumn);
    }

    // calculate the total width and height of the items
    let totalWidth = (colWidth + styles.hGap) * totalCols - styles.hGap;

    let colHeights = _.map(itemsInColumns, itemsInColumn => _.sumBy(itemsInColumn, item => item.size.height + styles.vGap) - styles.vGap);
    let totalHeight = Math.max(...colHeights);
    let totalSize = new geom.Size(totalWidth, totalHeight);

    // check if the items will fit within the container
    if (totalWidth > this.containerSize.width || totalHeight > this.containerSize.height) {
        isFit = false;
    }

    //calculate the offsets needed to vertically align each columns
    let verticalOffsets = [];
    switch (styles.colAlign) {
        case VerticalAlignType.TOP:
            for (let col = 0; col < totalCols; col++) {
                verticalOffsets.push(0);
            }
            break;
        case VerticalAlignType.MIDDLE:
            for (let col = 0; col < totalCols; col++) {
                let colHeight = _.reduce(itemsInColumns[col], (memo, item) => {
                    return memo + item.size.height + styles.vGap;
                }, -styles.vGap);
                verticalOffsets.push(totalSize.height / 2 - colHeight / 2);
            }
            break;
        case VerticalAlignType.BOTTOM:
            for (let col = 0; col < totalCols; col++) {
                let colHeight = _.reduce(itemsInColumns[col], (memo, item) => {
                    return memo + item.size.height + styles.vGap;
                }, -styles.vGap);
                verticalOffsets.push(totalSize.height - colHeight);
            }
            break;
    }

    // position the items
    itemIdx = 0;
    for (let col = 0; col < totalCols; col++) {
        let x = col * (colWidth + styles.hGap);
        let y = verticalOffsets[col];

        let colItems = itemsInColumns[col] || [];

        // calculate the widest item in the column
        let maxItemWidthInColumn = _.maxBy(colItems, item => {
            return item.size.width;
        }).size.width;

        for (let item of colItems) {
            // for (let i = 0; i < itemsInColumn.length; i++) {
            //     let item = this.items[itemIdx];

            // horizontally align each item within the column
            let posX = x;
            switch (styles.rowAlign || "left") {
                case HorizontalAlignType.LEFT:
                    break;
                case HorizontalAlignType.CENTER:
                    posX += colWidth / 2 - maxItemWidthInColumn / 2;
                    break;
                case HorizontalAlignType.RIGHT:
                    posX += colWidth - item.size.width;
                    break;
            }

            switch (styles.itemHorizontalAlign || "left") {
                case HorizontalAlignType.LEFT:
                    break;
                case HorizontalAlignType.CENTER:
                    break;
                case HorizontalAlignType.RIGHT:
                    posX += maxItemWidthInColumn - item.size.width;
                    break;
            }

            // set the item bounds
            item.bounds = new geom.Rect(posX, y, item.size);

            y += item.size.height + styles.vGap;
            // itemIdx++;
        }
    }

    // any itemProps without bounds were not fit
    for (let item of this.items) {
        if (!item.calculatedProps.bounds) {
            item.calculatedProps.bounds = new geom.Rect(0, 0, 200, 100);
        }
    }

    this.itemsInColumns = itemsInColumns;
    this.size = totalSize;
    this.isFit = isFit;
    this.isTextFit = this.items.every(item => item.calculatedProps.isTextFit !== false);

    return this;
}
