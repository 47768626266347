import React from "react";
import { ElementBlockEditor } from "./ElementBlockEditor";
import { BoundsBox } from "../../SelectionBox";
import { ControlBar, ControlBarGroup } from "../../../EditorComponents/ControlBar";
import { themeColors } from "legacy-js/react/sharedStyles";
import { BlockTypePopupMenu } from "./TextFormatBar";
import { PopupMenu } from "legacy-js/react/components/PopupMenu";
import { MenuItem } from "@material-ui/core";
import { ColorPicker } from "legacy-js/react/components/ColorPicker";

export class StartRegionBlockEditor extends ElementBlockEditor {
    handleChangeStyle = type => {
        const { block, refreshCanvasAndSaveChanges } = this.props;
        block.model.regionType = type;
        refreshCanvasAndSaveChanges();
    }

    render() {
        const { bounds, block, editorConfig, refreshCanvasAndSaveChanges } = this.props;
        const { height } = this.state;

        return (
            <BoundsBox bounds={bounds}>
                <ControlBar position="above" offset={10}>
                    <ControlBarGroup color={themeColors.darkBlue}>
                        <BlockTypePopupMenu
                            selectedBlocks={[block]}
                            allowedBlockTypes={editorConfig.allowedBlockTypes}
                            onChange={refreshCanvasAndSaveChanges}
                        />
                    </ControlBarGroup>
                    <ControlBarGroup>
                        <PopupMenu label="Style" showArrow onChange={this.handleChangeStyle} childrenAreMenuItems>
                            <MenuItem value="inset">Inset</MenuItem>
                            <MenuItem value="color">Color</MenuItem>
                        </PopupMenu>
                    </ControlBarGroup>
                </ControlBar>
            </BoundsBox>
        );
    }
}
