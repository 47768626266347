import * as geom from "js/core/utilities/geom";
import { FormatType, BlockStructureType } from "legacy-common/constants";

import { Pictograph } from "../../elements/infographics/Pictograph";
import { TextElement } from "../../base/Text/TextElement";

import CompareValuesItem from "./CompareValuesItem";
import CompareValuesItemLabel from "./CompareValuesItemLabel";

export default class CompareVerticalPictographItem extends CompareValuesItem {
    _build() {
        this.pictograph = this.addElement("pictograph", () => Pictograph, {
            labelElement: CompareValuesItemLabel,
            valueFunction: () => this.percentageValue,
            showValue: this.parentElement.format != FormatType.NONE,
            valuePosition: this.parentElement.model.valuePosition
        });
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            autoHeight: true,
            syncFontSizeWithSiblings: true
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let textProps = this.text.calcProps(new geom.Size(size.width, options.textHeight), options);
        let pictProps = this.pictograph.calcProps(size.deflate({ bottom: options.textHeight }));
        pictProps.itemIndex = this.itemIndex;
        pictProps.bounds = new geom.Rect(size.width / 2 - pictProps.size.width / 2, 0, pictProps.size);
        textProps.bounds = new geom.Rect(0, pictProps.size.height, textProps.size);

        return { size };
    }

    get animationElementName() {
        return `Icons #${this.itemIndex + 1}`;
    }

    _getAnimations() {
        return [{
            name: "Fill in",
            prepare: () => {
                this.text.animationState.fadeInProgress = 0;
                this.pictograph.animationState.fadeInProgress = 0;
                this.pictograph.animationState.value = this.pictograph.icons.animationState.value = 0;
            },
            onBeforeAnimationFrame: progress => {
                this.text.animationState.fadeInProgress = Math.clamp((progress - 0.7) / 0.3, 0, 1);
                this.pictograph.animationState.fadeInProgress = Math.min(1, progress * 3);
                this.pictograph.animationState.value = this.pictograph.icons.animationState.value = progress;

                return this;
            }
        }];
    }
}
