import { _ } from "legacy-js/vendor";
import * as geom from "js/core/utilities/geom";

import { TimelineSpanItem } from "./TimelineSpanItem";
import { TextElement } from "../../base/Text/TextElement";
import { SVGRectElement } from "../../base/SVGElement";

export class TimelineSpanSliceItem extends TimelineSpanItem {
    get cornerRadius() {
        return this.styles.cornerRadius;
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGRectElement);
        this.label = this.addElement("label", () => TextElement, this.textElementProps);
    }

    _calcProps(props) {
        const { size } = props;

        const shapeProps = this.shape.calcProps(size);
        shapeProps.bounds = new geom.Rect(0, 0, size);
        shapeProps.cornerRadius = this.cornerRadius;

        const labelProps = this.label.calcProps(size);
        labelProps.bounds = new geom.Rect(0, size.height / 2 - labelProps.size.height / 2, labelProps.size);

        return { size };
    }

    getBackgroundColor(forElement) {
        if (forElement && forElement instanceof TextElement) {
            return this.getShapeFillColor(this.shape);
        } else {
            return super.getBackgroundColor(forElement);
        }
    }
}
