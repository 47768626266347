function convertChartType(chartModel, convertTo) {
    const infographicElement = chartModel.infographic;

    switch (convertTo) {
        case "pie":
        case "donut":
            // switch to pie chart

            // if we are currently a chart, migrate first series of chart data into pie
            if (chartModel.elementType == "chart") {
                // If the chart has connected annotations, remove them as they're not supported by the pie or donut charts
                if (infographicElement?.model?.annotations) {
                    infographicElement.model.annotations = {};
                }

                let categories = chartModel.chart.chartData.xAxis.categories;
                let data = chartModel.chart.chartData.series[0].data;
                let pieData = [];
                for (let i = 0; i < categories.length; i++) {
                    pieData.push({
                        label: {
                            text: categories[i]
                        },
                        value: data[i] ? data[i].y : 0,
                        color: "auto",
                        offset: 0
                    });
                }
                chartModel.piechart = {
                    data: pieData,
                    isDonut: convertTo === "donut"
                };

                chartModel.elementType = "piechart";
                delete chartModel.chart;
            } else if (chartModel.elementType === "piechart") {
                chartModel.piechart.isDonut = (convertTo === "donut");
            }

            // when switching to a piechart and scale should be reset, set the chartScale to null so it autofits
            chartModel.piechart.chartScale = null;

            break;
        default:
            // switch to standard chart

            // if we are currently already a chart, update the chart type
            if (chartModel.elementType === "chart") {
                for (let series of chartModel.chart.chartData.series) {
                    series.type = convertTo;
                }
            } else {
                // otherwise, update any existing series in the model to the new type
                // if the dashboardItem already has a chart model, update it's series
                if (chartModel.chart?.chartData?.series) {
                    for (let series of chartModel.chart.chartData.series) {
                        series.type = convertTo;
                    }
                } else {
                    // otherwise create a new chart model with one series of the new type
                    let defaultModel = getDefaultChartModel(convertTo);

                    // if we are currently a piechart, migrate the chart data into the first series of any existing chart data
                    if (chartModel.elementType == "piechart") {
                        defaultModel.chartData.xAxis.categories = [];
                        defaultModel.chartData.series[0].data = [];
                        for (let d of chartModel.piechart.data) {
                            // Convert the label blocks into a space delimited string
                            const text = d.label.blocks
                                .map(block => block.html)
                                .join(" ");
                            defaultModel.chartData.xAxis.categories.push(text);
                            defaultModel.chartData.series[0].data.push({ y: d.showZeroFlag ? 0 : d.value, pt: true });
                        }
                    }

                    chartModel.chart = defaultModel;
                }

                // and set the elementType so we build a chart
                chartModel.elementType = "chart";
            }
            break;
    }

    return chartModel;
}

function getDefaultChartModel(seriesType) {
    const year = (new Date()).getFullYear();

    return {
        showLegend: false,
        showXAxis: true,
        showYAxis: true,
        showAxisLabels: false,
        showXGridLines: true,
        showYGridLines: true,
        groupData: false,
        chartData: {
            series: [
                {
                    id: "series1",
                    name: "Data",
                    type: seriesType,
                    data: [
                        { y: 10 },
                        { y: 40 },
                        { y: 50 },
                        { y: 30 },
                        { y: 70 },
                        { y: 50 },
                        { y: 90 },
                        { y: 110 }
                    ],
                    colorName: "chart1",
                    marker: "none",
                    zones: [
                        {
                            style: "default",
                        }
                    ]
                }
            ],
            xAxis: {
                visible: true,
                showFirstLabel: true,
                showLastLabel: true,
                endOnTick: true,
                categories: [
                    `Jan 1 ${year}`,
                    `Feb 1 ${year}`,
                    `Mar 1 ${year}`,
                    `Apr 1 ${year}`,
                    `May 1 ${year}`,
                    `Jun 1 ${year}`,
                    `Jul 1 ${year}`,
                    `Aug 1 ${year}`
                ],
                dateFormatting: "MMM",
                showAxisLine: true,
                showGridLine: false,
                showMajorTicks: false,
                showMinorTicks: false,
                axisTitle: false,
                axisTitleText: "Title",
                labelInterval: 1,
                type: "linear",
                autoType: "linear",
                categoryType: "auto",
                labels: {
                    enabled: true,
                },
                zeroAxisPadding: false
            },
            yAxis: {
                visible: true,
                showFirstLabel: true,
                showLastLabel: true,
                endOnTick: true,
                showAxisLine: true,
                showGridLines: true,
                showMajorTicks: false,
                showMinorTicks: false,
                labelAbbreviation: false,
                labelCurrency: "none",
                labelFormat: "ones",
                axisTitle: "none",
                axisTitleText: "Title",
                labels: {
                    enabled: true
                },
                format: "number",
                formatOptions: {
                    decimal: 0,
                    separator: true,
                    abbreviated: false,
                    changeStyle: "none",
                    changeColor: true,
                    currency: "$",
                    dateFormat: "MMM Do",
                    accountingStyle: false
                }
            },
            yAxis2: {
                visible: false,
                opposite: true,
                showFirstLabel: true,
                showLastLabel: true,
                endOnTick: true,
                showAxisLine: true,
                showGridLines: true,
                showMajorTicks: false,
                showMinorTicks: false,
                labelAbbreviation: false,
                labelCurrency: "none",
                labelFormat: "ones",
                axisTitle: "none",
                axisTitleText: "Title",
                labels: {
                    enabled: true
                },
                format: "number",
                formatOptions: {
                    decimal: 0,
                    separator: true,
                    abbreviated: false,
                    changeStyle: "none",
                    changeColor: true,
                    currency: "$",
                    dateFormat: "MMM Do",
                    accountingStyle: false
                }
            },
            legend: {
                enabled: false
            }
        },
        legendPosition: undefined,
    };
}

module.exports = {
    convertChartType,
    getDefaultChartModel
};
