const { ListStyleType } = require("../../../constants");

const variations = {

    tasklist: [
        {
            label: "Kanban Board",
            template: "kanban",
        }, {
            label: "Horizontal Task List",
            template: "horizontal-task-list",
        }
    ],

    calendar: [
        {
            label: "Month Calendar",
            template: "calendar-month",
        }, {
            label: "Week Calendar",
            template: "calendar-week",
        }
    ],

    target: [
        {
            label: "Target",
            template: "target",
            icon: "target",
            model: {
                primary: {
                    items: [
                        {
                            segments: [{
                                color: "auto"
                            }],
                        }, {
                            segments: [{
                                color: "auto"
                            }],
                        }, {
                            segments: [{
                                color: "auto"
                            }],
                        }
                    ]
                }
            }
        }, {
            label: "Segmented Ring",
            template: "target",
            icon: "segmented-ring",
            model: {
                primary: {
                    innerRadius: 80,
                    items: [
                        {
                            segments: [{
                                color: "auto",
                                labelType: "inside",
                                label: "Section 1"
                            }, {
                                color: "auto",
                                labelType: "inside",
                                label: "Section 2"
                            }, {
                                color: "auto",
                                labelType: "inside",
                                label: "Section 3"
                            }, {
                                color: "auto",
                                labelType: "inside",
                                label: "Section 4"
                            }, {
                                color: "auto",
                                labelType: "inside",
                                label: "Section 5"
                            }, {
                                color: "auto",
                                labelType: "inside",
                                label: "Section 6"
                            }]
                        }
                    ]
                }
            }
        }

    ],

    title: [
        {
            label: "Plain",
            icon: "title",
            model: {
                primary: {
                    decoration: "none"
                }
            },
            transition: false
        },
        {
            label: "Left Bar",
            icon: "title_bar_left",
            model: {
                primary: {
                    decoration: "left_border"
                }
            },
            transition: false
        },
        {
            label: "Top Bar",
            icon: "title_bar_top",
            model: {
                primary: {
                    decoration: "top_border"
                }
            },
            transition: false
        },
        {
            label: "Border",
            icon: "title_border",
            model: {
                primary: {
                    decoration: "frame"
                }
            },
            transition: false
        },
        {
            label: "Block",
            icon: "title_block",
            model: {
                primary: {
                    decoration: "block",
                    textStyle: null
                }
            },
            transition: false
        },
        {
            label: "Underline",
            icon: "title_underline",
            model: {
                primary: {
                    decoration: "underline",
                    textStyle: null
                }
            }
        },

    ],

    compare: [
        {
            label: "Radial Bars",
            template: "compare_radial_bars",
        }, {
            label: "Circles",
            template: "compare_circles",
        }, {
            label: "Vertical Bars",
            template: "compare_vertical_bars",
        }, {
            label: "Horizontal Bars",
            template: "compare_horizontal_bars",
        }, {
            label: "Arrow Bars",
            template: "arrow_bars",
        }, {
            label: "Vertical Pictograph",
            template: "compare_vertical_pictograph",
        }, {
            label: "Radial Bar Chart",
            template: "radial_bar_chart",
        }, {
            label: "Pictorial Charts",
            template: "visual_percentage",
        }, {
            label: "Compare Slices",
            template: "slicechart",
        }, {
            label: "Venn Diagram",
            template: "vennDiagram",
        }
    ],

    stackdiagram: [
        {
            label: "Hierarchy",
            template: "stackDiagram",
            icon: "stack_hierarchy",
            model: {
                primary: {
                    items: [{
                        x: 0, y: 0, width: 12, height: 4
                    }, {
                        x: 0, y: 4, width: 6, height: 4
                    }, {
                        x: 6, y: 4, width: 6, height: 4
                    }, {
                        x: 0, y: 8, width: 4, height: 4
                    }, {
                        x: 4, y: 8, width: 4, height: 4
                    }, {
                        x: 8, y: 8, width: 4, height: 4
                    }]
                }
            }
        },
        {
            label: "Architecture",
            template: "stackDiagram",
            icon: "stack_architecture",
            model: {
                primary: {
                    items: [{
                        x: 0, y: 0, width: 12, height: 3
                    }, {
                        x: 0, y: 3, width: 3, height: 6
                    }, {
                        x: 3, y: 3, width: 3, height: 6
                    }, {
                        x: 6, y: 3, width: 3, height: 6
                    }, {
                        x: 9, y: 3, width: 3, height: 6
                    }, {
                        x: 0, y: 9, width: 12, height: 3
                    }]
                }
            }
        },
        {
            label: "Side By Side",
            template: "stackDiagram",
            icon: "stack_sidebyside",
            model: {
                primary: {
                    items: [{
                        x: 0, y: 0, height: 6, width: 3
                    }, {
                        x: 0, y: 6, height: 3, width: 6
                    }, {
                        x: 3, y: 0, width: 3, height: 6,
                    }, {
                        x: 6, y: 0, width: 3, height: 6,
                    }, {
                        x: 0, y: 9, width: 6, height: 3,
                    }, {
                        x: 6, y: 6, width: 6, height: 3,
                    }, {
                        x: 9, y: 0, width: 3, height: 6,
                    }, {
                        x: 6, y: 9, width: 6, height: 3,
                    }]
                }
            }
        },
        {
            label: "Four Square",
            template: "stackDiagram",
            icon: "stack_foursquare",
            model: {
                primary: {
                    items: [{
                        x: 0, y: 0, width: 6, height: 6
                    }, {
                        x: 0, y: 6, width: 6, height: 6
                    }, {
                        x: 6, y: 0, width: 6, height: 6
                    }, {
                        x: 6, y: 6, width: 6, height: 6
                    }]
                }
            }
        },
    ],

    pyramid: [
        {
            label: "Pyramid",
            template: "pyramid",
            icon: "pyramid",
            model: {
                primary: {
                    capSize: 0,
                    baseSize: 60,
                    step: false,
                    inverted: false,
                    capAlign: "center"
                }
            }
        },
        {
            label: "Funnel",
            template: "pyramid",
            icon: "funnel",
            model: {
                primary: {
                    capSize: 65,
                    baseSize: 15,
                    step: false,
                    inverted: false,
                    capAlign: "center"
                }
            }
        },
        {
            label: "Wedge",
            template: "pyramid",
            icon: "pyramid_wedge",
            model: {
                primary: {
                    capSize: 35,
                    baseSize: 65,
                    step: false,
                    inverted: false,
                    capAlign: "center"
                }
            }
        },
        {
            label: "Volcano",
            template: "pyramid",
            icon: "pyramid_volcano",
            model: {
                primary: {
                    capSize: 7,
                    baseSize: 65,
                    step: false,
                    inverted: false,
                    capAlign: "center"
                }
            }
        },
        {
            label: "Aztec Pyramid",
            template: "pyramid",
            icon: "pyramid_step",
            model: {
                primary: {
                    capSize: 0,
                    baseSize: 60,
                    step: true,
                    inverted: false,
                    capAlign: "center"
                }
            }
        },
        {
            label: "Staircase",
            template: "pyramid",
            icon: "pyramid_staircase",
            model: {
                primary: {
                    capSize: 10,
                    baseSize: 40,
                    step: true,
                    inverted: false,
                    capAlign: "left"
                }
            }
        },
    ],

    list: [
        {
            label: "Icon List",
            template: "textgrid_vertical_icons",
        }, {
            label: "Bullets",
            template: "textlist",
            templateProps: { listStyle: ListStyleType.BULLET, textOnly: true },
        }, {
            label: "Numbered",
            template: "textlist",
            templateProps: { listStyle: ListStyleType.NUMBERED, textOnly: true },
            icon: "textgrid_numbered",
        }, {
            label: "Photo List",
            template: "textgrid_photos",
        }, {
            label: "Carousel",
            template: "textgrid_carousel",
            cleanupMethod: "onRemoveCarouselVariation",
        }, {
            label: "Text Boxes",
            template: "textBoxGrid",
        }, {
            label: "Photo Collage",
            template: "collage",
        }, {
            label: "Logo Grid",
            template: "logogrid",
        }, {
            label: "Agenda",
            template: "agenda",
        }, {
            label: "Quotes",
            template: "user_testimonial",
        }
    ],

    process: [
        {
            label: "Process Diagram",
            template: "step_chevrons",

        }, {
            label: "Numbered Boxes",
            template: "step_boxes",
        }
    ],

    nodes: [
        {
            label: "Looping Process",
            template: "step_circular",
            templateProps: { shape_is_polygon: false },
        },
        {
            label: "Straight-line Looping Process",
            template: "step_circular",
            templateProps: { shape_is_polygon: true },
        },
        {
            label: "Hub and Spoke",
            template: "hub_and_spoke",
        }
    ],

    tabular: [
        {
            label: "Table",
            template: "table-v2",
        },
        {
            label: "Line Chart",
            template: "chart_line",
        },
        {
            label: "Column Chart",
            template: "chart_column",
        },
        {
            label: "Bar Chart",
            template: "chart_bar",
        },
        {
            label: "Area Chart",
            template: "chart_area",
        },
        {
            label: "Waterfall Chart",
            template: "chart_waterfall",
        },
        {
            label: "Pie Chart",
            template: "chart_pie",
        }
    ],

    timeline: [{
        label: "Timeline",
        template: "timeline",
    }, {
        label: "Journey",
        template: "journey",
    }],

    xygraph: [{
        label: "XY Plot",
        template: "xygraph",
        icon: "chart_scatter",
        model: {
            primary: {
                showAxis: true,
                showGrid: true,
                showAxisLabels: true,
                showArrowHeads: true,
                showQuadrants: false,
                showQuadrantLabels: false
            }
        }
    }, {
        label: "Quadrants",
        template: "xygraph",
        icon: "xy_axis",
        model: {
            primary: {
                showAxis: false,
                showGrid: true,
                showAxisLabels: false,
                showArrowHeads: true,
                showQuadrants: true,
                showQuadrantLabels: true
            }
        }
    }, {
        label: "Bubbles",
        template: "xygraph",
        icon: "bubbles",
        model: {
            primary: {
                showAxis: false,
                showGrid: false,
                getShowAxisLabels: false,
                showArrowHeads: false,
                showQuadrants: false,
                showQuadrantLabels: false
            }
        }
    }],

    contact: [{
        label: "Map",
        icon: "",
        model: {
            primary: {
                styleVariation: "left_map"
            }
        }
    }, {
        label: "Picture On Left",
        icon: "",
        model: {
            primary: {
                styleVariation: "left_picture"
            }
        }
    }, {
        label: "Picture On Top",
        icon: "",
        model: {
            primary: {
                styleVariation: "top_picture"
            }
        }
    }],

};

module.exports = variations;
