import * as geom from "js/core/utilities/geom";
import { _ } from "legacy-js/vendor";

import {
    AssetType,
    BackgroundStyleType,
    HorizontalAlignType,
    ShapeType,
    VerticalAlignType,
    ContentFrameType,
    BlockStructureType,
    AuthoringBlockType,
    TextStyleType,
} from "legacy-common/constants";
import { detectListContent } from "js/core/services/sharedModelManager";

import { CollectionElement, CollectionItemElement } from "../../base/CollectionElement";
import { layoutHelper } from "../../layouts/LayoutHelper";
import { FramedMediaElement } from "../../base/MediaElements/FramedMediaElement";
import { TextElement } from "../../base/Text/TextElement";

class PhotoTextList extends CollectionElement {
    static get schema() {
        return {
            mediaHeight: 9999,
        };
    }

    getChildItemType() {
        return PhotoTextListItem;
    }

    get defaultItemData() {
        return {
            frameType: _.last(this.itemCollection)?.frameType || ContentFrameType.RECT,
            decorationStyle: _.last(this.itemCollection)?.decorationStyle || "filled"
        };
    }

    get maxItemCount() {
        return 6;
    }

    _calcProps(props, options) {
        let { size } = props;

        let columnWidth = (size.width - this.styles.hGap * (this.itemElements.length - 1)) / this.itemElements.length;

        let maxTextHeight = 0;
        for (let item of this.itemElements) {
            let textProps = item.text.calcProps(new geom.Size(columnWidth, size.height));
            maxTextHeight = Math.max(maxTextHeight, textProps.size.inflate(item.styles.padding || 0).inflate(item.styles.margin || 0).height);
        }
        maxTextHeight = Math.ceil(maxTextHeight);

        let x = 0;
        let maxContentHeight = 0;
        let maxItemHeight = 0;

        let mediaHeight = Math.min(this.model.mediaHeight, size.height - maxTextHeight);

        let maxMediaHeight = 0;
        for (let item of this.itemElements) {
            let mediaProps = item.media.calcProps(new geom.Size(columnWidth, mediaHeight), {
                fitToHeight: false,
                autoHeight: !item.media.frameType.equalsAnyOf(ShapeType.RECT, ShapeType.ROUNDED_RECT)
            });
            maxMediaHeight = Math.max(maxMediaHeight, mediaProps.size.height);
        }

        maxMediaHeight = Math.min(mediaHeight, maxMediaHeight);

        for (let item of this.itemElements) {
            let itemProps = item.calcProps(new geom.Size(columnWidth, size.height), {
                textHeight: maxTextHeight,
                mediaHeight: maxMediaHeight
            });
            itemProps.bounds = new geom.Rect(x, 0, itemProps.size);
            x += columnWidth + this.styles.hGap;

            maxContentHeight = Math.max(maxContentHeight, item.media.bounds.height);
            maxItemHeight = Math.max(maxItemHeight, itemProps.size.height);
        }

        layoutHelper.alignItemsInContainer(this.itemElements, size, HorizontalAlignType.CENTER, VerticalAlignType.MIDDLE);

        let isFit = true;

        return {
            size,
            isFit,
            maxTextHeight
        };
    }

    _exportToSharedModel() {
        const listContent = this.itemElements.map(itemElement => ({
            text: itemElement.text._exportToSharedModel().textContent[0],
            asset: itemElement.media._exportToSharedModel().assets[0]
        }));

        const textContent = this.itemElements.reduce(
            (textContent, itemElement) => ([
                ...textContent, ...itemElement.text._exportToSharedModel().textContent
            ]), []
        );

        const assets = this.itemElements.reduce(
            (assets, itemElement) => ([
                ...assets, ...itemElement.media._exportToSharedModel().assets
            ]), []
        );

        return { listContent, textContent, assets };
    }

    _importFromSharedModel(model) {
        const listContent = detectListContent(model);
        if (!listContent?.length) return;

        const items = listContent.map(({ text, asset }) => ({
            ...(asset ? {
                content_type: asset.type,
                content_value: asset.value,
                assetProps: asset.props,
                assetName: asset.name,
                ...(asset.configProps ?? {}),
            } : {}),
            ...(text ? {
                text: {
                    blocks: [
                        {
                            html: text.mainText.text,
                            textStyle: TextStyleType.TITLE,
                            type: AuthoringBlockType.TEXT,
                        },
                        ...text.secondaryTexts.map(secondaryText => ({
                            html: secondaryText.text,
                            textStyle: TextStyleType.BODY,
                            type: AuthoringBlockType.TEXT,
                        })),
                    ]
                }
            } : {})
        }));

        items.splice(this.maxItemCount);
        return { items };
    }
}

class PhotoTextListItem extends CollectionItemElement {
    static get schema() {
        return {
            frameType: ShapeType.RECT,
            decorationStyle: "filled"
        };
    }

    get selectionPadding() {
        return { top: 0, left: 0, right: 0, bottom: 10 };
    }

    _build() {
        this.media = this.addElement("media", () => FramedMediaElement, {
            defaultAssetType: AssetType.IMAGE,
            autoHeight: false,
            fitToHeight: true,
            allowUnframedImages: true,
        });

        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            autoHeight: true,
            allowAlignment: true,
            syncFontSizeWithSiblings: true
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let mediaHeight = options.mediaHeight;

        let mediaBounds = new geom.Rect(0, 0, size.width, mediaHeight);
        let mediaProps = this.media.calcProps(mediaBounds.size, {

        });
        mediaProps.bounds = new geom.Rect(mediaBounds.width / 2 - mediaProps.size.width / 2, mediaHeight / 2 - mediaProps.size.height / 2, mediaProps.size);

        let textProps = this.text.calcProps(new geom.Size(size.width, options.textHeight));
        textProps.bounds = new geom.Rect(0, mediaHeight, textProps.size);

        return {
            size: new geom.Size(size.width, textProps.size.height + mediaHeight)
        };
    }

    _getBackgroundColor(forElement) {
        if (forElement) {
            return this.getParentBackgroundColor(forElement);
        } else {
            return BackgroundStyleType.IMAGE;  // this is for the inner widgets in the selection overlay
        }
    }

    _migrate_10() {
        delete this.model.blocks; // delete any blocks property that was left in this model from switch template
    }
}

export const elements = {
    PhotoTextList
};
