import { ds } from "js/core/models/dataService";
import { AuthoringBlockType, TextStyleType, WidgetPositionType } from "legacy-common/constants";
import { AssetType, PositionType } from "legacy-common/constants";
import { app } from "js/namespaces";
import { controls, createDefaultOverlayButton } from "legacy-js/editor/ui";
import * as geom from "js/core/utilities/geom";
import { $, _ } from "legacy-js/vendor";
import { getStaticUrl } from "legacy-js/config";
import { AnchorType } from "js/core/utilities/geom";
import { CreateAdjustImageMenu, CreateMediaMenu } from "./pictureEditor";
import { ShowDialog, ShowDialogAsync } from "legacy-js/react/components/Dialogs/BaseDialog";
import BadFitDialog from "legacy-js/react/components/Dialogs/BadFitDialog";
import { AddAssetsContainer } from "legacy-js/react/views/AddAssets";

import {
    ElementDefaultOverlay, ElementOptionsMenu,
    ElementRollover,
    ElementSelection
} from "../BaseElementEditor";
import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";
import { TextElementSelection } from "../elements/TextEditor";
import { BuildTextFrameOptionsMenu } from "../elements/TextFrameEditor";
import { mergeMediaElementModelDefaults } from "legacy-common/assetUtils";

const PresentationTitleSelection = ElementSelection.extend({
    renderControls: function() {
        // CreateTextStylePopup(this, this.element, { label: "Text Options" });

        if (this.element.showImage) {
            CreateAdjustImageMenu(this, this.element.image, {
                onRemove: () => {
                    this.element.markStylesAsDirty();
                    this.element.model.image = null;
                    this.element.canvas.updateCanvasModel(true);
                }
            });

            this.addControl({
                type: controls.POPUP_BUTTON,
                label: "Layout",
                menuClass: "icon-menu",
                showArrow: true,
                items: [{
                    value: "background",
                    label: "Background",
                    image: getStaticUrl("/images/ui/trays/background_full.png")
                }, {
                    value: "left", label: "Left", image: getStaticUrl("/images/ui/trays/left_tray_full.png")
                }, {
                    value: "right", label: "Right", image: getStaticUrl("/images/ui/trays/right_tray_full.png")
                }, {
                    value: "top", label: "Top", image: getStaticUrl("/images/ui/trays/top_tray_full.png")
                }, {
                    value: "bottom", label: "Bottom", image: getStaticUrl("/images/ui/trays/bottom_tray_full.png")
                }],
                callback: position => {
                    this.element.model.image.position = position;
                    switch (position) {
                        case PositionType.LEFT:
                        case PositionType.RIGHT:
                            this.element.model.image.traySize = 400;
                            break;
                        case PositionType.TOP:
                        case PositionType.BOTTOM:
                            this.element.model.image.traySize = 360;
                            break;
                    }

                    this.element.markStylesAsDirty();
                    this.element.canvas.updateCanvasModel(true);
                }
            });
        } else {
            this.addControl({
                type: controls.BUTTON,
                label: "Add Image",
                icon: "add_a_photo",
                callback: () => {
                    ShowDialog(AddAssetsContainer, {
                        workspaceId: ds.selection.presentation.getWorkspaceId(),
                        callback: model => {
                            this.element.markStylesAsDirty();
                            this.element.model.image = this.element.model.image || {};

                            if (this.element.titleDecoration != "block") {
                                this.element.model.textStyle = "white_box";
                            }

                            mergeMediaElementModelDefaults(
                                this.element.model.image,
                                model,
                                {
                                    position: "background",
                                },
                            );
                            this.element.canvas.updateCanvasModel(true);
                        },
                    });
                }
            });
        }

        BuildTextFrameOptionsMenu(this, this.element.textFrame, "Text Options");
    }
});

const PresentationTitleOptionsMenu = ElementOptionsMenu.extend({

    renderControls() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Image",
            value: this.element.showImage,
            callback: value => {
                this.closeMenu();
                if (value) {
                    ShowDialog(AddAssetsContainer, {
                        workspaceId: ds.selection.presentation.getWorkspaceId(),
                        callback: model => {
                            this.element.markStylesAsDirty();
                            this.element.model.image = this.element.model.image || {};
                            this.element.model.textStyle = "white_box";

                            mergeMediaElementModelDefaults(
                                this.element.model.image,
                                model,
                                {
                                    position: "background",
                                },
                            );
                            this.element.canvas.updateCanvasModel(true);
                        },
                    });
                } else {
                    this.element.markStylesAsDirty();
                    this.element.model.image = null;
                    this.element.canvas.updateCanvasModel(true);
                }
            }
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Logo",
            value: this.element.showLogos,
            callback: value => {
                this.closeMenu();
                if (value) {
                    if (this.element.model.logos == null || this.element.model.logos.items == null || this.element.model.logos.items.length == 0) {
                        ShowDialog(AddAssetsContainer, {
                            assetType: AssetType.LOGO,
                            workspaceId: ds.selection.presentation.getWorkspaceId(),
                            callback: (model, isNew, asset) => {
                                if (!this.element.model.logos || !this.element.model.logos.items || this.element.model.logos.items.length == 0) {
                                    this.element.model.logos = {
                                        items: [{ ...model, showBackdrop: asset?.attributes?.hasAlpha == false }],
                                    };
                                    this.element.model.logos.position = PositionType.TOP_LEFT;
                                }
                                this.element.model.showLogos = true;
                                this.element.canvas.updateCanvasModel(true);
                            },
                        });
                    } else {
                        this.element.model.showLogos = true;
                        this.element.canvas.updateCanvasModel(true);
                    }
                } else {
                    this.element.model.showLogos = false;
                    this.element.model.logos = null;
                    this.element.canvas.updateCanvasModel(true);
                }
            }
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Presenters",
            value: this.element.showPresenters,
            callback: value => {
                this.closeMenu();
                if (value) {
                    if (this.element.model.presenters == null || this.element.model.presenters.items == null || this.element.model.presenters.items.length == 0) {
                        this.element.model.presenters = {
                            items: [{ title: "" }]
                        };
                    }
                    this.element.model.showPresenters = true;
                } else {
                    this.element.model.showPresenters = false;
                    this.element.model.presenters = null;
                }
                this.element.canvas.updateCanvasModel(true);
            }
        });
    }

});

const PresentationTitleTextFrameSelection = TextElementSelection.extend({

    showSelectionBox: false,

    renderContextControls() {
        let element = this.element.text;

        let labelBlock = element.getBlockByTextStyle(TextStyleType.LABEL);
        let descriptionBlock = element.getBlockByTextStyle(TextStyleType.BODY);

        if (labelBlock && descriptionBlock) return false;

        // let offsetY = 0;
        // if (element.canvas.model.layout.headerPosition == HeaderPositionType.LEFT) {
        //     offsetY = -30;
        // } else {
        //     offsetY = descriptionBlock ? 15 : 5;
        // }
        let $container = this.createContextControls(element.textAlign, this.element.textFrameBox.bounds.left, this.element.textFrameBox.bounds.bottom + 50);

        if (!labelBlock) {
            $container.append(controls.createButton(this, {
                label: "Add Label",
                callback: async () => {
                    ds.selection.element = null;

                    let block = element.addBlock({
                        html: "",
                        type: AuthoringBlockType.TEXT,
                        index: 0,
                        textStyle: TextStyleType.LABEL
                    });

                    element.canvas.refreshCanvas().then(() => {
                        ds.selection.element = element;
                        element.overlay.focusBlock(element.getBlockByTextStyle(TextStyleType.LABEL).id);
                    });
                }
            }));
        }

        if (!descriptionBlock) {
            $container.append(controls.createButton(this, {
                label: "Add SubHeader",
                callback: async () => {
                    ds.selection.element = null;

                    let block = element.addBlock({
                        html: "",
                        type: AuthoringBlockType.TEXT,
                        textStyle: TextStyleType.BODY
                    });

                    element.canvas.refreshCanvas().then(() => {
                        ds.selection.element = element;
                        element.overlay.focusBlock(element.getBlockByTextStyle(TextStyleType.BODY).id);
                    });
                }
            }));
        }

        return $container;
    },

});

const PresentationTitleImageDefaultOverlay = ElementDefaultOverlay.extend({
    className: "default_overlay contentFrameDefaultOverlay",

    render: function() {
        if (!this.element.styles.decoration || this.element.styles.decoration.shape == "none" || (this.element.styles.decoration.fillColor == "none" && this.element.styles.decoration.strokeColor == "none")) {
            this.$el.addClass("showFrame");
        }

        let $button = this.$el.addEl(controls.createButton(this, {
            icon: "add_a_photo",
            className: "large_icon",
            callback: () => {
                if (_.isEmpty(this.element.model[this.element.bindTo])) {
                    ShowDialog(AddAssetsContainer, {
                        workspaceId: ds.selection.presentation.getWorkspaceId(),
                        bindTo: this.element.bindTo,
                        callback: model => {
                            mergeMediaElementModelDefaults(
                                this.element.model,
                                model,
                            );
                            this.element.canvas.updateCanvasModel(false);
                        },
                    });
                } else {
                    ds.selection.element = this.element.assetElement;
                }
            }
        }));

        if (this.element.model.position == "background") {
            $button.css("position", "absolute").right(20).bottom(10);
        }

        return this;
    }
});

const renderPresentationTitleImageDefaultOverlay = element => {
    let addPhoto = createDefaultOverlayButton(element.svg, "addPhoto", "", () => {
        if (_.isEmpty(element.model[element.bindTo])) {
            ShowDialog(AddAssetsContainer, {
                workspaceId: ds.selection.presentation.getWorkspaceId(),
                bindTo: element.bindTo,
                callback: model => {
                    mergeMediaElementModelDefaults(
                        element.model,
                        model,
                    );
                    element.canvas.updateCanvasModel(false);
                },
            });
        } else {
            ds.selection.element = element.assetElement;
        }
    });
    addPhoto.move(element.bounds.width / 2 - 23, element.bounds.height / 2 - 23);

    element.defaultOverlayNode = addPhoto;
};

const PresentationTitleImageSelection = ElementSelection.extend({
    getOffset() {
        return -60;
    },

    getWidgetPosition() {
        return "inner";
    },

    renderControls() {
        this.createDeleteComponentWidget({
            action: () => {
                this.element.parentElement.model.image = null;
                this.element.parentElement.model.imagePosition = null;
                this.element.parentElement.markStylesAsDirty();
                this.element.canvas.updateCanvasModel(true);
            }
        });
        if (!this.element.showDefaultOverlay) {
            CreateMediaMenu(this, this.element, {
                onRemove: () => {
                    this.element.parentElement.model.image = null;
                    this.element.parentElement.markStylesAsDirty();
                }
            });
        }
    },
});

const PresentationTitleImageRollover = ElementRollover.extend({
    renderControls() {
        if (ds.selection.element == this.element.assetElement) return;
        this.renderTrayResizer();
    },

    renderTrayResizer: function() {
        let titleElement = this.element.parentElement;
        let image = titleElement.image;

        if (titleElement.showImage === false || image && image.model.position === PositionType.BACKGROUND) return;

        let $trayResizer = this.$widgets.addEl($.div("ui_widget"));
        $trayResizer.append($.div("tray_resizer"));

        let constrainDrag;

        let trayBounds = titleElement.canvasBounds;
        if (titleElement.showLogos && titleElement.model.logos.backgroundColor != "none" && (titleElement.model.logos.position == PositionType.TOP_LEFT || titleElement.model.logos.position == PositionType.TOP_RIGHT)) {
            trayBounds = trayBounds.deflate({ top: titleElement.logos.calculatedProps.bounds.height });
        }
        if (titleElement.showPresenters) {
            trayBounds = trayBounds.deflate({ bottom: titleElement.presenters.calculatedProps.bounds.height });
        }
        // calculate the maxWidth by directly asking the trayElement - if we ask the tray, it will use the current trayWidth
        let maxTrayWidth = trayBounds.width / 2;
        let minTrayWidth = 100;
        let maxTrayHeight = trayBounds.height - 150;
        let minTrayHeight = 85;

        let dragHandleOffset = 11;
        let direction;

        let imageSelectionBounds = this.element.bounds.zeroOffset().multiply(this.canvas.canvasScale);

        switch (image.model.position) {
            case PositionType.LEFT:
                $trayResizer.center(imageSelectionBounds.getPoint(AnchorType.RIGHT).offset(-dragHandleOffset, -dragHandleOffset));
                constrainDrag = new geom.Rect(trayBounds.left + minTrayWidth, 0, maxTrayWidth - minTrayWidth - dragHandleOffset, this.element.canvas.CANVAS_HEIGHT);
                direction = "horizontal";
                break;
            case PositionType.RIGHT:
                $trayResizer.center(imageSelectionBounds.getPoint(AnchorType.LEFT).offset(-dragHandleOffset, -dragHandleOffset));
                constrainDrag = new geom.Rect(trayBounds.right - maxTrayWidth - dragHandleOffset, 0, maxTrayWidth - minTrayWidth, this.element.canvas.CANVAS_HEIGHT);
                direction = "horizontal";
                break;
            case PositionType.TOP:
                $trayResizer.center(imageSelectionBounds.getPoint(AnchorType.BOTTOM).offset(-dragHandleOffset, -dragHandleOffset));
                constrainDrag = new geom.Rect(trayBounds.left, trayBounds.top + minTrayHeight, trayBounds.width, maxTrayHeight - minTrayHeight - dragHandleOffset);
                direction = "vertical";
                break;
            case PositionType.BOTTOM:
                $trayResizer.center(imageSelectionBounds.getPoint(AnchorType.TOP).offset(-dragHandleOffset, -dragHandleOffset));
                constrainDrag = new geom.Rect(trayBounds.left, trayBounds.bottom - maxTrayHeight - dragHandleOffset, trayBounds.width, maxTrayHeight - minTrayHeight);
                direction = "vertical";
                break;
        }

        let refresh = _.throttle(value => {
            image.model.traySize = parseInt(value);
            this.element.canvas.refreshCanvasAutoRevert();
        }, 10);

        $trayResizer.makeDraggable({
            axis: direction == "vertical" ? "y" : "x",
            constrainDrag: constrainDrag,
            start: event => {
                this.selectionLayer.hideWidgets($trayResizer);
            },
            drag: (event, position) => {
                let traySize;
                switch (image.model.position) {
                    case PositionType.LEFT:
                        traySize = position.canvasPosition.x + dragHandleOffset / this.canvas.canvasScale;
                        break;
                    case PositionType.RIGHT:
                        traySize = titleElement.bounds.width - position.canvasPosition.x - dragHandleOffset / this.canvas.canvasScale;
                        break;
                    case PositionType.TOP:
                        traySize = position.canvasPosition.y + dragHandleOffset / this.canvas.canvasScale;
                        break;
                    case PositionType.BOTTOM:
                        traySize = titleElement.bounds.height - position.canvasPosition.y - dragHandleOffset / this.canvas.canvasScale;
                        break;
                }
                refresh(traySize);
            },
            stop: async event => {
                await this.element.canvas.updateCanvasModel(false);
                this.selectionLayer.showWidgets($trayResizer);
            }
        });
    },

    _layout() {

    }

});

const PresentationTitleFooterSelection = ElementSelection.extend({
    captureMouseEvents: true,

    renderControls: function() {
        this.createDeleteComponentWidget({
            target: this.element,
            position: WidgetPositionType.RIGHT,
            offset: 0,
            action: () => {
                this.element.model.showFooter = false;
                this.element.canvas.updateCanvasModel(true);
            }
        });
    }
});

const PresentationLogosSelection = CollectionElementSelection.extend({

    showSelectionBox: true,

    getOffset() {
        return "top -40";
    },

    renderControls() {
        this.addControl({
            type: controls.BUTTON,
            icon: "add_a_photo",
            label: "Add Logo",
            enabled: this.model.items.length < 4,
            callback: () => {
                ShowDialog(AddAssetsContainer, {
                    assetType: AssetType.LOGO,
                    callback: (assetModel, isNew, asset) => {
                        this.addItem({
                            model: {
                                ...assetModel,
                                showBackdrop: !asset?.attributes.hasAlpha
                            }
                        });
                    },
                });
            }
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Position",
            property: "position",
            items: [{
                value: PositionType.TOP_LEFT, label: "Top Left"
            }, {
                value: PositionType.TOP_RIGHT, label: "Top Right"
            }, {
                value: PositionType.CENTER, label: "With Title"
            }]
        });

        this.addControl({
            type: controls.SLIDER,
            property: "scale",
            min: 0.25,
            max: 1.5,
            step: 0.01
        });
    },
});

const PresentationLogoItemSelection = CollectionItemElementSelection.extend({
    showSelectionBox: false,

    getOffset() {
        return 10;
    },

    getWidgetPosition() {
        return "inner";
    },

    canDelete: function() {
        return true;
    },

    getWidgetColor() {
        return "dark";
    },

    renderControls() {
        CreateMediaMenu(this, this.element.logo);
    }
});

const PresentationLogoItemOptionsMenu = ElementOptionsMenu.extend({

    renderControls() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Backdrop",
            property: "showBackdrop"
        });
    }
});

const PresentationTitlePresentersSelection = CollectionElementSelection.extend({
    captureMouseEvents: false,

    getAddItemLabel() {
        return "Add Presenter";
    },

    getOffset() {
        return 0;
    },

    renderControls: function() {
        if (this.element.isOverImage) {
            this.addControl({
                id: "text-style",
                type: controls.POPUP_BUTTON,
                label: "Style",
                menuClass: "icon-menu",
                markStylesAsDirty: true,
                items: [{
                    value: "white_text", label: "White", image: getStaticUrl("/images/ui/textstyles/text_white.svg")
                }, {
                    value: "white_text_with_shadow",
                    label: "White with Shadow",
                    image: getStaticUrl("/images/ui/textstyles/text_shadow.svg")
                }, {
                    value: "dark_text", label: "Dark", image: getStaticUrl("/images/ui/textstyles/text_dark.svg")
                }, {
                    value: "white_box",
                    label: "White Backdrop",
                    image: getStaticUrl("/images/ui/textstyles/backdrop_white.svg")
                }, {
                    value: "transparent_light_box",
                    label: "Transparent Backdrop",
                    image: getStaticUrl("/images/ui/textstyles/backdrop_transparent.svg")
                }, {
                    value: "transparent_dark_box",
                    label: "Dark Backdrop",
                    image: getStaticUrl("/images/ui/textstyles/backdrop_dark.svg")
                }],
                property: "textStyle"
            });
        }

        this.addControl({
            id: "alignment",
            type: controls.POPUP_BUTTON,
            label: "Alignment",
            items: [{
                value: "left", icon: "format_align_left", label: "Left Align"
            }, {
                value: "center", icon: "format_align_center", label: "Center Align"
            }, {
                value: "right", icon: "format_align_right", label: "Right Align"
            }],
            property: "horizontalAlign"
        });

        this.$addItem = this.$el.addEl(controls.createButtonWidget({
            icon: "add",
            className: "large",
            callback: () => {
                this.element.addItem();
                this.element.canvas.updateCanvasModel(true);
            }
        }));
    },

    _layout: function() {
        if (this.$addItem) {
            let lastItem = _.last(this.element.itemElements);
            this.$addItem.left(lastItem.offsetBounds.right * this.canvasScale + 40);
            this.$addItem.top(this.element.bounds.height / 2 * this.canvasScale - this.$addItem.find(".icon_button").height() / 2);
        }
    }
});

const PresentationTitlePresenterItemSelection = CollectionItemElementSelection.extend({
    showSelectionBox: false
});

const PresentationTitlePresenterTextSelection = TextElementSelection.extend({
    renderContextControls() {
        let bodyBlock = this.element.getBlockByTextStyle(TextStyleType.BODY);

        if (bodyBlock) return false;

        let $container = this.createContextControls(this.element.textAlign, this.element.paddedBounds.left, this.element.paddedBounds.bottom + 20);
        $container.append(controls.createButton(this, {
            label: "Add Role/Title",
            callback: async () => {
                ds.selection.element = null;

                let block = this.element.addBlock({
                    html: "",
                    type: AuthoringBlockType.TEXT,
                    index: 1,
                    textStyle: TextStyleType.BODY
                });

                this.element.canvas.refreshCanvas().then(() => {
                    ds.selection.element = this.element;
                    this.element.overlay.focusBlock(this.element.getBlockByTextStyle(TextStyleType.BODY).id);
                });
            }
        }));

        return $container;
    }
});

function PresentationTitleAddContentMenu(element, menu) {
    let setImagePosition = (position, value) => {
        if (value) {
            if (!element.model.image) {
                element.model.image = {};

                if (element.temporaryImageRef) {
                    element.model.image.content_value = element.temporaryImageRef.content_value;
                    element.model.image.content_type = element.temporaryImageRef.content_type;
                }
            }
            element.model.image.position = position;
            switch (position) {
                case PositionType.LEFT:
                case PositionType.RIGHT:
                    element.model.image.traySize = 400;
                    break;
                case PositionType.TOP:
                case PositionType.BOTTOM:
                    element.model.image.traySize = 360;
                    break;
            }
        } else {
            if (element.model.image && element.model.image.content_value) {
                element.temporaryImageRef = {
                    content_value: element.model.image.content_value,
                    content_type: element.model.image.content_type
                };
            }
            element.model.image = null;
        }

        if (value && !element.model.image.content_value) {
            ShowDialog(AddAssetsContainer, {
                assetType: AssetType.IMAGE,
                workspaceId: ds.selection.presentation.getWorkspaceId(),
                callback: model => {
                    mergeMediaElementModelDefaults(
                        element.model.image,
                        model,
                    );
                    element.canvas.updateCanvasModel(false).then(() => {
                        menu.render();
                    });
                },
            });
        }

        element.canvas.updateCanvasModel(true).then(() => {
            menu.render();
        }).catch(() => {
            ShowDialogAsync(BadFitDialog, {
                title: `Sorry, the content on your slide won't fit with the image on the ${position}`,
            });
        });
    };

    let setLogoPosition = value => {
        if (!element.model.logos || !element.model.logos.items || element.model.logos.items.length == 0) {
            element.model.logos = {
                items: [{}],
                backgroundColor: "background_light",
            };
        }
        element.model.logos.position = value;
        element.canvas.updateCanvasModel(true).then(() => {
            menu.render();
        });
    };

    return [{
        title: "Image",
        options: [{
            icon: "background_full",
            label: "Background",
            enabled: true,
            selected: element.model.image && element.model.image.position == PositionType.BACKGROUND,
            callback: value => setImagePosition(PositionType.BACKGROUND, value)
        }, {
            icon: "left_tray_full",
            label: "Left",
            enabled: true,
            selected: element.model.image && element.model.image.position == PositionType.LEFT,
            callback: value => setImagePosition(PositionType.LEFT, value)
        }, {
            icon: "right_tray_full",
            label: "Right",
            enabled: true,
            selected: element.model.image && element.model.image.position == PositionType.RIGHT,
            callback: value => setImagePosition(PositionType.RIGHT, value)
        }, {
            icon: "top_tray_full",
            label: "Top",
            enabled: true,
            selected: element.model.image && element.model.image.position == PositionType.TOP,
            callback: value => setImagePosition(PositionType.TOP, value)
        }, {
            icon: "bottom_tray_full",
            label: "Bottom",
            enabled: true,
            selected: element.model.image && element.model.image.position == PositionType.BOTTOM,
            callback: value => setImagePosition(PositionType.BOTTOM, value)
        }]
    }, {
        title: "Footer",
        options: [{
            icon: "bottom_left_titlelogo",
            label: "Footer",
            enabled: true,
            selected: element.showFooter,
            callback: value => {
                element.model.showFooter = value;
                element.canvas.updateCanvasModel(true).then(() => {
                    menu.render();
                });
            }
        }]
    }];
}

export const editors = {
    PresentationTitleSelection,
    PresentationTitleOptionsMenu,
    PresentationTitleImageSelection,
    PresentationTitleImageDefaultOverlay,
    renderPresentationTitleImageDefaultOverlay,
    PresentationTitleImageRollover,
    PresentationTitleFooterSelection,
    PresentationLogosSelection,
    PresentationLogoItemSelection,
    PresentationLogoItemOptionsMenu,
    PresentationTitlePresentersSelection,
    PresentationTitlePresenterItemSelection,
    PresentationTitleAddContentMenu,
    PresentationTitleTextFrameSelection,
    PresentationTitlePresenterTextSelection
};
