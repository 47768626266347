import { _ } from "legacy-js/vendor";
import * as geom from "js/core/utilities/geom";
import { Shape } from "js/core/utilities/shapes";

import { TimelineSpanItem } from "./TimelineSpanItem";
import { TextElement } from "../../base/Text/TextElement";
import { SVGPolygonElement } from "../../base/SVGElement";

export class TimelineSpanChevronItem extends TimelineSpanItem {
    get chevronOffset() {
        return this.options.chevronOffset;
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGPolygonElement);
        this.label = this.addElement("label", () => TextElement, this.textElementProps);
    }

    _calcProps(props) {
        const { size } = props;
        const { showEndMarker } = this.options;

        const shapeBounds = new geom.Rect(0, 0, size.width, size.height).inflate({
            left: this.chevronOffset / 2,
            right: this.chevronOffset / 2
        });
        if (this.parentElement.isLast && !showEndMarker) {
            shapeBounds.width += this.chevronOffset / 2;
        }
        const shapeProps = this.shape.calcProps(size);
        shapeProps.path = Shape.drawChevron(shapeBounds, this.chevronOffset, this.parentElement.itemIndex === 0).toPolygonData();

        const labelProps = this.label.calcProps(new geom.Size(size.width - this.chevronOffset * 2, size.height));
        labelProps.bounds = new geom.Rect(this.chevronOffset, 0, labelProps.size);

        return { size };
    }

    getBackgroundColor(forElement) {
        if (forElement && forElement instanceof TextElement) {
            return this.getShapeFillColor(this.shape);
        }

        return super.getBackgroundColor(forElement);
    }
}
