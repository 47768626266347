import { CollectionItemElement } from "../../base/CollectionElement";

export default class CompareValuesItem extends CollectionItemElement {
    static get schema() {
        return {
            value: Math.round(Math.random() * 100)
        };
    }

    get drawPercentage() {
        if (this.isAnimating) {
            return this.animationState.value;
        }

        return 1;
    }

    get currentValue() {
        if (this.isAnimating) {
            return this.model.value * this.drawPercentage;
        }

        return this.model.value;
    }

    get percentageValue() {
        const compare = this.parentElement;
        const value = (Math.abs(this.model.value) - Math.max(0, compare.minValue)) / (compare.maxValue - compare.minValue);
        // if (compare.model.scaling == "log") {
        //     // position will be between 0 and 100
        //     let minp = compare.minValue;
        //     let maxp = compare.maxValue;
        //
        //     // The result should be between 100 an 10000000
        //     let minv = Math.log(1);
        //     let maxv = Math.log(100);
        //
        //     // calculate adjustment factor
        //     let scale = (maxv - minv) / (maxp - minp);
        //
        //     value =( (Math.log(value * 100) - minv) / scale + minp) / 100;
        // }
        return value;
    }
}
