import React from "react";
import { reactMount, reactUnmount } from "legacy-js/react/renderReactRoot";
import { AuthoringElementType, HeaderPositionType } from "legacy-common/constants";
import { $ } from "legacy-js/vendor";

import { ElementSelection } from "../BaseElementEditor";
import { AuthoringLayer } from "../authoring/AuthoringLayer";

const AuthoringCanvasSelection = ElementSelection.extend({
    recreateOnRefresh: false,

    renderControls: function() {
        this.renderAuthoringLayer();
    },

    renderAuthoringLayer: function() {
        if (!this.authoringRef) {
            this.authoringRef = React.createRef();
        }

        this.$authoringCanvasEl = this.$el.addEl($.div().css({ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }));

        reactMount(
            <AuthoringLayer
                ref={this.authoringRef}
                containerElement={this.element}
                isTemplate={this.element.canvas.slide.get("isTeamSlideTemplate")}
            />,
            this.$authoringCanvasEl[0]
        );
    },

    onRefresh: function() {
        if (this.authoringRef.current) {
            this.authoringRef.current.forceUpdate();
        }
    },

    cleanUp: function() {
        if (this.$authoringCanvasEl?.length) {
            reactUnmount(this.$authoringCanvasEl[0]);
        }
    }
});

function AuthoringCanvasAddContentMenu(authoringCanvas, menu) {
    const genHeaderCallback = headerPosition => {
        return () => {
            const header = authoringCanvas.itemElements.find(item => item.model.type === AuthoringElementType.HEADER);
            if (header) {
                if (header.model.headerPosition === headerPosition) {
                    // store the deleted header data in the elements.header model so we can restore if needed
                    authoringCanvas.canvas.model.elements.header = {
                        label: header.model.label,
                        title: header.model.title,
                        description: header.model.description
                    };
                    authoringCanvas.deleteItem(header.id);
                } else {
                    header.childElement.setPosition(headerPosition);
                }
            } else {
                const model = {
                    type: AuthoringElementType.HEADER,
                    x: 0,
                    y: 0,
                    width: authoringCanvas.calculatedProps.bounds.width,
                    height: authoringCanvas.calculatedProps.bounds.height,
                    headerPosition,
                    label: authoringCanvas.canvas.model.elements.header?.label,
                    title: authoringCanvas.canvas.model.elements.header?.title,
                    description: authoringCanvas.canvas.model.elements.header?.description
                };
                authoringCanvas.addItem(model);
            }
            authoringCanvas.canvas.updateCanvasModel(false);
        };
    };

    return [
        {
            title: "Header",
            options: [
                {
                    icon: "header_top",
                    label: "Top Header",
                    enabled: true,
                    selected: authoringCanvas.itemElements.some(item => item.model.type === AuthoringElementType.HEADER),
                    callback: genHeaderCallback(HeaderPositionType.TOP),
                },
                {
                    icon: "header_left",
                    label: "Left Header",
                    enabled: true,
                    selected: authoringCanvas.itemElements.some(item => item.model.type === AuthoringElementType.HEADER),
                    callback: genHeaderCallback(HeaderPositionType.LEFT),
                },
            ]
        },
        {
            title: "Footer",
            options: [{
                icon: "footer",
                label: "Footer",
                enabled: true,
                selected: authoringCanvas.itemElements.some(item => item.model.type === AuthoringElementType.FOOTER),
                callback: () => {
                    const footer = authoringCanvas.itemElements.find(item => item.model.type === AuthoringElementType.FOOTER);
                    if (footer) {
                        authoringCanvas.deleteItem(footer.id);
                    } else {
                        const model = {
                            type: AuthoringElementType.FOOTER,
                            x: 0,
                            y: authoringCanvas.calculatedProps.bounds.height - authoringCanvas.canvas.styleSheet.Footer.height,
                            width: authoringCanvas.calculatedProps.bounds.width,
                            height: authoringCanvas.canvas.styleSheet.Footer.height
                        };
                        authoringCanvas.addItem(model);
                    }
                    authoringCanvas.canvas.updateCanvasModel(false);
                }
            }]
        }
    ];
}

export const editors = {
    AuthoringCanvasSelection,
    AuthoringCanvasAddContentMenu
};
