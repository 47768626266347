import { controls } from "legacy-js/editor/ui";

import { ElementOptionsMenu } from "../../BaseElementEditor";
import { CollectionElementSelection, CollectionItemElementSelection } from "../../CollectionElementEditor";

const TextBoxGridSelection = CollectionElementSelection.extend({

    getWidgetPosition() {
        return "inside";
    },

    renderControls: function() {
        this.createAddItemButton();
    }

});

const TextBoxGridOptionsMenu = ElementOptionsMenu.extend({

    renderControls() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Fit Boxes to Text",
            property: "fitToText",
            enabled: this.element.itemElements.length < 4
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Flip Orientation",
            property: "flipOrientation",
            enabled: !(this.element.itemElements.length == 1 || this.element.itemElements.length == 4 || this.element.itemElements.length == 6)

        });
    }
});

const TextBoxGridItemSelection = CollectionItemElementSelection.extend({
    getWidgetPosition() {
        return "inner";
    },

    renderControls() {
        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            includeAuto: true
        });
    }
});

export const editors = {
    TextBoxGridSelection,
    TextBoxGridItemSelection,
    TextBoxGridOptionsMenu
};
