import { controls } from "legacy-js/editor/ui";
import { NodeType } from "legacy-common/constants";
import { getStaticUrl } from "legacy-js/config";
import { ds } from "js/core/models/dataService";
import { $ } from "legacy-js/vendor";

import { ContentItem } from "../../../elements/base/ContentItem";

import { CollectionElementSelection, CollectionItemElementSelection } from "../../CollectionElementEditor";
import { ElementOptionsMenu } from "../../BaseElementEditor";
import { ContentItemSelection, renderContentItemControls } from "../ContentItemEditor";

const HubAndSpokeSelection = CollectionElementSelection.extend({
    renderControls: function() {
        this.addControl({
            id: "nodeType",
            type: controls.POPUP_BUTTON,
            icon: "apps",
            showArrow: false,
            menuClass: "icon-menu fourcol",
            items: [{
                label: "Icon/Image",
                value: NodeType.CONTENT_AND_TEXT,
                image: getStaticUrl("/images/ui/node_types/node_content_and_text.svg")
            }, {
                label: "Bullet",
                value: NodeType.BULLET_TEXT,
                image: getStaticUrl("/images/ui/node_types/node_bullet_text.svg")
            }, {
                label: "Numbered",
                value: NodeType.NUMBERED_TEXT,
                image: getStaticUrl("/images/ui/node_types/node_numbered_text.svg")
            }, {
                label: "Lettered",
                value: NodeType.LETTERED_TEXT,
                image: getStaticUrl("/images/ui/node_types/node_lettered_text.svg")
            }],
            callback: value => {
                ds.selection.element = null;
                this.element.spokeNodes.forEach(node => {
                    node.model.nodeType = value;
                });
                this.element.canvas.updateCanvasModel(true);
            }
        });

        this.createAddItemButton();

        this.addControl({
            type: controls.POPUP_BUTTON,
            icon: "compare_arrows",
            showArrow: false,
            menuContents: closeMenu => {
                let connectorProps = {};

                let compareProp = (property, connector) => {
                    if (connectorProps[property] == undefined) {
                        connectorProps[property] = connector[property];
                    } else if (connectorProps[property] !== connector[property]) {
                        connectorProps[property] = "(mixed)";
                    }
                };

                for (let connector of this.element.connectors.itemElements) {
                    compareProp("startDecoration", connector);
                    compareProp("endDecoration", connector);
                    compareProp("lineStyle", connector);
                    compareProp("lineWeight", connector);
                    compareProp("color", connector);
                }

                let $menu = $.grid();

                let $connectorStyle = $menu.addEl($.div().css({ display: "flex", alignItems: "center" }));
                $connectorStyle.append(controls.createIconDropdownMenu(this, {
                    label: "Line Style",
                    value: connectorProps.lineStyle,
                    items: [{
                        value: "solid", label: "Solid", image: "/images/ui/connectors/line-style-solid.svg"
                    }, {
                        value: "dotted", label: "Dotted", image: "/images/ui/connectors/line-style-dotted.svg"
                    }, {
                        value: "dashed", label: "Dashed", image: "/images/ui/connectors/line-style-dashed.svg"
                    }, {
                        value: "animate_pulse",
                        label: "Pulse (animated)",
                        image: "/images/ui/connectors/line-style-pulse.svg"
                    }, {
                        value: "animate_dash",
                        label: "Dashed (animated)",
                        image: "/images/ui/connectors/line-style-dashed.svg"
                    }],
                    callback: value => {
                        for (let connector of this.element.connectors.itemElements) {
                            connector.model.lineStyle = value;
                        }
                        this.element.canvas.updateCanvasModel(false);
                    }
                }));
                $connectorStyle.append($.gap(20, 0));
                $connectorStyle.append(controls.createColorPalettePicker(this, {
                    label: "Color",
                    selectedColor: connectorProps.color ?? "none",
                    includePrimary: true,
                    includeSecondary: true,
                    includeAuto: true,
                    autoLabel: "AUTO",
                    callback: value => {
                        for (let connector of this.element.connectors.itemElements) {
                            connector.model.color = value;
                            connector.markStylesAsDirty();
                        }
                        this.element.canvas.updateCanvasModel(false);
                    }
                }));

                $menu.append(controls.createDropdownMenu(this, {
                    label: "Line Weight",
                    value: connectorProps.lineWeight,
                    items: [{
                        value: 1, label: "Extra Thin"
                    }, {
                        value: 2, label: "Thin"
                    }, {
                        value: 3, label: "Medium"
                    }, {
                        value: 5, label: "Thick"
                    }, {
                        value: "bold", label: "Bold"
                    }],
                    callback: value => {
                        for (let connector of this.element.connectors.itemElements) {
                            connector.model.lineWeight = value;
                        }
                        this.element.canvas.updateCanvasModel(false);
                    }
                }));

                $menu.append($.hr());

                let $decorations = $menu.addEl($.div().css({ display: "flex", alignItems: "center" }));
                $decorations.append(controls.createIconDropdownMenu(this, {
                    label: "Decoration",
                    value: connectorProps.startDecoration,
                    items: [{
                        value: "none", label: "None", image: "/images/ui/connectors/line-start-none.svg"
                    }, {
                        value: "arrow", label: "Arrow", image: "/images/ui/connectors/line-start-arrow.svg"
                    }, {
                        value: "circle", label: "Circle", image: "/images/ui/connectors/line-start-circle.svg"
                    }],
                    callback: value => {
                        for (let connector of this.element.connectors.itemElements) {
                            connector.model.startDecoration = value;
                        }
                        this.element.canvas.updateCanvasModel(false);
                    }
                }));
                $decorations.append($.gap(10, 0));
                $decorations.append(controls.createIconDropdownMenu(this, {
                    value: connectorProps.endDecoration,
                    items: [{
                        value: "none", label: "None", image: "/images/ui/connectors/line-end-none.svg"
                    }, {
                        value: "arrow", label: "Arrow", image: "/images/ui/connectors/line-end-arrow.svg"
                    }, {
                        value: "circle", label: "Circle", image: "/images/ui/connectors/line-end-circle.svg"
                    }],
                    callback: value => {
                        for (let connector of this.element.connectors.itemElements) {
                            connector.model.endDecoration = value;
                        }
                        this.element.canvas.updateCanvasModel(false);
                    }
                }));

                return $menu;
            }
        });

        this.addControl({
            type: controls.SLIDER,
            property: "ellipse_size",
            min: 1,
            max: 3,
            step: 0.01,
        });
    }
});

const HubAndSpokeOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Layout",
            property: "layout",
            items: [{
                value: "center", label: "Center"
            }, {
                value: "bottom", label: "Bottom"
            }, {
                value: "top", label: "Top"
            }],
            callback: () => {
                this.element.model.connectors = null;
                this.element.canvas.updateCanvasModel(true);

                this.render();
            }
        });

        this.addControl({
            type: controls.TOGGLE,
            property: "useAlternateRotation",
            label: "Offset rotation",
            enabled: this.element.spokeNodes.length === 2 || this.element.spokeNodes.length === 4 || this.element.spokeNodes.length === 6
        });
    }

});

const HubSpokeSelection = ContentItemSelection.extend({
    showDragDropTarget: true,
    dropTargetOverlapTreshold: 0.2,

    canDrag() {
        return true;
    },

    renderControls: function() {
        this.setupGridDragging();

        if (this.element instanceof ContentItem) {
            renderContentItemControls(this, { allowFrames: false, showSlider: false });
            if (this.element.showResizeHandle) {
                this.renderResizeHandle();
            }
        }

        if (this.element.canResizeTextWidth) {
            this.renderResizeHandle();
        }
    },

    getDropTargets: function(containerElement) {
        return containerElement.spokeNodes;
    },

    updateModelFromDropAction: function(dropTarget, dragProps) {
        // reset textDirection to auto
        dragProps.dragItem.model.textDirection = null;
        CollectionItemElementSelection.prototype.updateModelFromDropAction.apply(this, arguments);
    },

});

const HubSelection = ContentItemSelection.extend({

    canDrag() {
        return false;
    },

    canDelete() {
        return false;
    },

    renderControls: function() {
        if (this.element instanceof ContentItem) {
            renderContentItemControls(this, { allowFrames: true, canResize: true });
        }

        // if (this.element.canResizeTextWidth) {
        this.renderResizeHandle();
        // }
    },
});

export const editors = {
    HubAndSpokeSelection,
    HubAndSpokeOptionsMenu,
    HubSpokeSelection,
    HubSelection
};
