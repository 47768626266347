import { numeral } from "legacy-js/vendor";
import { controls } from "legacy-js/editor/ui";
import { AssetType, FormatType } from "legacy-common/constants";
import { ds } from "js/core/models/dataService";
import { $, SVG } from "legacy-js/vendor";
import * as geom from "js/core/utilities/geom";
import { ShowDialog, ShowWarningDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "legacy-js/react/views/AddAssets";
import EditTextDialog from "legacy-js/react/components/Dialogs/EditTextDialog";

import { TextElementSelection } from "./TextEditor";
import { FormatOptionsMenu } from "../FormatOptionsMenu";
import { ElementOptionsMenu } from "../BaseElementEditor";
import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";

const CompareGridSelection = CollectionElementSelection.extend({
    renderControls: function() {
        this.createAddItemButton({ skipTextSelection: true });

        this.addControl({
            id: "formatMenu",
            type: controls.POPUP_BUTTON,
            label: "Format",
            menuContents: closeMenu => {
                let $menu = new FormatOptionsMenu({
                    element: this.element,
                    allowedFormats: [FormatType.NONE, FormatType.PERCENT, FormatType.NUMBER, FormatType.CURRENCY],
                    showTableFormatOptions: false,
                    onLayoutMenu: () => {
                        $menu.layoutMenu();
                    }
                }).render().$el;
                return $menu;
            }
        });
    }
});

const CompareGridOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function($container) {
        this.addControl({
            type: controls.NUMERIC,
            label: "Axis Max",
            property: "maxValue",
            triggerEvent: "blur",
            min: 1
        });

        this.addControl({
            type: controls.NUMERIC,
            label: "Axis Min",
            property: "minValue",
            triggerEvent: "blur",
            min: 0
        });

        this.addControl({
            type: controls.BUTTON,
            label: "AutoFit Min/Max",
            callback: () => {
                this.element.autoFitRange();
            }
        });
    }
});

const CompareCollectionItemElementSelection = CollectionItemElementSelection.extend({
    renderControls: function() {
        this.createEmphasizeWidget();
        this.createValueSlider();
    },

    createValueSlider: function() {
        let compareElement = this.element.getRootElement();
        this.addControl({
            type: controls.SLIDER,
            min: compareElement.minValue,
            max: compareElement.maxValue,
            label: "Value",
            property: "value"
        });

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            includeAuto: true
        });
    }
});

const CompareBubbleSelection = CompareGridSelection.extend({});
const CompareBubbleOptionsMenu = CompareGridOptionsMenu.extend({

    renderControls() {
        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Align",
            value: this.element.circleAlign,
            items: [{
                value: "top", label: "Top"
            }, {
                value: "middle", label: "Middle"
            }, {
                value: "bottom", label: "Bottom"
            }],
            callback: value => {
                this.element.model.circleAlign = value;
                this.element.canvas.updateCanvasModel(false);
            }
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Use value for size",
            value: this.element.useValueForSize,
            callback: value => {
                this.element.model.useValueForSize = value;
                this.element.canvas.updateCanvasModel(false);
                this.render();
            }
        });

        if (this.element.useValueForSize) {
            CompareGridOptionsMenu.prototype.renderControls.apply(this, arguments);
        }
    }

});
const CompareBubbleItemSelection = CompareCollectionItemElementSelection.extend({
    getDragAxis() {
        return "x";
    },

    createValueSlider: function() {
        let compareElement = this.element.getRootElement();

        if (this.element.useValueForSize) {
            this.addControl({
                type: controls.SLIDER,
                min: compareElement.minValue,
                max: compareElement.maxValue,
                label: "Value",
                property: "value"
            });
        } else {
            this.addControl({
                type: controls.SLIDER,
                min: 0.1,
                max: 1,
                step: 0.001,
                label: "Size",
                property: "size",
                transitionModel: false
                // value: this.element.model.size,
                // callback: value => {
                //     this.element.model.size = value;
                //     // this.element.updateCanvasModel(true);
                //     this.element.refreshElement();
                // }
            });
        }

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            includeAuto: true
        });
    }
});

const CompareHorizontalBarSelection = CompareGridSelection.extend({});
const CompareHorizontalBarOptionsMenu = CompareGridOptionsMenu.extend({
    renderControls() {
        CompareGridOptionsMenu.prototype.renderControls.apply(this, arguments);
    }
});
const CompareHorizontalBarItemSelection = CompareCollectionItemElementSelection.extend({
    getDragAxis() {
        return "y";
    }
});

const CompareVerticalBarSelection = CompareGridSelection.extend({});
const CompareVerticalBarOptionsMenu = CompareGridOptionsMenu.extend({
    renderControls() {
        CompareGridOptionsMenu.prototype.renderControls.apply(this, arguments);
    }
});
const CompareVerticalBarItemSelection = CompareCollectionItemElementSelection.extend({
    getDragAxis() {
        return "x";
    }
});

const CompareRadialBarSelection = CompareGridSelection.extend({});
const CompareRadialBarOptionsMenu = CompareGridOptionsMenu.extend({});
const CompareRadialBarItemSelection = CompareCollectionItemElementSelection.extend({
    renderControls: function() {
        this.createValueSlider();
    },
});

const CompareVerticalPictographSelection = CompareGridSelection.extend({});
const CompareVerticalPictographOptionsMenu = CompareGridOptionsMenu.extend({
    renderControls() {
        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Value Position",
            items: [{
                value: "none", label: "None"
            }, {
                value: "top", label: "Top"
            }, {
                value: "bottom", label: "Bottom"
            }],
            property: "valuePosition",
            transitionModel: false
        });
        CompareGridOptionsMenu.prototype.renderControls.apply(this, arguments);
    }
});
const CompareVerticalPictographItemSelection = CompareCollectionItemElementSelection.extend({
    getDragAxis() {
        return "x";
    },

    onStopDrag: function(event, position, dragProps) {
        CollectionItemElementSelection.prototype.onStopDrag.call(this, event, position, dragProps, { forceRender: true });
    },

    transitionOnDelete: function() {
        return false;
    },

    renderControls: function() {
        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Icon",
            menuContents: closeMenu => {
                let $menu = $.div("pictorial-chart-menu");

                let loadIcon = ($menu, iconId) => {
                    let $item = $menu.addEl($.div("icon"));
                    $item.data("icon", iconId);
                    ds.assets.getAssetById(iconId, "icon").then(icon => {
                        const url = icon.get("original");
                        if (url.startsWith("http")) {
                            return fetch(url).then(res => {
                                return res.text();
                            });
                        } else {
                            return Promise.resolve(url);
                        }
                    }).then(svgData => {
                        $item.append(svgData);
                    });

                    $item.on("click", () => {
                        this.element.model.iconId = iconId;
                        this.element.canvas.updateCanvasModel(false);
                        closeMenu();
                    });
                };

                let $icons = $menu.addEl($.div("preset-icons"));
                loadIcon($icons, "figure-woman");
                loadIcon($icons, "figure-man");
                loadIcon($icons, "torso-female");
                loadIcon($icons, "torso-male");
                loadIcon($icons, "baby");
                loadIcon($icons, "pregnant");
                loadIcon($icons, "stroller");
                loadIcon($icons, "people");
                loadIcon($icons, "laborer");
                loadIcon($icons, "business-man");
                loadIcon($icons, "business-woman");
                loadIcon($icons, "female");
                loadIcon($icons, "male");
                loadIcon($icons, "star");
                loadIcon($icons, "full");

                let $choose = $menu.addEl(controls.createButton(this, {
                    label: "Choose Icon...",
                    callback: () => {
                        closeMenu();
                        ShowDialog(AddAssetsContainer, {
                            assetType: AssetType.ICON,
                            workspaceId: ds.selection.presentation.getWorkspaceId(),
                            callback: model => {
                                if (model.content_type == AssetType.ICON) {
                                    this.element.model.iconId = model.content_value;
                                    this.element.canvas.updateCanvasModel(false);
                                } else {
                                    ShowWarningDialog({
                                        title: "Please choose an icon",
                                    });
                                }
                            },
                        });
                    }
                }));

                return $menu;
            },
        });

        this.createValueSlider();
    }
});

const CompareValuesItemLabelSelection = TextElementSelection.extend({
    showSelectionBox: false,

    setup: function() {
        ds.selection.selection = null;
        const selectionBounds = this.element.selectionBounds;
        const screenBounds = this.element.getScreenBounds().offset(0, -selectionBounds.height / 2);
        const targetPt = new geom.Point(screenBounds.centerH, screenBounds.centerV);

        ShowDialog(EditTextDialog, {
            targetPt,
            target: this.element,
            minWidth: 150,
            value: this.element.parentElement.model.value,
            callback: value => {
                if (numeral.validateEx(value)) {
                    this.element.parentElement.model.value = numeral(value).value();
                    this.element.parentElement.canvas.updateCanvasModel(false).then(() => {
                        ds.selection.element = this.element.parentElement;
                    });
                }
            },
        });
    },
});

export const editors = {
    CompareGridSelection,
    CompareGridOptionsMenu,
    CompareCollectionItemElementSelection,
    CompareBubbleSelection,
    CompareBubbleOptionsMenu,
    CompareBubbleItemSelection,
    CompareHorizontalBarSelection,
    CompareHorizontalBarOptionsMenu,
    CompareHorizontalBarItemSelection,
    CompareVerticalBarSelection,
    CompareVerticalBarOptionsMenu,
    CompareVerticalBarItemSelection,
    CompareRadialBarSelection,
    CompareRadialBarOptionsMenu,
    CompareRadialBarItemSelection,
    CompareVerticalPictographSelection,
    CompareVerticalPictographOptionsMenu,
    CompareVerticalPictographItemSelection,
    CompareValuesItemLabelSelection,
};
