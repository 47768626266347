import React from "react";

import * as geom from "js/core/utilities/geom";
import { prefixZeros } from "js/core/utilities/utilities";
import { ShapeType } from "legacy-common/constants";
import { Shape } from "js/core/utilities/shapes";
import { getSVGStyleProps, SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import { _ } from "legacy-js/vendor";

import { BaseElement } from "../base/BaseElement";
import { SVGPathElement } from "../base/SVGElement";
import { TextElement } from "../base/Text/TextElement";

const RAD = Math.PI / 180;
const SHIFT_PROGRESS_BAR_BY = 88 * RAD;
const MAXIMUM_PROGRESS_BAR_SIZE = 360 * RAD;
const TIMER_BAR_RADIUS = 0.3675;
const DELAY_BEFORE_TIMER_START = 1500;
const REMAINING_SECONDS_FOR_CLOCK_PULSE = 0;

class TimerLabel extends TextElement {
    get canSelect() {
        return true;
    }

    get _canRollover() {
        return false;
    }

    get autoHeight() {
        return true;
    }

    get autoWidth() {
        return true;
    }

    get canEdit() {
        return false;
    }
}

class Timer extends BaseElement {
    get shouldAdvanceSlide() {
        return this.canvas.isPlayback && // is in playback mode
            this.model.autoAdvanceSlide && // has been set to advance slides
            this.canvas.options?.advanceToSlide && // is able to advance slides
            this.timeRemaining === 0; // has expired
    }

    get durationInMS() {
        return this.model.duration * 1000;
    }

    get isUsingRoundedProgressBar() {
        const shapeType = this.canvas.getTheme().get("styleShape");
        const includes = [ShapeType.CIRCLE, ShapeType.ROUNDED_RECT].includes(shapeType);

        return includes;
    }

    get canRefreshElement() {
        return true;
    }

    refreshElement(transition, suppressRefreshCanvasEvent) {
        this.canvas.refreshElement(this, transition, suppressRefreshCanvasEvent);
    }

    refreshTimer() {
        this.refreshElement(false, true);
    }

    // public access reset function
    resetTimer = (startTimer = true) => {
        this.initializedDurationValue = this.model.duration;
        this.timeRemaining = this.model.duration;

        if (startTimer) {
            this._startTimer();
        }
    }

    // returns the text display for remaining time
    _getTimeRemaining(asDisplayString) {
        // get the remaining time
        let { timeRemaining: remaining = this.model.duration } = this;
        remaining = Math.max(remaining, 0);

        // calculate the progress
        const percent = remaining / this.model.duration;
        const minutes = Math.floor(remaining / 60);
        const seconds = remaining % 60;
        const expired = remaining <= 0;

        // return the preferred format
        return asDisplayString
            ? `${prefixZeros(minutes, 2)}:${prefixZeros(seconds, 2)}`
            : { percent, minutes, seconds, expired, remaining };
    }

    // showing the slide
    _prepareToShowElement() {
        if (this.canvas.isPlayback) {
            this.isWaitingForAnimation = true;
            this.resetTimer(false);
            this.refreshTimer();
        }

        this._initializeAnimation();
    }

    _initializeAnimation = () => {
        // set default info
        this.initializedDurationValue = this.model.duration;

        // wait a moment before starting the timer
        this._startTimerTimeout = setTimeout(this._startTimer, DELAY_BEFORE_TIMER_START);

        // clear and rebuild
        delete this._targetTime;

        // perform a refresh in playback to force
        // the timer to reset
        if (this.canvas.isPlayback) {
            this.refreshTimer();
        }
    }

    _startTimer = () => {
        this.timeRemaining = this.model.duration;

        // start the counter
        clearInterval(this._tickInterval);
        this._tickInterval = setInterval(this._tick, 1000);
        this._tick();
    }

    _stopTimer = () => {
        this._clearPendingTimeouts();
    }

    _clearPendingTimeouts() {
        clearTimeout(this._startTimerTimeout);
        clearTimeout(this._advanceSlideTimeout);
        clearInterval(this._tickInterval);
    }

    // clean up
    _stopElement() {
        this._clearPendingTimeouts();
        this.timeRemaining = this.model.duration;

        delete this._targetTime;

        // if not in playback mode, make sure to reset
        // the timer by refreshing the view
        if (!this.canvas.isPlayback) {
            this.refreshTimer();
        }
    }

    // tick the clock
    _tick = () => {
        // check if we need to move to the next slide
        if (this.shouldAdvanceSlide) {
            // wait a moment before advancing
            this._advanceSlideTimeout = setTimeout(() => {
                this.canvas.options.advanceToSlide(1);
                this._clearPendingTimeouts();
            }, 2000);
        }

        // has the value changed (when in editor mode)
        if (this.initializedDurationValue !== this.model.duration) {
            this.initializedDurationValue = this.model.duration;
            this._startTimer(true);
        }

        // check for counting down
        if (this.isWaitingForAnimation && !this.canvas.isAnimating) {
            this.isWaitingForAnimation = false;
            this.resetTimer();

            // is allowed to start the animation
        } else if (!this.isWaitingForAnimation) {
            this.timeRemaining--;
            this.refreshTimer();
        }
    }

    _build() {
        // display the time
        this.time = this.addElement("time", () => TimerLabel, {
            html: this._getTimeRemaining(true)
        });

        // create the progress
        this.track = this.addElement("track", () => SVGPathElement);
        this.progress = this.addElement("progress", () => SVGPathElement);
    }

    _calcProps(props, options) {
        const { styles, isUsingRoundedProgressBar } = this;
        let { size } = props;

        let area = Math.min(size.width, size.height) * TIMER_BAR_RADIUS;
        let cx = size.width * 0.5;
        let cy = size.height * 0.5;
        let radius = area - Math.max(styles.track.strokeWidth, styles.progress.strokeWidth) / 2;

        // set the background track
        this.track.createProps({
            path: Shape.drawCircle(radius, { x: cx, y: cy }).toPathData()
        });

        // check if in the emphasized phase
        const { remaining, percent } = this._getTimeRemaining(false);
        const emphasize = parseInt(this.model.emphasize);

        // check if we need to show the emphasized styling
        if (!isNaN(emphasize) && emphasize > 0 && remaining <= emphasize && remaining > 0) {
            this.progress.styles.applyStyles(styles.progress.emphasized);
        } else if (percent > 0) {
            // is starting over
            this.progress.styles.applyStyles(styles.progress.default);
        }

        // blink the time when at 00:00 or first starting
        if (remaining <= REMAINING_SECONDS_FOR_CLOCK_PULSE) {
            this.time.styles.applyStyles(styles.time.emphasized);
        } else {
            // reset to default
            this.time.styles.applyStyles(styles.time.default);
        }

        // fade out the bar when expired
        if (remaining <= 1) {
            this.progress.styles.applyStyles(styles.progress.expired);
        }

        // use rounded corners, if needed
        if (isUsingRoundedProgressBar) {
            this.progress.styles.applyStyles(styles.progress.rounded);
        } else {
            this.progress.styles.applyStyles(styles.progress.flat);
        }

        // if using a style with rounded ends to lines, then
        // we want a small minimum gap between the progress
        let startingProgressGap = isUsingRoundedProgressBar
            ? ((Math.PI * 2) * 0.001) * 0.5
            : 0.0001;

        // determine the percentage to display - we never want the
        // bar fully filled, so always reduce it slightly
        const thicknessShift = (this.track.styles.strokeWidth * startingProgressGap);
        const startAngle = thicknessShift - SHIFT_PROGRESS_BAR_BY;
        const endAngle = (MAXIMUM_PROGRESS_BAR_SIZE * percent) - thicknessShift - SHIFT_PROGRESS_BAR_BY;

        // draw both arcs
        const progress = Shape.drawArc(cx, cy, radius, startAngle, endAngle).toPathData();
        this.progress.createProps({ path: progress });
        this.currentProgress = progress;

        // update the time
        let timeProps = this.time.calcProps(size, { html: this._getTimeRemaining(true) });
        timeProps.bounds = new geom.Rect(0, size.height / 2, timeProps.size);
        timeProps.bounds.left = cx - timeProps.bounds.width / 2;

        return { size };
    }

    renderChildren(transition) {
        const children = super.renderChildren(transition);

        const glowBase = getSVGStyleProps(this.styles.glow);

        const { remaining, percent } = this._getTimeRemaining(false);
        const emphasize = parseInt(this.model.emphasize);

        let styles = {};
        if (!isNaN(emphasize) && emphasize > 0 && remaining <= emphasize && remaining > 0) {
            styles = getSVGStyleProps(this.styles.glow.emphasized);
        } else if (percent > 0) {
            // is starting over
            styles = getSVGStyleProps(this.styles.glow.default);
        }

        // fade out the bar when expired
        if (remaining <= 1) {
            styles = getSVGStyleProps(this.styles.glow.expired);
        }

        const styledPath = _.merge(glowBase, styles);

        children.push(
            <SVGGroup key={this.id}>
                <defs>
                    <filter id="blur-filter" x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="0">
                            <animate attributeName="stdDeviation" from="0" to="15" dur="2s" repeatCount="indefinite" values="0;15;0"/>
                        </feGaussianBlur>
                        <feOffset dx="0" dy="0" />
                        <feMerge>
                            <feMergeNode />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>
                <path id="glow" d={this.currentProgress}
                    style={styledPath}
                />
            </SVGGroup>
        );

        return children;
    }
}

export const elements = {
    Timer
};
