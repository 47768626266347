import { _ } from "legacy-js/vendor";
import { ds } from "js/core/models/dataService";
import { controls } from "legacy-js/editor/ui";
import { ListStyleType, TextStyleType } from "legacy-common/constants";

import { CollectionElementSelection } from "../CollectionElementEditor";
import { ElementOptionsMenu } from "../BaseElementEditor";
import { ShowErrorDialog } from "legacy-js/react/components/Dialogs/BaseDialog";

const AgendaSelection = CollectionElementSelection.extend({

    renderControls: function() {
        this.addControl({
            type: controls.BUTTON,
            label: "Add Agenda Item",
            className: "",
            icon: "add_circle",
            enabled: this.element.textColumnsCount > 0 || this.element.maxItemCount > this.element.itemCollection.length,
            callback: () => {
                const lastColumn = _.last(this.element.itemElements.filter(element => !element.isMediaColumn));
                if (!lastColumn) {
                    this.element.model.autoArrangeColumnsCount = this.element.itemCollection.length + 1;
                    this.addItem()
                        .then(() => {
                            ds.selection.element = this.element.itemElements[this.element.itemElements.length - 1];
                        });
                    return;
                }

                lastColumn.text.addBlock({
                    textStyle: TextStyleType.BULLET_LIST,
                    listStyle: ListStyleType.NUMBERED
                });

                this.element.canvas.updateCanvasModel(true);
            }
        });

        this.addControl({
            type: controls.BUTTON,
            label: "Add Agenda Section",
            className: "",
            icon: "add_circle",
            enabled: this.element.textColumnsCount > 0 || this.element.maxItemCount > this.element.itemCollection.length,
            callback: () => {
                const lastColumn = _.last(this.element.itemElements.filter(element => !element.isMediaColumn));
                if (!lastColumn) {
                    this.element.model.autoArrangeColumnsCount = this.element.itemCollection.length + 1;
                    this.addItem()
                        .then(() => {
                            ds.selection.element = this.element.itemElements[this.element.itemElements.length - 1];
                        });
                    return;
                }

                lastColumn.text.addBlock({
                    textStyle: TextStyleType.SECTION
                });

                this.element.canvas.updateCanvasModel(true);
            }
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Auto-arrange",
            items: [{
                value: 1, label: "One Column"
            }, {
                value: 2, label: "Two Columns"
            }, {
                value: 3, label: "Three Columns"
            }].slice(0, this.element.maxTextColumnsCount),
            value: this.element.textColumnsCount,
            callback: value => {
                try {
                    this.element.distributeBullets(value);
                } catch (err) {
                    ShowErrorDialog({ title: "Sorry, we couldn't make this change", message: err });
                    this.element.canvas.revertCanvasModel();
                    return;
                }
                this.element.canvas.updateCanvasModel(true);
            }
        });
    }
});

const AgendaOptionsMenu = ElementOptionsMenu.extend({

    renderControls() {
        this.addControl({
            type: controls.NUMERIC,
            label: "Start Numbering At",
            property: "startNum",
            min: 1,
            max: 9999,
            step: 1
        });
    }

});

export const editors = {
    AgendaSelection,
    AgendaOptionsMenu
};
