import * as geom from "js/core/utilities/geom";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/Text/TextElement";
import { AuthoringBlockType, BlockStructureType, TextStyleType } from "legacy-common/constants";
import { layoutHelper } from "../layouts/LayoutHelper";

export class Cards extends CollectionElement {
    getChildItemType(itemModel) {
        return Card;
    }

    get minItemCount() {
        return 1;
    }

    get maxItemCount() {
        return 6;
    }

    get rows() {
        return 1;
    }

    get passThroughSelection() {
        return false;
    }

    getItemsInRows() {
        let itemsInRows = [];
        for (const item of this.itemElements) {
            const rowIdx = item.model.row ?? 0;
            if (!itemsInRows[rowIdx]) {
                itemsInRows[rowIdx] = [];
            }
            itemsInRows[rowIdx].push(item);
        }

        // Remove empty rows
        itemsInRows = itemsInRows.filter(items => items);

        // Reset row indexes in model
        itemsInRows.forEach((rowItems, rowIdx) => rowItems.forEach(item => item.model.row = rowIdx));

        return itemsInRows;
    }

    _calcProps(props, options) {
        let { size } = props;

        const itemsInRows = this.getItemsInRows();

        const vGap = this.styles.vGap ?? 10;
        const hGap = this.styles.hGap ?? 10;

        let rowHeights = [];
        let y = 0;
        for (const rowItems of itemsInRows) {
            let cardWidth = (size.width - hGap * rowItems.length) / rowItems.length;
            for (let card of rowItems) {
                card.calcProps(new geom.Size(cardWidth, size.height), { calcHeight: true });
            }
            let cardHeight = layoutHelper.getMaxHeightOfItems(rowItems);

            let x = 0;
            for (let card of rowItems) {
                let cardProps = card.calcProps(new geom.Size(cardWidth, cardHeight));
                cardProps.bounds = new geom.Rect(x, y, cardWidth, cardHeight);
                x += cardWidth + hGap;
            }

            y += cardHeight + vGap;

            rowHeights.push(cardHeight);
        }

        return {
            size: new geom.Size(size.width, y),
            rowHeights
        };
    }
}

class Card extends CollectionItemElement {
    get selectionPadding() {
        return 0;
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.FREEFORM,
            autoHeight: true,
            allowedBlockTypes: [
                TextStyleType.HEADING,
                TextStyleType.TITLE,
                TextStyleType.BODY,
                TextStyleType.CAPTION,
                TextStyleType.LABEL,
                AuthoringBlockType.MEDIA,
                AuthoringBlockType.DIVIDER,
                AuthoringBlockType.CODE,
                AuthoringBlockType.EQUATION
            ]
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let textProps = this.text.calcProps(size);
        textProps.bounds = new geom.Rect(0, 0, size);

        if (options.calcHeight) {
            return { size: textProps.size };
        } else {
            return { size };
        }
    }
}

export const elements = {
    Cards
};

