import React, { Component } from "react";
import { ds } from "js/core/models/dataService";
import styled from "styled-components";
import { app } from "js/namespaces";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { PopupMenuPaddedContainer } from "legacy-js/react/components/PopupMenu";
import { BlueButton } from "legacy-js/react/components/UiComponents";
import { ShowDialog, ShowWarningDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "legacy-js/react/views/AddAssets";
import { AssetType } from "legacy-common/constants";
import getLogger from "js/core/logger";

const logger = getLogger();

const DEFAULT_ICONS = ["thumbs-up", "thumbs-down", "star", "star-half", "star-none", "full", "quarters-three", "contrast", "quarter", "none"];

const IconPreviewContainer = styled.div`
  width: 50px;
  height: 50px;

  &:hover {
    background: #f1f1f1;
  }
`;

class IconPreview extends Component {
    state = {
        iconPath: null
    }

    async componentDidMount() {
        let { iconId } = this.props;

        ds.assets.getAssetById("icon-" + app.currentTheme.get("iconStyle") + "-" + iconId, "icon").then(icon => {
            const url = icon.get("original");
            return fetch(url).then(res => res.text()).then(data => {
                let r = / d="(.*?)"/m;
                let pathString = r.exec(data)[1];
                this.setState({ iconPath: pathString });
            });
        }).catch(err => {
            logger.error(err, "[IconPreview] failed to load icon", { iconId });
        });
    }

    render() {
        let { onClick } = this.props;
        let { iconPath } = this.state;

        return (
            <IconPreviewContainer onClick={onClick}>
                {iconPath &&
                    <svg width="100%" height="100%" viewBox="0 0 128 128">
                        <path d={iconPath} fill="black" />
                    </svg>
                }
            </IconPreviewContainer>
        );
    }
}

export class IconListDecorationMenu extends Component {
    handleChooseIcon = () => {
        const { onChange } = this.props;

        ShowDialog(AddAssetsContainer, {
            assetType: AssetType.ICON,
            workspaceId: ds.selection.presentation.getWorkspaceId(),
            callback: asset => {
                if (asset.content_type == AssetType.ICON) {
                    onChange(asset.content_value);
                } else {
                    ShowWarningDialog({
                        title: "Unsupported asset type",
                        message: "Please choose an icon from the asset chooser.",
                    });
                }
            },
        });
    }

    render() {
        const { onChange } = this.props;

        return (
            <PopupMenuPaddedContainer>
                <FlexBox gap={10} width={250} wrap>
                    {DEFAULT_ICONS.map(icon =>
                        <IconPreview key={icon} iconId={icon} onClick={() => onChange(icon)} />
                    )}
                </FlexBox>
                <BlueButton onClick={this.handleChooseIcon}>Choose Icon...</BlueButton>

            </PopupMenuPaddedContainer>
        );
    }
}
