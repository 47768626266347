import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Parser } from "hot-formula-parser";
import { v4 as uuid } from "uuid";

import {
    AssetType,
    CellChangeStyle,
    FormatType,
    HorizontalAlignType
} from "legacy-common/constants";
import { cjkFallbackFonts } from "legacy-common/fontConstants";
import getLogger, { LogGroup } from "js/core/logger";
import { getValueOrDefault } from "js/core/utilities/extensions";
import { formatter } from "js/core/utilities/formatter";
import * as geom from "js/core/utilities/geom";
import { SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import { blendColors } from "js/core/utilities/utilities";
import { FlexSpacer } from "legacy-js/react/components/Gap";
import { $, tinycolor, _ } from "legacy-js/vendor";
import { Path } from "js/core/utilities/shapes";
import { getSelectedSpreadsheetData } from "js/core/utilities/xlsx";
import { detectTabularData } from "js/core/services/sharedModelManager";

import { BaseElement } from "../../base/BaseElement";
import { ContentElement } from "../../base/ContentElement";
import { SVGElement } from "../../base/SVGElement";

const logger = getLogger(LogGroup.ELEMENTS);

const fallbackFonts = [
    "sans-serif",
    ...cjkFallbackFonts.map(({ fontFaceName }) => `'${fontFaceName}'`)
].join(",");

export class TableFrame extends BaseElement {
    static get schema() {
        const schema = {
            tableWidth: 1,
            tableHeight: 1,
            showTopLeftCell: true,
            showBorder: true,
            showColGridLines: true,
            showRowGridLines: true,
            cells: [],
            cols: [{ index: 0, size: 50, style: "headerCol" }, { index: 1, size: 50, style: "defaultCol" }, {
                index: 2,
                size: 50,
                style: "defaultCol"
            }, { index: 3, size: 50, style: "defaultCol" }],
            rows: [{ index: 0, size: 50, style: "headerRow" }, { index: 1, size: 50, style: "defaultRow" }, {
                index: 2,
                size: 50,
                style: "defaultRow"
            }]
        };

        // Generating cells
        schema.cols.forEach(col =>
            schema.rows.forEach(row => {
                schema.cells.push({
                    col: col.index,
                    row: row.index,
                    format: FormatType.TEXT
                });
            }));

        return schema;
    }

    _build() {
        this.table = this.addElement("table", () => Table);
    }

    _calcProps(props) {
        let { size } = props;

        let tableSize;
        if (this.isOnAuthoringCanvas) {
            tableSize = size;
        } else {
            tableSize = new geom.Size((this.model.tableWidth || 1) * size.width, (this.model.tableHeight || 1) * size.height);
        }

        let tableProps = this.table.calcProps(tableSize);
        tableProps.bounds = new geom.Rect(size.width / 2 - tableSize.width / 2, size.height / 2 - tableSize.height / 2, tableSize);

        return { size };
    }

    get rolloverPadding() {
        return { top: 50, left: 50, right: 30, bottom: 30 };
    }

    refreshElement(transition, suppressRefreshSelectionLayer = false) {
        this.canvas.refreshElement(this, transition, suppressRefreshSelectionLayer);
    }

    get canRefreshElement() {
        return true;
    }

    get disableAllAnimationsByDefault() {
        return true;
    }

    get animationElementName() {
        return "Table";
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }

    _useUpdatedDataSource(dataSourceEntry) {
        let tableData = dataSourceEntry.validatedData, initialImport = dataSourceEntry.initialImport;

        if (this.hasDataSourceLink() && dataSourceEntry.id) {
            const spreadsheetData = dataSourceEntry.spreadsheetData;

            const { selectedSheetIndex, selectedCellRange, isDataTransposed } = this.model.dataSourceLink;
            const selectedData = getSelectedSpreadsheetData({ sheets: spreadsheetData }, selectedSheetIndex, selectedCellRange);

            if (isDataTransposed) selectedData.csvData = _.unzip(selectedData.csvData);

            tableData = selectedData.csvData;
        }

        this.table.updateTableData(tableData, initialImport);
    }

    _exportToSharedModel() {
        const { cells, rows, cols } = this.model;

        const data = new Array(rows.length).fill().map(() => new Array(cols.length).fill(""));
        for (let cell of cells) {
            data[cell.row][cell.col] = cell.cellText?.text || "";
        }

        return {
            tabularData: [{
                data, dataSourceLink: _.omit(this.model.dataSourceLink, ["useFirstRowAsCategory", "useFirstColAsLegend", "isDataTransposed"])
            }]
        };
    }

    _importFromSharedModel(model) {
        const tabularData = detectTabularData(model);
        if (!tabularData?.data) return;

        const tableData = this.table.updateTableData(tabularData.data, true, [], true);
        return {
            ...tableData,
            dataSourceLink: tabularData.dataSourceLink,
            postProcessingFunction: canvas => {
                const tableElement = canvas.getPrimaryElement().table;
                const allHaveSizes = !(tableElement.model.cols.some(c => !c.size) || tableElement.model.rows.some(r => !r.size));
                if (!allHaveSizes) {
                    tableElement.calcAutoFit("both");
                }
            }
        };
    }
}

class Table extends BaseElement {
    get canSelect() {
        return true;
    }

    get MAX_COLS() {
        return 20;
    }

    get MAX_ROWS() {
        return 25;
    }

    get rolloverPadding() {
        return { top: 50, left: 50, right: 30, bottom: 30 };
    }

    getChildItemType() {
        return TableCell;
    }

    get totalCols() {
        return this.model.cols.length;
    }

    get totalRows() {
        return this.model.rows.length;
    }

    get collectionPropertyName() {
        return "cells";
    }

    get isDirty() {
        return true;
    }

    getColumn(index) {
        return _.find(this.model.cols, { index });
    }

    getRow(index) {
        return _.find(this.model.rows, { index });
    }

    getColumnStyle(index) {
        let col = this.getColumn(index);
        return col?.style || "defaultCol";
    }

    getRowStyle(index) {
        let row = this.getRow(index);
        return row?.style || "defaultRow";
    }

    getCell(col, row) {
        return _.find(this.cells, { col, row });
    }

    getCellsInColumn(col) {
        return _.filter(this.cells, { col });
    }

    getCellsInRow(row) {
        return _.filter(this.cells, { row });
    }

    findCellAtPoint(x, y) {
        const pt = geom.Convert.ScreenToCanvasCoordinates(this.canvas, x, y);
        for (let cell of this.cells) {
            const cellBounds = cell.bounds.offset(this.canvasBounds.position);
            if (cellBounds?.contains(pt)) {
                return cell;
            }
        }
        return null;
    }

    get emphasizedScale() {
        return 1.10;
    }

    get MIN_COL_WIDTH() {
        return 50;
    }

    get MIN_ROW_HEIGHT() {
        return 30;
    }

    get MAX_COL_WIDTH() {
        return 2200;
    }

    get MAX_ROW_HEIGHT() {
        return 2200;
    }

    get positiveChangeColor() {
        return this.canvas.getTheme().palette.getColor("positive").toRgbString();
    }

    get negativeChangeColor() {
        return this.canvas.getTheme().palette.getColor("negative").toRgbString();
    }

    get matchCellFontSizes() {
        return getValueOrDefault(this.model.matchCellFontSizes, true);
    }

    get isInteractive() {
        const result = this.cells.some(cell => cell.model.link || cell.model.linkToSlide);
        return result;
    }

    _build() {
        /**
         * We encountered cases when rows didn't have index on them, this code mitigates it.
         * Please refer to BA-14212.
         */
        if (this.model.cols.some(col => col.index == undefined)) {
            logger.warn(`[table] some cols missing index, removing...`, { slideId: this.canvas.dataModel?.id });
            this.model.cols = this.model.cols.filter(col => col.index != undefined);
        }
        if (this.model.rows.some(row => row.index == undefined)) {
            logger.warn(`[table] some rows missing index, removing...`, { slideId: this.canvas.dataModel?.id });
            this.model.rows = this.model.rows.filter(row => row.index != undefined);
        }

        /**
         * We encountered cases when a cell got removed from a table, probably this could have
         * happened due to a selection layer bug. We have to check for and recreate missing cells to be
         * able to render tables that got broken due to that.
         * Please refer to BA-8401.
         */
        this.model.cols.forEach(col =>
            this.model.rows.forEach(row => {
                if (!this.model.cells.some(cell => cell.col === col.index && cell.row === row.index)) {
                    logger.warn(`[table] cell at index ${col.index}:${row.index} not found, recreating...`, { slideId: this.canvas.dataModel?.id });
                    this.model.cells.push({
                        col: col.index,
                        row: row.index,
                        format: FormatType.TEXT
                    });
                }
            }));

        // Remove cells that reference non-existing columns and rows
        this.model.cells = this.model.cells.filter(cell => this.getColumn(cell.col) && this.getRow(cell.row));

        this.tableBackground = this.addElement("tableBackground", () => TableBackgrounds);
        this.tableBackground.layer = -1;
        this.tableGridLines = this.addElement("tableGridLines", () => TableGridLines);
        this.tableGridLines.layer = 10;

        this.generateCells();
    }

    generateCells() {
        this.cells = [];

        const seenIds = [];
        for (const cell of this.model.cells) {
            while (!cell.id || seenIds.includes(cell.id)) {
                cell.id = `cell${uuid()}`;
            }
            seenIds.push(cell.id);

            if (cell.format === "icon") {
                this.addElement(cell.id, () => TableCellIcon, {
                    model: cell
                });
            }

            this.cells.push(new TableCell(cell, this));
        }

        // remove any orphaned iconCells if the model changed due to undo or collab change
        for (const iconCell of _.filter(this.elements, element => element.type == "TableCellIcon")) {
            if (!this.model.cells.contains(iconCell.model) || iconCell.model.format != "icon") {
                this.removeElement(iconCell);
            }
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        // Note: explicily setting props and sending our styles down to the grid and backgrounds
        this.tableBackground.createProps();
        this.tableGridLines.createProps();

        _.last(this.model.cols).break = false;
        _.last(this.model.rows).break = false;

        let totalFlexColWidth = size.width - this.model.cols.filter(col => col.break).length * this.styles.colBreak;
        let totalFlexRowHeight = size.height - this.model.rows.filter(row => row.break).length * this.styles.rowBreak;

        let baseColWidth = totalFlexColWidth / _.sumBy(this.model.cols, col => (col.size || 1));
        let baseRowHeight = totalFlexRowHeight / _.sumBy(this.model.rows, row => (row.size || 1));

        let colBounds = [];
        let x = 0;
        for (let col of this.model.cols) {
            let colWidth = (col.size || 1) * baseColWidth;
            colBounds.push(new geom.Rect(x, 0, colWidth, size.height));
            x += colWidth + (col.break ? this.styles.colBreak : 0);
        }

        let rowBounds = [];
        let y = 0;
        for (let row of this.model.rows) {
            let rowHeight = (row.size || 1) * baseRowHeight;
            rowBounds.push(new geom.Rect(0, y, size.width, rowHeight));
            y += rowHeight + (row.break ? this.styles.rowBreak : 0);
        }

        const minIconHeight = Math.min(...this.cells.filter(cell => cell.model.format === "icon")
            .map(cell => {
                let colBound = colBounds[cell.col];
                let rowBound = rowBounds[cell.row];

                let cellWidth = colBounds[cell.col + (cell.model.width || 1) - 1].right - colBounds[cell.col].left;
                let cellHeight = rowBounds[cell.row + (cell.model.height || 1) - 1].bottom - rowBounds[cell.row].top;

                return new geom.Rect(colBound.left, rowBound.top, cellWidth, cellHeight).height;
            }));

        for (let cell of this.cells) {
            let colBound = colBounds[cell.col];
            let rowBound = rowBounds[cell.row];

            let cellWidth = colBounds[cell.col + (cell.model.width || 1) - 1].right - colBounds[cell.col].left;
            let cellHeight = rowBounds[cell.row + (cell.model.height || 1) - 1].bottom - rowBounds[cell.row].top;

            let cellBounds = new geom.Rect(colBound.left, rowBound.top, cellWidth, cellHeight);
            if (this.getColumnStyle(cell.col) == "emphasizedCol") {
                cellBounds.height = rowBound.height * this.emphasizedScale;
                cellBounds.top -= (size.height / 2 - cellBounds.top) * (this.emphasizedScale - 1);
            }

            cell.bounds = cellBounds;

            if (cell.model.format == "icon" && !cell.model.isHidden) {
                // icon cells acutally use a TableCellIcon child so need to have props set
                let iconCellProps = this.elements[cell.model.id].calcProps(new geom.Size(cellBounds.width, cellBounds.height), { fitAsset: true, iconCellHeight: minIconHeight, matchCellHeight: true });
                iconCellProps.bounds = cellBounds;
            }
        }

        return {
            size,
            colBounds, rowBounds, baseColWidth, baseRowHeight,
        };
    }

    _getBackgroundColor(forElement) {
        if (forElement && forElement instanceof TableCellIcon) {
            let colStyle = this.getColumnStyle(forElement.model.col);
            let rowStyle = this.getRowStyle(forElement.model.row);

            let colStyleProps = this.styles.columnStyles[colStyle];
            let rowStyleProps = this.styles.rowStyles[rowStyle];

            if (colStyle == "cleanCol" || rowStyle == "cleanRow") {
                return this.parentElement.getBackgroundColor();
            }

            let backgroundColor;
            if (this.model.tableBackgroundColor) {
                backgroundColor = this.canvas.getTheme().palette.getColor(this.model.tableBackgroundColor);
            } else {
                backgroundColor = tinycolor("white");
            }

            if (colStyleProps && colStyleProps.fillColor && colStyle != "clearCol") {
                backgroundColor = blendColors(this.canvas.getTheme().palette.getForeColor(colStyleProps.fillColor + " " + colStyleProps.fillOpacity, this.getSlideColor(), this.getParentBackgroundColor()), backgroundColor);
            }
            if (rowStyleProps && rowStyleProps.fillColor && rowStyle != "clearRow") {
                backgroundColor = blendColors(this.canvas.getTheme().palette.getForeColor(rowStyleProps.fillColor, this.getSlideColor(), this.getParentBackgroundColor()), backgroundColor);
            }

            return backgroundColor;
        } else {
            if (this.model.tableBackgroundColor) {
                return this.canvas.getTheme().palette.getColor(this.model.tableBackgroundColor);
            } else {
                return tinycolor("white");
            }
        }
    }

    getCssFontProps(styles) {
        const { fontId, fontWeight } = styles;
        // Set backup font families
        // fallback fonts (sans-serif and cjk fonts)

        return {
            fontFamily: `${fontId}, ${fallbackFonts}`,
            fontWeight
        };
    }

    calcCellProps(cell) {
        let colStyle = this.getColumnStyle(cell.col);
        let rowStyle = this.getRowStyle(cell.row);

        // Merge Priority: cell > row > col
        let styles = _.merge({},
            this.styles.columnStyles[colStyle],
            this.styles.rowStyles[rowStyle],
            this.styles.TableCell);

        let model = cell.model;

        let cellStyles = {};

        cellStyles.paddingLeft = styles.paddingLeft;
        cellStyles.paddingRight = styles.paddingRight;
        cellStyles.paddingTop = styles.paddingTop;
        cellStyles.paddingBottom = styles.paddingBottom;

        let format = cell.model.format || FormatType.TEXT;
        let formatOptions;
        if (cell.model.formatOptions && typeof (cell.model.formatOptions) == "object") {
            formatOptions = cell.model.formatOptions;
        } else {
            formatOptions = formatter.getDefaultFormatOptions();
        }

        let text = (cell.value && cell.value != "") ? formatter.formatValue(cell.value, format, formatOptions) : "";

        let tableBackgroundColor = this.canvas.getTheme().palette.getColor(this.model.tableBackgroundColor || "white");

        let textColor;
        let borderColor;
        let flagDecoration;
        let flagColor;

        let rowAndColBackgroundColor = tableBackgroundColor;
        if (styles.resolved_fillColor) {
            rowAndColBackgroundColor = styles.resolved_fillColor;
            if (styles.fillOpacity && styles.fillOpacity != 1) {
                rowAndColBackgroundColor = blendColors(rowAndColBackgroundColor.setAlpha(styles.fillOpacity), tableBackgroundColor);
            }
        }

        let backgroundColor;

        let cellColor = cell.cellColor ?? styles.resolved_fillColor?.name;
        if (cell.model.row === 0 && cell.table.model.rows[0].style === "defaultRow") {
            cellColor = cell.cellColor;
        }
        switch (model.cellStyle) {
            case "fill":
                // Cell color has priority over row and column color
                backgroundColor = cellColor ? this.canvas.getTheme().palette.getColor(cellColor) : rowAndColBackgroundColor;
                break;
            case "stroke":
                borderColor = this.canvas.getTheme().palette.getColor(cellColor);
                break;
            case "text":
                textColor = this.canvas.getTheme().palette.getColor(cellColor);
                break;
            case "flag":
                flagColor = this.canvas.getTheme().palette.getColor(cellColor).toRgbString();
                flagDecoration = (
                    <svg style={{ position: "absolute", top: 0, right: 0, width: cell.flagDecorationSize, height: cell.flagDecorationSize }}>
                        <path d="M0,0 L20,0 L20,20 z" fill={flagColor} />
                    </svg>
                );
                break;
        }

        if (textColor) {
            cellStyles.color = textColor.toRgbString();
        } else {
            const cellBackgroundColor = colStyle === "cleanCol" || rowStyle === "cleanRow"
                ? this.canvas.getBackgroundColor()
                : backgroundColor ?? rowAndColBackgroundColor;
            const cellTextColor = this.canvas.getTheme().palette.getForeColor(styles.fontColor, null, cellBackgroundColor);

            if (cellBackgroundColor && cellBackgroundColor.isDark() && cellTextColor.isDark()) {
                cellStyles.color = "white";
            } else if (cellBackgroundColor && !cellBackgroundColor.isDark() && !cellTextColor.isDark()) {
                cellStyles.color = "black";
            } else {
                cellStyles.color = cellTextColor.toRgbString();
            }
        }

        cellStyles.flagColor = flagColor;
        cellStyles.fontSize = styles.fontSize;
        cellStyles.letterSpacing = styles.letterSpacing;
        cellStyles.lineHeight = styles.lineHeight;
        Object.assign(cellStyles, this.getCssFontProps(styles));
        cellStyles.textAlign = formatOptions.textAlign || styles.textAlign || HorizontalAlignType.CENTER;
        if (model.bold) {
            cellStyles.fontWeight = Math.max(600, cellStyles.fontWeight);
        }
        cellStyles.fontStyle = model.italic ? "italic" : "normal";
        cellStyles.textDecoration = model.strikeThrough ? "line-through" : "none";
        const switchDirections = formatOptions.changeStyle == CellChangeStyle.ARROWS ||
            formatOptions.accountingStyle && (format === FormatType.CURRENCY || format === FormatType.NUMBER);
        const verticalKey = switchDirections ? "justifyContent" : "alignItems";
        const horizontalKey = switchDirections ? "alignItems" : "justifyContent";
        cellStyles[horizontalKey] = "center";

        switch (cellStyles.textAlign) {
            case HorizontalAlignType.LEFT:
                cellStyles[verticalKey] = "flex-start";
                break;
            case HorizontalAlignType.CENTER:
                cellStyles[verticalKey] = "center";
                break;
            case HorizontalAlignType.RIGHT:
                cellStyles[verticalKey] = "flex-end";
                break;
        }

        let decoration;
        if (formatOptions.changeStyle == CellChangeStyle.ARROWS) {
            const arrowWidth = 12;
            const arrowHeight = 9;
            let arrow;
            if (parseFloat(cell.value) < 0) {
                let path = new Path();
                path.moveTo(0, 0);
                path.lineTo(arrowWidth, 0);
                path.lineTo(arrowWidth / 2, arrowHeight);
                path.close();

                arrow = <path d={path.toPathData()} fill={this.negativeChangeColor} />;
            } else if (parseFloat(cell.value) > 0) {
                let path = new Path();
                path.moveTo(arrowWidth / 2, 0);
                path.lineTo(arrowWidth, arrowHeight);
                path.lineTo(0, arrowHeight);
                path.close();

                arrow = <path d={path.toPathData()} fill={this.positiveChangeColor} />;
            }
            switchDirections && (cellStyles.flexDirection = "row");
            decoration = (
                <svg key="arrows" style={{ width: arrowWidth, height: arrowHeight, marginRight: 6 }}>{arrow}</svg>);
        }

        if ((formatOptions.changeStyle !== CellChangeStyle.NONE || formatOptions.accountingStyle) && formatOptions.changeColor && model.cellText && (format === FormatType.CURRENCY || format === FormatType.NUMBER || format === FormatType.PERCENT)) {
            if (parseFloat(cell.value.replace(/[^0-9.-]+/g, "")) < 0) {
                cellStyles.color = this.negativeChangeColor;
            } else if (parseFloat(cell.value.replace(/[^0-9.-]+/g, "")) > 0) {
                cellStyles.color = this.positiveChangeColor;
            }
        }

        if (formatOptions.accountingStyle && (format === FormatType.CURRENCY || format === FormatType.NUMBER)) {
            switchDirections && (cellStyles.flexDirection = "row");
            if (format === FormatType.CURRENCY) {
                cellStyles.paddingLeft = cellStyles.paddingRight = 10;
                decoration = (
                    <Fragment>
                        {formatOptions.currency}
                        <FlexSpacer />
                    </Fragment>
                );
            } else {
                decoration = <FlexSpacer />;
            }
        }

        if (model.emphasized) {
            Object.assign(cellStyles, this.getCssFontProps(styles.emphasized));
            cellStyles.border = `solid 2px ${this.canvas.getTheme().palette.getColor(this.canvas.getSlideColor()).toRgbString()}`;
        } else if (borderColor) {
            cellStyles.border = `solid 2px ${borderColor.toRgbString()}`;
        }

        cellStyles.fontSize = getValueOrDefault(model.fontSize, styles.fontSize);

        if (backgroundColor) {
            cellStyles.background = backgroundColor.toRgbString();
        } else if (cell.model.width > 1 || cell.model.height > 1) {
            // use the rowAndCellBackgroundColor for merged cells in case they span multiple styles
            cellStyles.background = rowAndColBackgroundColor.toRgbString();
        } else {
            cellStyles.background = "transparent";
        }

        cell.styles = cellStyles;

        const wrappers = [];

        if (model.link) {
            wrappers.push(content => (
                <a href={model.link} target="_blank">{content}</a>
            ));
        }

        return { bounds: cell.bounds, cellStyles, text, decoration, flagDecoration, wrappers };
    }

    calcOptimalCellSize(cell, maxWidth) {
        let cellProps = this.calcCellProps(cell);

        let $div = $.div().css({ position: "absolute", top: 0, opacity: 0, maxWidth: maxWidth });
        let $cell = $div.addEl($.div("", cellProps.text).css({ display: "flex" }));
        $cell.css(cellProps.cellStyles);

        $("body").append($div);
        let bounds = geom.Rect.FromBoundingClientRect($cell[0].getBoundingClientRect());

        let lastLineHeightPadding = cellProps.cellStyles.fontSize * cellProps.cellStyles.lineHeight - cellProps.cellStyles.fontSize;
        bounds.height -= lastLineHeightPadding;

        $div.remove();

        return bounds;
    }

    calcAutoFit(type, index = null) {
        if (!this.calculatedProps) return;

        if (type == "columns" || type == "both") {
            let cols = index ? [this.model.cols[index]] : this.model.cols;
            for (let col of cols) {
                col.scale = 1;
            }

            let tableWidth = _.filter(this.model.cols, { break: true }).length * this.styles.colBreak;

            const MAX_COL_WIDTH = 350;
            for (let col of cols) {
                let colWidth = 0;
                for (let cell of this.getCellsInColumn(col.index)) {
                    let cellBounds = this.calcOptimalCellSize(cell, MAX_COL_WIDTH);
                    colWidth = Math.max(colWidth, cellBounds.width + 20);
                }
                col.size = Math.max(colWidth, this.MIN_COL_WIDTH);
                tableWidth += Math.max(colWidth, this.MIN_COL_WIDTH * 1.25);
            }

            if (index == null) {
                this.model.tableWidth = Math.min(1, tableWidth / this.calculatedProps.allowedSize.width);
            }
        }

        if (type == "rows" || type == "both") {
            for (let row of this.model.rows) {
                if (index === null || row.index === index) {
                    row.size = 1;
                }
            }

            let tableHeight = _.filter(this.model.rows, { break: true }).length * this.styles.rowBreak;

            let headerRows = [];
            let numericRows = [];
            let textRows = [];
            let imageRows = [];
            for (let row of this.model.rows) {
                let rowStyle = this.getRowStyle(row.index);
                if (rowStyle == "headerRow") {
                    headerRows.push(row);
                } else {
                    let hasText = false;
                    let hasMedia = false;
                    for (let cell of this.getCellsInRow(row.index)) {
                        if (this.getRow(cell.row).style != "headerCol" && cell.model.format == "text" && cell.model.cellText && !_.isEmpty(cell.model.cellText.text)) {
                            hasText = true;
                        }
                        if (cell.model.format == FormatType.ICON && cell.model.content_type != AssetType.ICON && cell.model.content_value) {
                            hasMedia = true;
                        }
                    }
                    if (hasMedia) {
                        imageRows.push(row);
                    }
                    if (hasText) {
                        textRows.push(row);
                    } else {
                        numericRows.push(row);
                    }
                }
            }

            const MAX_HEADER_ROW_HEIGHT = 400;
            const MIN_HEADER_ROW_HEIGHT = 40;

            for (let row of headerRows) {
                let rowHeight = 0;

                for (let cell of this.getCellsInRow(row.index)) {
                    let cellBounds;
                    // merged cell
                    if (cell.width > 1) {
                        const getColumnsSize = (startCol, numOfCols) => [...Array(numOfCols).keys()].reduce((sum, i) => sum + this.getColumn(startCol + i).size, 0);
                        cellBounds = this.calcOptimalCellSize(cell, getColumnsSize(cell.model.col, cell.model.width) * this.calculatedProps.baseColWidth);
                    } else {
                        cellBounds = this.calcOptimalCellSize(cell, this.getColumn(cell.col).size * this.calculatedProps.baseColWidth);
                    }
                    rowHeight = Math.max(rowHeight, cellBounds.height);

                    // if (cell.contents && cell.contents instanceof TableCellText && cell.contents.model.cellText && cell.contents.model.cellText.text != "") {
                    //     let cellContentProps = cell.contents.calcProps(new geom.Size(cell.colWidth, MAX_HEADER_ROW_HEIGHT), { styles: styles.TableCell.cellText });
                    //     rowHeight = Math.max(rowHeight, cellContentProps.textBounds.height + cellContentProps.styles.paddingTop + cellContentProps.styles.paddingBottom);
                    // } else {
                    //     rowHeight = Math.max(rowHeight, MIN_HEADER_ROW_HEIGHT);
                    // }
                }
                tableHeight += rowHeight;
                if (index === null || row.index === index) {
                    row.size = Math.clamp(rowHeight, MIN_HEADER_ROW_HEIGHT, MAX_HEADER_ROW_HEIGHT);
                }
            }

            for (let row of numericRows) {
                row.size = this.MIN_ROW_HEIGHT;
                tableHeight += this.MIN_ROW_HEIGHT * 2;
            }

            for (let row of textRows) {
                let rowHeight = 0;
                for (let cell of this.getCellsInRow(row.index).filter(cell => !cell.model.isHidden)) {
                    let cellBounds;
                    // merged cell
                    if (cell.width > 1) {
                        const getColumnsSize = (startCol, numOfCols) => [...Array(numOfCols).keys()].reduce((sum, i) => sum + this.getColumn(startCol + i).size, 0);
                        cellBounds = this.calcOptimalCellSize(cell, getColumnsSize(cell.model.col, cell.model.width) * this.calculatedProps.baseColWidth);
                    } else {
                        cellBounds = this.calcOptimalCellSize(cell, this.getColumn(cell.col).size * this.calculatedProps.baseColWidth);
                    }

                    rowHeight = Math.max(rowHeight, cellBounds.height);

                    // if (cell.contents && cell.contents instanceof TableCellText) {
                    //     let cellContentProps = cell.contents.calcProps(new geom.Size(cell.colWidth, MAX_HEADER_ROW_HEIGHT), { styles: styles.TableCell.cellText });
                    //     rowHeight = Math.max(rowHeight, cellContentProps.textBounds.height + cellContentProps.styles.paddingTop + cellContentProps.styles.paddingBottom);
                    // } else if (cell.contents && cell.contents instanceof TableCellIcon && cell.contents.model.content_value != AssetType.ICON) {
                    //     rowHeight = Math.max(rowHeight, 50);
                    // }
                }
                // row.size = Math.max(this.MIN_ROW_HEIGHT, rowHeight);
                tableHeight += Math.max(this.MIN_ROW_HEIGHT, rowHeight) * 2;

                if (index === null || row.index === index) {
                    row.size = Math.max(this.MIN_ROW_HEIGHT, rowHeight);
                }
            }

            for (let row of imageRows) {
                if (index === null || row.index === index) {
                    row.size = 60;
                }
                tableHeight += 60;
            }

            this.model.tableHeight = Math.min(1, tableHeight / this.calculatedProps.allowedSize.height);
        }

        return this.canvas.updateCanvasModel(false);
    }

    setCellFormat(cell, cellText) {
        const format = formatter.detectFormatFromString(cellText);

        switch (cellText) {
            case "✓":
            case "✔":
            case "✅":
            case "☑":
                cell.format = FormatType.ICON;
                cell.content_type = AssetType.ICON;
                cell.content_value = "check-yes";
                break;
            case "☒":
            case "✗":
            case "✘":
            case "x":
                cell.format = FormatType.ICON;
                cell.content_type = AssetType.ICON;
                cell.content_value = "x";
                break;
            default:
                cell.cellText = { text: cellText };
                cell.format = format.format;
        }

        return format;
    }

    replaceEntireTable(tableData, initialImport = false, colCount, rowCount) {
        this.model.cols = [...new Array(colCount).keys()].map(index => ({
            index,
            style: "defaultCol",
            break: false,
            ...(this.model.cols[index ?? {}])
        }));
        this.model.cols = this.model.cols.slice(0, colCount);

        this.model.rows = [...new Array(rowCount).keys()].map(index => ({
            index,
            style: "defaultRow",
            break: false,
            ...(this.model.rows[index ?? {}])
        }));
        this.model.rows = this.model.rows.slice(0, rowCount);

        const currCells = [...this.model.cells];
        this.model.cells = [];

        for (const colModel of this.model.cols) {
            const colIndex = colModel.index;
            for (const rowModel of this.model.rows) {
                const rowIndex = rowModel.index;

                const cell = { row: rowIndex, col: colIndex, id: `${rowIndex}:${colIndex}` };
                const currCell = currCells.find(c => c.row === rowIndex && c.col === colIndex) || {};

                let cellText = tableData[rowIndex][colIndex];
                cellText = String(cellText).replace(/\n/g, String.fromCharCode(13));

                const format = this.setCellFormat(currCell, cellText);

                if (!initialImport) {
                    if (currCell.format) cell.format = currCell.format;
                    cell.formatOptions = { ...format.formatOptions, ...currCell.formatOptions };
                }

                this.model.cells.push({ ...currCell, ...cell });
            }
        }
    }

    replaceSimpleTextInSelectedCellsInTable(tableData, selectedCells) {
        // Simple text was copied
        selectedCells.forEach(({ model }) => {
            let cellText = tableData[0][0];
            cellText = cellText.replace(/\n/g, String.fromCharCode(13));

            const currentCellIndex = this.model.cells.findIndex(cell => cell.row === model.row && cell.col === model.col);
            const currCell = this.model.cells[currentCellIndex];

            const format = this.setCellFormat(currCell, cellText);

            currCell.formatOptions = { ...format.formatOptions, ...currCell.formatOptions };
        });
    }

    updateTableData(tableData, initialImport = false, selectedCells = [], skipSaving = false) {
        if (tableData && tableData.length && tableData[0].length) {
            const rowCount = tableData.length;
            const colCount = tableData[0].length;

            // We are updating only the selected cells with copied to clipboard simple text
            if (tableData.length === 1 && tableData[0].length === 1 && selectedCells.length > 0) {
                this.replaceSimpleTextInSelectedCellsInTable(tableData, selectedCells);
            } else {
                this.replaceEntireTable(tableData, initialImport, colCount, rowCount);
            }

            if (skipSaving) return this.model;

            const allHaveSizes = !(this.model.cols.some(c => !c.size) || this.model.rows.some(r => !r.size));
            if (allHaveSizes) {
                return this.canvas.updateCanvasModel(false);
            } else {
                return this.canvas.refreshCanvas(false).then(
                    () => this.calcAutoFit("both")
                );
            }
        }
    }

    transpose() {
        const transposedContent = this.model.cells.reduce((acc, cell) => {
            if (!acc[cell.col]) acc[cell.col] = [];
            acc[cell.col][cell.row] = cell.content_type ? {
                content_type: cell.content_type,
                content_value: cell.content_value,
                format: FormatType.ICON,
            } : cell.cellText ? {
                cellText: cell.cellText,
                fontSize: cell.fontSize,
                format: cell.format,
                formatOptions: cell.formatOptions
            } : {
                format: FormatType.TEXT,
                formatOptions: cell.formatOptions
            };
            return acc;
        }, []);

        this.model.cells = this.model.cells.map(cell => {
            const transposedCell = transposedContent[cell.col][cell.row];
            return {
                ...cell, row: cell.col, col: cell.row,
                ...transposedCell,
                cellStyle: cell.cellStyle || transposedCell.cellStyle || "none",
                cellColor: cell.cellColor || transposedCell.cellColor || "none"
            };
        }).map(c => c.cellStyle !== "content" && c.format === FormatType.ICON ? { ...c, cellStyle: "content" } : c);

        let newRowLength = this.model.cols.length;
        let newColLength = this.model.rows.length;

        this.model.rows.splice(newRowLength);
        if (this.model.rows.length < newRowLength) {
            this.model.rows.push(...[...new Array(newRowLength - this.model.rows.length).keys()].map(index => ({
                index: this.model.rows.length + index,
                style: "defaultRow",
                break: false
            })));
        }

        this.model.cols.splice(newColLength);
        if (this.model.cols.length < newColLength) {
            this.model.cols.push(...[...new Array(newColLength - this.model.cols.length).keys()].map(index => ({
                index: this.model.cols.length + index,
                style: "defaultCol",
                break: false
            })));
        }
    }

    renderChildren(transition) {
        const children = super.renderChildren(transition);

        const cells = this.cells.map(cell => {
            if (!this.model.showTopLeftCell && cell.row === 0 && cell.col === 0) {
                return null;
            }
            if (cell.model.isHidden) {
                return null;
            }
            if (cell.model.format === FormatType.ICON) {
                return null;
            }

            return (
                <TableCellComponent
                    key={cell.model.id}
                    cell={cell}
                    {...this.calcCellProps(cell)}
                />
            );
        });

        return [children[0], ...cells, ...children.slice(1)].filter(Boolean);
    }
}

class TableBackgrounds extends SVGElement {
    renderSVG(props) {
        const tableElement = this.parentElement;
        const styles = tableElement.styles;

        let cellBackgrounds = [];

        let colGroups = [{ start: 0, end: 0 }];
        for (let colIndex = 0; colIndex < tableElement.model.cols.length; colIndex++) {
            let colModel = _.find(tableElement.model.cols, { index: colIndex });

            let colStyle = tableElement.getColumnStyle(colIndex);

            if (colStyle == "cleanCol") {
                if (_.last(colGroups).start == colIndex) {
                    _.last(colGroups).isCleanCol = true;
                } else {
                    colGroups.push({ start: colIndex, end: colIndex, isCleanCol: true });
                }
                if (colIndex < tableElement.model.cols.length - 1) {
                    colGroups.push({ start: colIndex + 1, end: colIndex + 1 });
                }
                continue;
            }

            if (colStyle == "emphasizedCol") {
                if (_.last(colGroups).start == colIndex) {
                    _.last(colGroups).isEmphasizedCol = true;
                } else {
                    colGroups.push({ start: colIndex, end: colIndex, isEmphasizedCol: true });
                }
                if (colIndex < tableElement.model.cols.length - 1) {
                    colGroups.push({ start: colIndex + 1, end: colIndex + 1 });
                }
                continue;
            }

            _.last(colGroups).end = colIndex;

            if (colModel.break && colIndex < tableElement.model.cols.length - 1) {
                colGroups.push({ start: colIndex + 1, end: colIndex + 1 });
            }
        }

        for (let colGroup of colGroups) {
            colGroup.left = tableElement.getCell(colGroup.start, 0).bounds.left;
            colGroup.right = tableElement.getCell(colGroup.end, 0).bounds.right;
        }

        let rowGroups = [{ start: 0, end: 0 }];
        for (let rowIndex = 0; rowIndex < tableElement.model.rows.length; rowIndex++) {
            let rowModel = _.find(tableElement.model.rows, { index: rowIndex });
            let rowStyle = tableElement.getRowStyle(rowIndex);

            if (rowStyle == "cleanRow") {
                if (_.last(rowGroups).start == rowIndex) {
                    _.last(rowGroups).isCleanRow = true;
                } else {
                    rowGroups.push({ start: rowIndex, end: rowIndex, isCleanRow: true });
                }
                if (rowIndex < tableElement.model.rows.length - 1) {
                    rowGroups.push({ start: rowIndex + 1, end: rowIndex + 1 });
                }
                continue;
            }

            if (rowStyle == "headerRow") {
                if (_.last(rowGroups).start == rowIndex) {
                    _.last(rowGroups).isHeaderRow = true;
                } else {
                    rowGroups.push({ start: rowIndex, end: rowIndex, isHeaderRow: true });
                }
                if (rowIndex < tableElement.model.rows.length - 1) {
                    rowGroups.push({ start: rowIndex + 1, end: rowIndex + 1 });
                }
                continue;
            }

            _.last(rowGroups).end = rowIndex;
            if (rowModel.break) {
                rowGroups.push({ start: rowIndex + 1, end: rowIndex + 1 });
            }
        }

        for (let rowGroup of rowGroups) {
            // Fix for BA-3348 - get a cell from the first non-emphasized (because it's taller) column to calculate rowGroup top/bottom
            let col = _.findIndex(tableElement.model.cols, col => {
                return col.style != "emphasizedCol";
            }) || 0;

            rowGroup.top = tableElement.getCell(col, rowGroup.start).bounds.top;
            rowGroup.bottom = tableElement.getCell(col, rowGroup.end).bounds.bottom;
        }

        let slideColor = tableElement.getSlideColor();
        let backgroundColor = tableElement.getBackgroundColor();

        let a1cell = tableElement.getCell(0, 0);

        // draw rects
        for (let colGroup of colGroups) {
            for (let rowGroup of rowGroups) {
                let topLeftCell = tableElement.getCell(colGroup.start, rowGroup.start);
                let bottomRightCell = tableElement.getCell(colGroup.end, rowGroup.end);

                let skipColStyles = colGroup.isCleanCol;
                let skipRowStyles = rowGroup.isCleanRow;

                let backgroundColor = this.canvas.getTheme().palette.getColor(this.model.tableBackgroundColor || "white");

                // style rows
                for (let rowIndex = rowGroup.start; rowIndex <= rowGroup.end; rowIndex++) {
                    if (skipRowStyles) continue;

                    let rowCell = tableElement.getCell(colGroup.start, rowIndex);

                    let rowStyle = tableElement.getRowStyle(rowIndex);

                    let rowStyleProps = { ...styles.rowStyles[rowStyle] };

                    rowStyleProps.fillColor = rowStyleProps.fillColor || this.model.tableBackgroundColor || "white";
                    if (!rowStyleProps.resolved_fillColor) {
                        rowStyleProps.resolved_fillColor = this.canvas.getTheme().palette.getColor(rowStyleProps.fillColor);
                    }

                    if (rowStyleProps.fillOpacity && rowStyleProps.fillOpacity != 1) {
                        // if the fill is transparent, blend with the table background
                        rowStyleProps.resolved_fillColor = blendColors(rowStyleProps.resolved_fillColor.setAlpha(rowStyleProps.fillOpacity), backgroundColor);
                        rowStyleProps.fillOpacity = 1; // remove opacity because we've pre-blended
                    }

                    let left = colGroup.left;
                    let right = colGroup.right;

                    if (rowIndex == 0 && colGroup.start == 0 && tableElement.model.showTopLeftCell == false) {
                        left += a1cell.bounds.width;
                        if (tableElement.getColumn(0).break) {
                            left += styles.colBreak;
                        }
                    }

                    if (rowStyleProps.fillColor && right >= left && !skipColStyles) {
                        let rowFill = {
                            color: rowStyleProps.resolved_fillColor.toRgbString(),
                            opacity: rowStyleProps.fillOpacity || 1
                        };

                        if (tableElement.model.alternateRows && rowStyle == "defaultRow" && rowIndex % 2 == 0) {
                            let rowFillColor = rowStyleProps.resolved_fillColor;
                            if (rowFillColor.isDark()) {
                                rowFill.color = blendColors(tinycolor("rgba(255,255,255,.06"), rowFillColor).toRgbString();
                            } else {
                                rowFill.color = blendColors(tinycolor("rgba(0,0,0,.06"), rowFillColor).toRgbString();
                            }
                        }

                        cellBackgrounds.push(<rect key={`cellBackground${cellBackgrounds.length}`} x={left}
                            y={rowCell.bounds.top} width={right - left + 1}
                            height={rowCell.bounds.height + 1} fill={rowFill.color}
                            fillOpacity={rowFill.opacity} />);
                    }
                }
                // style columns
                for (let colIndex = colGroup.start; colIndex <= colGroup.end; colIndex++) {
                    if (skipColStyles) continue;

                    let colCell = tableElement.getCell(colIndex, rowGroup.start);

                    let colStyle = tableElement.getColumnStyle(colIndex);

                    let colStyleProps = styles.columnStyles[colStyle];

                    let top = colCell.bounds.top;
                    let bottom = tableElement.getCell(colIndex, rowGroup.end).bounds.bottom;

                    if (colIndex == 0 && rowGroup.start == 0 && tableElement.model.showTopLeftCell == false) {
                        top += a1cell.bounds.height;
                        if (tableElement.getRow(0).break) {
                            top += styles.rowBreak;
                        }
                    }

                    if (colStyleProps && colStyleProps.fillColor && bottom >= top && !skipRowStyles) {
                        let colBounds = new geom.Rect(colCell.bounds.left, top, colCell.bounds.width, bottom - top);
                        let colFill = {
                            color: this.canvas.getTheme().palette.getForeColor(colStyleProps.fillColor, slideColor, backgroundColor).toRgbString(),
                            opacity: colStyleProps.fillOpacity || 1
                        };
                        cellBackgrounds.push(<rect key={`cellBackground${cellBackgrounds.length}`} x={colBounds.left}
                            y={colBounds.top} width={colBounds.width + 1}
                            height={colBounds.height + 1} fill={colFill.color}
                            fillOpacity={colFill.opacity} />);
                    }
                }
            }
        }

        return (
            <SVGGroup ref={this.ref} key={this.id}>
                <svg style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    overflow: "visible"
                }}>
                    {cellBackgrounds}
                </svg>
            </SVGGroup>
        );
    }
}

class TableGridLines extends SVGElement {
    renderSVG(props) {
        const tableElement = this.parentElement;
        let styles = tableElement.styles;

        let gridLines = [];

        let borderColor = this.canvas.getTheme().palette.getForeColor(styles.border.strokeColor, tableElement.getSlideColor(), tableElement.getBackgroundColor());
        let colGridColor = this.canvas.getTheme().palette.getForeColor(styles.colGridLine.strokeColor, tableElement.getSlideColor(), tableElement.getBackgroundColor());
        let rowGridColor = this.canvas.getTheme().palette.getForeColor(styles.rowGridLine.strokeColor, tableElement.getSlideColor(), tableElement.getBackgroundColor());

        let borderStroke = {
            color: borderColor.setAlpha(1).toRgbString(),
            width: styles.border.strokeWidth,
            opacity: tableElement.model.showBorder ? styles.border.strokeOpacity * borderColor.getAlpha() : 0
        };
        let colGridLineStroke = {
            color: colGridColor.setAlpha(1).toRgbString(),
            opacity: styles.colGridLine.strokeOpacity * colGridColor.getAlpha(),
            width: styles.colGridLine.strokeWidth
        };
        let rowGridLineStroke = {
            color: rowGridColor.setAlpha(1).toRgbString(),
            opacity: styles.rowGridLine.strokeOpacity * rowGridColor.getAlpha(),
            width: styles.rowGridLine.strokeWidth
        };

        for (let cell of tableElement.cells) {
            if (cell.model.isHidden) continue;
            if (cell.row == 0 && cell.col == 0 && tableElement.model.showTopLeftCell == false) continue;

            let col = tableElement.getColumn(cell.col + (cell.width || 1) - 1);
            let row = tableElement.getRow(cell.row + (cell.height || 1) - 1);

            let colStyle = tableElement.getColumnStyle(cell.col);
            let rowStyle = tableElement.getRowStyle(cell.row);

            let prevCol, prevRow;

            if (cell.col > 0) {
                prevCol = tableElement.getColumn(cell.col - 1);
            }
            if (cell.row > 0) {
                prevRow = tableElement.getRow(cell.row - 1);
            }

            if (colStyle == "cleanCol" || rowStyle == "cleanRow") continue;

            if (colStyle != "cleanCol") {
                let colStroke;
                if (cell.col == 0 || prevCol.break || tableElement.getColumnStyle(cell.col - 1) == "cleanCol" || (cell.col == 1 && cell.row == 0 && !tableElement.model.showTopLeftCell)) {
                    colStroke = borderStroke;
                } else {
                    if (tableElement.model.showColGridLines) {
                        colStroke = colGridLineStroke;
                    }
                }

                if (colStroke) {
                    gridLines.push(<line key={`gridLine${gridLines.length}`}
                        x1={cell.bounds.left}
                        y1={cell.bounds.top}
                        x2={cell.bounds.left}
                        y2={cell.bounds.bottom}
                        stroke={colStroke.color}
                        strokeOpacity={colStroke.opacity}
                        strokeWidth={colStroke.width}
                    />);
                }
                if (col.index == tableElement.totalCols - 1 || col.break || tableElement.getColumnStyle(col.index + 1) == "cleanCol") {
                    gridLines.push(<line key={`gridLine${gridLines.length}`}
                        x1={cell.bounds.right}
                        y1={cell.bounds.top}
                        x2={cell.bounds.right}
                        y2={cell.bounds.bottom}
                        stroke={borderStroke.color}
                        strokeOpacity={borderStroke.opacity}
                        strokeWidth={borderStroke.width}
                    />);
                }
            }

            if (rowStyle != "cleanRow") {
                let rowStroke;
                if (cell.row == 0 || prevRow.break || tableElement.getRowStyle(cell.row - 1) == "cleanRow" || (cell.col == 0 && cell.row == 1 && !tableElement.model.showTopLeftCell)) {
                    rowStroke = borderStroke;
                } else {
                    if (tableElement.model.showRowGridLines) {
                        rowStroke = rowGridLineStroke;
                    }
                }

                if (rowStroke) {
                    gridLines.push(<line key={`gridLine${gridLines.length}`}
                        x1={cell.bounds.left}
                        y1={cell.bounds.top}
                        x2={cell.bounds.right}
                        y2={cell.bounds.top}
                        stroke={rowStroke.color}
                        strokeOpacity={rowStroke.opacity}
                        strokeWidth={rowStroke.width}
                    />);
                }

                if (row.index == tableElement.totalRows - 1 || row.break || tableElement.getRowStyle(row.index + 1) == "cleanRow") {
                    gridLines.push(<line key={`gridLine${gridLines.length}`}
                        x1={cell.bounds.left}
                        y1={cell.bounds.bottom}
                        x2={cell.bounds.right}
                        y2={cell.bounds.bottom}
                        stroke={borderStroke.color}
                        strokeOpacity={borderStroke.opacity}
                        strokeWidth={borderStroke.width}
                    />);
                }
            }
        }

        return (
            <SVGGroup ref={this.ref} key={this.id}>
                <svg style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    overflow: "visible"
                }}>
                    {gridLines}
                </svg>
            </SVGGroup>
        );
    }
}

class TableCell {
    get row() {
        return this.model.row;
    }

    get col() {
        return this.model.col;
    }

    get width() {
        return this.model.width;
    }

    get height() {
        return this.model.height;
    }

    get flagDecorationSize() {
        return 20;
    }

    get format() {
        return this.model.format;
    }

    get isHidden() {
        return this.model.isHidden;
    }

    get _formatOptions() {
        return this.model.formatOptions;
    }

    get cellColor() {
        return this.model.cellColor;
    }

    get cellStyle() {
        return this.model.cellStyle;
    }

    get fontSize() {
        return this.styles.fontSize;
    }

    get decoration() {
        return this.styles.decoration;
    }

    get bold() {
        return this.styles.fontWeight == 600;
    }

    get italic() {
        return this.styles.fontStyle == "italic";
    }

    get strikeThrough() {
        return this.styles.textDecoration == "line-through";
    }

    get value() {
        if (!this.model.cellText?.text) {
            return "";
        }

        if (this.model.cellText.text.startsWith("=")) {
            // this method will return a number, which will be converted to a string
            // in order to be displayed in the TableCellComponent component
            return this.parseFormula(this.model.cellText.text).toString();
        } else {
            return this.model.cellText.text;
        }
    }

    parseFormula(formula) {
        formula = formula.substring(1);

        let parser = new Parser();
        parser.on("callCellValue", (cellCoord, done) => {
            if (cellCoord.column.index == this.col && cellCoord.row.index == this.row) {
                throw new Error("Circular formula");
            }

            let cell = this.table.getCell(cellCoord.column.index, cellCoord.row.index);
            if (cell && cell.value) {
                done(parseFloat(cell.value));
            }
        });
        parser.on("callRangeValue", (startCellCoord, endCellCoord, done) => {
            let value = 0;
            for (let row = startCellCoord.row.index; row <= endCellCoord.row.index; row++) {
                for (let col = startCellCoord.column.index; col <= endCellCoord.column.index; col++) {
                    if (col == this.col && row == this.row) {
                        throw new Error("Circular formula");
                    }
                    let cell = this.table.getCell(col, row);
                    if (cell && cell.value) {
                        value += parseFloat(cell.value);
                    }
                }
            }
            done(value);
        });

        let results = parser.parse(formula);
        if (results.error) {
            return results.error;
        } else {
            return results.result;
        }
    }

    constructor(model, table) {
        this.model = model;
        this.table = table;

        let colStyle = table.getColumnStyle(this.model.col);
        let rowStyle = table.getRowStyle(this.model.row);

        // if no cell style is set, check the column or row style if the cell is
        // a header cell and set the cell style to fill
        if (this.model.cellStyle === undefined &&
            !this.model.isHidden &&
            ((colStyle === "headerCol" && this.model.row === 0) || rowStyle === "headerRow"
            )) {
            this.model.cellStyle = "fill";
        }
        this.styles = {};
    }

    toString() {
        return JSON.stringify(this.model);
    }
}

const TableCellDiv = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
`;

class TableCellComponent extends Component {
    render() {
        const {
            text,
            cellStyles,
            decoration,
            flagDecoration,
            wrappers,
            bounds,
        } = this.props;
        const lines = text.split("\r");
        const textList = lines.length > 1 ? lines.map((item, i) => (<div key={i}>{item}</div>)) : <div>{lines[0]}</div>;

        let content = (
            <>
                {decoration}
                {flagDecoration}
                {textList}
            </>
        );
        wrappers.forEach(wrapper => content = wrapper(content));

        return (
            <TableCellDiv style={{
                ...bounds.toObject(),
                ...cellStyles,
            }}>{content}</TableCellDiv>
        );
    }
}

class TableCellIcon extends ContentElement {
    get canSelect() {
        return false;
    }

    get canRollover() {
        return false;
    }

    get col() {
        return this.model.col;
    }

    get row() {
        return this.model.row;
    }

    // override this flag to always be false because the iconElement special-cases icon colors on authoring canvas and we dont
    // want that to happen for icons in a table
    get isOnAuthoringCanvas() {
        return false;
    }

    _loadStyles(styles) {
        if (this.model.content_type == AssetType.LOGO) {
            styles.paddingLeft = styles.paddingRight = 20;
            styles.paddingTop = styles.paddingBottom = 10;
            styles.decoration = {
                type: "frame",
                shape: "rect",
                fillColor: "white"
            };
        } else {
            let cellStyle = this.model.emphasized ? "stroke" : this.model.cellStyle;

            switch (cellStyle) {
                case "fill":
                    styles.decoration = {
                        type: "frame",
                        shape: "rect",
                        fillColor: this.model.cellColor
                    };
                    break;
                case "stroke":
                    styles.decoration = {
                        type: "frame",
                        shape: "rect",
                        strokeColor: this.model.cellColor,
                        strokeWidth: 2,
                        fillColor: "transparent"

                    };
                    break;
                case "text":
                    styles.asset = {
                        fillColor: this.canvas.getTheme().palette.getForeColor(this.model.cellColor).toRgbString()
                    };
                    styles.decoration = {
                        type: "frame",
                        shape: "rect",
                        fillColor: "transparent"
                    };
                    break;
                case "flag":
                    styles.decoration = {
                        type: "svg",
                        def: `<path d="M#{100w - 20},0 L#{100w},0 L#{100w},20 z" fill="${this.canvas.getTheme().palette.getForeColor(this.model.cellColor).toRgbString()}"/>`,
                        fillColor: "transparent"
                    };
                    break;
                case "none":
                default:
                    styles.decoration = {
                        type: "frame",
                        shape: "rect",
                        fillColor: "transparent"
                    };
                    break;
            }
        }
    }

    _build() {
        super._build();
        this.assetElement.options.canRollover = false;
        if (this.model.content_type == AssetType.ICON) {
            this.assetElement.options.doubleClickToSelect = false;
        }
    }
}

export { Table };

export const elements = {
    TableFrame,
};
