import React from "reactn";
import * as geom from "js/core/utilities/geom";
import { AnchorType } from "js/core/utilities/geom";
import { _ } from "legacy-js/vendor";
import { SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import { PositionType, AuthoringBlockType, TextStyleType } from "legacy-common/constants";
import { Path, Shape } from "js/core/utilities/shapes";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { SVGPathElement, SVGElement } from "../base/SVGElement";
import { BaseElement } from "../base/BaseElement";
import { AnnotationLayer } from "./AnnotationLayer";

class TargetAnnotations extends AnnotationLayer {
}

class Target extends CollectionElement {
    static get schema() {
        return {
            position: PositionType.CENTER,
            innerRadius: 0,
            outerRadius: 100
        };
    }

    getChildItemType() {
        return TargetBand;
    }

    get maxItemCount() {
        return 6;
    }

    get defaultItemData() {
        return {
            segments: [{
                color: "auto"
            }]
        };
    }

    get segmentGap() {
        return 0;
    }

    get minArcWidth() {
        return 50;
    }

    get innerRadius() {
        return this.model.innerRadius || 0;
    }

    get outerRadius() {
        return this.model.outerRadius || 100;
    }

    get position() {
        return this.model.position || PositionType.CENTER;
    }

    deleteItem(itemId) {
        let band = this.getChild(itemId);
        if (band) {
            let node = this.annotations.itemElements.find(node => node.connectorsFromNode.length > 0 && node.connectorsFromNode[0].model.target && node.connectorsFromNode[0].model.target.contains(band.uniquePath));
            if (node) {
                this.annotations.deleteItem(node.id);
            }
        }
        super.deleteItem(itemId);
    }

    _migrate_8() {
        super._migrate_8();

        this.model.annotations = { items: [], connections: { items: [] } };

        const annotationsModel = this.canvas.model.elements.annotations;
        if (!annotationsModel || !annotationsModel.items) {
            return;
        }

        const targetConnectors = annotationsModel.connections.items.filter(connector => connector.target && connector.target.includes(this.id));
        if (targetConnectors.length === 0) {
            return;
        }

        for (const connectorModel of targetConnectors) {
            this.model.annotations.connections.items.push(connectorModel);
            _.remove(annotationsModel.connections.items, connector => connector.id === connectorModel.id);

            const sourceNode = annotationsModel.items.find(item => item.id === connectorModel.source);
            if (sourceNode) {
                // We used to have callouts in the annotations layer which has bigger bounds,
                // so we're making a rough estimation on where they should be within
                // the target annotations
                sourceNode.x = Math.clamp((sourceNode.x - 0.1) / 0.8, 0, 1);
                sourceNode.y = Math.clamp((sourceNode.y - 0.15) / 0.8, 0, 1);
                this.model.annotations.items.push(sourceNode);
                _.remove(annotationsModel.items, node => node.id === sourceNode.id);

                // If the node has other connectors, move them as well
                const connectorsFromNode = annotationsModel.connections.items.filter(connector => connector.source.includes(sourceNode.id));
                for (const connectorFromNode of connectorsFromNode) {
                    this.model.annotations.connections.items.push(connectorFromNode);
                    _.remove(annotationsModel.connections.items, connector => connector.id === connectorFromNode.id);
                }
            }
        }
    }

    _build() {
        if (!this.model.annotations) {
            this.model.annotations = { items: [], connections: { items: [] } };
        }

        super._build();

        this.annotations = this.addElement("annotations", () => TargetAnnotations, { model: this.model.annotations });
        // Explicitly setting annotations layer because Target is a collection element
        // and newly added items will be on top of the annotations element if we don't to that
        this.annotations.layer = 999;
    }

    _calcProps(props, options) {
        let { size } = props;

        let center;

        switch (this.position) {
            case PositionType.LEFT:
                center = new geom.Point(size.height / 2, size.height / 2);
                break;
            case PositionType.RIGHT:
                center = new geom.Point(size.width - size.height / 2, size.height / 2);
                break;
            case PositionType.CENTER:
            default:
                center = new geom.Point(size.width / 2, size.height / 2);
        }

        let outerRadius = Math.min(size.width, size.height) / 2 * (this.outerRadius / 100);
        let maxInnerRadius = outerRadius - this.minArcWidth * this.itemElements.length;

        this.maxInnerRadius = maxInnerRadius;

        let innerRadius = 0;
        if (maxInnerRadius > 0) {
            innerRadius = maxInnerRadius * this.innerRadius / 100;
        }
        let radius = innerRadius;

        let totalBandWidth = outerRadius - innerRadius;
        let bandWidth = (totalBandWidth - (this.itemElements.length - 1) * this.segmentGap) * 1 / this.itemElements.length;

        for (let item of this.itemElements) {
            let innerRadius = radius;
            let outerRadius = innerRadius + bandWidth;

            let itemProps = item.calcProps(size, { innerRadius, outerRadius, center });
            // itemProps.bounds = new geom.Rect(size.width / 2 - outerRadius, size.height / 2 - outerRadius, itemProps.size);
            itemProps.bounds = new geom.Rect(center.x - outerRadius, center.y - outerRadius, itemProps.size);

            radius = outerRadius + this.segmentGap;
        }

        this.annotations.calcProps(size);

        return { size };
    }

    getAnimations() {
        return super.getAnimations().sort((a, b) => {
            if (a.element.type === b.element.type) return 0;
            if (a.element instanceof TargetBand) return -1;
            if (b.element instanceof TargetBand) return 1;
            return 0;
        });
    }

    _exportToSharedModel() {
        const textContent = this.itemElements.map(item => item.segments.map(seg => seg.model.label).filter(Boolean)).flat()
            .map(label => ({ mainText: { text: label }, secondaryTexts: [] }));

        return { textContent };
    }

    _importFromSharedModel(model) {
        const { textContent } = model;
        if (!textContent?.length) return this.canvas.slideTemplate.defaultData.primary;

        const items = textContent.map(t => ({
            segments: [{
                color: "auto",
                labelType: "inside",
                label: t.mainText.text
            }]
        }));

        return { items };
    }
}

class TargetBand extends CollectionItemElement {
    static get schema() {
        return {
            segments: [{ color: "auto" }]
        };
    }

    get canSelect() {
        return true;
    }

    _build() {
        this.segments = [];

        let startAngle = 0;
        let arcLength = 360 / this.model.segments.length;
        for (let i = 0; i < this.model.segments.length; i++) {
            this.segments.push(this.addElement("segment" + i, () => TargetBandSegment, {
                startAngle, arcLength,
                model: this.model.segments[i]
            }));
            startAngle += arcLength;
        }
    }

    _calcProps(props, options) {
        let { size, children } = props;

        let outerRadius = options.outerRadius;
        let innerRadius = options.innerRadius;

        size = new geom.Size(outerRadius * 2, outerRadius * 2);

        for (let segment of this.segments) {
            segment.calcProps(size, options);
        }

        this.outerRadius = outerRadius;
        this.innerRadius = innerRadius;

        return { size };
    }

    containsPoint(pt) {
        let center = this.selectionBounds.getPoint(AnchorType.CENTER);
        let distance = center.distance(pt);
        return (distance >= this.innerRadius && distance <= this.outerRadius);
    }

    get animationElementName() {
        return `Band #${this.itemIndex + 1}`;
    }
}

class TargetBandSegment extends BaseElement {
    get canSelect() {
        return true;
    }

    get selectionPadding() {
        return 0;
    }

    get shouldTransitionWhenNew() {
        return false;
    }

    getSelectionPath(scale) {
        let center = new geom.Point(this.bounds.width / 2, this.bounds.height / 2).multiply(scale);

        if (this.innerRadius > 0 && this.arcLength < 360) {
            return Shape.drawArc2(this.outerRadius * scale, this.innerRadius * scale, this.startAngle, this.endAngle, center);
        } else {
            return Shape.drawCircle(this.outerRadius * scale, center);
        }
    }

    get labelType() {
        return this.model.labelType || "none";
    }

    get anchorBounds() {
        return this.parentElement.bounds;
    }

    getAnchorPoint(connector, anchor, connectorPoint, connectorType) {
        let anchorPointAngle;
        if (this.arcLength <= 180) {
            anchorPointAngle = this.startAngle + this.arcLength / 2;
        } else {
            anchorPointAngle = this.parentElement.bounds.position.offset(this.centerPoint).angleToPoint(connectorPoint);
        }

        let anchorPoint = geom.Point.PointFromAngle(this.innerRadius + (this.outerRadius - this.innerRadius) / 2, anchorPointAngle, this.centerPoint);
        return this.parentElement.bounds.position.offset(anchorPoint);
    }

    get availableAnchorPoints() {
        return [AnchorType.FREE];
    }

    get startAngle() {
        return this.options.startAngle - 90;
    }

    get arcLength() {
        return this.options.arcLength;
    }

    get endAngle() {
        return this.startAngle + this.arcLength;
    }

    get centerPoint() {
        return new geom.Point(this.outerRadius, this.outerRadius);
    }

    _build() {
        this.path = this.addElement("path", () => SVGPathElement);
        this.path.layer = -1;

        if (this.labelType == "inside") {
            this.label = this.addElement("label", () => TargetBandSegmentInsideLabel);
        }
    }

    _calcProps(props, options) {
        let { size, children } = props;

        let outerRadius = options.outerRadius;
        let innerRadius = options.innerRadius;
        let startAngle = this.startAngle;
        let arcLength = this.arcLength;
        let endAngle = this.startAngle + this.arcLength;

        let center = new geom.Point(outerRadius, outerRadius);

        let path = new Path();

        if (arcLength < 360) {
            path = Shape.drawArc2(outerRadius, innerRadius, startAngle, endAngle, center);
        } else {
            let outerArcStartPoint = geom.Point.PointFromAngle(outerRadius, 0, center);
            path.moveTo(outerArcStartPoint);
            path.arc(outerRadius, outerRadius, 0, 1, 1, outerArcStartPoint.x - outerRadius * 2, outerArcStartPoint.y);
            path.arc(outerRadius, outerRadius, 0, 1, 1, outerArcStartPoint.x, outerArcStartPoint.y);
            if (innerRadius > 0) {
                let innerArcStartPoint = geom.Point.PointFromAngle(innerRadius, 0, center);
                path.moveTo(innerArcStartPoint);
                path.arc(innerRadius, innerRadius, 0, 1, 0, innerArcStartPoint.x - innerRadius * 2, innerArcStartPoint.y);
                path.arc(innerRadius, innerRadius, 0, 1, 0, innerArcStartPoint.x, innerArcStartPoint.y);
            }
        }

        let pathProps = this.path.createProps({
            path: path.toPathData()
        });

        this.outerRadius = outerRadius;
        this.innerRadius = innerRadius;

        if (this.labelType == "inside") {
            this.label.calcProps(size, {
                innerRadius,
                outerRadius,
                segmentsCount: this.parentElement.segments.length,
                arcLength,
                startAngle,
                endAngle
            });
        }

        return { size: new geom.Size(outerRadius * 2, outerRadius * 2) };
    }

    containsPoint(pt) {
        let center = this.selectionBounds.getPoint(AnchorType.CENTER);

        let distance = center.distance(pt);
        let angle = (center.angleToPoint(pt) + 90) % 360;

        return (distance >= this.innerRadius && distance <= this.outerRadius && angle >= this.startAngle + 90 && angle <= this.endAngle + 90);
    }
}

class TargetBandSegmentInsideLabel extends SVGElement {
    get exportAsImage() {
        return true;
    }

    _calcProps(props, options) {
        const { size } = props;
        return { size };
    }

    renderSVG(props, svgStyles, transition) {
        const { options: { innerRadius, outerRadius, segmentsCount, arcLength, startAngle, endAngle } } = props;

        const textProps = {
            fontFamily: this.styles.fontId,
            fontWeight: this.styles.fontWeight,
            fontSize: this.styles.fontSize,
            textAnchor: "middle",
            fill: this.canvas.getTheme().palette.getForeColor("primary", null, this.getSlideColor()).toRgbString()
        };

        const tspan = <tspan dy={6}>{this.model.label || "Double-click to edit"}</tspan>;

        if (innerRadius > 0 || segmentsCount > 1) {
            const center = new geom.Point(outerRadius, outerRadius);

            const textRadius = outerRadius - Math.min(50, (outerRadius - innerRadius) / 2);
            const textArc = Math.min(180, arcLength);
            let textStartAngle = startAngle;
            if (arcLength > 180) {
                textStartAngle = -180;
            }

            const textArcStartPoint = geom.Point.PointFromAngle(textRadius, textStartAngle, center);
            const textArcEndPoint = geom.Point.PointFromAngle(textRadius, textStartAngle + textArc, center);

            const textPath = new Path();
            if (startAngle >= 0 && endAngle <= 180) {
                textPath.moveTo(textArcEndPoint);
                textPath.arc(textRadius, textRadius, 0, 0, 0, textArcStartPoint.x, textArcStartPoint.y);
            } else {
                textPath.moveTo(textArcStartPoint);
                textPath.arc(textRadius, textRadius, 0, 0, 1, textArcEndPoint.x, textArcEndPoint.y);
            }

            return (
                // We have to use this tricky key to enfore React to re-render the text when its position changes
                <SVGGroup ref={this.ref} key={`label${textArcStartPoint.x}${textArcStartPoint.y}${textArcEndPoint.x}${textArcEndPoint.y}`} >
                    <text {...textProps}>
                        <path id={`${this.uniqueId}-path`} d={textPath.toPathData()} />
                        <textPath href={`#${this.uniqueId}-path`} startOffset="50%">
                            {tspan}
                        </textPath>
                    </text>
                </SVGGroup>
            );
        } else {
            return (<SVGGroup ref={this.ref} key={"label"}>
                <text {...textProps} x={this.bounds.centerH} y={this.bounds.centerV}>{tspan}</text>
            </SVGGroup>);
        }
    }
}

export const elements = {
    Target
};
