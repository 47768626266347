import React, { Component } from "react";
import { v4 as uuid } from "uuid";

import * as geom from "js/core/utilities/geom";
import { ds } from "js/core/models/dataService";

export class AuthoringBlock extends Component {
    constructor() {
        super();

        this.state = {
            isDragging: false,
            renderKey: 0,
            hasFocus: false
        };

        this.ref = React.createRef();
        this.containerRef = React.createRef();

        this.uid = uuid();
    }

    get blockMargin() {
        return {
            top: 10,
            bottom: 10,
            left: 0,
            right: 0
        };
    }

    get bounds() {
        if (this.ref.current) {
            return geom.Rect.FromBoundingClientRect(this.ref.current.getBoundingClientRect()).inflate(this.blockMargin);
        }

        return null;
    }

    get model() {
        return this.props.model;
    }

    get indent() {
        return 0;
    }

    get index() {
        return this.props.index;
    }

    get scale() {
        return this.props.scale;
    }

    get type() {
        return this.model.type;
    }

    get element() {
        return this.props.element;
    }

    get canRollover() {
        return true;
    }

    handleMouseDown = event => {
        const { element, canEdit, canSelect } = this.props;

        if (canEdit && canSelect && element.canvas?.isEditable) {
            if (!element.overlay?.textEditorRef) {
                event.persist();

                // Let selection layer handle the event first
                element.canvas.selectionLayer.once("mousedown_handled", () => {
                    if (ds.selection.element !== element) {
                        // We're not selected anymore so remove text selection by
                        // 1. Preventing default to handle cases when it hasn't been executed yet
                        // 2. Force re-rendering contenteditable DOM element to handle cases when
                        //    the event's default action has already been processed
                        event.preventDefault();
                        this.setState(({ renderKey }) => ({ renderKey: renderKey + 1 }));
                    } else if (!element.overlay?.textEditorRef) {
                        // Still no text editor then render it
                        element.overlay.setupTextEditor(this);
                    }
                });
            }
        }
    }
}
