import { _ } from "legacy-js/vendor";
import { app } from "js/namespaces";
import * as geom from "js/core/utilities/geom";
import { BlockStructureType, HiliteType, HorizontalAlignType, TextStyleType } from "legacy-common/constants";

import { ContentElement } from "../../base/ContentElement";
import { Icon } from "../../base/MediaElements/IconElement";
import { OrgChartNode } from "./OrgChartNode";
import { TextElement } from "../../base/Text/TextElement";

export class PictureOrgChartNode extends OrgChartNode {
    get selectionBounds() {
        if (this.nodeStyle === "photo2" && !this.isAssistant) {
            return this.canvasBounds.inflate(this.selectionPadding || 0).offset(this.canvasBounds.width / 2 - this.content.bounds.width / 2, 0);
        } else {
            return super.selectionBounds;
        }
    }

    loadEmphasizedStyles(styleNode, hiliteType) {
        // if element is emphasized or de-emphasized, merge the root styles with the appropriate state styles
        switch (hiliteType) {
            case HiliteType.DEEMPHASIZED:
                if (styleNode.deemphasized) {
                    this.updateStyles(styleNode.deemphasized);
                    styleNode = _.merge(styleNode, styleNode.deemphasized);
                }
                break;
            case HiliteType.EMPHASIZED:
                if (styleNode.emphasized) {
                    this.updateStyles(styleNode.emphasized);
                    styleNode = _.merge(styleNode, styleNode.emphasized);
                }
                break;
            case HiliteType.NONE:
                break;
        }
    }

    _loadStyles(styles) {
        switch (this.nodeStyle) {
            case "photo1":
                styles.applyStyles(styles.style1);
                break;
            case "photo2":
                styles.applyStyles(styles.style2);
                break;
            case "photo3":
                styles.applyStyles(styles.style3);
                break;
        }

        super._loadStyles(styles);
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            allowedBlockTypes: [TextStyleType.TITLE, TextStyleType.BODY],
            autoWidth: true,
            autoHeight: true,
            requireParentSelection: true,
            passThroughSelection: false,
            syncFontSizeWithSiblings: true,
            getSiblings: () => {
                return this.getRootElement().findChildElements("TextElement");
            }
        });

        this.content = this.addElement("content", () => ContentElement, {
            useSmallContentUI: true
        });
        this.content.layer = 10;
    }

    get convertDecorationToAuthoringBeforeChildren() {
        return true;
    }

    get CONTENT_SIZE() {
        return 50;
    }

    get renderAllDecorationBehind() {
        return true;
    }

    _calcProps(props, options) {
        let { size } = props;

        switch (this.nodeStyle) {
            case "photo1":
                this.updateStyles(this.styles.style1, true);
                break;
            case "photo2":
                this.updateStyles(this.styles.style2, true);
                break;
            case "photo3":
                this.updateStyles(this.styles.style3, true);
                break;
        }

        if (this.parentElement.isRowSmall(this.rowIndex) && this.styles.small) {
            this.updateStyles(this.styles.small, true);
        }

        if (this.content.decoration) {
            if (!this.content.asset || (this.content.assetElement && this.content.assetElement instanceof Icon)) {
                this.content.decoration.updateStyles({
                    type: "frame",
                    shape: "circle",
                    fillColor: "white",
                    strokeColor: "slide !",
                    strokeWidth: this.canvas.styleSheet.$iconFrameStrokeWidth / 2
                });
            } else if (this.canvas.styleSheet.$iconFrameStrokeWidth > 1) {
                this.content.decoration.styles.strokeColor = "slide !";
                this.content.decoration.styles.strokeWidth = this.canvas.styleSheet.$iconFrameStrokeWidth / 2;
            }
        }

        const contentWidth = this.styles.content.width;
        const contentHeight = this.styles.content.height;

        const contentProps = this.content.calcProps(new geom.Size(contentWidth, contentHeight));

        const contentShift = 15;
        const contentHeightOverlap = contentHeight - contentShift;
        const contentWidthOverlap = contentWidth - contentShift;

        if (this.nodeStyle === "photo1") {
            const textProps = this.text.calcProps(new geom.Size(size.width - contentWidthOverlap, size.height), options);

            contentProps.bounds = new geom.Rect(contentWidthOverlap - contentWidth, contentHeightOverlap - contentHeight, contentWidth, contentHeight);
            textProps.bounds = new geom.Rect(contentWidthOverlap, 0, textProps.size);

            return { size: new geom.Size(size.width, options.fillHeight ? size.height : textProps.size.height) };
        } else if (this.nodeStyle === "photo2") {
            if (this.model.isAssistant) {
                options.textAlign = options.assistantNodeSide == 1 ? HorizontalAlignType.LEFT : HorizontalAlignType.RIGHT;
            }

            const textProps = this.text.calcProps(new geom.Size(size.width - contentWidthOverlap, size.height), options);

            if (this.model.isAssistant) {
                if (options.assistantNodeSide == 1) {
                    contentProps.bounds = new geom.Rect(0, -contentWidth / 2 + textProps.size.height / 2, contentWidth, contentHeight);
                    textProps.bounds = new geom.Rect(contentWidth, 0, textProps.size);
                } else {
                    contentProps.bounds = new geom.Rect(size.width - contentWidth, -contentWidth / 2 + textProps.size.height / 2, contentWidth, contentHeight);
                    textProps.bounds = new geom.Rect(size.width - contentWidth - textProps.size.width, 0, textProps.size);
                }
            } else {
                if (this.parentElement.layoutDirection == "vertical" && !options.isStacked) {
                    contentProps.bounds = new geom.Rect(size.width / 2 - contentWidth / 2, -contentWidth / 2 + textProps.size.height / 2, contentWidth, contentHeight);
                    textProps.bounds = new geom.Rect(size.width / 2 + contentWidth / 2, 0, textProps.size);
                } else {
                    contentProps.bounds = new geom.Rect(0, size.height / 2 - contentHeight / 2, contentWidth, contentHeight);
                    textProps.bounds = new geom.Rect(contentWidth, size.height / 2 - textProps.size.height / 2, textProps.size);
                }
            }
            return { size: new geom.Size(size.width, options.fillHeight ? size.height : textProps.size.height) };
        } else if (this.nodeStyle === "photo3") {
            const textProps = this.text.calcProps(new geom.Size(size.width, size.height), options);

            contentProps.bounds = new geom.Rect(size.width / 2 - contentWidth / 2, 0, contentWidth, contentHeight);
            textProps.bounds = new geom.Rect(size.width / 2 - textProps.size.width / 2, contentHeight, textProps.size);

            return { size: new geom.Size(size.width, options.fillHeight ? size.height : textProps.size.height + contentHeight) };
        }

        throw new Error(`Unrecognized node style ${this.nodeStyle}`);
    }

    _layerChildren() {
        this.content && this.content.svg.front();
    }
}
