import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import { AuthoringBlockType, ListStyleType, TextStyleType } from "legacy-common/constants";
import { cjkFallbackFonts } from "legacy-common/fontConstants";
import { isOfflinePlayer, isRenderer } from "legacy-js/config";
import * as geom from "js/core/utilities/geom";
import ContentEditable from "legacy-js/react/components/ContentEditable";
import { themeColors } from "legacy-js/react/sharedStyles";
import { $, _, tinycolor } from "legacy-js/vendor";

import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import { sanitizeHtml } from "js/core/utilities/dompurify";
import { DivGroup } from "legacy-js/core/utilities/svgHelpers";
import { AuthoringBlock } from "./AuthoringBlock";
import { BlockDecoration } from "./BlockDecoration";
import { SimpleNumberedDecoration } from "./ListDecorations";

const DEBUG_TEXT_LAYOUT = false;

const DebugBoundsBoxContainer = styled.div.attrs(({ bounds, color, thin }) => ({
    style: {
        ...bounds.toObject(),
        outline: `dotted ${thin ? "0.5" : "2"}px ${color}`
    }
}))`
  position: absolute;
  pointer-events: none;
`;

const DebugBoundsBoxLabel = styled.div.attrs(({ color }) => ({
    style: {
        color
    }
}))`
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-100%, -100%);

  font-family: monospace;
  opacity: 0.5;
  letter-spacing: 2px;
  font-size: 14px;
`;

function DebugBoundsBox({ bounds, color, thin, name, showLabel = false }) {
    return (<DebugBoundsBoxContainer bounds={bounds} color={color} thin={thin}>
        {showLabel && <DebugBoundsBoxLabel color={color}>{name}</DebugBoundsBoxLabel>}
    </DebugBoundsBoxContainer>);
}

export const TextBlockContainer = styled.div`
  position: relative;
  width: 100%;
  font-size: 20px;
  pointer-events: auto;
  //display: flex;

  letter-spacing: 0px;
  
  ul, ol {
    margin: 0px;
    padding: 10px 0px 0px 40px;

    ul, ol {
      padding-top: 0px;
    }
  }

  li {
    margin-bottom: 20px;
  }

  a {
    color: ${(props => props.themeColors?.hyperlink) ?? themeColors.ui_blue};
    text-decoration: none;
    pointer-events: auto;
    cursor: text;
  }

  .footnote {
    font-size: 0.5em;
    vertical-align: super;

    background: ${themeColors.ui_blue};
    border-radius: 50%;
    width: 1em;
    height: 1em;
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-family: Source Sans Pro;
    padding: 2px;
    cursor: pointer;
  }

  .isPlayback {
    a {
      cursor: pointer;
    }
  }

  .emphasized {
    font-weight: ${props => props.emphasizedFontWeight};
    color: ${props => props.emphasizedColor};
  }
  
  b {
    font-weight: ${props => props.emphasizedFontWeight};
  }

  .content-editable {
    position: relative;
    z-index: 1;
    outline: none;
    -webkit-user-select: auto;

    div {
      margin: 10px 0px;
    }
  }

  // needed for correct height measurement of empty text
  .content-editable:empty:before {
    content: "Spacer";
    pointer-events: none;
    display: block;
    opacity: 0;
  }

  // Auto color (so we can force it if needed)
  .color-auto {
    color: ${props => props.autoColor};
  }

  // Theme colors
  .color-primary_light {
    color: ${props => props.themeColors?.primary_light ?? "black"};
  }
  .color-primary_dark {
    color: ${props => props.themeColors?.primary_dark ?? "black"};
  }
  .color-secondary_light {
    color: ${props => props.themeColors?.secondary_light ?? "black"};
  }
  .color-secondary_dark {
    color: ${props => props.themeColors?.secondary_dark ?? "black"};
  }
  .color-theme {
    color: ${props => props.themeColors?.theme ?? "black"};
  }
  .color-accent1 {
    color: ${props => props.themeColors?.accent1 ?? "black"};
  }
  .color-accent2 {
    color: ${props => props.themeColors?.accent2 ?? "black"};
  }
  .color-accent3 {
    color: ${props => props.themeColors?.accent3 ?? "black"};
  }
  .color-accent4 {
    color: ${props => props.themeColors?.accent4 ?? "black"};
  }
  .color-accent5 {
    color: ${props => props.themeColors?.accent5 ?? "black"};
  }
  .color-accent6 {
    color: ${props => props.themeColors?.accent6 ?? "black"};
  }
  .color-accent7 {
    color: ${props => props.themeColors?.accent7 ?? "black"};
  }
  .color-accent8 {
    color: ${props => props.themeColors?.accent8 ?? "black"};
  }
  .color-background_dark {
    color: ${props => props.themeColors?.background_dark ?? "black"};
  }
  .color-background_light {
    color: ${props => props.themeColors?.background_light ?? "black"};
  }
  .color-background_accent {
    color: ${props => props.themeColors?.background_accent ?? "black"};
  }
  .color-chart1 {
    color: ${props => props.themeColors?.chart1 ?? "black"};
  }
  .color-chart2 {
    color: ${props => props.themeColors?.chart2 ?? "black"};
  }
  .color-chart3 {
    color: ${props => props.themeColors?.chart3 ?? "black"};
  }
  .color-chart4 {
    color: ${props => props.themeColors?.chart4 ?? "black"};
  }
  .color-chart5 {
    color: ${props => props.themeColors?.chart5 ?? "black"};
  }
  .color-chart6 {
    color: ${props => props.themeColors?.chart6 ?? "black"};
  }
`;

const Backdrop = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(-45deg, #D3E9F6, #D3E9F6 10px, #50bbe6 10px, #50bbe6 20px);
    opacity: 0.5;
`;

const Placeholder = styled.div.attrs(({ contentEditableStyles }) => ({
    style: {
        ...contentEditableStyles,
        color: tinycolor(contentEditableStyles.color).setAlpha(0.3).toRgbString(),
        fontStyle: "italic",
        pointerEvents: "none",
        position: "absolute",
        top: 0,
        left: 0
    }
}))``;

const PlaceholderFocusedText = styled.div.attrs(({ bounds }) => ({
    style: {
        ...bounds.offset(0, (bounds.height - 22) / 2).toObject(),
        width: "auto",
        height: "22px"
    }
}))`
    font-style: normal;
    font-size: 10px;
    font-weight: 600;
    font-family: "Source Sans Pro";
    padding: 0px 10px;
    background: #e1e1e1;
    text-transform: uppercase;
    color: #333;
    box-shadow: #d8d8d8 0px 0px 9px 0px;
    white-space: nowrap;
    display: flex;
    position: absolute;
    justify-content: flex-start;
    align-items: center;
`;

export const PlaceholderActions = styled.div`
    gap: 10px;
    position: absolute;
    margin-left: 10px;
    margin-top: 2px;
    display: inline-flex;
`;

export function getListDecorationSpacing(blockProps) {
    if (blockProps.model.listStyle == ListStyleType.TEXT) {
        return 0;
    } else {
        return blockProps.fontHeight * blockProps.textStyles.bulletSpacing;
    }
}

export class TextBlock extends AuthoringBlock {
    get type() {
        return AuthoringBlockType.TEXT;
    }

    get blockMargin() {
        return {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        };
    }

    get bounds() {
        const { textStyles, bounds: calculatedBounds, canvas, containerRef } = this.props;

        let bounds = new geom.Rect(calculatedBounds.left * canvas.getScale(), calculatedBounds.top * canvas.getScale(), calculatedBounds.width * canvas.getScale(), calculatedBounds.height * canvas.getScale())
            .inflate(this.blockMargin)
            .inflate({ left: textStyles.marginLeft, right: textStyles.marginRight });

        if (containerRef.current) {
            const containerBounds = geom.Rect.FromBoundingClientRect(containerRef.current.getBoundingClientRect());
            bounds = bounds.offset(containerBounds.position);
        }

        return bounds;
    }

    get indent() {
        return this.props.indent ?? 0;
    }

    get textContent() {
        return this.ref.current.textContent;
    }

    get textStyle() {
        return this.props.textStyle;
    }

    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
    }

    renderListDecoration(indentPadding, textAlign) {
        const { isCalculatingLayout, listDecorationElement, model, textStyles } = this.props;

        if (isCalculatingLayout) {
            return null;
        }

        if (model.listStyle == ListStyleType.NUMBERED && (!textStyles.allowFancyNumberedDecorations || model.useThemedListDecoration === false)) {
            return <SimpleNumberedDecoration {...this.props} indentSize={indentPadding} style={model.listDecorationStyle} />;
        }

        if (listDecorationElement?.calculatedProps) {
            return <DivGroup>{listDecorationElement.renderElement(false)}</DivGroup>;
        }

        return null;
    }

    renderBlockDecoration(textAlign) {
        const { textBounds, element, textStyles, isCalculatingLayout, renderProps: { bounds: renderBounds } } = this.props;

        if (isCalculatingLayout) {
            return null;
        }

        if (!textStyles.blockDecorationStyles) {
            return null;
        }

        return (
            <BlockDecoration
                bounds={renderBounds.zeroOffset()}
                textBounds={textBounds.offset(-renderBounds.position.x, -renderBounds.position.y)}
                textStyles={textStyles}
                textAlign={textAlign}
                styles={textStyles.blockDecorationStyles}
                element={element}
            />
        );
    }

    handleClick = event => {
        const { canvas } = this.props;

        const target = event.target;
        if (!target) {
            return;
        }

        // closest() will also return target if it is a link
        const linkNode = $(target).closest("a")[0];
        if (!linkNode) {
            return;
        }

        // Intercepting link clicks and forcing them to open in
        // new window
        event.stopPropagation();
        event.preventDefault();

        // Ignore clicks on links unless we are playing back
        if (!canvas.isPlayback) {
            return;
        }

        const href = linkNode.getAttribute("href");

        if (window.isPlayer && href.startsWith("bai://")) {
            const slideId = href.replace("bai://", "");
            const slideIndex = canvas.slide.presentation.getSlideIndex(slideId);
            if (!canvas.slide.presentation.getSips().includes(slideId)) return;

            canvas.playerView.goToSlide(slideIndex);
        } else {
            canvas.slide.presentation.openExternalUrl(href);
        }
    }

    handleCopy = event => {
        event.preventDefault();

        const range = window.getSelection().getRangeAt(0);

        const containerNode = this.ref.current;
        const containerStyle = window.getComputedStyle(containerNode);

        let styleNode = range.commonAncestorContainer;
        while (styleNode.nodeName === "#text") {
            styleNode = styleNode.parentElement;
        }

        let colorClass = null;
        let hasEmphasized = false;
        let classNode = styleNode;
        while (classNode !== containerNode) {
            if (!colorClass) {
                colorClass = [...classNode.classList.values()].find(className => className.startsWith("color-"));
            }
            if (!hasEmphasized) {
                hasEmphasized = [...classNode.classList.values()].some(className => className === "emphasized");
            }

            classNode = classNode.parentElement;
        }

        let contents = range.cloneContents();
        if (styleNode !== containerNode) {
            const style = window.getComputedStyle(styleNode);

            const fontStyle = style.getPropertyValue("font-style");
            const fontWeight = style.getPropertyValue("font-weight");
            const color = style.getPropertyValue("color");

            if (fontStyle === "italic") {
                const wrapper = document.createElement("i");
                wrapper.appendChild(contents);
                contents = wrapper;
            }

            if (fontWeight > containerStyle.getPropertyValue("font-weight")) {
                const wrapper = document.createElement("b");
                wrapper.appendChild(contents);
                contents = wrapper;
            }

            if (colorClass) {
                const wrapper = document.createElement("font");
                wrapper.setAttribute("class", colorClass);
                wrapper.appendChild(contents);
                contents = wrapper;
            } else if (color !== containerStyle.getPropertyValue("color")) {
                const wrapper = document.createElement("font");
                wrapper.style.setProperty("color", color);
                wrapper.appendChild(contents);
                contents = wrapper;
            }

            if (hasEmphasized) {
                const wrapper = document.createElement("font");
                wrapper.setAttribute("class", "emphasized");
                wrapper.appendChild(contents);
                contents = wrapper;
            }
        }

        const div = document.createElement("div");
        div.appendChild(contents);

        clipboardWrite({
            [ClipboardType.HTML]: sanitizeHtml(div.innerHTML),
            [ClipboardType.TEXT]: sanitizeHtml(div.innerHTML),
        });
    }

    setEditor = () => {
        this.setState({ hasEditor: true });
    }

    removeEditor = () => {
        this.setState({ hasEditor: false });
    }

    onStartEditing = () => {
        this.props.onStartEditing(this);
    }

    onStopEditing = () => {
        this.props.onStopEditing(this);
    }

    render() {
        const {
            model,
            textStyle,
            textStyles,
            textAlign,
            canvas,
            placeholder,
            blockPadding,
            canEdit,
            canSelect,
            isCalculatingLayout,
            containerBounds,
            lines,
            bounds,
            textBounds,
            ignoreSpellcheck,
            hasCanvasOverflow,
            showCanvasOverflow,
            indentWidth,
            parentBlock,
            element
        } = this.props;

        const {
            isDragging,
            renderKey
        } = this.state;

        let html = model.html || "";

        let isPlayback = canvas?.isPlayback;
        let isRenderingTemplateThumbnail = canvas?.isRenderingTemplateThumbnail;
        let isDisabled = !canEdit || !canSelect || element.parentElement?.model?.isLocked;

        if (!canvas || !canvas.isEditable || !canvas.isCurrentCanvas) {
            isDisabled = true;
        }

        // if there's no content, then show the placeholder
        let showPlaceholder = !_.trim(html).length;

        // if in playback mode or editing, then we don't want
        // to show the placeholder
        if (isPlayback && !isRenderingTemplateThumbnail) {
            isDisabled = true;
            showPlaceholder = false;
        } else if (showPlaceholder) {
            // if the placeholder is still expected to be
            // shown, trim the content (so it causes the placeholder
            // to actually be visible)
            html = "";
        }

        let textClasses = ["content-editable"];
        if (isPlayback) {
            textClasses.push("isPlayback");
        }

        let styles = {
            pointerEvents: "auto",
            cursor: isDisabled ? "default" : "text",
            userSelect: isDisabled ? "none" : "text",
            width: "100%",
            height: "100%",
            ...textStyles,
            marginTop: 0, // overrides margin in textStyles because it's handled at the TextBlockContainer
            marginBottom: 0,
        };
        if (isPlayback && model.linkToSlide) {
            styles.cursor = "pointer";
        }

        if (styles.maxLines === Number.POSITIVE_INFINITY) {
            delete styles.maxLines;
        }

        if (textStyles.textAlign == null) {
            styles.textAlign = textAlign;
        }

        if (model.textAlign && element.allowAlignment) {
            styles.textAlign = model.textAlign;
        }

        let indentPadding = indentWidth;

        if (model.listStyle && model.listStyle !== ListStyleType.TEXT) {
            // pad for list decoration spacing
            indentPadding += getListDecorationSpacing(this.props);
        }

        indentPadding += (textStyles.blockInset ?? 0);

        if (parentBlock && parentBlock.model.listStyle == ListStyleType.CHECKBOX && parentBlock.model.checkState == "unchecked") {
            styles.opacity = 0.5;
        }

        // Override padding for lists
        if (styles.textAlign === "left") {
            styles.padding = `0px 0px 0px ${indentPadding}px`;
        } else if (styles.textAlign === "right") {
            styles.padding = `0px ${indentPadding}px 0px 0px `;
        }

        styles.columnCount = model.columns ?? null;

        styles.hyphens = model.hyphenation ? "auto" : "none";
        styles.fontVariantLigatures = model.ligatures === false ? "none" : "normal";
        styles.fontVariantNumeric = "lining-nums";
        styles.overflowWrap = "normal";
        if (textStyles.isRTL) {
            styles.direction = "rtl";
        }

        if (hasCanvasOverflow) {
            styles.overflow = "hidden";
        }

        const emphasizedFontWeight = Math.clamp(textStyles.fontWeight + 200, 600, 900);
        const fontColor = textStyles.color;
        if (model.emphasized) {
            styles.color = textStyles.emphasizedColor;
            styles.fontWeight = emphasizedFontWeight;
        } else if (textStyles.hasEmphasized) {
            if (textStyles.emphasizedColor === fontColor || textStyle === TextStyleType.BODY) {
                styles.color = tinycolor(fontColor).setAlpha(0.5).toRgbString();
            }
        } else {
            styles.color = fontColor;
        }

        if (isRenderer || isOfflinePlayer) {
            // Set backup font families for renderer and electron to force it use the correct
            // fallback fonts (sans-serif and cjk fonts)

            const fallbackFonts = [
                "sans-serif",
                ...cjkFallbackFonts.map(({ fontFaceName }) => `'${fontFaceName}'`)
            ].join(",");

            styles.fontFamily = `${styles.fontFamily}, ${fallbackFonts}`;

            if (html) {
                const domparser = new DOMParser();
                const document = domparser.parseFromString(html, "text/html");
                document.querySelectorAll("*").forEach(element => {
                    if (element.style) {
                        const fontFamily = element.style.getPropertyValue("font-family");
                        if (fontFamily) {
                            element.style.setProperty("font-family", `${fontFamily}, ${fallbackFonts}`);
                        }
                    }
                });
                html = sanitizeHtml(document.body.innerHTML);
            }
        }

        if (isDisabled && document.activeElement === this.ref.current) {
            // Force blur if the element is disabled and it's still focused
            this.ref.current.blur();
        }

        return (
            <TextBlockContainer className="text-block-container"
                ref={this.containerRef}
                showPlaceholder={showPlaceholder}
                onMouseDown={this.handleMouseDown}
                emphasizedColor={textStyles.emphasizedColor}
                emphasizedFontWeight={emphasizedFontWeight}
                autoColor={textStyles.autoColor}
                themeColors={textStyles.themeColors}
                style={{
                    padding: isCalculatingLayout ? blockPadding : null,
                    opacity: isDragging ? 0.3 : null
                }}
            >
                {this.renderBlockDecoration(styles.textAlign)}
                {this.renderListDecoration(indentPadding, styles.textAlign)}
                <ContentEditable
                    id={this.uid}
                    key={renderKey}
                    contentEditable
                    html={html}
                    innerRef={this.ref}
                    onMouseDown={isDisabled ? event => event.preventDefault() : null}
                    onClick={this.handleClick}
                    onCopy={this.handleCopy}
                    onFocus={() => { }}
                    onBlur={() => { }}
                    className={textClasses.join(" ")}
                    style={styles}
                    lang="en"
                    spellCheck={!ignoreSpellcheck}
                    disableSelectionHandling={isCalculatingLayout || isDisabled}
                />
                {hasCanvasOverflow && showCanvasOverflow && <Backdrop />}
                {showPlaceholder && <>
                    <Placeholder
                        className={"text-block-placeholder"}
                        contentEditableStyles={styles}
                    >
                        {placeholder.text}
                    </Placeholder>
                </>
                }
                {!isCalculatingLayout && (DEBUG_TEXT_LAYOUT || window.debug.showLayoutDebugBox) && <>
                    {lines.map((line, lineIdx) => line.map((word, wordIdx) => (
                        <DebugBoundsBox
                            key={`${lineIdx}${wordIdx}`}
                            bounds={word.bounds}
                            thin
                            color="red"
                            name="wordBounds"
                        />
                    )))}
                    <DebugBoundsBox
                        bounds={containerBounds.zeroOffset()}
                        color="yellow"
                        name="containerBounds"
                    />
                    <DebugBoundsBox
                        bounds={bounds.offset(-containerBounds.left, -containerBounds.top)}
                        color="blue"
                        name="bounds"
                    />
                    <DebugBoundsBox
                        bounds={textBounds.offset(-containerBounds.left, -containerBounds.top)}
                        color="green"
                        name="textBounds"
                    />
                </>}
            </TextBlockContainer>
        );
    }
}
