import * as geom from "js/core/utilities/geom";
import { FormatType, VerticalAlignType, BlockStructureType, TextStyleType } from "legacy-common/constants";

import { TextElement } from "../../base/Text/TextElement";
import { SVGRectElement } from "../../base/SVGElement";
import { layoutHelper } from "../../layouts/LayoutHelper";

import CompareValuesItem from "./CompareValuesItem";
import CompareValuesItemLabel from "./CompareValuesItemLabel";
import { sanitizeHtmlText } from "js/core/utilities/htmlTextHelpers";

export default class CompareHorizontalBarItem extends CompareValuesItem {
    get selectionPadding() {
        return { left: 20, right: 0, top: 0, bottom: 0 };
    }

    get labelText() {
        return this.parentElement.formatValue(this.currentValue);
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGRectElement);

        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            allowedBlockTypes: [TextStyleType.TITLE, TextStyleType.BODY],
            autoWidth: false,
            autoHeight: true,
            refreshElement: this.parentElement,
            syncFontSizeWithSiblings: true
        });

        if (this.parentElement.format != FormatType.NONE) {
            this.label = this.addElement("label", () => CompareValuesItemLabel, {
                autoWidth: true,
                canEdit: false,
                html: this.labelText
            });
        }
    }

    _calcProps(props, options) {
        const { size: allowedSize } = props;
        const { titleWidth, barHeight } = options;

        const size = new geom.Size(allowedSize.width, barHeight);

        const textProps = this.text.calcProps(new geom.Size(titleWidth, barHeight));
        textProps.bounds = new geom.Rect(0, size.height / 2 - textProps.size.height / 2, titleWidth, textProps.size.height);

        const textWidth = textProps.bounds.width;

        const compare = this.parentElement;

        const availableWidth = size.width - textWidth;
        let barWidth = availableWidth * this.percentageValue;
        if (this.isAnimating) {
            barWidth *= this.drawPercentage;
        }

        const shapeProps = this.shape.createProps({
            layer: -1
        });

        if (compare.minValue >= 0) {
            shapeProps.bounds = new geom.Rect(textWidth, 0, barWidth, size.height);
        } else {
            shapeProps.bounds = new geom.Rect(0, 0, barWidth, size.height);
            const baseLineX = textWidth + availableWidth - availableWidth * Math.abs(compare.maxValue) / (compare.maxValue - compare.minValue);
            if (this.model.value >= 0) {
                shapeProps.bounds.left = baseLineX;
            } else {
                shapeProps.bounds.left = baseLineX - barWidth;
            }
        }

        if (this.label) {
            this.label.styles.fontSize = size.height * 0.5;

            if (this.isAnimating) {
                this.label.options.html = sanitizeHtmlText(this.labelText);
            }

            const labelProps = this.label.calcProps(size);

            const isInside = this.isAnimating ? this.finalLabelPositionIsInside : (shapeProps.bounds.width > labelProps.size.width);
            props.isLabelInside = isInside;

            if (isInside) {
                labelProps.bounds = new geom.Rect(this.shape.bounds.right - labelProps.size.width, shapeProps.bounds.top + layoutHelper.getVerticalAlignOffset(labelProps.size.height, shapeProps.bounds.height, VerticalAlignType.MIDDLE), labelProps.size);
            } else {
                labelProps.bounds = new geom.Rect(shapeProps.bounds.right, shapeProps.bounds.top + layoutHelper.getVerticalAlignOffset(labelProps.size.height, shapeProps.bounds.height, VerticalAlignType.MIDDLE), labelProps.size);
            }

            if (this.hasStoredPropChanged("isLabelInside", props.isLabelInside)) {
                this.markStylesAsDirty();
            }
        }

        return { size };
    }

    _getBackgroundColor(forElement) {
        if (this.label && forElement === this.label && this.calculatedProps.isLabelInside) {
            return this.getShapeFillColor(this.shape);
        } else {
            return super._getBackgroundColor(forElement);
        }
    }

    get animationElementName() {
        return `Bar #${this.itemIndex + 1}`;
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            animatingElements: [this.parentElement, this],
            prepare: () => {
                this.text.animationState.fadeInProgress = 0;
                this.animationState.fadeInProgress = 0;
                this.animationState.value = 0;
                if (this.label) {
                    this.finalLabelPositionIsInside = this.calculatedProps.isLabelInside;
                    this.label.animationState.fadeInProgress = 0;
                }
            },
            onBeforeAnimationFrame: progress => {
                this.text.animationState.fadeInProgress = Math.clamp((progress - 0.7) / 0.3, 0, 1);
                this.animationState.fadeInProgress = Math.min(1, progress * 3);
                this.animationState.value = progress;
                if (this.label) {
                    this.label.animationState.fadeInProgress = progress;
                }
                return this;
            }
        }];
    }
}
