import * as geom from "js/core/utilities/geom";
import { Shape } from "js/core/utilities/shapes";

import { BaseElement } from "../../base/BaseElement";
import { SVGPathElement } from "../../base/SVGElement";
import { CollectionElement } from "../../base/CollectionElement";

class RadialBarGraph extends BaseElement {
    get currentValue() {
        if (this.isAnimating) {
            return this.model.value * this.animationState.value;
        }

        return this.model.value;
    }

    get labelText() {
        return this.findClosestOfType(CollectionElement).formatValue(this.currentValue);
    }

    _build() {
        this.circle = this.addElement("radialCircle", () => SVGPathElement);
        this.path = this.addElement("radialPath", () => SVGPathElement);

        if (this.options.showLabel) {
            this.label = this.addElement("label", () => this.options.labelElement, {
                model: {
                    label: this.labelText
                },
                constrainWidth: true,
                scaleTextToFit: true,
                canEdit: false
            });
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        const strokeWidth = Math.max(this.styles.radialCircle.strokeWidth, this.styles.radialPath.strokeWidth);

        const bounds = new geom.Rect(0, 0, size);
        const circleBounds = new geom.Rect(0, 0, size)
            .square()
            .centerInRect(bounds);

        const { centerH, centerV, width } = circleBounds.removePadding(strokeWidth / 2);
        this.circle.createProps({
            path: Shape.drawCircle(width / 2, {
                y: centerH,
                x: centerV
            }).toPathData(),
        });

        let value = this.options.valueFunction ? this.options.valueFunction() : (this.model.value ?? 100);
        if (this.isAnimating) {
            value = value * this.animationState.value;
        }

        const startAngle = 0 - 90 / 180 * Math.PI;
        const angle = Math.min(359.99, 360 * value);
        const endAngle = (angle - 90) / 180 * Math.PI;

        this.path.createProps({
            path: Shape.drawArc(centerH, centerV, width / 2, startAngle, endAngle).toPathData(),
        });

        if (this.getBackgroundColor().isColor) {
            // force the path to always be white on color backgrounds
            this.path.styles.strokeColor = "white";
        }

        if (this.options.showLabel) {
            if (this.isAnimating) {
                this.label.updateText(this.labelText);
            }

            const labelBounds = circleBounds.removePadding(strokeWidth);
            const labelProps = this.label.calcProps(labelBounds.size);
            labelProps.bounds = labelBounds;
        }

        return { size };
    }
}

export { RadialBarGraph };
