import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces.js";
import { controls } from "legacy-js/editor/ui";

import { DragType, HorizontalAlignType } from "legacy-common/constants";
import { $, _ } from "legacy-js/vendor";
import * as geom from "js/core/utilities/geom";
import { getCollectionPropertyValue } from "js/core/utilities/extensions";

import { TextElementSelection } from "./TextEditor";
import { ElementOptionsMenu, ElementRollover } from "../BaseElementEditor";
import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";

const BigNumbersSelection = CollectionElementSelection.extend({

    renderControls() {
        this.addControl({
            type: controls.BUTTON,
            label: "Add Item",
            icon: "add_circle",
            enabled: this.element.maxItemCount ? (this.element.itemCount < this.element.maxItemCount) : true,
            callback: () => {
                let addToRow = 0;
                let itemsInRows = this.element.getItemsInRows();
                if (itemsInRows[0].length > 1) {
                    addToRow = 1;
                }

                this.addItem({ model: { row: addToRow } });
            },
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Layout",
            items: () => [{
                type: "control",
                view: () => controls.createIconGrid(this, {
                    model: this.element.model,
                    property: "layout",
                    cols: 2,
                    items: [{
                        value: "fill",
                        label: "Fill Widths",
                        icon: "/images/ui/big-numbers-align-justify.svg"
                    }, {
                        value: "fit",
                        label: "Fit Widths",
                        icon: "/images/ui/big-numbers-align-center.svg",
                    }]
                })
            }]
        });
    }
});

const BigNumberItemSelection = CollectionItemElementSelection.extend({

    getOffset() {
        return 10;
    },

    renderControls() {
        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            showBackgroundColors: true,
            omitCurrentBackgroundColor: true,
            includeAuto: true,
            autoLabel: "AUTO",
            getAutoColor: () => {
                if (this.element.canvas.getBackgroundColor().name == "background_light") {
                    return "background_accent";
                } else {
                    return "white";
                }
            }
        });
    },

    getDragOptions: function() {
        return {
            type: DragType.INSERT_HORIZONTAL,
            multipleRows: true,
            insertGap: this.element.parentElement.styles.hGap
        };
    },

    getWidgetPosition() {
        return "inner";
    },

    getDropTargets: function(containerElement) {
        let targets = containerElement.itemElements;

        $(".add-row-indicator").remove();

        if (containerElement.rows < 3) {
            let canvasBounds = containerElement.canvasBounds;
            let targetBounds = new geom.Rect(canvasBounds.left, canvasBounds.bottom - 50, canvasBounds.width, 50);

            targets.push({
                id: "add-row",
                itemIndex: 0,
                canvasBounds: targetBounds
            });

            // TODO update to uuse canvas selectionLayer once i merge that code
            let $dropIndicator = this.selectionLayer.$el.addEl($.div("add-row-indicator", "Add Row"));
            $dropIndicator.setBounds(this.canvasToSelectionCoordinates(targetBounds));
        }
        return targets;
    },

    renderInsertDropIndicator: function(dropTarget, dropEdge) {
        $(".drop-target-indicator").remove();

        if (dropTarget) {
            if (dropTarget.id === "add-row") {
                $(".add-row-indicator").addClass("hover");
                return;
            }
            $(".add-row-indicator").removeClass("hover");

            CollectionItemElementSelection.prototype.renderInsertDropIndicator.apply(this, arguments);
        }
    },

    updateModelFromDropAction: async function(dropTarget, dragProps, insertBeforeTarget) {
        let containerElement = this.element.parentElement;

        if (dropTarget && dropTarget.id === "add-row") {
            // add the dragItem into a new row
            // containerElement.model.rows += 1;
            dragProps.dragItem.model.row = containerElement.rows + 1;
        } else if (dropTarget && dropTarget.id === "row") {
            // update the dragItem's row to match the dropTarget's row
            dragProps.dragItem.model.row = dropTarget.row;
        } else {
            // update the dragItem's row to match the dropTarget's row
            dragProps.dragItem.model.row = dropTarget.model.row;

            let rows = containerElement.model.rows;
            let emptyRows = 0;
            let itemsInRows = containerElement.getItemsInRows();
            for (let row = 0; row < itemsInRows.length; row++) {
                if (itemsInRows[row].length === 0) {
                    emptyRows++;
                }
                for (let item of itemsInRows[row]) {
                    item.model.row -= emptyRows;
                }
            }
            containerElement.model.rows = rows - emptyRows;

            CollectionItemElementSelection.prototype.updateModelFromDropAction.apply(this, arguments);
        }
    },

    onStopDrag: function(event, position, dragProps, options = { forceRender: false }) {
        CollectionItemElementSelection.prototype.onStopDrag.apply(this, arguments);
        $(".add-row-indicator").remove();
    },
});

const BigNumberItemOptionsMenu = ElementOptionsMenu.extend({

    renderControls() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Label",
            property: "showLabel"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Change in Value",
            property: "showChangeInValue"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Description",
            property: "showDescription"
        });
    }

});

const BigNumbersOptionsMenu = ElementOptionsMenu.extend({

    renderControls() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Label",
            value: getCollectionPropertyValue(this.element.itemCollection, "showLabel"),
            callback: value => {
                for (let model of this.element.itemCollection) {
                    model.showLabel = value;
                }
                this.element.canvas.updateCanvasModel(true);
            }
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Change In Value",
            value: getCollectionPropertyValue(this.element.itemCollection, "showChangeInValue"),
            callback: value => {
                for (let model of this.element.itemCollection) {
                    model.showChangeInValue = value;
                }
                this.element.canvas.updateCanvasModel(true);
            }
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Description",
            value: getCollectionPropertyValue(this.element.itemCollection, "showDescription"),
            callback: value => {
                for (let model of this.element.itemCollection) {
                    model.showDescription = value;
                }
                this.element.canvas.updateCanvasModel(true);
            }
        });
    }
});

const BigNumberChangeInValueIconRollover = ElementRollover.extend({

    onClick: function(event) {
        if (app.isEditingText) return;

        this.element.model.positive = !this.element.model.positive;
        this.element.markStylesAsDirty();
        this.element.canvas.updateCanvasModel(false);
    },

});

const BigNumberChangeInValueLabelSelection = TextElementSelection.extend({
    renderControls() {
        TextElementSelection.prototype.renderControls.apply(this, arguments);

        this.createDeleteComponentWidget({
            action: () => {
                ds.selection.element = null;
                this.element.findClosestOfType("BigNumberItem").model.showChangeInValue = false;
                this.element.canvas.updateCanvasModel(true);
            }
        });
    }
});

const BigNumberDescriptionSelection = TextElementSelection.extend({
    renderControls() {
        TextElementSelection.prototype.renderControls.apply(this, arguments);

        this.createDeleteComponentWidget({
            action: () => {
                ds.selection.element = null;
                this.element.findClosestOfType("BigNumberItem").model.showDescription = false;
                this.element.canvas.updateCanvasModel(true);
            }
        });
    }
});

export const editors = {
    BigNumbersSelection,
    BigNumberItemSelection,
    BigNumberItemOptionsMenu,
    BigNumbersOptionsMenu,
    BigNumberDescriptionSelection,
    BigNumberChangeInValueIconRollover,
    BigNumberChangeInValueLabelSelection
};
