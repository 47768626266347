import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";
import { controls } from "../../../../editor/ui";
import {
    AuthoringBlockType,
    HorizontalAlignType,
    NodeType,
    PaletteColorType,
    PositionType,
    TextStyleType
} from "legacy-common/constants";
import { ElementOptionsMenu, ElementSelection } from "../BaseElementEditor";
import { $, _, SVG } from "../../../../vendor";
import * as geom from "js/core/utilities/geom";
import { app } from "js/namespaces";
import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import EditTextDialog from "legacy-js/react/components/Dialogs/EditTextDialog";

const TargetSelection = CollectionElementSelection.extend({
    renderControls: function() {
        this.createAddItemButton();
    },

    getAddItemLabel: function() {
        return "Add Band";
    },
});

const TargetOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function($container) {
        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Position",
            items: [{
                value: PositionType.LEFT, label: "Left"
            }, {
                value: PositionType.CENTER, label: "Center"
            }, {
                value: PositionType.RIGHT, label: "Right"
            }],
            property: "position"
        });

        this.addControl({
            type: controls.SLIDER,
            label: "Inner Radius",
            property: "innerRadius",
            min: 0,
            max: 100,
            enabled: this.element.maxInnerRadius > 0,
        });

        this.addControl({
            type: controls.SLIDER,
            label: "Outer Radius",
            property: "outerRadius",
            min: 50,
            max: 100,
        });
    }
});

const TargetBandSelection = CollectionItemElementSelection.extend({
    captureMouseEvents: false,

    canDrag() {
        return false;
    },

    getOffset() {
        return 20;
    },

    renderControls: function() {
        this.addControl({
            type: controls.SLIDER,
            label: "Segments",
            value: this.element.model.segments.length,
            min: 1,
            max: 8,
            callback: value => {
                let segmentColor = _.last(this.element.model.segments).color;

                if (value < this.element.model.segments.length) {
                    this.element.model.segments = this.element.model.segments.slice(0, value);
                } else if (value > this.element.model.segments.length) {
                    for (let i = this.element.model.segments.length; i < value; i++) {
                        this.element.model.segments.push({ color: segmentColor });
                    }
                }

                this.element.canvas.refreshCanvas(false);
            }
        });
    },

});

const TargetBandSegmentSelection = ElementSelection.extend({
    showSelectionBox: false,
    captureMouseEvents: true,

    getOffset() {
        return 0;
    },

    renderControls: function() {
        let $selectionPathContainer = this.$el.addEl($.div("selection-path control"));
        this.selectionPathSVG = SVG($selectionPathContainer[0]);

        for (let segment of this.element.parentElement.segments) {
            if (segment != this.element) {
                segment.DOMNode.style.opacity = "0.5";
            } else {
                segment.DOMNode.style.opacity = "1";
            }
        }

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Label",
            items: [{
                value: "none", label: "None"
            }, {
                value: "inside", label: "Inside"
            }, {
                value: "callout", label: "Callout"
            }],
            callback: value => {
                let annotationContainer = this.canvas.layouter.canvasElement.primary.annotations;
                let defaultLabelText = "";

                if (this.model.labelType == "callout" && value == "callout") {
                    if (annotationContainer.connectors.itemElements.find(c => c.model.target == this.element.uniquePath)) {
                        return;
                    }
                } else if (this.model.labelType == value) {
                    return;
                }

                switch (value) {
                    case "callout":
                        if (this.model.labelType == "inside") {
                            defaultLabelText = this.model.label || "";
                        }

                        let angle;
                        if (this.element.arcLength < 360) {
                            angle = this.element.startAngle + this.element.arcLength / 2;
                        } else {
                            angle = this.element.itemIndex * 90 - 45;
                        }

                        let calloutPt = geom.Point.PointFromAngle(this.element.outerRadius + 150, angle, this.element.centerPoint);
                        calloutPt = calloutPt.offset(this.element.canvasBounds.position);

                        if (calloutPt.x < annotationContainer.bounds.width / 2) {
                            calloutPt.x -= 100;
                        } else {
                            calloutPt.x += 100;
                        }

                        let x = Math.clamp(calloutPt.x / annotationContainer.bounds.width, 0, 1);
                        let y = Math.clamp(calloutPt.y / annotationContainer.bounds.height, 0, 1);

                        if (x < 0.5 && x > 0.4) {
                            x -= 0.2;
                        }
                        if (x >= 0.5 && x < 0.6) {
                            x += 0.2;
                        }

                        let annotation = annotationContainer.addItem({
                            nodeType: NodeType.TEXT,
                            x: x,
                            y: y,
                            color: PaletteColorType.BACKGROUND_DARK,
                            text: {
                                blocks: [{
                                    type: AuthoringBlockType.TEXT,
                                    textStyle: TextStyleType.TITLE,
                                    html: defaultLabelText
                                }]
                            }
                        });

                        annotationContainer.connectors.addItem({
                            source: annotation.id,
                            target: this.element.uniquePath,
                            endDecoration: "circle",
                            connectorType: "angle"
                        });
                        break;
                    case "inside":
                    case "none":
                        // check if there is a callout
                        let labelText = "";
                        if (this.model.labelType == "callout") {
                            let connector = annotationContainer.connectors.itemElements.find(c => c.model.target == this.element.uniquePath);
                            if (connector) {
                                let nodeElement = connector.startTarget;
                                // if (nodeElement && nodeElement.text && nodeEl nodeElement.text.itemElements.length > 0 && nodeElement.text.itemElements[0].content && nodeElement.text.itemElements[0].content.textModel) {
                                //     labelText = nodeElement.text.itemElements[0].content.textModel.text || "";
                                // }
                                if (nodeElement && nodeElement.text) {
                                    labelText = nodeElement.text.firstBlock.html;
                                }
                                annotationContainer.deleteItem(connector.model.source);
                                annotationContainer.connectors.deleteItem(connector.id);
                            }
                        }

                        if (value == "inside") {
                            this.model.label = labelText;
                        }

                        break;
                }
                this.model.labelType = value;
                this.element.canvas.updateCanvasModel();
            }
        });

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            includeAuto: true
        });
    },

    onClick() {
        const timestamp = new Date().getTime();
        // Checking if it's a double click
        if (this.lastClickTimestamp && this.lastClickTimestamp > timestamp - 500) {
            if (this.element.labelType == "inside") {
                const targetPt = this.element.getScreenBounds().getPoint("center");

                ShowDialog(EditTextDialog, {
                    targetPt,
                    target: this.element,
                    value: this.element.model.label,
                    callback: value => {
                        this.element.model.label = value;
                        this.element.canvas.updateCanvasModel(false);
                    },
                });
            }
        }
        this.lastClickTimestamp = timestamp;
    },

    _layout() {
        this.selectionPathSVG.clear();
        this.selectionPathSVG.path(this.element.getSelectionPath(this.canvas.canvasScale).toPathData());
    },

    cleanUp() {
        for (let segment of this.element.parentElement.segments) {
            segment.DOMNode && (segment.DOMNode.style.opacity = "1");
        }
    }
});

export const editors = {
    TargetSelection,
    TargetOptionsMenu,
    TargetBandSelection,
    TargetBandSegmentSelection
};
