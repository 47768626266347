import * as geom from "js/core/utilities/geom";
import { AssetType } from "legacy-common/constants";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { ContentElement } from "../base/ContentElement";

class LogoGrid extends CollectionElement {
    getChildItemType(itemModel) {
        return LogoGridItem;
    }

    get maxItemCount() {
        return 30;
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, this.itemElements, size).calcBoxGridLayout();

        return { size: layouter.size };
    }

    _exportToSharedModel() {
        const assets = this.itemElements.reduce((assets, itemElement) => ([
            ...assets, ...itemElement.logo._exportToSharedModel().assets
        ]), []);

        return { assets };
    }

    _importFromSharedModel(model) {
        const { assets } = model;
        if (!assets?.length) return;

        const items = assets.map((asset, i) => ({
            content_type: asset.type,
            content_value: asset.value,
            assetName: asset.name,
            assetProps: asset.props,
            ...(asset.configProps ?? {})
        }));

        return { items };
    }
}

class LogoGridItem extends CollectionItemElement {
    static get schema() {
        return {
            backgroundColor: "white"
        };
    }

    get selectionPadding() {
        return 0;
    }

    _build() {
        this.logo = this.addElement("logo", () => ContentElement, {
            defaultAssetType: AssetType.LOGO,
            allowBackdrop: false,
            canSelect: false
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let frameColor = "white";
        if (this.logo.assetElement && this.logo.assetElement.hasAlpha) {
            frameColor = this.model.backgroundColor;
        }

        this.createDecoration({
            type: "frame",
            shape: "rect",
            fillColor: frameColor,
            strokeColor: "secondary_dark 0.1",
            strokeWidth: this.parentElement.model.showFrame ? 1 : 0
        });

        let logoProps = this.logo.calcProps(size);
        logoProps.bounds = new geom.Rect(0, 0, size);

        return { size };
    }
}

export const elements = {
    LogoGrid
};
