import * as TextList from "./elements/Lists/TextList";
import * as IconsList from "./elements/Lists/IconsList";
import * as Carousel from "./elements/Lists/Carousel";
import * as TextSlide from "./elements/TextSlide";
import * as Timeline from "./elements/Timeline";
import * as UserTestimonial from "./elements/UserTestimonial";
import * as VennDiagram from "./elements/VennDiagram";
import * as Video from "./elements/Video";
import * as WordCloudElement from "./elements/WordCloud";
import * as XYGraph from "./elements/Diagrams/XYGraph";
import * as Thermometer from "./elements/Thermometer";
import * as PPTSlide from "./elements/PPTSlide";
import * as MediaBlock from "./elements/MediaBlock";
import * as LogoGrid from "./elements/LogoGrid";
import * as OrgChart from "./elements/OrgChart";
import * as PhotoCollage from "./elements/PhotoCollage";
import * as PhotoTextList from "./elements/Lists/PhotoTextList";
import * as PictorialCharts from "./elements/PictorialChart";
import * as PresentationTitle from "./elements/PresentationTitle";
import * as ProcessDiagram from "./elements/ProcessDiagram";
import * as ProductScreenshot from "./elements/ProductScreenshot";
import * as Pyramid from "./elements/Pyramid";
import * as SliceChart from "./elements/SliceChart";
import * as SlidePlaceholder from "./elements/SlidePlaceholder";
import * as StackDiagram from "./elements/StackDiagram";
import * as SwotDiagram from "./elements/SwotDiagram";
import * as Table from "./elements/Table/Table";
import * as TableV1 from "./elements/Table/previous/Table.v1";
import * as Team from "./elements/Team";
import * as TextBoxGrid from "./elements/TextBoxGrid";
import * as TextGrid from "./elements/Lists/previous/TextGrid";
import * as Decoration from "./base/DecorationElement";
import * as Header from "./elements/Header";
import * as Cards from "./elements/Cards";
import * as Authoring from "./elements/AuthoringCanvas";
import * as Countdown from "./elements/Countdown";
import * as Timer from "./elements/Timer";
import * as Agenda from "./elements/Agenda";
import * as BigNumbers from "./elements/BigNumbers";
import * as WebView from "./elements/WebView";
import * as Calendar from "./elements/Calendar";
import * as VerticalTaskList from "./elements/VerticalTaskList";
import * as HorizontalTaskList from "./elements/HorizontalTaskList";
import * as ArrowBars from "./elements/ArrowBars";
import * as RadialBarChart from "./elements/RadialBarChart";
import * as Target from "./elements/Target";
import * as SimpleGanttChart from "./elements/GanttChart";
import * as Compare from "./elements/compare";
import * as ContactInfo from "./elements/ContactInfo";
import * as Dashboard from "./elements/Dashboard";
import * as DebugBox from "./elements/DebugBox";
import * as Embed from "./elements/Embed";
import * as Headline from "./elements/Headline";
import * as HubAndSpoke from "./elements/Diagrams/HubAndSpoke";
import * as IconCircle from "./elements/Diagrams/IconCircle";
import * as Journey from "./elements/Journey";
import * as NodeDiagram from "./elements/NodeDiagram";

class ElementManager {
    constructor() {
        this.constructors = {};

        this.register(Decoration.elements);
        this.register(Header.elements);
        this.register(Cards.elements);
        this.register(Authoring.elements);
        this.register(Countdown.elements);
        this.register(Timer.elements);
        this.register(Agenda.elements);
        this.register(BigNumbers.elements);
        this.register(WebView.elements);
        this.register(Calendar.elements);
        this.register(VerticalTaskList.elements);
        this.register(HorizontalTaskList.elements);
        this.register(ArrowBars.elements);
        this.register(RadialBarChart.elements);
        this.register(Target.elements);
        this.register(SimpleGanttChart.elements);
        this.register(Compare.elements);
        this.register(ContactInfo.elements);
        this.register(Dashboard.elements);
        this.register(DebugBox.elements);
        this.register(Embed.elements);
        this.register(Headline.elements);
        this.register(HubAndSpoke.elements);
        this.register(IconCircle.elements);
        this.register(Journey.elements);
        this.register(NodeDiagram.elements);
        this.register(LogoGrid.elements);
        this.register(OrgChart.elements);
        this.register(PhotoCollage.elements);
        this.register(PhotoTextList.elements);
        this.register(PictorialCharts.elements);
        this.register(PresentationTitle.elements);
        this.register(ProcessDiagram.elements);
        this.register(ProductScreenshot.elements);
        this.register(Pyramid.elements);
        this.register(SliceChart.elements);
        this.register(SlidePlaceholder.elements);
        this.register(StackDiagram.elements);
        this.register(SwotDiagram.elements);
        this.register(Table.elements);
        this.register(TableV1.elements);
        this.register(Team.elements);
        this.register(TextBoxGrid.elements);
        this.register(TextGrid.elements);
        this.register(TextList.elements);
        this.register(IconsList.elements);
        this.register(Carousel.elements);
        this.register(TextSlide.elements);
        this.register(Timeline.elements);
        this.register(UserTestimonial.elements);
        this.register(VennDiagram.elements);
        this.register(Video.elements);
        this.register(WordCloudElement.elements);
        this.register(XYGraph.elements);
        this.register(Thermometer.elements);
        this.register(PPTSlide.elements);
        this.register(MediaBlock.elements);
    }

    register(moduleElements) {
        if (moduleElements == undefined) {
            throw new Error("Module not initialized, possible circular dependency?");
        }

        for (const className in moduleElements) {
            if (className == undefined) {
                throw new Error("Cannot register editor");
            }

            this.constructors[className] = moduleElements[className];
        }
    }

    get(name) {
        return this.constructors[name];
    }

    has(name) {
        return name in this.constructors;
    }
}

export default new ElementManager();
