import * as geom from "js/core/utilities/geom";
import { BlockStructureType, TextStyleType } from "legacy-common/constants";

import { BaseElement } from "../base/BaseElement";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/Text/TextElement";
import { ContentElement } from "../base/ContentElement";
import { FramedMediaElement } from "../base/MediaElements/FramedMediaElement";
import { StreetMap } from "./infographics/StreetMap";

class ContactInfo extends BaseElement {
    getCanvasMargins() {
        if (this.canvas.layouter.showFooter) {
            return { left: 0, top: 0, right: 0, bottom: 10 };
        } else {
            return { left: 0, top: 0, right: 0, bottom: 0 };
        }
    }

    get layout() {
        return this.model.layout || "left_map";
    }

    _build() {
        switch (this.layout) {
            case "left_map":
            case "right_map":
                this.map = this.addElement("map", () => StreetMap);
                break;
            case "left_picture":
            case "top_picture":
            case "right_picture":
                this.picture = this.addElement("picture", () => ContentElement);
                break;
        }
        this.list = this.addElement("list", () => ContactInfoItemList);
    }

    _migrate_10() {
        if (this.model.map_address) {
            this.model.map_address.blocks = [{
                html: this.model.map_address.text,
                hyphenation: false,
                id: "map_address",
                ligatures: false,
                textStyle: "title",
                type: "text"
            }];
            delete this.model.map_address.text;
        }
    }
    // endregion

    _calcProps(props, options) {
        let { size } = props;
        let listBounds;

        let mapProps, pictureProps;

        switch (this.layout) {
            case "none":
                listBounds = new geom.Rect(0, 0, size);
                break;
            case "left_map":
                mapProps = this.map.calcProps(new geom.Size(size.width / 2, size.height));
                mapProps.bounds = new geom.Rect(0, 0, size.width / 2, size.height);
                listBounds = new geom.Rect(size.width / 2, 0, size.width / 2, size.height);
                break;
            case "right_map":
                mapProps = this.map.calcProps(new geom.Size(size.width / 2, size.height));
                mapProps.bounds = new geom.Rect(size.width / 2, 0, size.width / 2, size.height);
                listBounds = new geom.Rect(0, 0, size.width / 2, size.height);
                break;
            case "left_picture":
                pictureProps = this.picture.calcProps(new geom.Size(size.width / 2, size.height));
                pictureProps.bounds = new geom.Rect(0, 0, size.width / 2, size.height);
                listBounds = new geom.Rect(size.width / 2, 0, size.width / 2, size.height);
                break;
            case "top_picture":
                pictureProps = this.picture.calcProps(new geom.Size(size.width, size.height / 2));
                pictureProps.bounds = new geom.Rect(0, 0, size.width, size.height / 2);
                listBounds = new geom.Rect(0, size.height / 2, size.width, size.height / 2);
                break;
            case "right_picture":
                pictureProps = this.picture.calcProps(new geom.Size(size.width / 2, size.height));
                pictureProps.bounds = new geom.Rect(size.width / 2, 0, size.width / 2, size.height);
                listBounds = new geom.Rect(0, 0, size.width / 2, size.height);
                break;
        }

        let listProps = this.list.calcProps(listBounds.size);
        listProps.bounds = listBounds;

        return { size };
    }

    _exportToSharedModel() {
        const assets = this.picture?._exportToSharedModel() || [];
        const contentItems = this.list._exportToSharedModel();
        return { ...contentItems, assets: [...assets, ...contentItems.assets] };
    }

    _importFromSharedModel(model) {
        return this.canvas.slideTemplate.defaultData.primary;
    }
}

class ContactInfoItemList extends CollectionElement {
    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get canRefreshElement() {
        return true;
    }

    get canSelect() {
        return false;
    }

    getChildItemType(model) {
        switch (model.type) {
            case "contact_header":
                return ContactItemHeader;
            case "address":
                return ContactItemAddress;
            case "text":
                return ContactItemText;
            default:
                return ContactItemIcon;
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.calcColumnLayout();
        layouter.alignVertically();

        return { size };
    }

    _exportToSharedModel() {
        return this.itemElements.reduce((model, itemElement) => ({
            assets: [
                ...(model.assets || []),
                ...(itemElement.icon?._exportToSharedModel().assets || [])
            ],
            textContent: [
                ...(model.textContent || []), ...(itemElement.text?._exportToSharedModel().textContent || [])
            ]
        }), {});
    }
}

class ContactItem extends CollectionItemElement {
    get minHeight() {
        return 50;
    }

    get selectionPadding() {
        return 10;
    }
}

class ContactItemHeader extends ContactItem {
    get minHeight() {
        return this.text.minHeight;
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.SINGLE_BLOCK,
            autoHeight: true,
            scaleTextToFit: true
        });
    }
}

class ContactItemAddress extends ContactItem {
    _build() {
        this.text = this.addElement("map_address", () => TextElement, {
            blockStructure: BlockStructureType.SINGLE_BLOCK,
            singleLine: true,
            autoHeight: true,
            selection: "ContactItemAddressTextSelection",
            model: this.parentElement.model // share the map_address from the ContactInfo model with the map
        });
    }
}

class ContactItemText extends ContactItem {
    _build() {
        this.text = this.addElement("text", () => TextElement, {
            autoHeight: true
        });
    }
}

class ContactItemIcon extends ContactItem {
    get minHeight() {
        return this.styles.icon.height;
    }
    _build() {
        let icon;
        let placeholderPrompt;
        switch (this.model.type) {
            case "phone":
                icon = "phone-call";
                placeholderPrompt = "Type phone";
                break;
            case "email":
                icon = "symbol-at";
                placeholderPrompt = "Type email";
                break;
            case "fax":
                icon = "printer";
                placeholderPrompt = "Type fax";
                break;
            case "website":
                icon = "internet";
                placeholderPrompt = "Type website";
                break;
            case "social":
                icon = this.model.social_type;
                placeholderPrompt = "Type " + icon;
                break;
        }

        if (this.model.content_value == undefined || this.model.content_type == undefined) {
            this.model.content_type = "icon";
            this.model.content_value = icon;
        }

        this.icon = this.addElement("icon", () => FramedMediaElement, {
            canDoubleClickToEdit: false,
            canSelect: false
        });

        this.text = this.addElement("text", () => TextElement, {
            placeholder: placeholderPrompt,
            singleLine: true,
            autoHeight: true
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, [this.icon, this.text], size);
        layouter.distributeHorizontally({ verticalAlign: layouter.VerticalAlignType.MIDDLE });

        return { size: layouter.size };
    }
}

export const elements = {
    ContactInfo
};
