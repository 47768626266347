import React, { Component } from "react";
import styled from "styled-components";
import { getSVGStyleProps, SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import { Shape } from "js/core/utilities/shapes";
import * as geom from "js/core/utilities/geom";
import { sanitizeSvg } from "js/core/utilities/dompurify";

const DecorationFrame = styled.div.attrs(({ bounds }) => ({
    style: { ...bounds.toObject() }
}))`
  position: absolute;
`;

export class BlockDecoration extends Component {
    render() {
        const { bounds, styles } = this.props;

        if (!styles) return null;

        let decoration;
        if (styles.type == "svg") {
            decoration = this.renderSVG();
        } else if (styles.type == "shape") {
            switch (styles.shape) {
                case "rect":
                    decoration = this.renderRect();
                    break;
                case "circle":
                    decoration = this.renderCircle();
                    break;
                case "octagon":
                    decoration = this.renderOctagon();
                    break;
                case "pill":
                    decoration = this.renderPill();
                    break;
            }
        }

        return (
            <DecorationFrame bounds={bounds}>
                {decoration}
            </DecorationFrame>
        );
    }

    getDecorationBounds() {
        const { styles, bounds, textBounds } = this.props;

        let decorationBounds = textBounds.clone();
        decorationBounds.height = bounds.height;
        decorationBounds = decorationBounds.zeroOffset().inflate({ left: styles.paddingLeft ?? 0, top: styles.paddingTop ?? 0, bottom: styles.paddingBottom ?? 0, right: styles.paddingRight ?? 0 });

        return decorationBounds;
    }

    getSVGStyles() {
        const { styles, color } = this.props;

        let svgStyles = {
            fill: styles.resolved_fillColor?.toRgbString() ?? "none",
            stroke: styles.resolved_strokeColor?.toRgbString() ?? "none",
            strokeWidth: styles.strokeWidth ?? 0,
            cornerRadius: styles.cornerRadius ?? 0
        };

        return svgStyles;
    }

    renderRect() {
        let bounds = this.getDecorationBounds();

        return (
            <SVGGroup>
                <path d={Shape.drawRect(bounds, this.props.styles.cornerRadius ?? 0)} style={this.getSVGStyles()}></path>
            </SVGGroup>
        );
    }

    renderPill() {
        let bounds = this.getDecorationBounds();

        return (
            <SVGGroup>
                <path d={Shape.drawRect(bounds, bounds.height / 2)} style={this.getSVGStyles()}></path>
            </SVGGroup>
        );
    }

    renderCircle() {
        let bounds = this.getDecorationBounds().square();

        return (
            <SVGGroup>
                <circle cx={bounds.centerH} cy={bounds.centerV} r={bounds.width / 2} style={this.getSVGStyles()} />
            </SVGGroup>
        );
    }

    renderOctagon() {
        let bounds = this.getDecorationBounds().square();

        let polygon = Shape.drawOctagon(bounds.width).offset(new geom.Rect(0, 0, bounds.size).centerInRect(bounds).position).toPolygonData();

        return (
            <SVGGroup>
                <polygon
                    points={polygon}
                    style={this.getSVGStyles()}
                />
            </SVGGroup>
        );
    }

    renderSVG() {
        const { styles, bounds, textBounds, element, color, textStyles, textAlign } = this.props;

        const canvas = element.canvas;

        let svgDef = styles.def;
        svgDef = svgDef.replace(/(\$.*?)(?=[ }'"])/gm, match => {
            return canvas.styleSheet.variables[match.substr(1)];
        });

        // replace palette colors in svg definition
        if (svgDef.contains("decorationColor")) {
            svgDef = svgDef.replace(/decorationColor/g, "slide");
        }
        if (color) {
            svgDef = svgDef.replace(/slide/g, color);
        }

        svgDef = svgDef.replace(/fill='(.*?)'|stroke='(.*?)'/g, (str, fillColor, strokeColor) => {
            if (fillColor && !fillColor.startsWith("url")) {
                str = str.replace(fillColor, canvas.getTheme().palette.getForeColor(fillColor, element.getSlideColor(), element.getBackgroundColor()).toRgbString());
            }
            if (strokeColor) {
                str = str.replace(strokeColor, canvas.getTheme().palette.getForeColor(strokeColor, element.getSlideColor(), element.getBackgroundColor()).toRgbString());
            }
            return str;
        });

        let decorationBounds = textBounds;

        if (textAlign === "left") {
            decorationBounds = decorationBounds.deflate({ left: textStyles.blockInset ?? 0 });
        } else if (textAlign === "right") {
            decorationBounds = decorationBounds.deflate({ right: textStyles.blockInset ?? 0 });
        }

        const decorationSVG = {
            __html: sanitizeSvg(this.parseSVGForDecoration(svgDef, decorationBounds, styles, element))
        };

        return (
            <SVGGroup key={123}>
                <g dangerouslySetInnerHTML={decorationSVG} style={{ transition: "fill 300ms" }}></g>
            </SVGGroup>
        );
    }

    parseSVGForDecoration(def, bounds, styles, parentElement) {
        let parentProps = parentElement.calculatedProps;
        let paddedBounds = bounds.deflate(styles.margins || 0);

        const svg = def.replace(/#{(.*?)}/g, (match, value) => {
            let expression = "";
            for (let component of value.split(" ")) {
                if (component.startsWith("getBlock")) {
                    let textStyle = component.match(/\((.*?)\)/)[1];
                    let matchedBlocks = parentProps.blockProps.filter(b => b.textStyle == textStyle);

                    if (matchedBlocks.length) {
                        let startIndex = matchedBlocks[0].index;
                        let bounds;
                        for (let block of matchedBlocks) {
                            if (matchedBlocks.indexOf(block) == 0 || block.index == startIndex + matchedBlocks.indexOf(block)) {
                                bounds = bounds ? bounds.union(block.textBounds) : block.textBounds;

                                //special-case for block decoration in header
                                if (styles.forceBackgroundColor) {
                                    // block.forceBackgroundColor = styles.forceBackgroundColor;
                                    block.forceBackgroundColor = this.canvas.getTheme().palette.getForeColor(styles.forceBackgroundColor, this.getSlideColor(), this.getBackgroundColor());
                                }
                            } else {
                                break;
                            }
                        }
                        expression += bounds.offset(parentElement.loadedStyles.paddingLeft, parentElement.loadedStyles.paddingTop)[component.split(".")[1]];
                    }
                } else {
                    switch (component) {
                        case "tw":
                            expression += parentProps.textBounds.width;
                            break;
                        case "th":
                            // expression += parentProps.textBounds.height;
                            expression += parentProps.blockProps[0].fontHeight;
                            break;
                        case "tl":
                            expression += bounds.left;
                            break;
                        case "tr":
                            expression += bounds.right;
                            break;
                        case "tc":
                            expression += bounds.centerH;
                            break;
                        case "tm":
                            expression += bounds.centerV;
                            break;
                        case "tt":
                            expression += bounds.top - parentProps.blockProps[0].topSpace;
                            break;
                        case "tb":
                            expression += bounds.bottom;
                            break;
                        case "ct":
                            expression += parentProps.bounds.width / 2 - parentProps.textBounds.width / 2;
                            break;
                        case "first_char_width":
                            // expression += parentProps.textLayout.lines[0].words[0].glyphWidths[0] * 2;
                            break;
                        case "first_char_left":
                            // expression += parentProps.textLayout.lines[0].words[0].x;
                            break;
                        case "first_char_top":
                            // expression += parentProps.textLayout.lines[0].words[0].y;
                            break;
                        case "last_char_right":
                            // expression += _.last(_.last(parentProps.textLayout.lines).words).x + _.last(_.last(parentProps.textLayout.lines).words).width;
                            break;
                        case "last_char_top":
                            // expression += _.last(_.last(parentProps.textLayout.lines).words).y;
                            break;
                        case "marginBottom":
                            expression += parentProps.styles.marginBottom || 0;
                            break;
                        default:
                            if (component.indexOf("w") > -1) {
                                expression += paddedBounds.width * (parseInt(component) / 100);
                            } else if (component.indexOf("h") > -1) {
                                expression += paddedBounds.height * (parseInt(component) / 100);
                            } else {
                                expression += component;
                            }
                    }
                }
                expression += " ";
            }

            return eval(expression);
        });
        if (svg == "") {
            return "<g/>";
        } else {
            return svg;
        }
    }
}

