import { AuthoringBlockType, BlockStructureType, HorizontalAlignType, TextStyleType } from "legacy-common/constants";

import { BaseElement } from "../base/BaseElement";
import { TextElement } from "../base/Text/TextElement";
import * as geom from "js/core/utilities/geom";
import { TextFrameBox } from "../base/Text/TextFrame";
import { _ } from "legacy-js/vendor";

class TextSlide extends BaseElement {
    static get schema() {
        return {
            textAlign: HorizontalAlignType.LEFT
        };
    }

    get colCount() {
        return this.model.columns || 1;
    }

    _loadStyles(styles) {
        _.merge(styles, this.canvas.styleSheet.TextFrameBox);
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            bindTo: "text",
            blockStructure: BlockStructureType.FREEFORM,
            defaultBlockTextStyle: TextStyleType.BODY,
            columns: this.colCount,
            allowColumns: true,
            positionWidgetBarWithTextBlock: true,
            scaleTextToFit: true,
            allowAlignment: true,
            canAddBlocks: true,
            createSubBulletOnEnter: true,
            allowedBlockTypes: [
                TextStyleType.HEADING,
                TextStyleType.TITLE,
                TextStyleType.BODY,
                TextStyleType.BULLET_LIST,
                TextStyleType.CAPTION,
                TextStyleType.LABEL,
                AuthoringBlockType.MEDIA,
                AuthoringBlockType.DIVIDER,
                AuthoringBlockType.CODE,
                AuthoringBlockType.EQUATION,
            ]
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let textProps = this.text.calcProps(size);
        textProps.bounds = new geom.Rect(0, 0, size);

        return { size };
    }

    _migrate_10() {
        TextFrameBox.migrateToV10(this);
        delete this.model.blocks;
    }

    _exportToSharedModel() {
        return this.text._exportToSharedModel(true);
    }

    _importFromSharedModel(model) {
        const { textContent } = model;
        if (!textContent?.length) return;

        const textContentMerged = textContent.reduce((acc, { mainText, secondaryTexts }) => ({
            mainText: acc.mainText ? acc.mainText : mainText,
            secondaryTexts: [
                ...(acc.secondaryTexts || []), ...(acc.mainText ? [mainText] : []), ...secondaryTexts
            ]
        }), {});
        return this.text._importFromSharedModel({ textContent: [textContentMerged] });
    }
}

export const elements = {
    TextSlide,
};
