import { controls } from "legacy-js/editor/ui";

import { ElementSelection } from "../BaseElementEditor";

const SwotDiagramSelection = ElementSelection.extend({
});

const SwotDiagramItemSelection = ElementSelection.extend({

    getOffet() {
        return 0;
    },

    renderControls() {
        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            label: "Color",
            property: "color",
            showAuto: true
        });
    }

});

export const editors = {
    SwotDiagramSelection,
    SwotDiagramItemSelection
};
