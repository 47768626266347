import React, { Component } from "react";
import { MenuItem, Slider } from "@material-ui/core";

import { PopupMenu, PopupMenuPaddedContainer } from "legacy-js/react/components/PopupMenu";

import { ControlBar, ControlBarGroup } from "../../../EditorComponents/ControlBar";
import { BoundsBox, ReverseScaleBox } from "../../SelectionBox";
import { BlockTypePopupMenu } from "./TextFormatBar";
import { app } from "js/namespaces";
import { $ } from "legacy-js/vendor";
import { ds } from "js/core/models/dataService";
import { themeColors } from "legacy-js/react/sharedStyles";
import { Gap10, Gap20 } from "legacy-js/react/components/Gap";

export class DividerBlockEditor extends Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.state = {
            style: props.block.model.style,
            dividerWidth: props.block.model.dividerWidth,
        };
    }

    componentDidMount() {
        // Remove current selection
        window.getSelection().removeAllRanges();
    }

    handleChangeStyle = value => {
        const { block, refreshCanvasAndSaveChanges } = this.props;

        this.setState({ style: value });
        block.model.style = value;
        refreshCanvasAndSaveChanges();
    }

    handleChangeWidth = value => {
        const { block, refreshCanvasAndSaveChanges } = this.props;

        this.setState({ dividerWidth: value });
        block.model.dividerWidth = value;
        refreshCanvasAndSaveChanges();
    }

    render() {
        const { bounds, block, editorConfig, refreshCanvasAndSaveChanges } = this.props;
        const { height } = this.state;

        return (
            <BoundsBox bounds={bounds}>
                <ControlBar position="above" offset={10}>
                    <ControlBarGroup color={themeColors.darkBlue}>
                        <BlockTypePopupMenu
                            selectedBlocks={[block]}
                            allowedBlockTypes={editorConfig.allowedBlockTypes}
                            onChange={refreshCanvasAndSaveChanges}
                        />
                    </ControlBarGroup>
                    <ControlBarGroup>
                        <PopupMenu label="Style" showArrow onChange={this.handleChangeStyle} childrenAreMenuItems>
                            <MenuItem value="spacer">Spacer</MenuItem>
                            <MenuItem value="line">Line</MenuItem>
                            <MenuItem value="dashed">Dashed</MenuItem>
                            <MenuItem value="dotted">Dotted</MenuItem>
                            <MenuItem value="fancy">Fancy</MenuItem>
                        </PopupMenu>
                        <PopupMenu label="Width" showArrow onChange={this.handleChangeWidth} childrenAreMenuItems>
                            <MenuItem value="full">Full</MenuItem>
                            <MenuItem value="half">Half</MenuItem>
                            <MenuItem value="short">Short</MenuItem>
                        </PopupMenu>
                    </ControlBarGroup>
                </ControlBar>
            </BoundsBox>
        );
    }
}
