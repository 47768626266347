import { app } from "js/namespaces.js";
import { controls } from "legacy-js/editor/ui";
import * as geom from "js/core/utilities/geom";
import { TrayType, TrayElementType } from "legacy-common/constants";
import { getStaticUrl } from "legacy-js/config";
import { _, $ } from "legacy-js/vendor";
import { ds } from "js/core/models/dataService";
import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "legacy-js/react/views/AddAssets";

import { CreatePhotoCollageControls } from "./PhotoCollageEditor";
import { ElementDefaultOverlay, ElementSelection } from "../BaseElementEditor";

const TrayContainerDefaultOverlay = ElementDefaultOverlay.extend({
    className: "default_overlay trayContainerDefaultOverlay showFrame",

    render: function() {
        let availableElementTypes = this.element.canvas.slideTemplate.availableTrayElements;

        let $options = this.$el.addEl($.div("options"));

        if (availableElementTypes.length > 1) {
            $options.addEl($.div("label", "Add Content"));
        }

        availableElementTypes.contains(TrayElementType.IMAGE) && $options.append(controls.createButton(this, {
            icon: "add_a_photo",
            className: "large_icon",
            label: "Images",
            callback: () => {
                ShowDialog(AddAssetsContainer, {
                    workspaceId: ds.selection.presentation.getWorkspaceId(),
                    callback: assetModel => {
                        this.element.model.aspectRatio = "fill";
                        this.element.model.frame = "none";
                        this.element.model.showShadow = false;
                        this.element.model.showGutter = false;
                        this.element.model.trayElement = TrayElementType.IMAGE;
                        this.element.model.items = [assetModel];
                        this.element.canvas.updateCanvasModel(false);
                    },
                });
            }
        }));

        availableElementTypes.contains(TrayElementType.TEXT) && $options.append(controls.createButton(this, {
            icon: "text_fields",
            className: "large_icon",
            label: "Text",
            callback: () => {
                this.element.model.trayElement = TrayElementType.TEXT;
                this.element.model.trayColor = "theme";
                this.element.model.tray = {
                    textStyle: "headline",
                };
                this.element.canvas.updateCanvasModel(false);
            }
        }));

        availableElementTypes.contains(TrayElementType.BULLET_LIST) && $options.append(controls.createButton(this, {
            icon: "format_list_bulleted",
            className: "large_icon",
            label: "List",
            callback: () => {
                this.element.model.trayElement = TrayElementType.TEXT;
                this.element.model.trayColor = "theme";
                this.element.model.textStyle = "bullet_list";
                this.element.model.listStyle = "bullets";
                this.element.canvas.updateCanvasModel(false);
            }
        }));

        return this;
    }
});

const TrayContainerSelection = ElementSelection.extend({
    showSelectionBox: true,
    captureMouseEvents: false,

    getOffset: function() {
        return "canvas";
    },

    renderControls: function() {
        this.renderTrayImageControls();
        this.renderTrayResizer();
    },

    renderTrayResizer: function() {
        // fit in tray is not resizable
        // if (this.element.isTray && this.element.model.aspectRatio == "fit"){
        //     return;
        // }

        this.$trayResizer = this.$widgets.addEl($.div("ui_widget"));
        this.$trayResizer.append($.div("tray_resizer"));

        let constrainDrag;

        // calculate the maxWidth by directly asking the trayElement - if we ask the tray, it will use the current trayWidth
        let maxTrayWidth = this.element.canvas.layouter.canvasElement.maxTrayWidth;
        let minTrayWidth = this.element.canvas.layouter.canvasElement.minTrayWidth;
        let maxTrayHeight = this.element.canvas.layouter.canvasElement.maxTrayHeight;
        let minTrayHeight = this.element.canvas.layouter.canvasElement.minTrayHeight;

        // if this is an inline image tray with a fit aspect ratio, limit trayWidth to fit the image
        if (this.element.isInline && this.element.trayElementType == TrayElementType.IMAGE) {
            //TODO
            // const size = this.element.trayElement.calculatedSize;
            // if (!this.element.fitSize || this.element.canvasBounds.height !== this.element.fitSizeHeight) {
            //     // store fitSize on element to keep state, skip these element calcs if inputs are the same
            //     this.element.fitSize = this.element.trayElement.calcSize(new geom.Size(1280, this.element.canvasBounds.height));
            //     this.element.fitSizeHeight = this.element.canvasBounds.height;
            //     //revert back
            //     this.element.trayElement.calcSize(size);
            // }
            // maxTrayWidth = Math.min(maxTrayWidth, this.element.fitSize.width);
        }

        let dragHandleOffset = 10;
        let direction;

        switch (this.element.trayLayout) {
            case TrayType.LEFT_TRAY:
            case TrayType.LEFT_INLINE:
                this.$trayResizer.right(10);
                constrainDrag = new geom.Rect(this.element.canvasBounds.left + minTrayWidth, 0, maxTrayWidth - minTrayWidth - dragHandleOffset, this.element.canvas.CANVAS_HEIGHT);
                direction = "horizontal";
                break;
            case TrayType.RIGHT_INLINE:
            case TrayType.RIGHT_TRAY:
                this.$trayResizer.left(-10);
                constrainDrag = new geom.Rect(this.element.canvasBounds.right - maxTrayWidth - dragHandleOffset, 0, maxTrayWidth - minTrayWidth, this.element.canvas.CANVAS_HEIGHT);
                direction = "horizontal";
                break;
        }

        let refresh = value => {
            if (direction == "vertical") {
                this.element.model.trayHeight = value;
            } else {
                this.element.model.trayWidth = value;
            }

            this.element.canvas.refreshCanvas()
                .then(() => {
                    this.lastTrayWidth = value;
                }).catch(err => {
                    this.element.model.trayWidth = this.lastTrayWidth;
                    this.element.canvas.refreshCanvasAutoRevert();
                });
        };

        this.$trayResizer.makeDraggable({
            axis: direction == "vertical" ? "y" : "x",
            constrainDrag: constrainDrag,
            start: event => {
                app.isTrayDragging = true;
                this.selectionLayer.hideWidgets(this.$trayResizer);
            },
            drag: (event, position) => {
                if (this.element.canvas.layouter.isGenerating) {
                    return;
                }

                let traySize;
                switch (this.element.trayLayout) {
                    case TrayType.LEFT_TRAY:
                    case TrayType.LEFT_INLINE:
                        traySize = position.canvasPosition.x + dragHandleOffset - this.element.canvasBounds.left;
                        break;
                    case TrayType.RIGHT_TRAY:
                    case TrayType.RIGHT_INLINE:
                        traySize = this.element.canvasBounds.right - position.canvasPosition.x - dragHandleOffset;
                        break;
                }
                refresh(traySize);
            },
            stop: event => {
                event.stopPropagation();
                app.isTrayDragging = false;
                if (this.element.canvas.layouter.canvasElement.elements.primary.updatesOwnModelAfterResize) {
                    this.element.canvas.refreshCanvas();
                } else {
                    this.element.canvas.updateCanvasModel(false).then(() => {
                        this.selectionLayer.showWidgets();
                    });
                }
            }
        });
    },

    _layout: function(bounds) {
        this.$trayResizer.top(bounds.height / 2 - 10);
    },

    renderTrayImageControls: function() {
        if (this.element.trayElement.model.scale > 1.5) {
            this.showWarning("Warning", "Image is scaled beyond 1.5X and may appear pixelated");
        }

        CreatePhotoCollageControls(this, this.element.trayElement, {
            addItemLabel: "Add Cell"
        });
    },

});

export const editors = {
    TrayContainerDefaultOverlay,
    TrayContainerSelection,
};
