import { SlideCanvas } from "./canvas/canvas";
import * as slideTemplates from "./slideTemplates/slideTemplates";
import { SelectionLayer } from "./selectionLayer/selectionLayer";
import elementManager from "./elements/elementManager";

// Ensure imports
import "./elementUI/editorImports";

export { SlideCanvas, slideTemplates, SelectionLayer, elementManager };

// DEV-START
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(err => {
        // eslint-disable-next-line no-console
        console.error(err);
    });
}
// DEV-END
