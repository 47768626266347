import * as geom from "js/core/utilities/geom";
import { VerticalAlignType, HorizontalAlignType, VerticalBlockAlignType } from "legacy-common/constants";
import { _ } from "legacy-js/vendor";

export function calcVerticalBlockLayout(options) {
    options = _.defaults(options, {
        horizontalAlign: HorizontalAlignType.CENTER,
        reverse: false,
    });

    let content = this.items[0];
    let text = this.items[1];

    let contentProps = content.calcProps(options.contentSize || this.containerSize, options.itemOptions);
    let contentSize = contentProps.size;

    let textProps = text.calcProps(new geom.Size(this.containerSize.width, this.containerSize.height - contentSize.height), options.itemOptions);
    let textSize = textProps.size;

    if (options.reverse) {
        textProps.bounds = new geom.Rect(0, 0, textSize);
        contentProps.bounds = new geom.Rect(0, textSize.height, contentSize);
    } else {
        contentProps.bounds = new geom.Rect(0, 0, contentSize);
        textProps.bounds = new geom.Rect(0, contentSize.height, textSize);
    }

    let maxWidth = Math.max(contentSize.width, textSize.width);

    switch (options.horizontalAlign) {
        case HorizontalAlignType.LEFT:
            contentProps.bounds.left = 0;
            textProps.bounds.left = 0;
            break;
        case HorizontalAlignType.CENTER:
            contentProps.bounds.left = maxWidth / 2 - contentSize.width / 2;
            textProps.bounds.left = maxWidth / 2 - textSize.width / 2;
            break;
        case HorizontalAlignType.RIGHT:
            contentProps.bounds.left = maxWidth - contentSize.width;
            textProps.bounds.left = maxWidth - textSize.width;
            break;
    }

    this.isTextFit = textProps.isTextFit;
    this.size = contentProps.bounds.union(textProps.bounds).size;

    return this;
}

export function calcHorizontalBlockLayout(options) {
    options = _.defaults(options, {
        verticalAlign: VerticalBlockAlignType.TOP,
        reverse: false,
        allowVerticalTextOverflow: false
    });

    this.isFit = true;

    let content = this.items[0];
    let text = this.items[1];

    let contentProps = content.calcProps(options.contentSize || this.containerSize, { ...options.itemOptions, stylesScale: options.stylesScale });
    let contentSize = contentProps.size;

    let textProps = text.calcProps(options.textSize || new geom.Size(this.containerSize.width - contentSize.width, this.containerSize.height), { ...options.itemOptions, stylesScale: options.stylesScale });
    let textSize = textProps.size;

    if (options.reverse) {
        textProps.bounds = new geom.Rect(0, 0, textSize);
        contentProps.bounds = new geom.Rect(textSize.width, 0, contentSize);
    } else {
        contentProps.bounds = new geom.Rect(0, 0, contentSize);
        textProps.bounds = new geom.Rect(contentSize.width, 0, textSize);
    }

    switch (options.verticalAlign) {
        case VerticalBlockAlignType.TOP:
            contentProps.bounds.top = 0;
            textProps.bounds.top = 0;
            break;
        case VerticalBlockAlignType.MIDDLE:
            if (contentSize.height > textSize.height) {
                contentProps.bounds.top = 0;
                textProps.bounds.top = contentProps.bounds.centerV - textSize.height / 2;
            } else {
                textProps.bounds.top = 0;
                contentProps.bounds.top = textProps.bounds.centerV - contentSize.height / 2;
            }
            break;
        case VerticalBlockAlignType.MIDDLE_OR_TOP:
            // middle-align if the text is shorter than the content otherwise top-align
            if (contentSize.height > textSize.height) {
                contentProps.bounds.top = 0;
                textProps.bounds.top = contentProps.bounds.centerV - textSize.height / 2;
            } else {
                // contentProps.bounds.top = textProps.bounds.centerV - contentSize.height / 2;
                contentProps.bounds.top = 0;
                textProps.bounds.top = 0;
            }
            break;
        case VerticalBlockAlignType.MIDDLE_TITLE:
            let firstLineHeight = text.calculatedProps.blockProps[0].fontHeight;
            if (textSize.height < contentSize.height) {
                contentProps.bounds.top = 0;
                textProps.bounds.top = contentSize.height / 2 - firstLineHeight / 2;
            } else {
                textProps.bounds.top = 0;
                contentProps.bounds.top = firstLineHeight / 2 - contentSize.height / 2;
            }
            break;
        case VerticalBlockAlignType.BOTTOM:
            break;
    }

    this.isTextFit = textProps.isTextFit;
    this.size = contentProps.bounds.union(textProps.bounds).size;

    if (this.size.height > this.containerSize.height && !options.allowVerticalTextOverflow) {
        this.isFit = false;
    }

    return this;
}

