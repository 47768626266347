import { isDevelopment } from "legacy-js/config";
import { getTemplates, variations, classicTemplates, chartUtils } from "legacy-common/slideTemplates/v10"; // eslint-disable-line

const slideTemplates = getTemplates(isDevelopment);

export {
    slideTemplates,
    variations,
    classicTemplates,
    chartUtils
};
