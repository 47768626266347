import * as geom from "js/core/utilities/geom";
import { FormatType, VerticalAlignType, HorizontalAlignType, BlockStructureType } from "legacy-common/constants";

import { SVGCircleElement, SVGPolylineElement } from "../../base/SVGElement";
import { layoutHelper } from "../../layouts/LayoutHelper";
import { TextElement } from "../../base/Text/TextElement";

import CompareValuesItem from "./CompareValuesItem";
import CompareValuesItemLabel from "./CompareValuesItemLabel";
import { sanitizeHtml } from "js/core/utilities/dompurify";

export default class CompareBubbleItem extends CompareValuesItem {
    get labelText() {
        return this.parentElement.formatValue(this.currentValue);
    }

    get useValueForSize() {
        return this.parentElement.useValueForSize;
    }

    get size() {
        return this.model.size ?? 1;
    }

    get circleAlign() {
        return this.parentElement.circleAlign;
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGCircleElement);
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            autoHeight: true,
            syncFontSizeWithSiblings: true
        });

        if (this.parentElement.format != FormatType.NONE) {
            this.label = this.addElement("label", () => CompareValuesItemLabel, {
                html: this.labelText,
                autoWidth: true,
                autoHeight: true,
                canEdit: false
            });
            this.connector = this.addElement("connector", () => SVGPolylineElement);
        }
    }

    calculateCircleSize(maxSize, value) {
        return maxSize * Math.sqrt(Math.max(value, 0.25) / Math.PI) / 5.641895835;
    }

    _calcProps(props, options) {
        let { size } = props;

        this.maxTextHeight = options.textHeight;

        let textProps = this.text.calcProps(new geom.Size(size.width, this.maxTextHeight));

        const maxCircleSize = size.height - this.maxTextHeight;

        let circleSize, finalCircleSize;

        if (this.useValueForSize) {
            finalCircleSize = this.calculateCircleSize(Math.min(size.width, maxCircleSize), this.percentageValue * 100);
        } else {
            finalCircleSize = size.width * this.size;
        }
        circleSize = finalCircleSize * this.drawPercentage;

        let shapeProps = this.shape.createProps({
            layer: -1
        });

        if (this.isAnimating) {
            shapeProps.bounds = new geom.Rect(0, finalCircleSize - circleSize, size.width, circleSize);
        } else {
            shapeProps.bounds = new geom.Rect(0, 0, size.width, circleSize);
        }

        let maxCircleHeight = size.width;

        let textY = 0;
        switch (this.circleAlign) {
            case "top":
                maxCircleHeight = size.width;
                textY = maxCircleHeight;
                break;
            case "middle":
                maxCircleHeight = size.width;
                shapeProps.bounds.top = maxCircleHeight / 2 - circleSize / 2;
                textY = maxCircleHeight;
                break;
            case "bottom":
                maxCircleHeight = finalCircleSize;
                textY = finalCircleSize;
                break;
        }

        if (this.label) {
            if (this.isAnimating) {
                this.label.options.html = sanitizeHtml(this.labelText);
            }

            let labelProps = this.label.calcProps(new geom.Size(300, 300));

            let positionLabelInsideCircle = circleSize > labelProps.size.width && circleSize > labelProps.size.height;
            if (this.isAnimating) {
                positionLabelInsideCircle = this.finalLabelPositionIsInside;
            }

            if (positionLabelInsideCircle) {
                // position label within circle
                props.isLabelInside = true;
                labelProps.bounds = new geom.Rect(shapeProps.bounds.centerH - circleSize / 2 + layoutHelper.getHorizontalAlignOffset(labelProps.size.width, circleSize, HorizontalAlignType.CENTER), shapeProps.bounds.top + layoutHelper.getVerticalAlignOffset(labelProps.size.height, shapeProps.bounds.height, VerticalAlignType.MIDDLE), labelProps.size);
            } else {
                // position label outside of circle with connector line
                props.isLabelInside = false;
                labelProps.bounds = new geom.Rect(shapeProps.bounds.centerH + circleSize / 2, shapeProps.bounds.top - labelProps.size.height / 1.3333, labelProps.size);

                let connectorProps = this.connector.createProps({
                    path: [[shapeProps.bounds.centerH + circleSize / 2 - circleSize * .333, shapeProps.bounds.top + circleSize * .333], [shapeProps.bounds.centerH + circleSize / 2 + 10, shapeProps.bounds.top - 10]]
                });
            }

            if (this.hasStoredPropChanged("isLabelInside", props.isLabelInside)) {
                this.markStylesAsDirty();
            }
        }

        textProps.bounds = new geom.Rect(0, textY, textProps.size);

        return { size: new geom.Size(size.width, Math.floor(maxCircleHeight) + this.maxTextHeight) };
    }

    _getBackgroundColor(forElement) {
        if (this.label && forElement === this.label && this.calculatedProps.isLabelInside) {
            return this.getShapeFillColor(this.shape);
        } else {
            return super._getBackgroundColor(forElement);
        }
    }

    get animationElementName() {
        return `Circle #${this.itemIndex + 1}`;
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            animatingElements: [this.parentElement, this],
            prepare: () => {
                this.text.animationState.fadeInProgress = 0;
                this.animationState.fadeInProgress = 0;
                this.animationState.value = 0;
                if (this.label) {
                    this.finalLabelPositionIsInside = this.calculatedProps.isLabelInside;
                    this.label.animationState.fadeInProgress = 0;
                }
            },
            onBeforeAnimationFrame: progress => {
                this.text.animationState.fadeInProgress = Math.clamp((progress - 0.7) / 0.3, 0, 1);
                this.animationState.fadeInProgress = Math.min(1, progress * 3);
                this.animationState.value = progress;
                if (this.label) {
                    this.label.animationState.fadeInProgress = progress;
                }
                return this;
            }
        }];
    }
}
