export const parseWebUrl = url => {
    // Ensure the text starts with '<iframe' if that tag is present
    let match = /<iframe.+$/.exec(url);
    if (match) {
        url = match[0];
    }

    // If the text starts with '<iframe', extract the `src` url
    if (url.startsWith("<iframe")) {
        let match = /src=("|')(.*?)("|')/.exec(url);

        url = match?.[2] || null;
    }

    // Ensure the url starts with 'http' or 'https'
    if (url?.length && !url.startsWith("http")) {
        url = "https://" + url;
    }
    return url;
};
