import CompareValuesCollection from "./CompareValuesCollection";
import CompareHorizontalBarItem from "./CompareHorizontalBarItem";

export default class CompareHorizontalBar extends CompareValuesCollection {
    getChildItemType() {
        return CompareHorizontalBarItem;
    }

    get maxItemCount() {
        return 7;
    }

    getCanvasMargins() {
        return { left: 50, right: 100, top: 50, bottom: 50 };
    }

    _calcProps(props, options) {
        let { size } = props;

        // Calc the max title width
        let maxTitleWidth = 0;
        for (let item of this.itemElements) {
            maxTitleWidth = Math.max(maxTitleWidth, item.text.calcProps(size, { autoWidth: true }).size.width);
        }
        maxTitleWidth = Math.min(size.width * 0.33, maxTitleWidth);

        let barHeight = Math.min(100, size.height / this.itemElements.length - this.styles.vGap);

        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.distributeVertically({
            gap: this.styles.vGap,
            itemOptions: {
                titleWidth: maxTitleWidth,
                barHeight: barHeight
            }
        });
        layouter.alignVertically(this.styles.verticalAlign);

        props.isFit = layouter.isFit;

        return { size: layouter.size };
    }
}
