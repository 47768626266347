import * as geom from "js/core/utilities/geom";

import { TextElement } from "../../base/Text/TextElement";
import { OrgChartNode } from "./OrgChartNode";
import { BlockStructureType, TextStyleType } from "legacy-common/constants";

export class LabelOrgChartNode extends OrgChartNode {
    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            allowedBlockTypes: [TextStyleType.TITLE, TextStyleType.BODY],
            autoHeight: true,
            requireParentSelection: true,
            passThroughSelection: false,
            allowAlignment: true
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const textProps = this.text.calcProps(size);
        textProps.bounds = new geom.Rect(0, size.height / 2 - textProps.size.height / 2, textProps.size);

        return { size: new geom.Size(size.width, textProps.size.height) };
    }
}
