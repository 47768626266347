import React from "reactn";

import { _ } from "legacy-js/vendor";
import { app } from "js/namespaces";
import { getStaticUrl } from "legacy-js/config";
import * as geom from "js/core/utilities/geom";
import { getClipPath, SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import { ShapeType } from "legacy-common/constants";

import { BaseElement } from "./BaseElement";
import { DivDecorationElement } from "./SVGElement";

class Frame extends BaseElement {
    get canSelect() {
        return false;
    }

    get canRollover() {
        return false;
    }

    get clip() {

    }
}

class BoxFrame extends Frame {
    _build() {
        this.frame = this.addElement("frame", () => DivDecorationElement);
    }

    get layer() {
        return -1;
    }

    _calcProps(props, options) {
        const { size, children } = props;

        const frameProps = this.frame.calcProps(size);
        frameProps.bounds = new geom.Rect(0, 0, size);

        switch (this.options.frameStyle) {
            case "light":
                this.frame.updateStyles({
                    strokeColor: "white",
                    shadow: {
                        blur: 14,
                        offsetX: 0,
                        offsetY: 0,
                        opacity: 0.2
                    }
                });
                break;
            case "dark":
            default:
                this.frame.updateStyles({
                    strokeColor: "black"
                });
                break;
        }

        this.frame.updateStyles({ strokeWidth: this.canvas.styleSheet.decorationVariables[this.canvas.styleSheet.BoxFrame.strokeWidth.substr(1)] });
        const clipBounds = new geom.Rect(0, 0, size).deflate(this.frame.styles.strokeWidth);

        return {
            size,
            clipBounds
        };
    }
}

class CircleFrame extends Frame {
    _build() {
        this.frame = this.addElement("frame", () => DivDecorationElement);
    }

    get layer() {
        return -1;
    }

    _calcProps(props, options) {
        const { size } = props;
        const frameProps = this.frame.createProps();
        frameProps.bounds = new geom.Rect(0, 0, size.square()).centerInRect(new geom.Rect(0, 0, size));

        this.frame.styles.borderRadius = "50%";
        this.frame.styles.strokeWidth = this.canvas.styleSheet.decorationVariables[this.canvas.styleSheet.BoxFrame.strokeWidth.substr(1)];

        switch (this.options.frameStyle) {
            case "circle-light":
                this.frame.updateStyles({
                    strokeColor: "white",
                    shadow: {
                        blur: 14,
                        offsetX: 0,
                        offsetY: 0,
                        opacity: 0.2
                    }
                });
                break;
            case "circle-dark":
                this.frame.updateStyles({
                    strokeColor: "black"
                });
                break;
            default:
                this.frame.styles.strokeWidth = 0;
                break;
        }

        const clipBounds = frameProps.bounds;
        const frameClipPath = getClipPath("circle", clipBounds.size, 0, false, this.canvas.getScale());

        return { size, clipBounds, frameClipPath };
    }
}

class ImageFrame extends Frame {
    get hasClipPath() {
        return this.frameDef.clipPath;
    }

    get frameDef() {
        // note: added trim because there were a few device id's with extra spaces that i repaired
        let def = _.find(DeviceFrames, { id: this.model.frame.trim() || "iphone_x" });
        if (!def) {
            def = DeviceFrames.find(device => device.file != "");
        }
        return def;
    }

    get frameURL() {
        return getStaticUrl("/images/frames/" + this.frameDef.file);
    }

    _load() {
        return new Promise(resolve => {
            const image = new Image();
            image.src = this.frameURL;

            image.onload = () => {
                this.imageSize = new geom.Size(image.naturalWidth, image.naturalHeight);
                resolve();
            };
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const scale = geom.fitImageToRect(this.imageSize, size);
        const scaledImageSize = this.imageSize.scale(scale);

        return {
            size: scaledImageSize,
            imageScale: scale,
            clipBounds: new geom.Rect(this.frameDef.clip).multiply(scale),
            customClip: this.frameDef.clipPath,
            clipScale: this.frameDef.clipScale
        };
    }

    renderChildren(transition) {
        let props = this.calculatedProps;
        return <SVGGroup key={this.id}><image href={this.frameURL} style={{ transform: `scale(${props.imageScale})` }} /></SVGGroup>;
    }
}

const DeviceFrames = [{
    id: "none",
    name: "No Frame",
    file: "",
    thumbnail: "frame_none.png",
    category: "Shape",
    fixedAspectRatio: false,
},
{
    id: ShapeType.RECT,
    name: "Rect",
    file: "",
    thumbnail: "Square.svg",
    category: "Shape",
    fixedAspectRatio: false,
},
{
    id: ShapeType.CIRCLE,
    name: "Circle",
    file: "",
    thumbnail: "Circle.svg",
    category: "Shape",
    fixedAspectRatio: true,
},
{
    id: ShapeType.ROUNDED_RECT,
    name: "Rounded Rect",
    file: "",
    thumbnail: "Rounded.svg",
    category: "Shape",
    fixedAspectRatio: false,
},
{
    id: ShapeType.OCTAGON,
    name: "Octagon",
    file: "",
    thumbnail: "Octagon.svg",
    category: "Shape",
    fixedAspectRatio: true,
},
{
    id: "polaroid",
    name: "Polaroid",
    file: "polaroid2.png",
    thumbnail: "polaroid.png",
    category: "Decorative",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 100,
        top: 72,
        width: 635,
        height: 650
    }
},
{
    id: "apple_imac",
    name: "Apple iMac",
    file: "Apple iMac.png",
    thumbnail: "Apple iMac.png",
    category: "Computers",
    fixedAspectRatio: true,
    clip: {
        left: 49,
        top: 52,
        width: 1111,
        height: 623
    }
},
{
    id: "apple_macbook_air_13",
    name: 'Apple Macbook Air 13"',
    file: "Apple Macbook Air 13.png",
    thumbnail: "Apple Macbook Air 13.png",
    category: "Computers",
    fixedAspectRatio: true,
    clip: {
        left: 262,
        top: 80,
        width: 1440,
        height: 900
    },
    isHidden: true
},
{
    id: "apple_macbook_space_grey",
    name: "Apple Macbook",
    file: "Apple-Macbook-Space-Grey.png",
    thumbnail: "Apple-Macbook-Space-Grey.png",
    category: "Computers",
    fixedAspectRatio: true,
    clip: {
        left: 213,
        top: 72,
        width: 1296,
        height: 809
    }
},
{
    id: "dell_xps_15",
    name: 'Dell XPS 15"',
    file: "Dell XPS 15.png",
    thumbnail: "Dell XPS 15.png",
    category: "Computers",
    fixedAspectRatio: true,
    clip: {
        left: 272,
        top: 21.33,
        width: 1438,
        height: 810
    }
},
{
    id: "microsoft_surface_book",
    name: "Microsoft Surface Book",
    file: "Microsoft Surface Book.png",
    thumbnail: "Microsoft Surface Book.png",
    category: "Computers",
    fixedAspectRatio: true,
    clip: {
        left: 233,
        top: 65,
        width: 1252,
        height: 834
    }
},
{
    id: "apple_thunderbolt_display",
    name: "Apple Thunderbolt Display",
    file: "Thunderbolt Display 27.png",
    thumbnail: "Thunderbolt Display 27.png",
    category: "Displays",
    fixedAspectRatio: true,
    clip: {
        left: 52,
        top: 52,
        width: 1205,
        height: 679
    }
},
{
    id: "dell_ultrasharp_hd_5k",
    name: "Dell Ultrasharp HD 5K Display",
    file: "Dell UltraSharp Ultra HD 5K Monitor 27.png",
    thumbnail: "Dell UltraSharp Ultra HD 5K Monitor 27.png",
    category: "Displays",
    fixedAspectRatio: true,
    clip: {
        left: 38.24,
        top: 40,
        width: 1173,
        height: 660
    }
},
{
    id: "sony_w850C",
    name: "Sony W85OC 75",
    file: "Sony W85OC 75.png",
    thumbnail: "Sony W85OC 75.png",
    category: "Displays",
    fixedAspectRatio: true,
    clip: {
        left: 24,
        top: 13,
        width: 1286,
        height: 721
    }
},
{
    id: "iphone_x",
    name: "Apple iPhone X",
    file: "Apple iPhone X Silver.png",
    thumbnail: "Apple iPhone X Silver.png",
    category: "Phones",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 50,
        top: 64,
        width: 404,
        height: 872
    },
    isHidden: true
},
{
    id: "apple_iphone_5c",
    name: "Apple iPhone 5c",
    file: "Apple iPhone 5c.png",
    thumbnail: "Apple iPhone 5c.png",
    category: "Phones",
    fixedAspectRatio: true,
    clip: {
        left: 41,
        top: 147,
        width: 396,
        height: 703
    },
    isHidden: true
},
{
    id: "apple_iphone_7_matte_black",
    name: "Apple iPhone 7 Black",
    file: "Apple iPhone 7 Matte Black.png",
    thumbnail: "Apple iPhone 7 Matte Black.png",
    category: "Phones",
    fixedAspectRatio: true,
    clip: {
        left: 63,
        top: 155,
        width: 388,
        height: 690
    },
    isHidden: true
},
{
    id: "apple_iphone_7_silver",
    name: "Apple iPhone 7 Silver",
    file: "Apple iPhone 7 Silver.png",
    thumbnail: "Apple iPhone 7 Silver.png",
    category: "Phones",
    fixedAspectRatio: true,
    clip: {
        left: 63,
        top: 155,
        width: 388,
        height: 690
    },
    isHidden: true
},
{
    id: "google_pixel",
    name: "Google Pixel",
    file: "Google Pixel.png",
    thumbnail: "Google Pixel.png",
    category: "Phones",
    fixedAspectRatio: true,
    clip: {
        left: 25,
        top: 101.6,
        width: 435,
        height: 774
    },
    isHidden: true
},
{
    id: "samsung_galaxy_s7_black",
    name: "Samsung Galaxy S7 Black",
    file: "Samsung Galaxy S7 Black.png",
    thumbnail: "Samsung Galaxy S7 Black.png",
    category: "Phones",
    fixedAspectRatio: true,
    clip: {
        left: 42,
        top: 118,
        width: 429,
        height: 762
    },
    isHidden: true
},
{
    id: "samsung_galaxy_s7_silver",
    name: "Samsung Galaxy S7 Silver",
    file: "Samsung Galaxy S7 Silver.png",
    thumbnail: "Samsung Galaxy S7 Silver.png",
    category: "Phones",
    fixedAspectRatio: true,
    clip: {
        left: 43,
        top: 118,
        width: 427,
        height: 761
    },
    isHidden: true
},
{
    id: "apple_watch",
    name: "Apple Watch",
    file: "apple watch.png",
    thumbnail: "apple watch.png",
    category: "Other",
    fixedAspectRatio: true,
    clip: {
        left: 76,
        top: 240,
        width: 314,
        height: 390
    },
    isHidden: true
},
{
    id: "ipad_portrait",
    name: "Apple iPad Portrait",
    file: "Apple iPad Air 2 Silver.png",
    thumbnail: "Apple iPad Air 2 Silver.png",
    category: "Other",
    fixedAspectRatio: true,
    clip: {
        left: 60,
        top: 107,
        width: 590,
        height: 785
    },
    isHidden: true
},
{
    id: "ipad_landscape",
    name: "Apple iPad Landscape",
    file: "Apple iPad Air 2 Silver Landscape.png",
    thumbnail: "Apple iPad Air 2 Silver Landscape.png",
    category: "Other",
    fixedAspectRatio: true,
    clip: {
        left: 108,
        top: 66,
        width: 786,
        height: 590
    },
    isHidden: true
},
{
    id: "microsoft_surface_pro_4",
    name: "Microsoft Surface Pro 4",
    file: "Microsoft Surface Pro 4.png",
    thumbnail: "Microsoft Surface Pro 4.png",
    category: "Other",
    fixedAspectRatio: true,
    clip: {
        left: 107,
        top: 89,
        width: 1232,
        height: 822
    },
    isHidden: true
},
{
    id: "cisco_board_pro",
    name: "Webex BoardPro",
    file: "Cisco Board Pro.png",
    thumbnail: "Cisco Board Pro.png",
    category: "Cisco",
    fixedAspectRatio: true,
    clip: {
        left: 128,
        top: 59,
        width: 1179,
        height: 662
    }
},
{
    id: "cisco_board_pro_wall",
    name: "Webex BoardPro Wall",
    file: "Cisco Board Pro Wall Mount.png",
    thumbnail: "Cisco Board Pro Wall Mount.png",
    category: "Cisco",
    fixedAspectRatio: true,
    clip: {
        left: 153,
        top: 110,
        width: 1692,
        height: 952
    },
},
{
    id: "cisco_desk",
    name: "Webex Desk",
    file: "Cisco Desk.png",
    thumbnail: "Cisco Desk.png",
    category: "Cisco",
    fixedAspectRatio: true,
    clip: {
        left: 200,
        top: 130,
        width: 1600,
        height: 900
    }
},
{
    id: "cisco_desk_mini",
    name: "Webex Desk Mini",
    file: "Cisco Desk Mini.png",
    thumbnail: "Cisco Desk Mini.png",
    category: "Cisco",
    fixedAspectRatio: true,
    clip: {
        left: 196,
        top: 84,
        width: 1225,
        height: 693
    }
},
{
    id: "ipad_13_portrait",
    name: "Apple iPad",
    file: "Apple iPad Pro 13 Silver - Portrait.png",
    thumbnail: "Apple iPad Pro 13 Silver - Portrait.png",
    category: "Other",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 63,
        top: 56,
        width: 656,
        height: 881
    }
},
{
    id: "ipad_13_landscape",
    name: "Apple iPad",
    file: "Apple iPad Pro 13 Silver - Landscape.png",
    thumbnail: "Apple iPad Pro 13 Silver - Landscape.png",
    category: "Other",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 81,
        top: 81,
        width: 1117,
        height: 838
    }
},
{
    id: "apple_watch_6",
    name: "Apple Watch",
    file: "Apple Watch 6.png",
    thumbnail: "Apple Watch 6.png",
    category: "Other",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 138,
        top: 259,
        width: 397,
        height: 483
    }
},
{
    id: "google_pixel_5_landscape",
    name: "Google Pixel",
    file: "Google Pixel 5 - Landscape.png",
    thumbnail: "Google Pixel 5 - Landscape.png",
    category: "Phones",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 134,
        top: 135,
        width: 1582,
        height: 730
    }
},
{
    id: "google_pixel_5_portrait",
    name: "Google Pixel",
    file: "Google Pixel 5 - Portrait.png",
    thumbnail: "Google Pixel 5 - Portrait.png",
    category: "Phones",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 70,
        top: 70,
        width: 398,
        height: 860
    }
},
{
    id: "samsung_galaxy_s21_landscape",
    name: "Samsung Galaxy",
    file: "Samsung Galaxy S21 5G - Landscape.png",
    thumbnail: "Samsung Galaxy S21 5G - Landscape.png",
    category: "Phones",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 135,
        top: 135,
        width: 1622,
        height: 730
    }
},
{
    id: "samsung_galaxy_s21_portrait",
    name: "Samsung Galaxy",
    file: "Samsung Galaxy S21 5G - Portrait.png",
    thumbnail: "Samsung Galaxy S21 5G - Portrait.png",
    category: "Phones",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 71,
        top: 71,
        width: 387,
        height: 857
    }
},
{
    id: "microsoft_surface_pro_X",
    name: "Microsoft Surface",
    file: "Surface Pro X - Landscape.png",
    thumbnail: "Surface Pro X - Landscape.png",
    category: "Other",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 84,
        top: 79,
        width: 1262,
        height: 842
    }
},
{
    id: "iphone_14_portrait",
    name: "Apple iPhone",
    file: "iPhone 14 - Midnight.png",
    thumbnail: "iPhone 14 - Midnight.png",
    category: "Phones",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 60,
        top: 55,
        width: 407,
        height: 881
    }
},
{
    id: "iphone_14_landscape",
    name: "Apple iPhone",
    file: "iPhone 14 - Landscape.png",
    thumbnail: "iPhone 14 - Landscape.png",
    category: "Phones",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 106,
        top: 109,
        width: 1678,
        height: 776
    }
},
{
    id: "browser_solid",
    name: "Browser Black",
    file: "Browser-solid.png",
    thumbnail: "Browser-solid.png",
    category: "Other",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 50,
        top: 130,
        width: 1600,
        height: 914
    }
},
{
    id: "browser_stroke",
    name: "Browser White",
    file: "Browser-stroke.png",
    thumbnail: "Browser-stroke.png",
    category: "Other",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 62,
        top: 126,
        width: 1570,
        height: 915
    }
},
{
    id: "desktop_solid",
    name: "Black",
    file: "Desktop-solid.png",
    thumbnail: "Desktop-solid.png",
    category: "Computers",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 90,
        top: 93,
        width: 1193,
        height: 699
    }
},
{
    id: "desktop_stroke",
    name: "White",
    file: "Desktop-stroke.png",
    thumbnail: "Desktop-stroke.png",
    category: "Computers",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 91,
        top: 94,
        width: 1193,
        height: 699
    }
},
{
    id: "laptop_solid",
    name: "Black",
    file: "Laptop-solid.png",
    thumbnail: "Laptop-solid.png",
    category: "Computers",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 175,
        top: 112,
        width: 1350,
        height: 820
    }
},
{
    id: "laptop_stroke",
    name: "White",
    file: "Laptop-stroke.png",
    thumbnail: "Laptop-stroke.png",
    category: "Computers",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 182,
        top: 120,
        width: 1330,
        height: 804
    }
},
{
    id: "phone_landscape_solid",
    name: "Black Landscape",
    file: "Phone-landscape-solid.png",
    thumbnail: "Phone-landscape-solid.png",
    category: "Phones",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 95,
        top: 95,
        width: 1512,
        height: 690
    }
},
{
    id: "phone_landscape_stroke",
    name: "White Landscape",
    file: "Phone-landscape-stroke.png",
    thumbnail: "Phone-landscape-stroke.png",
    category: "Phones",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 104,
        top: 105,
        width: 1494,
        height: 677
    }
},
{
    id: "phone_portrait_solid",
    name: "Black",
    file: "Phone-portrait-solid.png",
    thumbnail: "Phone-portrait-solid.png",
    category: "Phones",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 78,
        top: 78,
        width: 431,
        height: 945
    }
},
{
    id: "phone_portrait_stroke",
    name: "White",
    file: "Phone-portrait-stroke.png",
    thumbnail: "Phone-portrait-stroke.png",
    category: "Phones",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 82,
        top: 83,
        width: 423,
        height: 935
    }
},
{
    id: "tablet_landscape_solid",
    name: "Tablet Black Landscape",
    file: "Tablet-landscape-solid.png",
    thumbnail: "Tablet-landscape-solid.png",
    category: "Other",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 147,
        top: 107,
        width: 1185,
        height: 885
    }
},
{
    id: "tablet_landscape_stroke",
    name: "Tablet White Landscape",
    file: "Tablet-landscape-stroke.png",
    thumbnail: "Tablet-landscape-stroke.png",
    category: "Other",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 156,
        top: 117,
        width: 1176,
        height: 876
    }
},
{
    id: "tablet_portrait_solid",
    name: "Tablet Black",
    file: "Tablet-portrait-solid.png",
    thumbnail: "Tablet-portrait-solid.png",
    category: "Other",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 91,
        top: 118,
        width: 628,
        height: 839
    }
},
{
    id: "tablet_portrait_stroke",
    name: "Tablet White",
    file: "Tablet-portrait-stroke.png",
    thumbnail: "Tablet-portrait-stroke.png",
    category: "Other",
    fixedAspectRatio: true,
    isFront: true,
    clip: {
        left: 96,
        top: 124,
        width: 619,
        height: 829
    }
}];

export { ImageFrame, BoxFrame, CircleFrame, DeviceFrames };
