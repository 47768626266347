import React from "reactn";
import { _ } from "legacy-js/vendor";

import { ds } from "js/core/models/dataService";
import getLogger, { LogGroup } from "js/core/logger";
import * as geom from "js/core/utilities/geom";
import { AssetType, ASSET_FILETYPE, DEFAULT_ADDRESS } from "legacy-common/constants";
import { SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import Spinner from "legacy-js/react/components/Spinner";
import { isRenderer } from "legacy-js/config";
import { removeFormattingFromHtmlText } from "js/core/utilities/htmlTextHelpers";

import { BaseElement } from "../../base/BaseElement";
import { SVGElement } from "../../base/SVGElement";
import { migrateTextModel } from "../../base/Text/TextElement";

const logger = getLogger(LogGroup.ELEMENTS);

class Copyright extends SVGElement {
    renderSVG(props, styles) {
        const { size } = props;

        return (
            <SVGGroup ref={this.ref} key={this.id}>
                <g>
                    <rect width={size.width} height={size.height} fill="white" fillOpacity={0.5} />
                    <text fontSize={11} fill="#555" x={8} y={14}>
                        {`Map data ©${new Date().getFullYear()} Google`}
                    </text>
                </g>
            </SVGGroup>
        );
    }
}

class Map extends BaseElement {
    constructor(...args) {
        super(...args);

        this.loadMapDebounced = _.debounce(
            () => this.loadMap().catch(err => logger.error(err, "[StreetMap] this.loadMap() failed")),
            3000,
            { leading: false, trailing: true }
        );
    }

    get canSelect() {
        return true;
    }

    get address() {
        const address = this.model.map_address?.blocks[0].html;
        if (!address) {
            return DEFAULT_ADDRESS;
        }
        return removeFormattingFromHtmlText(address);
    }

    get selectionPadding() {
        return 0;
    }

    get selectionUIType() {
        return "StreetMapSelection";
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get canRefreshElement() {
        return true;
    }

    async _load() {
        if (isRenderer) {
            const assetMapCachingKey = this.getMapCachingKey();

            if (this.model.assetId && this.model.assetMapCachingKey === assetMapCachingKey) {
                this.assetUrl = await this.getAssetUrl();
                return;
            }

            // If we're in renderer and we don't have an up to date asset,
            // use the generated map url directly
            const mapUrl = this.getMapUrl();
            this.assetUrl = mapUrl;
            // This won't be used, but has to be defined
            this.model.assetId = "original";
            this.model.assetMapCachingKey = assetMapCachingKey;
        }
    }

    _migrate_5() {
        this.model.map_address = migrateTextModel(this.model.map_address);
    }

    get mapSize() {
        return 720;
    }

    get showMarker() {
        return _.defaultTo(this.model.showMarker, true);
    }

    get zoom() {
        return this.model.zoom || 12;
    }

    get mapStyle() {
        return this.model.mapStyle;
    }

    async getAssetUrl(reloadCache = false) {
        const asset = await ds.assets.getAssetById(this.model.assetId, "image");
        return asset.getURL("original", reloadCache);
    }

    _calcProps(props, options) {
        const { size } = props;

        const assetMapCachingKey = this.getMapCachingKey();
        if (this.model.assetId && this.model.assetMapCachingKey === assetMapCachingKey && this.assetUrl) {
            return { size, isLoading: false };
        }

        this.loadMapDebounced();

        return { size, isLoading: true };
    }

    async loadMap() {
        const assetMapCachingKey = this.getMapCachingKey();
        if (this.model.assetId && this.model.assetMapCachingKey === assetMapCachingKey) {
            this.assetUrl = await this.getAssetUrl();
            this.refreshElement();
            return;
        }

        const mapUrl = this.getMapUrl();
        const asset = await ds.assets.getOrCreateImage({
            url: mapUrl,
            name: this.address,
            fileType: ASSET_FILETYPE.JPG,
            assetType: AssetType.MAP,
            metadata: {
                source: "maps.googleapis.com"
            },
            hidden: true
        });

        this.model.assetId = asset.id;
        this.model.assetMapCachingKey = assetMapCachingKey;
        this.assetUrl = await this.getAssetUrl();

        await this.canvas.saveCanvasModel();
        this.refreshElement();
    }

    renderChildren(transition) {
        const { isLoading, bounds } = this.calculatedProps;

        const children = [];

        if (this.assetUrl) {
            children.push(<SVGGroup overflowHidden ref={this.ref} key={`${this.id}-map`}>
                <image
                    href={this.assetUrl}
                    x={bounds.width / 2 - this.mapSize / 2}
                    y={0}
                    width={this.mapSize + 30}
                    height={this.mapSize + 30}
                    onLoad={this.getImageOnLoadPromiseResolver(this.assetUrl)}
                />
            </SVGGroup>);
        }

        if (isLoading) {
            children.push(<Spinner key={`${this.id}-spinner`} />);
        }

        return children;
    }

    getMapCachingKey() {
        return `${this.address}${this.mapSize}${this.showMarker}${this.zoom}${this.mapStyle}`;
    }

    getMapUrl() {
        let params = "";
        params += "&size=" + this.mapSize + "x" + this.mapSize + "&scale=2";
        params += "&center=" + encodeURIComponent(this.address);
        if (this.showMarker) {
            params += `&markers=color:0x${this.getSlideColor().toHex()}|${encodeURIComponent(this.address)}`;
        }
        params += "&zoom=" + this.zoom;
        params += "&key=AIzaSyBCWY8G9jYNmAw2QlKWhhne_vyWklV6ndQ";

        const browserLocale = window.navigator.languages[0] || "en-US";
        const [language, region] = browserLocale.split("-");
        if (language) {
            params += `&language=${language}`;
        }
        if (region) {
            params += `&region=${region}`;
        }

        switch (this.mapStyle) {
            case "light":
                params += "&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e";
                break;
            case "dark":
                params += "&style=element:geometry%7Ccolor:0x212121&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x757575&style=element:labels.text.stroke%7Ccolor:0x212121&style=feature:administrative%7Celement:geometry%7Ccolor:0x757575&style=feature:administrative.country%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:administrative.land_parcel%7Cvisibility:off&style=feature:administrative.locality%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0x181818&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:poi.park%7Celement:labels.text.stroke%7Ccolor:0x1b1b1b&style=feature:road%7Celement:geometry.fill%7Ccolor:0x2c2c2c&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x8a8a8a&style=feature:road.arterial%7Celement:geometry%7Ccolor:0x373737&style=feature:road.highway%7Celement:geometry%7Ccolor:0x3c3c3c&style=feature:road.highway.controlled_access%7Celement:geometry%7Ccolor:0x4e4e4e&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:transit%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:water%7Celement:geometry%7Ccolor:0x000000&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x3d3d3d";
                break;
            case "retro":
                params += "&style=element:geometry%7Ccolor:0xebe3cd&style=element:labels.text.fill%7Ccolor:0x523735&style=element:labels.text.stroke%7Ccolor:0xf5f1e6&style=feature:administrative%7Celement:geometry.stroke%7Ccolor:0xc9b2a6&style=feature:administrative.land_parcel%7Celement:geometry.stroke%7Ccolor:0xdcd2be&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xae9e90&style=feature:landscape.natural%7Celement:geometry%7Ccolor:0xdfd2ae&style=feature:poi%7Celement:geometry%7Ccolor:0xdfd2ae&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x93817c&style=feature:poi.park%7Celement:geometry.fill%7Ccolor:0xa5b076&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x447530&style=feature:road%7Celement:geometry%7Ccolor:0xf5f1e6&style=feature:road.arterial%7Celement:geometry%7Ccolor:0xfdfcf8&style=feature:road.highway%7Celement:geometry%7Ccolor:0xf8c967&style=feature:road.highway%7Celement:geometry.stroke%7Ccolor:0xe9bc62&style=feature:road.highway.controlled_access%7Celement:geometry%7Ccolor:0xe98d58&style=feature:road.highway.controlled_access%7Celement:geometry.stroke%7Ccolor:0xdb8555&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x806b63&style=feature:transit.line%7Celement:geometry%7Ccolor:0xdfd2ae&style=feature:transit.line%7Celement:labels.text.fill%7Ccolor:0x8f7d77&style=feature:transit.line%7Celement:labels.text.stroke%7Ccolor:0xebe3cd&style=feature:transit.station%7Celement:geometry%7Ccolor:0xdfd2ae&style=feature:water%7Celement:geometry.fill%7Ccolor:0xb9d3c2&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x92998d";
                break;
            case "satellite":
                params += "&maptype=satellite";
                break;
            case "hybrid":
                params += "&maptype=hybrid";
                break;
            case "terrain":
                params += "&maptype=terrain";
                break;
            case "default":
            default:
                params += "&style=feature:landscape.natural|element:geometry|visibility:on|color:0xe0efef&style=feature:poi|element:geometry.fill|visibility:off|hue:0x1900ff|color:0xc0e8e8&style=feature:poi|element:labels.icon|visibility:off&style=feature:road|element:geometry|lightness:100|visibility:simplified&style=feature:road|element:labels|visibility:off&style=feature:road.arterial|element:geometry|visibility:on&style=feature:road.local|element:geometry|visibility:off&style=feature:transit|element:labels.icon|visibility:off&style=feature:transit.line|element:geometry|visibility:on|lightness:700&style=feature:water|element:all|color:0x7dcdcd|visibility:on";
                break;
        }

        return `https://maps.googleapis.com/maps/api/staticmap?${params}`;
    }

    remove() {
        super.remove();
        this.loadMapDebounced && this.loadMapDebounced.cancel();
    }
}

class StreetMap extends BaseElement {
    _build() {
        this.map = this.addElement("map", () => Map);
        this.copyright = this.addElement("copyright", () => Copyright);
    }

    _calcProps(props, options) {
        const { size, children } = props;

        const mapProps = this.map.calcProps(size, options);
        mapProps.layer = -1;

        this.copyright.calcProps(new geom.Size(150, 23), options);

        return { size };
    }
}

export { StreetMap };
