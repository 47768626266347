import { ds } from "js/core/models/dataService";
import { controls } from "legacy-js/editor/ui";
import { ElementTextBlockPositionType } from "legacy-common/constants";

import { TextElementSelection } from "./TextEditor";

const ElementTextBlockSelection = TextElementSelection.extend({
    captureMouseEvents: true,

    getOffset: function() {
        return 0;
    },

    getWidgetPosition() {
        if (this.element.canvas.model.layout.elementTextBlockPosition == ElementTextBlockPositionType.TRAY) {
            return "inner";
        } else {
            return "outer";
        }
    },

    renderControls: function() {
        this.createDeleteComponentWidget({
            action: () => {
                ds.selection.element = null;
                this.element.canvas.model.layout.elementTextBlockPosition = ElementTextBlockPositionType.NONE;
                this.element.canvas.updateCanvasModel(true);
            }
        });

        if (this.element.canvas.model.layout.elementTextBlockPosition == ElementTextBlockPositionType.TRAY) {
            this.addControl({
                type: controls.COLOR_PALETTE_PICKER,
                label: "Background",
                property: "color",
                includeAuto: true,
                showColors: !this.element.canvas.getBackgroundColor().isColor || this.element.canvas.getBackgroundColor().name == "background_accent",
                omitColors: [this.element.canvas.getBackgroundColor()],
                showBackgroundColors: true,
            });
        }
    }
});

const ElementAttributionSelection = TextElementSelection.extend({
    captureMouseEvents: true,

    renderControls: function() {
        this.createDeleteComponentWidget({
            action: () => {
                this.element.canvas.model.layout.showElementAttribution = false;
                ds.selection.rolloverElement = null;
                this.element.canvas.updateCanvasModel(true);
            }
        });
    }
});

const ElementAttributionEditor = TextElementSelection.extend({});

export const editors = {
    ElementTextBlockSelection,
    ElementAttributionSelection,
    ElementAttributionEditor,
};
