import { CollectionElementSelection } from "../CollectionElementEditor";
import { ElementSelection } from "../BaseElementEditor";
import { TextListSelection } from "./Lists/TextListEditor";
import { controls } from "legacy-js/editor/ui";
import { $ } from "legacy-js/vendor";
import renderReactRoot from "legacy-js/react/renderReactRoot";
import { ImageFrameMenu } from "../EditorComponents/ImageFrameMenu";
import { ShowErrorDialog } from "legacy-js/react/components/Dialogs/BaseDialog";

export const ProductScreenshotSelection = ElementSelection.extend({

    renderControls() {
        this.addControl({
            type: controls.POPUP_BUTTON,
            icon: "filter_frames",
            label: "Device Frame",
            showArrow: true,
            customMenuClass: "frame-popup",
            menuContents: closeMenu => {
                const $menu = $.div();
                renderReactRoot(ImageFrameMenu, {
                    allowedCategories: ["device"],
                    onSelect: (frame, aspectRatio) => {
                        let mediaColumn = this.element.itemElements.find(col => col.model.isMedia);
                        if (mediaColumn) {
                            mediaColumn.model.frameType = frame;
                            mediaColumn.model.size = Math.min(mediaColumn.calculatedProps.size.height * aspectRatio, this.element.calculatedProps.size.width * 0.66);
                        }

                        this.element.markStylesAsDirty();
                        this.element.canvas.updateCanvasModel(false);
                        closeMenu();
                    },
                    closePopup: () => closeMenu()
                }, $menu[0]);
                return $menu;
            },
            transitionModel: false
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Text Columns",
            items: [{
                value: 0, label: "No Text"
            }, {
                value: 1, label: "One Column"
            }, {
                value: 2, label: "Two Columns"
            }],
            value: this.element.textColumnsCount,
            callback: value => {
                try {
                    this.element.distributeBullets(value);
                } catch (err) {
                    ShowErrorDialog({ title: "Sorry, we couldn't make this change", message: err });
                    this.element.canvas.revertCanvasModel();
                    return;
                }
                this.element.canvas.updateCanvasModel(true);
            }
        });
    }

});

export const editors = {
    ProductScreenshotSelection,
};
