import { controls } from "legacy-js/editor/ui";
import ImportMediaDialog from "legacy-js/react/views/ImportMedia/ImportMediaDialog";
import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";

import { ElementOptionsMenu, ElementSelection } from "../BaseElementEditor";

const VideoSelection = ElementSelection.extend({
    renderControls: function() {
        this.addControl({
            type: controls.BUTTON,
            icon: "ondemand_video",
            label: "Set Video...",
            callback: () => {
                this.showImportMediaDialog();
            }
        });

        if (this.element.showDefaultOverlay) {
            this.$el.append(controls.createButton(this, {
                icon: "ondemand_video",
                label: "Import Video...",
                className: "large_icon light",
                callback: () => {
                    this.showImportMediaDialog();
                }
            }));
        }
    },
    showImportMediaDialog: function() {
        ShowDialog(ImportMediaDialog, {
            fileTypes: ["video"],
            onSuccess: asset => {
                const {
                    hostedVideoUrl,
                    id,
                } = asset;
                return this.setVideo(hostedVideoUrl, id);
            }
        });
    },
    setVideo: async function(hostedVideoUrl, videoAssetId) {
        this.element.model.videoId = hostedVideoUrl;
        this.element.model.videoAssetId = videoAssetId;
        this.element.model.muted = false;
        this.element.model.startTime = 0;
        await this.element.canvas.updateCanvasModel(false);
    }
});

const VideoOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Fullbleed",
            property: "fullBleed"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Mute Audio",
            property: "muted"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Autoplay",
            property: "autoPlay"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Loop",
            property: "loop"
        });

        this.addControl({
            type: controls.NUMERIC,
            label: "Start Time",
            property: "startTime",
            triggerEvent: "blur",
            min: 0,
            max: 9999
        });

        if (this.element.videoType === "upload") {
            this.addControl({
                type: controls.POPUP_BUTTON,
                property: "playbackRate",
                label: "Playback Speed",
                items: [
                    { label: "0.25", value: "0.25" },
                    { label: "0.5", value: "0.5" },
                    { label: "0.75", value: "0.75" },
                    { label: "Normal", value: "1" },
                    { label: "1.25", value: "1.25" },
                    { label: "1.5", value: "1.5" },
                    { label: "1.75", value: "1.75" },
                    { label: "2", value: "2" },
                ]
            });
        }
    }
});

export const editors = {
    VideoSelection,
    ImportMediaDialog,
    VideoOptionsMenu,
};
