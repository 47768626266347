import * as geom from "js/core/utilities/geom";
import React from "reactn";
import { app } from "js/namespaces";
import getLogger, { LogGroup } from "js/core/logger";

import {
    AssetType,
    FormatType,
    CellChangeStyle,
} from "legacy-common/constants";
import { formatter } from "js/core/utilities/formatter";
import { _, numeral } from "legacy-js/vendor";
import { blendColors } from "js/core/utilities/utilities";
import { getValueOrDefault } from "js/core/utilities/extensions";
import { getSVGTextProps, SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import { Path } from "js/core/utilities/shapes";

import { BaseElement } from "../../../base/BaseElement";
import { SVGElement } from "../../../base/SVGElement";
import { ContentElement } from "../../../base/ContentElement";
import { CollectionElement, CollectionItemElement } from "../../../base/CollectionElement";
import { TextElement } from "../../../base/Text/TextElement";

const logger = getLogger(LogGroup.ELEMENTS);

class TableFrameV1 extends BaseElement {
    _build() {
        this.table = this.addElement("table", () => Table);
    }

    _calcProps(props) {
        let { size } = props;

        let tableProps = this.table.calcProps(size);
        tableProps.bounds = new geom.Rect(size.width / 2 - tableProps.size.width / 2, size.height / 2 - tableProps.size.height / 2, tableProps.size);

        return { size };
    }

    get rolloverPadding() {
        return { top: 50, left: 50, right: 30, bottom: 30 };
    }
}

class Table extends CollectionElement {
    get canSelect() {
        return true;
    }

    get MAX_COLS() {
        return 15;
    }

    get MAX_ROWS() {
        return 18;
    }

    get rolloverPadding() {
        return { top: 50, left: 50, right: 30, bottom: 30 };
    }

    getChildItemType() {
        return TableCell;
    }

    get totalCols() {
        return this.model.cols.length;
    }

    get totalRows() {
        return this.model.rows.length;
    }

    get collectionPropertyName() {
        return "cells";
    }

    get isDirty() {
        return true;
    }

    get cells() {
        return this.itemElements;
    }

    getColumn(index) {
        return _.find(this.model.cols, { index });
    }

    getRow(index) {
        return _.find(this.model.rows, { index });
    }

    getColumnStyle(index) {
        let col = this.getColumn(index);
        if (this.model.showHeaderCol && index == 0 && col.style != "cleanCol") {
            return "headerCol";
        } else {
            return col.style;
        }
    }

    getRowStyle(index) {
        let row = this.getRow(index);
        if (this.model.showHeaderRow && index == 0 && row.style != "cleanRow") {
            return "headerRow";
        } else {
            return row.style;
        }
    }

    getCell(col, row) {
        return _.find(this.itemElements, { col, row });
    }

    getCellsInColumn(col) {
        return _.filter(this.itemElements, { col });
    }

    getCellsInRow(row) {
        return _.filter(this.itemElements, { row });
    }

    get emphasizedScale() {
        return 1.10;
    }

    get MIN_COL_WIDTH() {
        return 100;
    }

    get MIN_ROW_HEIGHT() {
        return 40;
    }

    get MAX_COL_WIDTH() {
        return 2200;
    }

    get MAX_ROW_HEIGHT() {
        return 2200;
    }

    get matchCellFontSizes() {
        return getValueOrDefault(this.model.matchCellFontSizes, true);
    }

    _build() {
        /**
         * We encountered some cases when a cell got removed from a table, probably this could have
         * happened due to a selection layer bug. We have to check for and recreate missing cells to be
         * able to render tables that got broken due to that.
         * Please refer to BA-8401.
         */
        this.model.cols.forEach(col =>
            this.model.rows.forEach(row => {
                if (!this.model.cells.some(cell => cell.col === col.index && cell.row === row.index)) {
                    logger.warn(`[table] cell at index ${col.index}:${row.index} not found, recreating...`);
                    this.model.cells.push({
                        col: col.index,
                        row: row.index,
                        format: FormatType.TEXT
                    });
                }
            }));

        super._build();

        this.cellsGridAndBackgrounds = this.addElement("cellsGridAndBackgrounds", () => TableCellsGridAndBackgrounds);
    }

    _calcProps(props, options) {
        let { size } = props;

        // TODO CHECK FOR STYlING
        // Note: explicily setting props and sending our styles down to the grid and backgrounds
        this.cellsGridAndBackgrounds.calcProps(size);
        this.cellsGridAndBackgrounds.updateStyles(this.styles);

        this.availableTableBounds = size.clone();

        if (this.model.autoFit || _.some(this.model.cols, col => col.size == null) || _.some(this.model.rows, row => row.size == null)) {
            this.calcAutoFit(props);
        }

        _.last(this.model.cols).break = false;
        _.last(this.model.rows).break = false;

        let x = 0;
        let y = 0;

        let tableHeight = _.sumBy(this.model.rows, "size") + _.filter(this.model.rows, "break").length * this.styles.rowBreak;

        // layout table cells
        for (let row = 0; row < this.totalRows; row++) {
            let rowModel = this.model.rows[row];
            x = 0;

            let rowHeight = rowModel.size;

            for (let col = 0; col < this.totalCols; col++) {
                let colModel = this.model.cols[col];
                let colWidth = colModel.size || 100;

                let cellBounds = new geom.Rect(x, y, colWidth, rowHeight);
                if (colModel.style == "emphasizedCol") {
                    cellBounds.left -= 0;
                    cellBounds.width += 0;
                    x += 0;

                    cellBounds.height = rowHeight * this.emphasizedScale;
                    cellBounds.top -= (tableHeight / 2 - cellBounds.top) * (this.emphasizedScale - 1);
                }

                let cell = _.find(this.itemElements, { row, col });
                if (cell) {
                    let cellProps = cell.calcProps(new geom.Size(colWidth, rowHeight));
                    cellProps.bounds = cellBounds;
                }
                x += colWidth;

                if (colModel.break) {
                    x += this.styles.colBreak;
                }
            }

            y += rowHeight;
            if (rowModel.break) {
                y += this.styles.rowBreak;
            }
        }

        let tableWidth = x;

        let cellTextScale = 1;
        let cellHeaderScale = 1;
        let cellIconRowHeight = size.height;

        for (let cell of this.itemElements) {
            let cellBounds = cell.bounds;

            if (cell.cellContentsType == "icon") {
                cell.calcProps(cellBounds.size, { forceTextScale: this.matchCellFontSizes ? cellTextScale : 1 });
                cellIconRowHeight = Math.min(cell.rowHeight, cellIconRowHeight);
            } else {
                if (cell.isHeaderCol || cell.isHeaderRow) {
                    cell.calcProps(cellBounds.size, { forceTextScale: this.matchCellFontSizes ? cellHeaderScale : 1 });
                    cellHeaderScale = Math.min(cellHeaderScale, cell.contents.textScale || 1);
                } else {
                    cell.calcProps(cellBounds.size, { forceTextScale: this.matchCellFontSizes ? cellTextScale : 1 });
                    cellTextScale = Math.min(cellTextScale, cell.contents.textScale || 1);
                }
            }

            cell.calculatedProps.bounds = cellBounds;
        }

        let iconHeight = cellIconRowHeight * this.styles.TableCell.cellIcon.iconScale;

        this.colAlignmentWidths = Array(this.totalCols).fill(0, 0, this.totalCols);

        this.getCell(0, 0).options.preventRender = !this.model.showTopLeftCell;

        // fix up any cells with new scales
        if (this.matchCellFontSizes) {
            for (let cell of this.itemElements) {
                let cellBounds = cell.bounds;

                if (cell.cellContentsType == "icon") {
                    // cell.calcLayout(props, cell.bounds.size, {
                    //     iconScale: iconHeight / cell.rowHeight // normalize iconScale so all icons are the same size regardless of rowheight
                    // });
                } else if (cell.contents.textScale != cellTextScale) {
                    if (cell.isHeaderRow || cell.isHeaderCol) {
                        cell.calcProps(cellBounds.size, { forceTextScale: cellHeaderScale });
                    } else {
                        cell.calcProps(cellBounds.size, { forceTextScale: cellTextScale });
                    }
                }

                // store the max width of any numeric cells for each col so we can do alignment math later
                if (cell.cellContentsType != "icon") {
                    this.colAlignmentWidths[cell.col] = Math.max(this.colAlignmentWidths[cell.col], cell.contents.textBounds.width);
                }

                cell.calculatedProps.bounds = cellBounds;
            }
        }

        props.isFit = Math.floor(tableWidth) <= size.width && Math.floor(tableHeight) <= size.height;

        return { size: new geom.Size(tableWidth, tableHeight) };
    }

    _getBackgroundColor(forElement) {
        if (forElement && forElement instanceof TableCell) {
            let colStyleProps = this.styles.columnStyles[this.getColumnStyle(forElement.col)];
            let rowStyleProps = this.styles.rowStyles[this.getRowStyle(forElement.row)];

            let backgroundColor = this.getParentBackgroundColor();

            if (colStyleProps && colStyleProps.fillColor && colStyleProps != "clearCol") {
                backgroundColor = blendColors(this.canvas.getTheme().palette.getForeColor(colStyleProps.fillColor + " " + colStyleProps.fillOpacity, this.getSlideColor(), this.getParentBackgroundColor()), backgroundColor);
            }
            if (rowStyleProps && rowStyleProps.fillColor && rowStyleProps != "clearRow") {
                backgroundColor = blendColors(this.canvas.getTheme().palette.getForeColor(rowStyleProps.fillColor, this.getSlideColor(), this.getParentBackgroundColor()), backgroundColor);
            }

            if (forElement.model.emphasized) {
                let style = _.get(this.styles.TableCell.cellStyles[this.getColumnStyle(forElement.col)][this.getRowStyle(forElement.row)], "emphasized");
                if (style && style.decoration && style.decoration.fillColor) {
                    backgroundColor = blendColors(this.canvas.getTheme().palette.getForeColor(style.decoration.fillColor + " " + colStyleProps.fillOpacity, this.getSlideColor(), this.getParentBackgroundColor()), backgroundColor);
                }
            }

            return backgroundColor;
        } else {
            return this.getParentBackgroundColor(forElement);
        }
    }

    calcAutoFit(props) {
        let { size } = props;

        for (let col of this.model.cols) {
            col.size = null;
            col.autoSize = true;
        }
        for (let row of this.model.rows) {
            row.size = null;
            row.autoSize = true;
        }

        // categorize header, text and numeric columns

        let headerCols = [];
        let textCols = [];
        let numericCols = [];

        for (let col of this.model.cols) {
            if (col.style == "headerCol") {
                headerCols.push(col);
            } else {
                let hasText = false;
                for (let cell of this.getCellsInColumn(col.index)) {
                    if (this.getRow(cell.row).style != "headerRow" && (cell.format == FormatType.TEXT || cell.format == FormatType.DATE) && cell.model.cellText && !_.isEmpty(cell.model.cellText.text)) {
                        hasText = true;
                        break;
                    }
                }
                if (hasText) {
                    textCols.push(col);
                } else {
                    numericCols.push(col);
                }
            }
        }

        let availableWidth = this.availableTableBounds.width;
        let availableHeight = this.availableTableBounds.height;

        // account for column/row breaks
        availableWidth = availableWidth - _.filter(this.model.cols, { break: true }).length * this.styles.colBreak;
        availableHeight = availableHeight - _.filter(this.model.rows, { break: true }).length * this.styles.rowBreak;

        // calculate the min headerCol widths
        const MAX_HEADER_COL_WIDTH = 350;

        for (let col of headerCols) {
            let colWidth = 0;
            for (let cell of this.getCellsInColumn(col.index)) {
                if (cell.contents && cell.contents instanceof TableCellText) {
                    let cellContentsProps = cell.contents.calcProps(new geom.Size(MAX_HEADER_COL_WIDTH, availableHeight));
                    colWidth = Math.max(colWidth, cellContentsProps.textBounds.width + cell.contents.styles.paddingLeft + cell.contents.styles.paddingRight);
                }
            }
            col.size = Math.max(colWidth, this.MIN_COL_WIDTH);
        }

        const NUMERIC_COL_WIDTH = 100;
        for (let col of numericCols) {
            col.size = NUMERIC_COL_WIDTH;
        }

        const TEXT_COL_WIDTH = 300;
        for (let col of textCols) {
            let colWidth = 0;
            for (let cell of this.getCellsInColumn(col.index)) {
                if (cell.contents && cell.contents instanceof TableCellText) {
                    let cellContentProps = cell.contents.calcProps(new geom.Size(TEXT_COL_WIDTH, availableHeight));
                    colWidth = Math.max(colWidth, cellContentProps.textBounds.width + cell.contents.styles.paddingLeft + cell.contents.styles.paddingRight);
                }
            }
            colWidth = Math.max(colWidth, this.MIN_COL_WIDTH);
            col.size = colWidth;
        }

        for (let col of this.model.cols) {
            for (let cell of this.getCellsInColumn(col.index)) {
                if (cell.contents && cell.contents instanceof TableCellIcon && cell.model.content_value != AssetType.ICON && cell.model.content_value) {
                    col.size = Math.max(col.size, NUMERIC_COL_WIDTH);
                    break;
                }
            }
        }

        let tableWidth = _.sumBy(headerCols, "size") + _.sumBy(textCols, "size") + _.sumBy(numericCols, "size");

        // adjust column widths to better fit header row text
        if (tableWidth < availableWidth) {
            if (this.model.rows[0].style == "headerRow") {
                let colWidth = 0;
                for (let cell of this.getCellsInRow(0)) {
                    if (cell.colStyleType != "headerCol" && cell.contents && cell.contents instanceof TableCellText) {
                        let cellContentProps = cell.contents.calcProps(new geom.Size(TEXT_COL_WIDTH, availableHeight));
                        colWidth = Math.max(colWidth, cellContentProps.textBounds.width + cell.contents.styles.paddingLeft + cell.contents.styles.paddingRight);
                    }
                }

                let nonHeaderWidth = availableWidth - _.sumBy(headerCols, "size");
                colWidth = Math.min(nonHeaderWidth / (textCols.length + numericCols.length), colWidth);

                for (let col of this.model.cols) {
                    if (col.style != "headerCol") {
                        col.size = Math.max(colWidth, col.size);
                    }
                }
            }
        }

        tableWidth = _.sumBy(headerCols, "size") + _.sumBy(textCols, "size") + _.sumBy(numericCols, "size");

        // final col spacing

        if (tableWidth > availableWidth) {
            // table is too wide, take space back
            let extraSpace = tableWidth - availableWidth;

            for (let col of this.model.cols) {
                col.size -= extraSpace / this.model.cols.length;
            }
        } else {
            // still some more space so let the cols expand a bt
            let extraSpace = (availableWidth - tableWidth) / this.model.cols.length - 1;
            for (let i = 0; i < this.model.cols.length; i++) {
                let col = this.model.cols[i];
                if (col.size < 400) {
                    let mod = 1.6;
                    if (col.style == "headerCol") {
                        mod = 1.2;
                    } else if (col.style == "cleanCol") {
                        mod = 1;
                    }
                    col.size = Math.clamp(col.size * mod, col.size, col.size + extraSpace);
                }
            }
        }

        let headerRows = [];
        let numericRows = [];
        let textRows = [];
        let imageRows = [];
        for (let row of this.model.rows) {
            if (row.style == "headerRow") {
                headerRows.push(row);
            } else {
                let hasText = false;
                let hasMedia = false;
                for (let cell of this.getCellsInRow(row.index)) {
                    if (this.getRow(cell.row).style != "headerCol" && cell.format == "text" && cell.model.cellText && !_.isEmpty(cell.model.cellText.text)) {
                        hasText = true;
                    }
                    if (cell.format == FormatType.ICON && cell.model.content_type != AssetType.ICON && cell.model.content_value) {
                        hasMedia = true;
                    }
                }
                if (hasMedia) {
                    imageRows.push(row);
                }
                if (hasText) {
                    textRows.push(row);
                } else {
                    numericRows.push(row);
                }
            }
        }

        const MAX_HEADER_ROW_HEIGHT = 400;
        const MIN_HEADER_ROW_HEIGHT = 40;

        for (let row of headerRows) {
            let rowHeight = 0;

            for (let cell of this.getCellsInRow(row.index)) {
                if (cell.contents && cell.contents instanceof TableCellText && cell.contents.model.cellText && cell.contents.model.cellText.text != "") {
                    let cellContentProps = cell.contents.calcProps(new geom.Size(cell.colWidth, MAX_HEADER_ROW_HEIGHT), { styles: this.styles.TableCell.cellText });
                    rowHeight = Math.max(rowHeight, cellContentProps.textBounds.height + cell.contents.styles.paddingTop + cell.contents.styles.paddingBottom);
                } else {
                    rowHeight = Math.max(rowHeight, MIN_HEADER_ROW_HEIGHT);
                }
            }
            row.size = rowHeight;
        }

        for (let row of numericRows) {
            row.size = this.MIN_ROW_HEIGHT;
        }

        for (let row of textRows) {
            let rowHeight = 0;
            for (let cell of this.getCellsInRow(row.index)) {
                if (cell.contents && cell.contents instanceof TableCellText) {
                    let cellContentProps = cell.contents.calcProps(new geom.Size(cell.colWidth, MAX_HEADER_ROW_HEIGHT), { styles: this.styles.TableCell.cellText });
                    rowHeight = Math.max(rowHeight, cellContentProps.textBounds.height + cell.contents.styles.paddingTop + cell.contents.styles.paddingBottom);
                } else if (cell.contents && cell.contents instanceof TableCellIcon && cell.contents.model.content_value != AssetType.ICON) {
                    rowHeight = Math.max(rowHeight, 50);
                }
            }
            row.size = Math.max(this.MIN_ROW_HEIGHT, rowHeight);
        }

        for (let row of imageRows) {
            row.size = Math.max(row.size, 100);
        }

        let tableHeight = _.sumBy(headerRows, "size") + _.sumBy(textRows, "size") + _.sumBy(numericRows, "size");

        if (tableHeight > availableHeight) {
            let extraSpace = (tableHeight - availableHeight) / (this.model.rows.length - 1);
            for (let i = 1; i < this.model.rows.length; i++) {
                let row = this.model.rows[i];
                row.size -= extraSpace;
            }
        } else {
            let extraSpace = (availableHeight - tableHeight) / this.model.rows.length;
            for (let row of this.model.rows) {
                row.size = Math.clamp(row.size * 1.3, row.size, row.size + extraSpace);
            }
        }
    }
}

class TableCellsGridAndBackgrounds extends SVGElement {
    renderSVG(props) {
        const tableElement = this.parentElement;

        let cellBackgrounds = [];
        let gridLines = [];

        let borderStroke = {
            color: this.canvas.getTheme().palette.getForeColor(this.styles.border.strokeColor, tableElement.getSlideColor(), tableElement.getBackgroundColor()).toRgbString(),
            width: this.styles.border.strokeWidth,
            opacity: this.styles.border.strokeOpacity
        };
        let colGridLineStroke = {
            color: this.canvas.getTheme().palette.getForeColor(this.styles.colGridLine.strokeColor, tableElement.getSlideColor(), tableElement.getBackgroundColor()).toRgbString(),
            opacity: this.styles.colGridLine.strokeOpacity,
            width: this.styles.colGridLine.strokeWidth
        };
        let rowGridLineStroke = {
            color: this.canvas.getTheme().palette.getForeColor(this.styles.rowGridLine.strokeColor, tableElement.getSlideColor(), tableElement.getBackgroundColor()).toRgbString(),
            opacity: this.styles.rowGridLine.strokeOpacity,
            width: this.styles.rowGridLine.strokeWidth
        };

        let colGroups = [{ start: 0, end: 0 }];
        for (let colIndex = 0; colIndex < tableElement.model.cols.length; colIndex++) {
            let colModel = _.find(tableElement.model.cols, { index: colIndex });

            let colStyle = tableElement.getColumnStyle(colIndex);

            if (colStyle == "cleanCol") {
                if (_.last(colGroups).start == colIndex) {
                    _.last(colGroups).isCleanCol = true;
                } else {
                    colGroups.push({ start: colIndex, end: colIndex, isCleanCol: true });
                }
                if (colIndex < tableElement.model.cols.length - 1) {
                    colGroups.push({ start: colIndex + 1, end: colIndex + 1 });
                }
                continue;
            }

            if (colStyle == "emphasizedCol") {
                if (_.last(colGroups).start == colIndex) {
                    _.last(colGroups).isEmphasizedCol = true;
                } else {
                    colGroups.push({ start: colIndex, end: colIndex, isEmphasizedCol: true });
                }
                if (colIndex < tableElement.model.cols.length - 1) {
                    colGroups.push({ start: colIndex + 1, end: colIndex + 1 });
                }
                continue;
            }

            _.last(colGroups).end = colIndex;

            if (colModel.break && colIndex < tableElement.model.cols.length - 1) {
                colGroups.push({ start: colIndex + 1, end: colIndex + 1 });
            }
        }

        for (let colGroup of colGroups) {
            colGroup.left = tableElement.getCell(colGroup.start, 0).calculatedProps.bounds.left;
            colGroup.right = tableElement.getCell(colGroup.end, 0).calculatedProps.bounds.right;
        }

        let rowGroups = [{ start: 0, end: 0 }];
        for (let rowIndex = 0; rowIndex < tableElement.model.rows.length; rowIndex++) {
            let rowModel = _.find(tableElement.model.rows, { index: rowIndex });
            let rowStyle = tableElement.getRowStyle(rowIndex);

            if (rowStyle == "cleanRow") {
                if (_.last(rowGroups).start == rowIndex) {
                    _.last(rowGroups).isCleanRow = true;
                } else {
                    rowGroups.push({ start: rowIndex, end: rowIndex, isCleanRow: true });
                }
                if (rowIndex < tableElement.model.rows.length - 1) {
                    rowGroups.push({ start: rowIndex + 1, end: rowIndex + 1 });
                }
                continue;
            }

            if (rowStyle == "headerRow") {
                if (_.last(rowGroups).start == rowIndex) {
                    _.last(rowGroups).isHeaderRow = true;
                } else {
                    rowGroups.push({ start: rowIndex, end: rowIndex, isHeaderRow: true });
                }
                if (rowIndex < tableElement.model.rows.length - 1) {
                    rowGroups.push({ start: rowIndex + 1, end: rowIndex + 1 });
                }
                continue;
            }

            _.last(rowGroups).end = rowIndex;
            if (rowModel.break) {
                rowGroups.push({ start: rowIndex + 1, end: rowIndex + 1 });
            }
        }

        for (let rowGroup of rowGroups) {
            // Fix for BA-3348 - get a cell from the first non-emphasized (because it's taller) column to calculate rowGroup top/bottom
            let col = _.findIndex(tableElement.model.cols, col => {
                return col.style != "emphasizedCol";
            }) || 0;

            rowGroup.top = tableElement.getCell(col, rowGroup.start).calculatedProps.bounds.top;
            rowGroup.bottom = tableElement.getCell(col, rowGroup.end).calculatedProps.bounds.bottom;
        }

        let slideColor = tableElement.getSlideColor();
        let backgroundColor = tableElement.getBackgroundColor();

        let a1cell = tableElement.getCell(0, 0);

        // draw lines
        for (let colGroup of colGroups) {
            for (let rowGroup of rowGroups) {
                let topLeftCell = tableElement.getCell(colGroup.start, rowGroup.start);
                let bottomRightCell = tableElement.getCell(colGroup.end, rowGroup.end);

                let skipColStyles = colGroup.isCleanCol;
                let skipRowStyles = rowGroup.isCleanRow;

                // style rows
                for (let rowIndex = rowGroup.start; rowIndex <= rowGroup.end; rowIndex++) {
                    if (skipRowStyles) continue;

                    // let row = tableElement.getRow(rowIndex);
                    let rowCell = tableElement.getCell(colGroup.start, rowIndex);
                    let rowCellProps = tableElement.itemElements.findById(rowCell.id);

                    let rowStyle = tableElement.getRowStyle(rowIndex);

                    if (tableElement.model.alternateRows && rowStyle == "defaultRow" && rowIndex % 2 == 0) {
                        rowStyle = "alternateRow";
                    }

                    let rowStyleProps = this.styles.rowStyles[rowStyle];

                    let left = colGroup.left;
                    let right = colGroup.right;

                    if (rowIndex == 0 && colGroup.start == 0 && tableElement.model.showTopLeftCell == false) {
                        left += a1cell.bounds.width;
                        if (tableElement.getColumn(0).break) {
                            left += this.styles.colBreak;
                        }
                    }

                    if (rowStyleProps && rowStyleProps.fillColor && right >= left && !skipColStyles) {
                        let rowFill = {
                            color: this.canvas.getTheme().palette.getForeColor(rowStyleProps.fillColor, slideColor, backgroundColor).toRgbString(),
                            opacity: rowStyleProps.fillOpacity || 1
                        };
                        cellBackgrounds.push(<rect key={`cellBackground${cellBackgrounds.length}`} x={left} y={rowCellProps.bounds.top} width={right - left}
                            height={rowCellProps.bounds.height} fill={rowFill.color} fillOpacity={rowFill.opacity} />);
                    }
                    if (tableElement.model.showRowGridLines && rowIndex > rowGroup.start && !skipColStyles) {
                        gridLines.push(<line key={`gridLine${gridLines.length}`} x1={left} y1={rowCellProps.bounds.top} x2={right}
                            y2={rowCellProps.bounds.top} stroke={rowGridLineStroke.color}
                            strokeOpacity={rowGridLineStroke.opacity}
                            strokeWidth={rowGridLineStroke.width} />);
                    }
                }
                // style columns
                for (let colIndex = colGroup.start; colIndex <= colGroup.end; colIndex++) {
                    if (skipColStyles) continue;

                    // let col = tableElement.getColumn(colIndex);
                    let colCell = tableElement.getCell(colIndex, rowGroup.start);
                    let colCellProps = tableElement.cells.findById(colCell.id);

                    let colStyle = tableElement.getColumnStyle(colIndex);

                    let colStyleProps = this.styles.columnStyles[colStyle];

                    let top = colCell.bounds.top;
                    let bottom = tableElement.getCell(colIndex, rowGroup.end).bounds.bottom;

                    if (colIndex == 0 && rowGroup.start == 0 && tableElement.model.showTopLeftCell == false) {
                        top += a1cell.bounds.height;
                        if (tableElement.getRow(0).break) {
                            top += this.styles.rowBreak;
                        }
                    }

                    if (colStyleProps && colStyleProps.fillColor && bottom >= top && !skipRowStyles) {
                        let colBounds = new geom.Rect(colCellProps.bounds.left, top, colCellProps.bounds.width, bottom - top);
                        let colFill = {
                            color: this.canvas.getTheme().palette.getForeColor(colStyleProps.fillColor, slideColor, backgroundColor).toRgbString(),
                            opacity: colStyleProps.fillOpacity || 1
                        };
                        cellBackgrounds.push(<rect key={`cellBackground${cellBackgrounds.length}`} x={colBounds.left} y={colBounds.top} width={colBounds.width}
                            height={colBounds.height} fill={colFill.color}
                            fillOpacity={colFill.opacity} />);
                    }
                    if (tableElement.model.showColGridLines && colIndex > colGroup.start && !skipRowStyles) {
                        gridLines.push(<line key={`gridLine${gridLines.length}`} x1={colCellProps.bounds.left} y1={rowGroup.top}
                            x2={colCellProps.bounds.left} y2={rowGroup.bottom}
                            stroke={colGridLineStroke.color} strokeOpacity={colGridLineStroke.opacity}
                            strokeWidth={colGridLineStroke.width} />);
                    }
                }

                if (tableElement.model.showBorder == true && !skipRowStyles && !skipColStyles) {
                    if (tableElement.model.showTopLeftCell == false && colGroup.start == 0 && rowGroup.start == 0 && colGroup.end > 0 && rowGroup.end > 0) {
                        if (colGroup.end > 0 || rowGroup.end > 0) {
                            let breakWidth = tableElement.getColumn(0).break ? this.styles.colBreak : 0;
                            let breakHeight = tableElement.getRow(0).break ? this.styles.rowBreak : 0;
                            let path = new Path();
                            path.moveTo(a1cell.calculatedProps.bounds.right + breakWidth, topLeftCell.calculatedProps.bounds.top);
                            path.lineTo(bottomRightCell.calculatedProps.bounds.right, topLeftCell.calculatedProps.bounds.top);
                            path.lineTo(bottomRightCell.calculatedProps.bounds.right, bottomRightCell.calculatedProps.bounds.bottom);
                            path.lineTo(topLeftCell.calculatedProps.bounds.left, bottomRightCell.calculatedProps.bounds.bottom);
                            path.lineTo(topLeftCell.calculatedProps.bounds.left, a1cell.calculatedProps.bounds.bottom + breakHeight);
                            path.lineTo(a1cell.calculatedProps.bounds.right + breakWidth, a1cell.calculatedProps.bounds.bottom + breakHeight);
                            path.close();
                            gridLines.push(<path key={`gridLine${gridLines.length}`} d={path.toPathData()} stroke={borderStroke.color}
                                strokeOpacity={borderStroke.opacity} strokeWidth={borderStroke.width}
                                fill="none" />);
                        }
                    } else {
                        let left = topLeftCell.calculatedProps.bounds.left;
                        let top = topLeftCell.calculatedProps.bounds.top;
                        let right = bottomRightCell.calculatedProps.bounds.right;
                        let bottom = bottomRightCell.calculatedProps.bounds.bottom;

                        let drawPath = true;
                        if (tableElement.model.showTopLeftCell == false && (colGroup.start == 0 || rowGroup.start == 0)) {
                            if (rowGroup.start == 0 && colGroup.start == 0 && rowGroup.end == 0 && colGroup.end == 0) {
                                drawPath = false;
                            } else if (rowGroup.start == 0 && rowGroup.end == 0 && colGroup.start == 0) {
                                left = a1cell.calculatedProps.bounds.right;
                                if (tableElement.getColumn(0).break) {
                                    left += this.styles.colBreak;
                                }
                            } else if (colGroup.start == 0 && colGroup.end == 0 && rowGroup.start == 0) {
                                top = a1cell.calculatedProps.bounds.bottom;
                                if (tableElement.getRow(0).break) {
                                    top += this.styles.rowBreak;
                                }
                            }
                        }

                        if (drawPath) {
                            let path = new Path();
                            path.moveTo(left, top);
                            path.lineTo(right, top);
                            path.lineTo(right, bottom);
                            path.lineTo(left, bottom);
                            path.close();
                            gridLines.push(<path key={`gridLine${gridLines.length}`} d={path.toPathData()} stroke={borderStroke.color}
                                strokeOpacity={borderStroke.opacity} strokeWidth={borderStroke.width}
                                fill="none" />);
                        }
                    }
                }
            }
        }

        return (
            <SVGGroup ref={this.ref} key={this.id}>
                <svg style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    overflow: "visible"
                }}>
                    {cellBackgrounds}
                    {gridLines}
                </svg>
            </SVGGroup>
        );
    }
}

class TableCell extends CollectionItemElement {
    get canSelect() {
        return false;
    }

    get selectionPadding() {
        return 0;
    }

    get row() {
        return this.model.row;
    }

    get col() {
        return this.model.col;
    }

    get colWidth() {
        return this.parentElement.getColumn(this.col).size;
    }

    get rowHeight() {
        return this.parentElement.getRow(this.row).size;
    }

    get colStyle() {
        return this.parentElement.getColumnStyle(this.col);
    }

    get rowStyle() {
        return this.parentElement.getRowStyle(this.row);
    }

    get colStyleProps() {
        return this.parentElement.styles.columnStyles[this.colStyle];
    }

    get rowStyleProps() {
        return this.parentElement.styles.rowStyles[this.rowStyle];
    }

    get isHeaderCol() {
        return this.colStyle == "headerCol";
    }

    get isHeaderRow() {
        return this.rowStyle == "headerRow";
    }

    get cellContentsType() {
        switch (this.format) {
            case "icon":
                return "icon";
            case "text":
            case "date":
                return "text";
            case "number":
            case "currency":
            default:
                return "numeric";
        }
    }

    get cellColor() {
        return this.model.cellColor;
    }

    get format() {
        return this.model.format || FormatType.TEXT;
    }

    get _formatOptions() {
        if (this.model.formatOptions && typeof (this.model.formatOptions) == "object") {
            return this.model.formatOptions;
        } else {
            return formatter.getDefaultFormatOptions();
        }
    }

    get icon() {
        let icon = this.model.icon || "blank";
        if (icon == "blank") {
            return null;
        } else {
            return icon;
        }
    }

    get positiveChangeColor() {
        return "green";
    }

    get negativeChangeColor() {
        return "red";
    }

    get canDelete() {
        return false;
    }

    _build() {
        if (_.isEmpty(this.model.formatOptions) || typeof (this.model.formatOptions) != "object") {
            this.model.formatOptions = formatter.getDefaultFormatOptions();
        }

        // Do not allow numerical "change style" for text or date cells
        if (this.cellContentsType === "text") {
            this.model.formatOptions.changeStyle = CellChangeStyle.NONE;
        }

        if (this.format == "icon") {
            this.contents = this.addElement("cellIcon", () => TableCellIcon);
        } else {
            this.contents = this.addElement("cellText", () => TableCellText, {
                scaleTextToFit: true,
                bindTo: "text",
                model: {
                    text: this.model.cellText ? formatter.formatValue(this.model.cellText.text, this.format, this.formatOptions) : ""
                }
            });
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        // merge this.styles from col and row to get the cell styles
        this.styles.applyStyles(this.colStyleProps, this.rowStyleProps, this.styles.cellStyles[this.colStyle][this.rowStyle]);

        if (this.cellColor && !this.model.emphasized) {
            this.createDecoration({
                type: "frame",
                shape: "rect",
                fillColor: this.cellColor
            });
        }

        if (this.model.emphasized) {
            this.styles.applyStyles(this.styles.emphasized);
            if (this.cellColor) {
                // this.styles.decoration.fillColor = this.cellColor;
                this.decoration.updateStyles({ fillColor: this.cellColor });
            }
            this.createDecoration();
        }

        // special cell text formattting

        if (this.format != "icon") {
            // apply the merged cellText styles to the content
            this.contents.updateStyles(this.styles.cellText);

            if ((this.formatOptions.changeStyle !== CellChangeStyle.NONE || this.formatOptions.accountingStyle) && this.formatOptions.changeColor && this.model.cellText) {
                if (parseFloat(this.model.cellText.text) < 0) {
                    this.contents.styles.fontColor = this.negativeChangeColor;
                } else if (parseFloat(this.model.cellText.text) > 0) {
                    this.contents.styles.fontColor = this.positiveChangeColor;
                }
            }

            // most cells should be autoWidth = true
            // but accounting cells need to be autoWidth = false so we can right align the text
            // options.autoWidth = true;

            if (this.formatOptions.accountingStyle && (this.format === FormatType.CURRENCY || this.format === FormatType.NUMBER)) {
                options.autoWidth = false;
                this.contents.styles.textAlign = "right";
                this.contents.styles.paddingLeft = this.contents.styles.paddingRight = 10;
                // size.width += 20;
                if (this.model.cellText && this.model.cellText.text && this.model.cellText.text > 0) {
                    this.contents.styles.marginRight = 7;
                    // size.width -= 5;
                }
            } else {
                this.contents.styles.textAlign = this.model.format === "text"
                    ? (this.formatOptions.textAlign || this.contents.styles.textAlign || "center")
                    : "center";
            }

            if (this.formatOptions.textSize === "small") {
                this.contents.styles.fontSize = this.contents.styles.fontSize * 0.75;
            }
        }

        let contentProps = this.contents.calcProps(size, options);
        contentProps.bounds = new geom.Rect(size.width / 2 - contentProps.size.width / 2, size.height / 2 - contentProps.size.height / 2, contentProps.size);

        return { size };
    }

    _getBackgroundColor(forElement) {
        if (this.model.cellColor && this.model.cellColor != "none") {
            return this.canvas.getTheme().palette.getForeColor(this.model.cellColor, this.getSlideColor(), this.parentElement.getBackgroundColor());
        }

        return this.parentElement.getBackgroundColor(this);
    }

    renderChildren(transition) {
        let props = this.calculatedProps;
        let renderChildren = super.renderChildren(transition);

        let isBlank = !this.model.cellText || this.model.cellText.text === "";

        // render up/down arrow
        if (!isBlank && this.formatOptions.changeStyle === CellChangeStyle.ARROWS && this.model.cellText && this.model.cellText.text) {
            const arrowWidth = 12;
            const arrowHeight = 9;
            let arrow;
            let val = numeral(this.model.cellText.text).value();
            if (val < 0) {
                let path = new Path();
                path.moveTo(0, 0);
                path.lineTo(arrowWidth, 0);
                path.lineTo(arrowWidth / 2, arrowHeight);
                path.close();

                arrow = <path d={path.toPathData()} fill={this.negativeChangeColor} />;
            } else if (val > 0) {
                let path = new Path();
                path.moveTo(arrowWidth / 2, 0);
                path.lineTo(arrowWidth, arrowHeight);
                path.lineTo(0, arrowHeight);
                path.close();

                arrow = <path d={path.toPathData()} fill={this.positiveChangeColor} />;
            }
            renderChildren.push(
                <SVGGroup key="arrows">
                    <g style={{ transform: `translateX(${props.children.cellText.textBounds.left - 7}px) translateY(${props.bounds.height / 2 - 5}px)` }}>
                        {arrow}
                    </g>
                </SVGGroup>
            );
        }

        if (!isBlank && this.formatOptions.accountingStyle && this.format === FormatType.CURRENCY) {
            renderChildren.push(
                <SVGGroup key="accounting">
                    <text dx={10} dy={props.bounds.height / 2}
                        dominantBaseline="middle"
                        {...getSVGTextProps(this.styles)}
                        opacity={0.5}
                        fill={this.canvas.getTheme().palette.getForeColor("primary", null, this.getBackgroundColor())}
                    >
                        {this.formatOptions.currency}
                    </text>
                </SVGGroup>
            );
        }

        return renderChildren;
    }
}

class TableCellIcon extends ContentElement {
    get canSelect() {
        return false;
    }

    get canRollover() {
        return false;
    }

    _loadStyles(styles) {
        if (this.model.content_type == AssetType.LOGO) {
            styles.paddingLeft = styles.paddingRight = styles.paddingTop = styles.paddingBottom = 20;
        }
        styles.marginLeft = styles.marginRight = styles.marginTop = styles.marginBottom = 1;
    }

    _build() {
        super._build();
        this.assetElement.options.canRollover = false;
        if (this.model.content_type == AssetType.ICON) {
            this.assetElement.options.doubleClickToSelect = false;
        }
    }
}

class TableCellText extends TextElement {
    get selectionPadding() {
        return 0;
    }

    get allowStyling() {
        return false;
    }

    get canSelect() {
        return false;
    }

    get canRollover() {
        return false;
    }

    get placeholderPrompt() {
        return "";
    }

    get autoHeight() {
        return true;
    }

    get autoWidth() {
        return false;
    }

    get constrainWidth() {
        return true;
    }

    get minTextScale() {
        return .2;
    }

    get defaultOverlayType() {
        return "TableCellTextDefaultOverlay";
    }
}

export { Table, TableCellText };

export const elements = {
    TableFrameV1,
};

