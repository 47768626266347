import * as geom from "js/core/utilities/geom";
import { _ } from "legacy-js/vendor";
import { HeaderPositionType, VerticalAlignType, BlockStructureType, TextStyleType, AuthoringBlockType } from "legacy-common/constants";

import { BaseElement } from "../base/BaseElement";
import { layoutHelper } from "../layouts/LayoutHelper";
import { SVGRectElement } from "../base/SVGElement";
import { TextElement } from "../base/Text/TextElement";

export class Header extends BaseElement {
    get textAlign() {
        return this.styles.textAlign || "center";
    }

    get position() {
        return this.model.headerPosition || this.canvas.model.layout.headerPosition || HeaderPositionType.TOP;
    }
    setPosition(headerPosition) {
        if (this.model.headerPosition !== headerPosition) {
            this.model.headerPosition = headerPosition;
            this.model.width = this.authoringCanvas.calculatedProps.bounds.width;
            this.model.height = this.authoringCanvas.calculatedProps.bounds.height;
        }
    }

    get selectionPadding() {
        if (this.model.backgroundColor && this.text.showDescription && this.position == HeaderPositionType.TOP) {
            // account for additional background shape padding
            return { left: 0, right: 0, top: 0, bottom: 20 };
        } else {
            return { left: 0, right: 0, top: 0, bottom: 0 };
        }
    }

    get rolloverPadding() {
        return 0;
    }

    get canSelect() {
        return true;
    }

    get isResizable() {
        return true;
    }

    get fitToContents() {
        return {
            width: this.position !== HeaderPositionType.TOP,
            height: this.position === HeaderPositionType.TOP
        };
    }

    get restrictResize() {
        return {
            width: false,
            height: true
        };
    }

    get needsFullSizeToCalcFit() {
        return {
            width: false,
            height: true
        };
    }

    get hasBackground() {
        return this.model.backgroundColor && this.model.backgroundColor != "none";
    }

    _loadStyles(styles) {
        styles.applyStyles(styles._position[this.position]);

        let decoration = this.canvas.getTheme().get("styleDecoration");
        if (decoration === "bar_left" && this.canvas.model.layout.trayLayout === "left_tray") {
            styles.applyStyles({ decoration: null });
        }

        if (decoration.contains("block") && this.model.backgroundColor && this.model.backgroundColor != "none") {
            styles.text.decoration = undefined;
            styles.text.heading.forceBackgroundColor = undefined;
            styles.text.body.spaceAbove = undefined;
            styles.text.label.spaceBelow = undefined;
        }

        if ((decoration === "bar_left" || decoration === "bar_top") && (this.model.backgroundColor && this.model.backgroundColor != "none")) {
            styles.applyStyles({ decoration: null });
        }

        if (this.isOnAuthoringCanvas) {
            styles.marginBottom = 20;
        }

        if (this.model.backgroundColor) {
            styles.backgroundFrame.fillColor = this.model.backgroundColor;
        }
    }

    _build() {
        if (this.model.backgroundColor) {
            this.backgroundFrame = this.addElement("backgroundFrame", () => SVGRectElement);
        }

        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.HEADER,
            singleLineHeadline: true,
            defaultBlockTextStyle: TextStyleType.HEADING,
            scaleTextToFit: true,
            allowAlignment: false,
            blockDefaults: {
                heading: {
                    evenBreak: true
                }
            }
        });
    }

    _calcProps(props, options) {
        let { size, allowedSize } = props;

        if (this.position == HeaderPositionType.TOP) {
            if ((this.model.backgroundColor && this.model.backgroundColor != "none")) {
                if (this.text.lastBlock.textStyle == TextStyleType.BODY) {
                    this.updateStyles(this.styles._position.top_with_background_and_subheader);
                } else {
                    this.updateStyles(this.styles._position.top_with_background);
                }
            } else {
                if (this.text.lastBlock.textStyle == TextStyleType.BODY) {
                    this.styles.paddingBottom = this.styles.bottomPaddingWithSubheader ?? 0; // adj for pre-html text styling
                }
            }
        }

        let backgroundProps;
        if (this.model.backgroundColor) {
            backgroundProps = this.backgroundFrame.createProps();
            backgroundProps.layer = -2;
            backgroundProps.bounds = new geom.Rect(0, 0, 0, 0);
        }

        let textProps;
        if (this.position == HeaderPositionType.TOP) {
            textProps = this.text.calcProps(size, { autoHeight: true });
            textProps.bounds = new geom.Rect(0, 0, textProps.size);

            if (this.model.backgroundColor && this.model.backgroundColor != "none") {
                backgroundProps.bounds = new geom.Rect(
                    0,
                    0,
                    allowedSize.width,
                    textProps.size.height + this.styles.paddingTop + this.styles.paddingBottom,
                );

                backgroundProps.bounds.height += 20;
            }
            return {
                size: new geom.Size(size.width, textProps.size.height)
            };
        } else if (
            this.position == HeaderPositionType.LEFT &&
            this.isOnAuthoringCanvas
        ) {
            textProps = this.text.calcProps(size, { scaleTextToFit: true, autoWidth: true, autoHeight: true });
            textProps.bounds = new geom.Rect(
                0,
                layoutHelper.getVerticalAlignOffset(textProps.size.height, size.height, this.text.styles.verticalAlign ?? VerticalAlignType.MIDDLE),
                textProps.size
            );

            if (this.model.backgroundColor && this.model.backgroundColor != "none") {
                backgroundProps.bounds = new geom.Rect(
                    0,
                    0,
                    textProps.size.width + this.styles.paddingLeft + this.styles.paddingRight,
                    allowedSize.height,
                );

                backgroundProps.bounds.width += 20;
            }
            return {
                size: new geom.Size(textProps.size.width, size.height)
            };
        } else {
            textProps = this.text.calcProps(size, { scaleTextToFit: true, autoHeight: true });
            textProps.bounds = new geom.Rect(
                0,
                layoutHelper.getVerticalAlignOffset(textProps.size.height, size.height, this.text.styles.verticalAlign ?? VerticalAlignType.MIDDLE),
                textProps.size
            );

            if (this.model.backgroundColor) {
                backgroundProps.bounds = new geom.Rect(0, 0, allowedSize.width, allowedSize.height);
            }

            return {
                size: new geom.Size(textProps.size.width, size.height)
            };
        }
    }

    getBackgroundColor(forElement) {
        if (this.backgroundFrame && forElement instanceof TextElement) {
            return this.getShapeFillColor(this.backgroundFrame);
        } else {
            return super._getBackgroundColor(forElement);
        }
    }

    get disableAnimationsByDefault() {
        return true;
    }

    _migrate_10() {
        if (this.model.userFontScale) {
            // these are old paths that will mess up migration so delete them
            delete this.model.userFontScale["CanvasElement/Header/DisplayTextHeadline"];
            delete this.model.userFontScale["CanvasElement/Header/DisplayTextLabel"];
            delete this.model.userFontScale["CanvasElement/Header/DisplayTextDescription"];
        }
    }

    _exportToSharedModel() {
        return { header: this.model };
    }

    _importFromSharedModel(model) {
        return model.header;
    }
}

export const elements = {
    Header
};
