import React from "reactn";
import { _ } from "legacy-js/vendor";
import { getSVGStyleProps, SVGGroup } from "legacy-js/core/utilities/svgHelpers";
import { Path } from "js/core/utilities/shapes";

import { BaseElement } from "../../base/BaseElement";

export class OrgChartConnectors extends BaseElement {
    renderChildren(transition) {
        let props = this.calculatedProps;
        const { styles, options: { vGap, hGap, connectorStyle, hideRootNode, layout, layoutDirection } } = props;

        const connectors = [];

        const itemElements = this.parentElement.itemElements;

        if (connectorStyle === "step") {
            for (const node of itemElements) {
                if (node.isRootNode && hideRootNode) {
                    continue;
                }

                if (node.childNodes.length) {
                    if (layout != "stacked" || _.some(node.childNodes, node => node.childNodes.length != 0)) {
                        const path = new Path();

                        switch (layoutDirection) {
                            case "vertical":
                                path.moveTo(node.bounds.centerH, node.bounds.bottom);
                                path.lineTo(node.bounds.centerH, node.childNodes[0].bounds.top - vGap / 2);
                                break;
                            case "horizontal":
                                path.moveTo(node.bounds.right, node.bounds.centerV);
                                path.lineTo(node.bounds.right + hGap / 2, node.bounds.centerV);
                                break;
                        }

                        let lineStyles;
                        if (_.some(node.childNodes, node => node.connectorType === "solid")) {
                            lineStyles = this.styles.solid;
                        } else {
                            lineStyles = this.styles.dotted;
                        }
                        connectors.push(<polyline key={`${node.id}-tail`}
                            points={path.toPolylineData()} {...getSVGStyleProps(lineStyles)}
                        />);
                    }
                }
            }
        }

        for (const node of itemElements) {
            if (node.model.parent) {
                const parentNode = node.parentNode;
                if (parentNode) {
                    let actualConnectorStyle = connectorStyle;
                    if (layout === "stacked" && !_.some(node.siblingNodes, node => node.childNodes.length)) {
                        actualConnectorStyle = "stacked";
                    }

                    if (parentNode.isRootNode && hideRootNode) {
                        actualConnectorStyle = "sibling";
                    }

                    if (node.isAssistant) {
                        actualConnectorStyle = "assistant";
                    }

                    const path = new Path();
                    switch (actualConnectorStyle) {
                        case "step":
                            switch (layoutDirection) {
                                case "vertical":
                                    path.moveTo(node.bounds.centerH, node.bounds.top);
                                    path.lineTo(node.bounds.centerH, node.bounds.top - vGap / 2);
                                    if (node.isLeftEdgeNode || node.isRightEdgeNode && node.siblingNodes.length > 1) {
                                        path.lineTo(parentNode.bounds.centerH, node.bounds.top - vGap / 2);
                                    }
                                    break;
                                case "horizontal":
                                    path.moveTo(node.bounds.left, node.bounds.centerV);
                                    path.lineTo(node.bounds.left - hGap / 2, node.bounds.centerV);
                                    if (node.isLeftEdgeNode || node.isRightEdgeNode && node.siblingNodes.length > 1) {
                                        path.lineTo(node.bounds.left - hGap / 2, parentNode.bounds.centerV);
                                    }
                            }
                            break;
                        case "straight":
                            path.moveTo(node.bounds.centerH, node.bounds.top);
                            path.lineTo(parentNode.bounds.centerH, parentNode.bounds.top);
                            break;
                        case "stacked":
                            path.moveTo(node.bounds.left, node.bounds.centerV);
                            path.lineTo(node.bounds.left - 15, node.bounds.centerV);
                            if (node.isRightEdgeNode || node.siblingNodes.length === 1) {
                                path.lineTo(parentNode.bounds.left + 15, parentNode.bounds.bottom);
                            }
                            break;
                        case "sibling":
                            const rightSiblingNode = node.rightSiblingNode;
                            if (rightSiblingNode) {
                                path.moveTo(node.bounds.right, node.bounds.centerV);
                                path.lineTo(rightSiblingNode.bounds.left, node.bounds.centerV);
                            }
                            break;
                        case "assistant":
                            if (parentNode.childNodes.length) {
                                // draw extension from existing path
                                path.moveTo(parentNode.bounds.centerH, node.bounds.centerV);
                            } else {
                                // draw full path to parent
                                path.moveTo(parentNode.bounds.centerH, parentNode.bounds.bottom);
                                path.lineTo(parentNode.bounds.centerH, node.bounds.centerV);
                            }
                            if (node.bounds.left > parentNode.bounds.centerH) {
                                path.lineTo(node.bounds.left, node.bounds.centerV);
                            } else {
                                path.lineTo(node.bounds.right, node.bounds.centerV);
                            }
                            break;
                    }

                    let lineStyles;
                    switch (node.connectorType) {
                        case "solid":
                            lineStyles = this.styles.solid;
                            break;
                        case "dot":
                            lineStyles = this.styles.dotted;
                            break;
                    }

                    connectors.push(<polyline key={`${node.id}-head`}
                        points={path.toPolylineData()} {...getSVGStyleProps(lineStyles)}
                    />);
                }
            }
        }

        return <SVGGroup key={this.id} ref={this.ref}>{connectors}</SVGGroup>;
    }
}
