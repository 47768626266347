import { BaseElement } from "../../base/BaseElement";

export class TimelineSpanItem extends BaseElement {
    get textElementProps() {
        return {
            scaleTextToFit: true,
            autoWidth: false,
            autoHeight: false,
            minTextScale: 1,
            syncFontSizeWithSiblings: true
        };
    }

    get canRollover() {
        return false;
    }
}
