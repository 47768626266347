import React, { Component } from "react";
import { AuthoringBlockType } from "legacy-common/constants";
import { AuthoringBlock } from "./AuthoringBlock";
import styled from "styled-components";
import { FlexSpacer, Gap20 } from "legacy-js/react/components/Gap";
import { _, $ } from "legacy-js/vendor";
import { Icon, MenuItem, Select } from "@material-ui/core";
import { themeColors } from "legacy-js/react/sharedStyles";
import { PopupMenu, PopupMenuPaddedContainer } from "legacy-js/react/components/PopupMenu";
import { LabeledContainer } from "legacy-js/react/components/LabeledContainer";
import { ColorPicker } from "legacy-js/react/components/ColorPicker";
import * as geom from "js/core/utilities/geom";
import CheckBox from "legacy-js/react/components/CheckBox";
import { app } from "js/namespaces";

const Container = styled.div`
  //background: #ccc;
  width: 100%;
  height: 100%;
`;

const InnerBlock = styled.div`
  width: 100%;
  //height: 100%;
  height: 0px;
  display: flex;
  flex-direction: column;
`;

export const RegionType = {
    INSET: "inset",
    COLOR: "color"
};

export class StartRegionBlock extends AuthoringBlock {
    get type() {
        return AuthoringBlockType.REGION_START;
    }

    get canRollover() {
        return false;
    }

    render() {
        return (
            <Container ref={this.containerRef}>
                <InnerBlock ref={this.ref}>
                    Start Region
                </InnerBlock>
            </Container>
        );
    }
}

export class EndRegionBlock extends AuthoringBlock {
    get type() {
        return AuthoringBlockType.REGION_END;
    }

    get canRollover() {
        return false;
    }

    render() {
        return (
            <Container ref={this.containerRef}>
                <InnerBlock ref={this.ref}>
                    End Region
                </InnerBlock>
            </Container>
        );
    }
}

const StyledRegionFrame = styled.div`
  padding: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export function RegionFrame({ model, children }) {
    let styles = {};

    if (model.columnCount) {
        styles.columnCount = model.columnCount;
        styles.columnGap = 70;
        styles.columnFill = "balance";
    }

    if (model.backgroundColor) {
        let color = app.currentTheme.palette.getColor(model.backgroundColor).toRgbString();
        styles.background = color;
    }

    if (model.regionType == RegionType.INSET) {
        styles.border = "solid 1px #ccc";
        styles.background = null;
    }

    if (model.fullBleed) {
        styles.marginLeft = -50;
        styles.marginRight = -50;
        styles.padding = 50;
    }

    return <StyledRegionFrame className="region-frame" data-region-id={model.id} style={styles}>{children}</StyledRegionFrame>;
}

const RegionWidgetContainer = styled.div.attrs(({ position }) => ({
    style: { ...position.toCSSObject() }
}))`
  position: absolute;
  width: 100px;
  height: 25px;
  background: ${themeColors.ui_blue};
  color: white;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  label {
    font-size: 12px;
  }
  .material-icons {
    font-size: 16px;
    pointer-events: auto;
    cursor: grab;
  }
`;

export class RegionWidget extends Component {
    handleChangeRegionStyle = event => {
        const { region, refreshCanvasAndSaveChanges } = this.props;
        region.props.model.regionType = event.target.value;
        refreshCanvasAndSaveChanges();
    }

    handleChangeColumnCount = event => {
        const { region, refreshCanvasAndSaveChanges } = this.props;
        region.props.model.columnCount = event.target.value;
        refreshCanvasAndSaveChanges();
    }

    handleChangeRegionColor = color => {
        const { region, refreshCanvasAndSaveChanges } = this.props;
        region.props.model.backgroundColor = color;
        refreshCanvasAndSaveChanges();
    }

    handleChangeFullBleed = value => {
        const { region, refreshCanvasAndSaveChanges } = this.props;
        region.props.model.fullBleed = value;
        refreshCanvasAndSaveChanges();
    }

    handleMouseDown = event => {
        const { element, blockContainer, region, canReorder } = this.props;

        if (canReorder) {
            const startPt = new geom.Point(event.pageX, event.pageY);
            $(document).on("mousemove.blockmenuwidget", event => {
                if (new geom.Point(event.pageX, event.pageY).distance(startPt) > 5) {
                    $(document).off(".blockmenuwidget");

                    let dragBlocks = [region];
                    const dragBlockIndex = blockContainer.blocks.indexOf(region);
                    for (const nextBlock of blockContainer.blocks.slice(dragBlockIndex + 1)) {
                        dragBlocks.push(nextBlock);
                        if (nextBlock.type == AuthoringBlockType.REGION_END) {
                            break;
                        }
                    }
                    element.canvas.selectionLayer.dragBlockManager.registerDrag(this, element, dragBlocks, region);
                }
            });
        }

        $(document).on("mouseup.blockmenuwidget", event => {
            $(document).off(".blockmenuwidget");
        });
    }

    render() {
        const { region, bounds, refreshCanvasAndSaveChanges } = this.props;

        let model = region.props.model;
        let widgetBounds = bounds.offset(-30 * app.currentCanvas.getScale(), -50 * app.currentCanvas.getScale());

        return (
            <RegionWidgetContainer className="region-widget" position={widgetBounds.position} >
                <Icon onMouseDown={this.handleMouseDown}>drag_indicator</Icon>
                <PopupMenu label="REGION" showArrow position="above">
                    <PopupMenuPaddedContainer>
                        <LabeledContainer label="Region Style">
                            <Select value={model.regionType ?? RegionType.COLOR} variant="outlined" onChange={this.handleChangeRegionStyle}>
                                <MenuItem value={RegionType.INSET}>Inset</MenuItem>
                                <MenuItem value={RegionType.COLOR}>Color</MenuItem>
                            </Select>
                        </LabeledContainer>
                        <ColorPicker
                            label="Color"
                            color={model.backgroundColor ?? "white"}
                            onChange={this.handleChangeRegionColor}
                            showBackgroundColors
                        />
                        <LabeledContainer label="Text Columns">
                            <Select value={model.columnCount ?? 1} variant="outlined" onChange={this.handleChangeColumnCount}>
                                <MenuItem value={1}>One column</MenuItem>
                                <MenuItem value={2}>Two columns</MenuItem>
                                <MenuItem value={3}>Three columns</MenuItem>
                            </Select>
                        </LabeledContainer>
                        <CheckBox checked={model.fullBleed} label="Full Bleed" onChange={this.handleChangeFullBleed} />
                    </PopupMenuPaddedContainer>
                </PopupMenu>
            </RegionWidgetContainer>
        );
    }
}
