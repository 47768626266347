import { _ } from "legacy-js/vendor";
import * as geom from "js/core/utilities/geom";
import { detectTextContent } from "js/core/services/sharedModelManager";
import { BlockStructureType, TextStyleType, AuthoringBlockType } from "legacy-common/constants";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/Text/TextElement";

const swotChars = ["S", "W", "O", "T"];

class SwotDiagram extends CollectionElement {
    getChildItemType() {
        return SwotDiagramItem;
    }

    get maxItemCount() {
        return 4;
    }

    get minItemCount() {
        return 4;
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, this.itemElements, size).calcTextBoxGridLayout();

        return { size: layouter.size };
    }

    deleteItem() {
        // noop
    }

    _exportToSharedModel() {
        const textContent = this.itemElements.reduce((textContent, itemElement) => ([
            ...textContent, ...itemElement.text._exportToSharedModel().textContent
        ]), []);

        return { textContent };
    }

    _importFromSharedModel(model) {
        const textContent = detectTextContent(model);
        if (!textContent?.length) return this.canvas.slideTemplate.defaultData.primary;

        const elModel = _.cloneDeep(this.canvas.slideTemplate.defaultData.primary);
        elModel.items.forEach((item, i) => {
            if (textContent[i]) {
                item.text.blocks = [
                    {
                        html: textContent[i].mainText.text,
                        textStyle: TextStyleType.TITLE,
                        type: AuthoringBlockType.TEXT,
                    },
                    ...textContent[i].secondaryTexts.map(({ text }) => ({
                        html: text,
                        textStyle: TextStyleType.BODY,
                        type: AuthoringBlockType.TEXT,
                    }))
                ];
            } else {
                item.text.blocks.forEach(block => block.html = "");
            }
        });

        return elModel;
    }
}

class SwotDiagramItem extends CollectionItemElement {
    get selectionPadding() {
        return 0;
    }

    get _canSelect() {
        return true;
    }

    _build() {
        if (!this.model.char) {
            this.model.char = {
                text: swotChars[this.itemIndex]
            };
        }

        this.char = this.addElement("char", () => TextElement, {
            blockStructure: BlockStructureType.SINGLE_BLOCK,
            syncFontSizeWithSiblings: true
        });

        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            defaultBlockTypes: [TextStyleType.TITLE, TextStyleType.BODY],
            allowAlignment: false,
            scaleTextToFit: true,
            syncFontSizeWithSiblings: true
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        const layoutBounds = new geom.Rect(0, 0, size);
        let remainingTextBounds = layoutBounds;
        let bodyBounds;
        let textLeft = 0;

        let charProps = this.char.calcProps(new geom.Size(0.4 * size.height, 0.4 * size.height));
        let charLeft = 0,
            charTop = 0;

        if (this.itemIndex % 2 === 0) {
            charLeft = size.width - charProps.size.width;
        } else {
            textLeft = charProps.size.width;
        }
        if (this.itemIndex < 2) {
            charTop = size.height - charProps.size.height;
        }
        charProps.bounds = new geom.Rect(charLeft, charTop, charProps.size);
        remainingTextBounds = layoutBounds.largestRemainingRect(charProps.bounds);

        let textProps = this.text.calcProps(remainingTextBounds.size);
        textProps.bounds = new geom.Rect(textLeft, 0, textProps.size);

        return { size };
    }

    get animationElementName() {
        return swotChars[this.itemIndex];
    }
}

export { SwotDiagramItem };

export const elements = {
    SwotDiagram,
};
