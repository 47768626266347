import { ShapeType } from "legacy-common/constants";

import { FramedMediaElement } from "../../base/MediaElements/FramedMediaElement";

export class AuthoringContentElement extends FramedMediaElement {
    static get schema() {
        return {
            frameType: ShapeType.NONE
        };
    }

    get isAuthoringElement() {
        return true;
    }

    get preserveAspectRatioForUnframed() {
        return false; // when frame is none, we want the FramedMediaElement to clip the contnet - not preserve it's aspect ratio
    }

    // Prevent use override of aspect ratio when resizing using mod key.
    // Only makes sense if preserveAspectRatio = true.
    get lockAspectRatio() {
        if (this.content?.elements?.asset?.lockAspectRatio) {
            return true;
        }

        if (this.frameType.equalsAnyOf(ShapeType.NONE, ShapeType.RECT, ShapeType.ROUNDED_RECT)) {
            return false;
        } else {
            return true;
        }
    }

    get preserveAspectRatioOnCornerResize() {
        return true;
    }

    get canSelect() {
        return false;
    }
}
