import React, { Component } from "react";
import styled from "styled-components";
import { Icon, MenuItem, Popover } from "@material-ui/core";

import { FlexBox } from "legacy-js/react/components/LayoutGrid";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 0px;
    height: 34px;
    border: none;
`;

const StepperIcon = styled(Icon)`
    &&& {
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background: rgba(255, 255, 255, 0.2);
            opacity: 1;
        }
    }
`;

const PopoverMenu = styled(Popover)`
    &&& {
        .MuiPaper-root {
            border-radius: 0px;
        }

        .MuiMenuItem-root {
            font-size: 14px;
            font-family: "Source Sans Pro";
            padding: 6px 4px;
            justify-content: center;
        }
    }
`;

const TextBox = styled.span`
    color: white;
    //background: white;
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
`;

export class NumberStepper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value ?? ""
        };
    }

    get normalizedValue() {
        const { minValue = 0, maxValue = 99, value: defaultValue } = this.props;
        let { value } = this.state;
        value = parseInt(value);
        value = Number.isNaN(value) ? defaultValue : value;
        value = Math.clamp(value, minValue, maxValue);
        return value;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value != this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    handleTextBoxClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    }

    closePopup = () => {
        this.setState({ anchorEl: null });
    }

    handleSelectValue = value => {
        this.setState({ value }, () => this.commitCurrentValue());
    }

    commitCurrentValue = () => {
        const { onChange } = this.props;
        this.setState({ value: this.normalizedValue }, () => this.hasUncommittedValue = false);
        onChange(this.normalizedValue);
    }

    handleDecrementValue = event => {
        let value = this.normalizedValue;
        if (event.shiftKey) {
            value -= 10;
        } else {
            value -= 1;
        }
        this.setState({ value }, () => this.commitCurrentValue());
    }

    handleIncrementValue = event => {
        let value = this.normalizedValue;
        if (event.shiftKey) {
            value += 10;
        } else {
            value += 1;
        }
        this.setState({ value }, () => this.commitCurrentValue());
    }

    render() {
        const { menuItems, width } = this.props;
        const { value, anchorEl } = this.state;

        return (
            <Container style={{ width }}>
                <TextBox
                    onClick={this.handleTextBoxClick}
                >
                    {value}
                </TextBox >
                <PopoverMenu
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    disableEnforceFocus
                    disableAutoFocus
                    onClose={this.closePopup}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    {menuItems.map(
                        value => (
                            <MenuItem
                                onClick={() => this.handleSelectValue(value)}
                                key={value}
                                value={value}>
                                {value}
                            </MenuItem>
                        ))
                    }
                </PopoverMenu>
                <FlexBox vertical middle center>
                    <StepperIcon onClick={this.handleIncrementValue}>arrow_drop_up</StepperIcon>
                    <StepperIcon onClick={this.handleDecrementValue}>arrow_drop_down</StepperIcon>
                </FlexBox>
            </Container>
        );
    }
}
