import { $, _ } from "legacy-js/vendor";
import CycleAnimator from "./CycleAnimator";

export default class ConnectorPulseAnimator extends CycleAnimator {
    get duration() {
        return 3000;
    }

    _handleAnimationFrame(time) {
        if (!this.element.calculatedProps) {
            return;
        }

        const baseRadius = 3 + this.element.calculatedProps.strokeWidth;
        const fadeDuration = this.duration / 4;

        let opacity = 1;
        let radius = baseRadius;
        if (time < fadeDuration) {
            opacity = $.Velocity.Easings["ease-in"](time / fadeDuration, 1);
            radius = baseRadius * opacity;
        } else if (time > this.duration - fadeDuration) {
            opacity = $.Velocity.Easings["ease-out"]((this.duration - time) / fadeDuration, 1);
            radius = baseRadius * opacity;
        }

        let percentComplete = time / this.duration;
        if (this.playReverse) {
            percentComplete = 1 - percentComplete;
        }

        const center = this.element.connectorPath.getPointAt(percentComplete);
        this.element.pulsePointData = { radius, center };
    }
}
