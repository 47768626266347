import * as geom from "js/core/utilities/geom";
import { ShapeType } from "legacy-common/constants";

import { BaseElement } from "../BaseElement";
import { ImageFrame } from "../Frame";
import { ContentElement } from "../ContentElement";

export class FramedMediaElement extends BaseElement {
    static get schema() {
        return {
            frameType: "theme"
        };
    }

    get allowUnframedImages() {
        return this.options.allowUnframedImages ?? true;
    }

    get hasValidAsset() {
        return this.content.hasValidAsset;
    }

    get frameType() {
        let frameType;
        if (this.model.frameType == "theme" || this.model.frameType == null) {
            if (this.options.frameType) {
                frameType = this.options.frameType;
            } else if (this.styles.content.decoration.shape == ShapeType.RECT && this.styles.content.decoration.cornerRadius > 0) {
                frameType = ShapeType.ROUNDED_RECT;
            } else {
                frameType = this.canvas.getTheme().get("styleShape");
            }
        } else {
            frameType = this.model.frameType ?? this.options.frameType ?? "none";
        }

        if (frameType == "none" && !this.allowUnframedImages && this.content && this.content.hasValidAsset) {
            frameType = "unframedRect";  // special-case where we want the asset constrained to a rect but with no frame
        }

        return frameType;
    }

    get hasFrame() {
        return this.frameType != "none" && this.frameType != "unframedRect";
    }

    get frameColor() {
        if (this.frameType == "unframedRect") {
            return "none";
        }
        return this.model.frameColor ?? "#222";
    }

    get framePadding() {
        return this.model.framePadding ?? 0;
    }

    get assetType() {
        return this.content.assetType;
    }

    get assetElement() {
        return this.content.assetElement;
    }

    get decorationStyle() {
        if (this.model.decorationStyle == null) {
            return this.canvas.getTheme().get("styleElementStyle");
        } else {
            return this.model.decorationStyle;
        }
    }

    get allowDecorationStyles() {
        return this.hasShapeFrame;
    }

    get hasShapeFrame() {
        return this.frameType.equalsAnyOf(ShapeType.RECT, ShapeType.ROUNDED_RECT, ShapeType.CIRCLE, ShapeType.OCTAGON, "square", "light", "dark", "circle", "circle-light", "circle-dark");
    }

    get preserveAspectRatioForUnframed() {
        return this.options.preserveAspectRatioForUnframed ?? true;
    }

    _build() {
        let forceIconColor = false;
        if (this.hasShapeFrame && (this.decorationStyle == "muted" || this.decorationStyle == "fillAndStroke")) {
            // this enables the icon to use the model color even when the bg is color color for muted backgrounds
            forceIconColor = true;
        } else if (!this.hasFrame) {
            // this enables the icon to use the model color even when the bg is color color for none frames
            forceIconColor = true;
        }

        this.content = this.addElement("content", () => ContentElement, {
            defaultAssetType: this.options.defaultAssetType,
            canSelect: false,
            canDoubleClickToEdit: this.options.canDoubleClickToEdit ?? true,
            iconScale: this.hasFrame ? this.styles.content.iconScale : 1,  // when frame is none, scale the icon to 1 otherwise use the style value
            forceIconColor, // this enables the icon to use color on color for muted backgrounds
            allowBackdrop: false,
        });

        let decorationShape;
        let cornerRadius = 0;

        this.content.removeDecoration();

        switch (this.frameType) {
            case "light":  // legacy
            case "dark":   // legacy
            case "square": // legacy
            case ShapeType.RECT:
            case "unframedRect":
                decorationShape = ShapeType.RECT;
                break;
            case "circle":// legacy
            case "circle-light":// legacy
            case "circle-dark":// legacy
            case ShapeType.CIRCLE:
                decorationShape = ShapeType.CIRCLE;
                break;
            case ShapeType.ROUNDED_RECT:
                decorationShape = ShapeType.RECT;
                cornerRadius = 10;
                break;
            case ShapeType.OCTAGON:
                decorationShape = ShapeType.OCTAGON;
                break;
            case "none":
                this.frame = null;
                break;
            default:
                this.frame = this.addElement("frame", () => ImageFrame, {
                    model: {
                        frame: this.frameType,
                    }
                });
                this.content.createDecoration({
                    type: "frame",
                    shape: "rect",
                    fillColor: this.model.color == "auto" ? "slide" : (this.model.color || "slide"), // use the model color if present and not auto, otherwise use "slide"
                    strokeWidth: 0,
                    cornerRadius: 0
                });
        }

        if (this.hasShapeFrame) {
            this.content.createDecoration({
                type: "frame",
                shape: decorationShape,
                cornerRadius: cornerRadius,
                fillColor: "$iconFrameFillColor",
                strokeColor: "$iconFrameStrokeColor",
                strokeWidth: "$iconFrameStrokeWidth"
            });
            this.content.decoration.loadedStyles.applyDecorationStyles(this, this.decorationStyle);
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        if (this.hasShapeFrame || this.frameType == "unframedRect") {
            if (this.hasShapeFrame && options.autoHeight) {
                size = size.square();
            }

            const contentProps = this.content.calcProps(size);
            if (options.autoWidth) {
                size.width = contentProps.size.width;
            } else if (options.autoHeight) {
                size.height = contentProps.size.height;
            }
            contentProps.bounds = new geom.Rect(size.width / 2 - contentProps.size.width / 2, size.height / 2 - contentProps.size.height / 2, contentProps.size);
        } else if (this.frameType == ShapeType.NONE) {
            if (this.preserveAspectRatioForUnframed) {
                let aspectRatio = this.content.assetElement.mediaSize ? this.content.assetElement.mediaSize.aspectRatio : 1;
                if (options.fitToHeight) {
                    if (size.height * aspectRatio > size.width) {
                        size = new geom.Size(size.width, size.width / aspectRatio);
                    } else {
                        size = new geom.Size(size.height * aspectRatio, size.height);
                    }
                } else {
                    size = new geom.Size(size.width, size.width / aspectRatio);
                }
            }

            const contentProps = this.content.calcProps(size);
            contentProps.bounds = new geom.Rect(size.width / 2 - contentProps.size.width / 2, size.height / 2 - contentProps.size.height / 2, contentProps.size);
        } else {
            // has an image frame
            const frameProps = this.frame.calcProps(size);

            if (options.autoWidth) {
                size.width = frameProps.size.width;
            } else if (options.autoHeight) {
                size.height = frameProps.size.height;
            }

            frameProps.bounds = new geom.Rect(size.width / 2 - frameProps.size.width / 2, size.height / 2 - frameProps.size.height / 2, frameProps.size);
            frameProps.layer = 1;

            const framePadding = this.content.isVideo && this.frameType.startsWith("circle") ? 0.5 : 0;
            const contentProps = this.content.calcProps(
                frameProps.clipBounds.size,
                {
                    // backgroundColor: this.frameColor,
                    // Accounting for anti-aliasing artifacts when video in circle
                    framePadding
                });
            contentProps.bounds = new geom.Rect(0, 0, contentProps.size).offset(frameProps.bounds.left, frameProps.bounds.top).offset(frameProps.clipBounds.left, frameProps.clipBounds.top);

            if (frameProps.frameClipPath) {
                contentProps.clipPath = frameProps.frameClipPath;
            }
            if (frameProps.customClip) {
                contentProps.customClip = frameProps.customClip;
            }
        }

        return { size };
    }

    _exportToSharedModel() {
        return this.content._exportToSharedModel();
    }

    _importFromSharedModel(model) {
        return this.content._importFromSharedModel(model);
    }
}

export const elements = {
    FramedMediaElement
};
