import { HorizontalAlignType } from "legacy-common/constants";
import CompareValuesCollection from "./CompareValuesCollection";
import CompareRadialBarItem from "./CompareRadialBarItem";

export default class CompareRadialBar extends CompareValuesCollection {
    getChildItemType() {
        return CompareRadialBarItem;
    }

    get maxItemCount() {
        return 12;
    }

    _calcProps(props, options) {
        let { size } = props;
        let layouter = this.getLayouter(props, this.itemElements, size);

        this.updateStyles(this.styles.vertical, true);
        layouter.calcRowLayout({ itemOptions: { orientation: "vertical" } });

        if (!layouter.isFit || !layouter.isTextFit) {
            this.itemElements.forEach(element => element.resetCalculatedProps(true));
            this.updateStyles(this.styles.horizontal, true);
            layouter.calcColumnLayout({ itemOptions: { orientation: "horizontal" } });
            layouter.alignHorizontally(HorizontalAlignType.CENTER);
        }

        layouter.alignVertically();

        return { size: layouter.size };
    }
}
